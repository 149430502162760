import { BaseHead, CellOfRow } from '@components/tables';
import { Box, Button, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import color from '@theme/Colors';
import { SetStateAction, useContext } from 'react';
import { formatDateTime, statusMap } from 'src/helpers';
import MyModal from '../hooks/MyModal';
import { PotentialContraventionContext } from '../hooks/PotentialContraventionProvider';
import { KPopup } from '../hooks/useModal';
import { PotentialContraventionLookUp } from '@EcamModel/model';
import { LoadingButton } from '@mui/lab';
import { pushSuccess, pushWarning } from '@components/toast';
type Props = KPopup & {
    popupChangePlace: {
        open: boolean;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
        handleOpen: () => void;
        handleClose: () => void;
    };
    handleCancel: () => void;
};
export default function ModalWarningDuplicatePCNs(props: Props) {
    const pcContext = useContext(PotentialContraventionContext);

    const { duplicate, isForeignPlate, selectedPc, vrn, handleChangePc } = pcContext;

    return (
        <>
            <MyModal
                {...props}
                handleClose={() => props.handleClose()}
                modalContent={
                    <Stack flexBasis={1} p={'24px'} gap={3}>
                        <Typography variant="h4" textAlign={'center'} sx={{ color: color.danger }}>
                            Warning
                        </Typography>
                        <Typography textAlign={'center'}>
                            A duplicate PCN has been created within the past 24 hours.
                        </Typography>
                        <Stack mt={3} mb={5}>
                            <TableContainer
                                component={Box}
                                sx={{ '& th': {}, maxHeight: 400, overflowY: 'auto', overflowX: 'auto' }}
                            >
                                <Table aria-label="simple table">
                                    <TableHead
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 5,
                                        }}
                                    >
                                        <TableRow>
                                            <BaseHead align="center" title="Reference" />
                                            <BaseHead align="center" title="Source" />
                                            <BaseHead align="center" title="Status" />
                                            <BaseHead align="center" title="Issued date" />
                                        </TableRow>
                                    </TableHead>
                                    {duplicate?.pcnDuplicate.map((pcn, index) => {
                                        return (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <CellOfRow
                                                        value={
                                                            <Typography component={'span'}>
                                                                {pcn.reference ?? ' '}
                                                            </Typography>
                                                        }
                                                        align="center"
                                                    />
                                                    <CellOfRow
                                                        value={<Typography component={'span'}>{pcn.source}</Typography>}
                                                        align="center"
                                                    />
                                                    <CellOfRow
                                                        value={
                                                            <Typography component={'span'}>
                                                                {statusMap[pcn.status]}
                                                            </Typography>
                                                        }
                                                        align="center"
                                                    />
                                                    <CellOfRow
                                                        value={
                                                            <Typography component={'span'}>
                                                                {formatDateTime(pcn.firstIssuedDate!)}
                                                            </Typography>
                                                        }
                                                        align="center"
                                                    />
                                                </TableRow>
                                            </TableBody>
                                        );
                                    })}
                                </Table>
                            </TableContainer>
                        </Stack>

                        <Stack direction="row" justifyContent={'space-between'}>
                            <Button
                                variant={'errorContained'}
                                onClick={() => {
                                    props.handleCancel();
                                }}
                                sx={{ minWidth: '200px' }}
                            >
                                Cancel PCN
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ minWidth: '200px' }}
                                onClick={() => {
                                    vrn === selectedPc?.Plate
                                        ? pushWarning('No changes')
                                        : pushSuccess(
                                              isForeignPlate ? 'Filled successfully' : 'Change plates successfully'
                                          );

                                    handleChangePc({
                                        Plate: vrn,
                                    });
                                    props.popupChangePlace.handleClose();
                                }}
                            >
                                Proceed
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </>
    );
}
