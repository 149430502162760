import React, { SVGProps } from 'react';
type Props = {
    isChecked: boolean;
} & SVGProps<SVGSVGElement>;

export default function RestartImageIcon(props: Props) {
    return (
        <>
            {props.isChecked ? (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12" r="11.5" stroke={props.color} />
                    <path
                        d="M16.5573 16.4194C15.6994 17.2072 14.6306 17.7283 13.4814 17.919C12.3321 18.1098 11.1522 17.9618 10.0857 17.4933C9.01917 17.0248 8.11216 16.256 7.47542 15.2807C6.83867 14.3055 6.49974 13.166 6.5 12.0014H7.49983C7.49976 13.008 7.80376 13.9912 8.37203 14.8222C8.9403 15.6532 9.74634 16.2933 10.6846 16.6587C11.6229 17.024 12.6496 17.0976 13.6304 16.8698C14.6112 16.642 15.5004 16.1233 16.1814 15.3818H14.7286C14.596 15.3818 14.4689 15.3292 14.3751 15.2355C14.2814 15.1417 14.2287 15.0146 14.2287 14.8821C14.2287 14.7495 14.2814 14.6224 14.3751 14.5287C14.4689 14.435 14.596 14.3823 14.7286 14.3823H17.0572C17.1898 14.3823 17.317 14.435 17.4107 14.5287C17.5045 14.6224 17.5572 14.7495 17.5572 14.8821V17.208C17.5572 17.3406 17.5045 17.4677 17.4107 17.5614C17.317 17.6551 17.1898 17.7078 17.0572 17.7078C16.9247 17.7078 16.7975 17.6551 16.7037 17.5614C16.61 17.4677 16.5573 17.3406 16.5573 17.208V16.4184V16.4194ZM8.81661 8.62191H10.2694C10.402 8.62191 10.5291 8.67457 10.6229 8.7683C10.7166 8.86202 10.7693 8.98914 10.7693 9.12169C10.7693 9.25424 10.7166 9.38135 10.6229 9.47508C10.5291 9.56881 10.402 9.62146 10.2694 9.62146H7.93976C7.80717 9.62146 7.68002 9.56881 7.58627 9.47508C7.49251 9.38135 7.43984 9.25424 7.43984 9.12169V6.79375C7.43984 6.6612 7.49251 6.53408 7.58627 6.44035C7.68002 6.34663 7.80717 6.29397 7.93976 6.29397C8.07235 6.29397 8.1995 6.34663 8.29325 6.44035C8.38701 6.53408 8.43968 6.6612 8.43968 6.79375V7.58339C9.29749 6.79455 10.3666 6.27261 11.5163 6.08135C12.666 5.89009 13.8466 6.0378 14.9137 6.50642C15.9808 6.97504 16.8883 7.74428 17.5252 8.72013C18.162 9.69599 18.5008 10.8362 18.5 12.0014H17.5002C17.5003 10.9946 17.1962 10.0113 16.6278 9.18019C16.0594 8.3491 15.2532 7.709 14.3147 7.34373C13.3763 6.97847 12.3494 6.90506 11.3685 7.13313C10.3876 7.36121 9.4985 7.88012 8.81761 8.62191H8.81661Z"
                        fill={props.color}
                    />
                </svg>
            ) : (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M27.3334 7.78C26.0362 5.9888 24.3325 4.53091 22.3623 3.52612C20.3921 2.52133 18.2117 1.9983 16 2C8.76404 2 2.81338 7.48667 2.07604 14.528L1.93738 15.8533L4.59071 16.132L4.72804 14.8053C4.97638 12.4584 5.9511 10.2475 7.51622 8.48115C9.08134 6.71476 11.1588 5.48096 13.4587 4.95188C15.7587 4.42281 18.1665 4.62484 20.3461 5.5298C22.5257 6.43475 24.3685 7.9975 25.6174 10H20.6667V12.6667H30V3.33334H27.3334V7.78ZM27.4094 15.868L27.2707 17.1947C27.022 19.5412 26.0472 21.7516 24.4822 23.5177C22.9172 25.2837 20.84 26.5172 18.5404 27.0463C16.2407 27.5753 13.8333 27.3734 11.6539 26.4688C9.47453 25.5642 7.63178 24.002 6.38271 22H11.3334V19.3333H2.00004V28.6667H4.66671V24.22C5.96393 26.0112 7.66762 27.4691 9.6378 28.4739C11.608 29.4787 13.7884 30.0017 16 30C23.2347 30 29.1867 24.5133 29.9227 17.472L30.0614 16.1467L27.4094 15.868Z"
                        fill="white"
                    />
                </svg>
            )}
        </>
    );
}
