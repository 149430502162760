import { BaseModel } from '@Core';
import { ZoneSM, Zones } from './Zones';
import { ServiceSM } from './ServiceSM';

export interface LocationSM extends BaseModel {
    Name: string;

    Address1: string;

    Address2: string;

    Address3: string;

    Town: string;

    County: string;

    Postcode: string;

    ExternalReference: string;

    Jurisdiction: number;

    InitialLegislation: number;

    Longitude: string;

    Latitude: string;

    Notes: string;

    LocationStatus?: LocationSMStatus;
    Zones?: ZoneSM[];

    CameraCount?: number;
    Services?: ServiceSM;

    Priority: number;
}

export interface Location extends LocationSM {
    Zones?: Zones[];
}

export enum LocationSMStatus {
    Prospective,
    Live,
    Transferred,
    Decommissioned,
    OnHold,
}
