import React from 'react';

export default function AuditorIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7843_26872)">
                <path
                    d="M14.6663 7.38527V7.99861C14.6655 9.43622 14.2 10.8351 13.3392 11.9865C12.4785 13.1379 11.2685 13.9803 9.88991 14.3879C8.5113 14.7955 7.03785 14.7465 5.68932 14.2483C4.3408 13.7501 3.18944 12.8293 2.40698 11.6233C1.62452 10.4173 1.25287 8.99065 1.34746 7.55615C1.44205 6.12165 1.99781 4.75616 2.93186 3.66332C3.86591 2.57049 5.1282 1.80886 6.53047 1.49204C7.93274 1.17521 9.39985 1.32017 10.713 1.90527M14.6663 2.66527L7.99968 9.33861L5.99968 7.33861"
                    stroke="#039855"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7843_26872">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
