import { CameraPhotoVehicles, PotentialContraventionLookUp } from '@EcamModel/model';
import NoDataIcon from '@assets/jsx-icon/NoDataIcon';
import BaseCheckbox from '@components/BaseCheckbox';
import { pcController } from '@controllers/index';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Stack,
    SxProps,
    TextField,
    TextFieldProps,
    Theme,
    Typography,
    styled,
} from '@mui/material';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import color from '@theme/Colors';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { Image as KonvaImage, Layer, Rect, Stage } from 'react-konva';
import { PCInfo } from '..';
import MyModal from '../hooks/MyModal';
import { PotentialContraventionContext } from '../hooks/PotentialContraventionProvider';
import { KPopup } from '../hooks/useModal';
import { adjustPreviewSize } from 'src/helpers';
import { appConfig } from '@configs/index';

type Props = KPopup & {
    selectedPc: PCInfo;
    popupChangePlace: {
        open: boolean;
        setOpen: Dispatch<SetStateAction<boolean>>;
        handleOpen: () => void;
        handleClose: () => void;
    };
    handleCancelDuplicateVerification: () => void;
    updatingPlate?: boolean;
    pathname: string;
};

export default function ModalChangePlate(props: Props) {
    const { selectedPc, pathname } = props;

    const pcContext = useContext(PotentialContraventionContext);

    const {
        setIsLoadingLookUp,
        setIsShowLookup,
        setIsShowForeignPlate,
        isLoadingLookUp,
        isShowLookup,
        isShowForeignPlate,
        isForeignPlate,
        setIsForeignPlate,
        vehicleLookup,
        setVehicleLookup,
        vrn,
        setVrn,
        handleInputChange,
        handleUpdateChange,
    } = pcContext;

    const handleClose = () => {
        // setVrn('');
        setIsLoadingLookUp(false);
        setIsShowLookup(false);
        setIsShowForeignPlate(false);
        props.handleClose();
    };

    const fetchVRN = async () => {
        setIsLoadingLookUp(true);
        setIsShowLookup(true);
        await pcController
            .lookUp(vrn!)
            .then((res) => {
                setVehicleLookup(res);
            })
            .catch((err) => {
                setIsShowForeignPlate(true);
            })
            .finally(() => {
                setIsLoadingLookUp(false);
            });
    };

    useEffect(() => {
        if (!props.open) return;
        setVrn(props.selectedPc.Plate);
    }, [props.selectedPc.Plate, props.open]);

    const VehicleLookupData = () => {
        return (
            <Stack
                direction={'column'}
                justifyContent={'center'}
                width={'100%'}
                sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}
                gap={1}
            >
                <Box
                    minHeight={350}
                    sx={{
                        background: `url('${vehicleLookup.BlobName ?? '/assets/images/CarNoImage.png'}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                    }}
                ></Box>
                <Stack direction={'row'} justifyContent={'space-evenly'}>
                    <Typography>Make: {vehicleLookup.Make}</Typography>
                    <Typography>Model: {vehicleLookup.Model}</Typography>
                    <Typography>Color: {vehicleLookup.Color}</Typography>
                </Stack>
            </Stack>
        );
    };

    const isForeignPlaceValid = isShowForeignPlate && !isForeignPlate;

    const ForeignPlate = () => {
        return (
            <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}
                sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}
                gap={1}
            >
                <NoDataIcon />
                <Typography>No Data</Typography>
                <Typography>The vehicle information could not be found.</Typography>
                <FormControlLabel
                    sx={{ '&.MuiFormControlLabel-root': { margin: 0 } }}
                    checked={isForeignPlate}
                    control={
                        <BaseCheckbox
                            onChange={(_, checked) => {
                                setIsForeignPlate(checked);
                            }}
                        />
                    }
                    label={
                        <Typography ml={0.5} mt={0.3} sx={{ color: color.priText }}>
                            Please tick here if your vehicle registration is a foreign license plate.
                        </Typography>
                    }
                />
            </Stack>
        );
    };

    const entryBlobName = selectedPc.EntryPhotos?.[selectedPc.EntryPhotos.length - 1]?.BlobName;
    const validationBlobName = selectedPc.ValidationPhotos?.[selectedPc.ValidationPhotos.length - 1]?.BlobName;
    const exitBlobName = selectedPc.ExitPhotos?.[selectedPc.ExitPhotos.length - 1]?.BlobName;

    const entryPhoto = selectedPc.EntryPhotos?.[selectedPc.EntryPhotos.length - 1];
    const validationPhoto = selectedPc.ValidationPhotos?.[selectedPc.ValidationPhotos.length - 1];
    const exitPhoto = selectedPc.ExitPhotos?.[selectedPc.ExitPhotos.length - 1];

    return (
        <MyModal
            {...props}
            handleClose={() => handleClose()}
            modalContent={
                <Stack flexBasis={1} p={'24px'} gap={3}>
                    <Typography variant="h4" textAlign={'center'}>
                        Change plate
                    </Typography>
                    <Typography textAlign={'center'}>
                        Please provide the license plate number that you wish to change in the box below.
                    </Typography>

                    <CustomTextField
                        value={vrn}
                        onChange={handleInputChange}
                        placeholder={'Enter Reg'}
                        autoFocus
                        disabled={false}
                        inputProps={{
                            maxLength: 20,
                        }}
                    />

                    <Stack direction={'row'} spacing={2}>
                        {entryBlobName && (
                            <Box
                                sx={{
                                    width: 273,
                                    height: 115,
                                    background: `url(${appConfig.gateway.blobURL}${entryBlobName}) no-repeat center center`,
                                    backgroundSize: 'cover',
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                }}
                            />
                        )}
                        {!entryBlobName && entryPhoto && (
                            <CropPlate key={entryPhoto.CameraPhoto?.BlobName} photo={entryPhoto} />
                        )}
                        {validationBlobName && (
                            <Box
                                sx={{
                                    width: 273,
                                    height: 115,
                                    background: `url(${appConfig.gateway.blobURL}${validationBlobName}) no-repeat center center`,
                                    backgroundSize: 'cover',
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                }}
                            />
                        )}
                        {!validationBlobName && validationPhoto && (
                            <CropPlate key={validationPhoto.CameraPhoto?.BlobName} photo={validationPhoto} />
                        )}
                        {exitBlobName && (
                            <Box
                                sx={{
                                    width: 273,
                                    height: 115,
                                    background: `url(${appConfig.gateway.blobURL}${exitBlobName}) no-repeat center center`,
                                    backgroundSize: 'cover',
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                }}
                            />
                        )}
                        {!exitBlobName && exitPhoto && (
                            <CropPlate key={exitPhoto.CameraPhoto?.BlobName} photo={exitPhoto} />
                        )}
                    </Stack>

                    {isShowLookup && (
                        <Stack sx={{ border: `1px solid ${color.grey300}`, borderRadius: '4px', p: 2 }}>
                            {isLoadingLookUp ? (
                                <Box sx={{ display: 'flex' }} justifyContent={'center'}>
                                    <CircularProgress />
                                </Box>
                            ) : isShowForeignPlate ? (
                                <ForeignPlate />
                            ) : (
                                <VehicleLookupData />
                            )}
                        </Stack>
                    )}

                    <Stack direction="row" justifyContent={'space-between'}>
                        <Button variant={'cancel'} onClick={() => handleClose()} sx={{ minWidth: '200px' }}>
                            Cancel
                        </Button>
                        {!isShowLookup ? (
                            <Button
                                variant={'contained'}
                                disabled={!vrn}
                                onClick={() => {
                                    fetchVRN();
                                }}
                                sx={{ minWidth: '200px' }}
                            >
                                Lookup
                            </Button>
                        ) : (
                            <LoadingButton
                                sx={{
                                    minWidth: '200px',
                                    justifyContent: props.updatingPlate ? 'space-between' : 'center',
                                }}
                                loading={props.updatingPlate}
                                loadingPosition="center"
                                variant="contained"
                                disabled={isLoadingLookUp}
                                startIcon={props.updatingPlate ? <></> : undefined}
                                onClick={() => {
                                    handleUpdateChange();
                                }}
                            >
                                Update change
                            </LoadingButton>
                        )}
                    </Stack>
                </Stack>
            }
        />
    );
}
interface CustomTextFieldProps {
    style?: SxProps<Theme>;
}

export const CustomTextField = styled(TextField)<CustomTextFieldProps>(({ style, error }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#FFFF00',
    },

    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: error ? `1px solid ${color.error}` : '1px solid #000000',
    },

    '& .MuiInputBase-input': {
        textAlign: 'center',
        letterSpacing: '4px',
        fontSize: style?.fontSize ? style?.fontSize : '32px',
        fontWeight: 500,
        fontFamily: 'Roboto',
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: error ? `1px solid ${color.error}` : '1px solid #000000',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: error ? `1px solid ${color.error}` : '1px solid #000000',
    },
}));

type CropPlateProps = {
    photo: CameraPhotoVehicles;
};

const CropPlate = (props: CropPlateProps) => {
    const blob = props.photo.CameraPhoto?.BlobName;
    const { image, loading, isError } = useImageDimensions(blob);
    console.log(`image:`, image?.width, image?.height);

    function calculateRectangleDimensions(x1, y1, x2, y2, x3, y3, x4, y4) {
        const width = Math.abs(x2 - x1);
        const height = Math.abs(y3 - y1);

        return { width, height };
    }

    const { X1, X2, X3, X4, Y1, Y2, Y3, Y4 } = props.photo;

    const plate = calculateRectangleDimensions(X1, Y1, X2, Y2, X3, Y3, X4, Y4);
    const containerWidth = 273;
    const height = 115;
    const { previewWidth, previewHeight } = adjustPreviewSize(plate.width, plate.height, containerWidth, height);

    return loading ? (
        <Stack sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: containerWidth, height: height }}>
            <CircularProgress size={20} sx={{ margin: 'auto' }} />
        </Stack>
    ) : (
        <Stage width={containerWidth} height={height}>
            <Layer>
                <Rect width={containerWidth} height={height} fill="white" />
                <KonvaImage
                    x={(containerWidth! - previewWidth) / 2}
                    y={(height! - previewHeight) / 2}
                    width={previewWidth}
                    height={previewHeight}
                    image={image}
                    crop={{
                        x: X1,
                        y: Y1,
                        width: plate.width,
                        height: plate.height,
                    }}
                />
            </Layer>
        </Stage>
    );
};
