import { PermitData } from '@EcamModel/model';
import { Divider, Stack, Typography } from '@mui/material';
import color from '@theme/Colors';
import { OrangeStack } from './PCDetail';

type Props = {
    permitData: PermitData;
};

export default function PermitDuplicate({ permitData }: Props) {
    return permitData?.hasPermit ? (
        <OrangeStack mt={2} direction={'column'} gap={1}>
            <Typography>Permit exists for this VRN with in this location at the time of issue</Typography>
            <Stack
                direction="row"
                divider={
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderRightWidth: 1, borderColor: color.textPrimary, py: '3px' }}
                    />
                }
                spacing={2}
            >
                <Typography>Bay number: {permitData.permitInfo.bayNumber ?? ''}</Typography>
                <Typography>Source: {permitData.permitInfo.source}</Typography>
                <Typography>Tenant: {permitData.permitInfo.tenant}</Typography>
            </Stack>
        </OrangeStack>
    ) : (
        <></>
    );
}
