import { Stack, CircularProgress } from '@mui/material';
import React from 'react';

export default function BaseLoading({ displayHeight }: { displayHeight: React.CSSProperties['minHeight'] }) {
    return (
        <Stack direction="row" width="100%" justifyContent="center" alignItems="center" minHeight={displayHeight}>
            <CircularProgress />
        </Stack>
    );
}
