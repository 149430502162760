import { Solar4gTimeInfo, TimeInfoDaylightSavingMode } from '@EcamModel/model';
import PopUpBase from '@components/PopUpBase';
import { pushError, pushSuccess } from '@components/toast';
import { IPopUp } from '@hooks/usePopUp';
import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import color from '@theme/Colors';
import moment from 'moment';
import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useBackdrop } from 'src/providers/BackdropProvider';
import timezoneJson from '../utils/timezoneSystemTime.json';

interface TimeZone {
    value: string;
    label: string;
}

type Props = Omit<IPopUp, 'onConfirm'> & {
    systemTime: Solar4gTimeInfo;
    onSave: (data: Partial<Solar4gTimeInfo>) => Promise<void>;
};

export default function PopUpSetSystemTime(props: Props) {
    const { open, onClose, systemTime, onSave } = props;
    const backdrop = useBackdrop();

    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm<Partial<Solar4gTimeInfo>>({});
    const disabled = !(isValid && isDirty);

    const defaultValues = {
        DaylightSaving: systemTime?.DaylightSaving,
        SyncMode: systemTime?.SyncMode,
        TimeManual: systemTime?.TimeManual,
    };

    useEffect(() => {
        reset({
            DaylightSaving: systemTime?.DaylightSaving,
            SyncMode: systemTime?.SyncMode,
            TimeManual: Boolean(systemTime?.TimeManual) ? (moment(systemTime?.TimeManual) as any) : (moment() as any),
        });
    }, [open, systemTime]);
    return (
        <PopUpBase
            open={open}
            title={<Typography variant="h4">Set the system time</Typography>}
            desc={
                <Box minWidth={550} bgcolor={color.grey100} paddingX={2} py={1.5}>
                    <Stack direction={'column'} spacing={1.5}>
                        <FormLayout
                            title="Time Zone:"
                            body={
                                <Autocomplete
                                    // {...field}
                                    id="grouped-demo"
                                    // value={field.value || null}
                                    options={transformedData}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            placeholder="Select time zone"
                                        />
                                    )}
                                    onChange={(e, val) => {
                                        // field.onChange(val);
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            padding: '9.95px 14px !important',
                                        },
                                        '& .MuiButtonBase-root': {
                                            marginRight: '5px !important',
                                        },
                                        '& .MuiAutocomplete-inputRoot': {
                                            '& 	.MuiAutocomplete-input': {
                                                padding: '0px 0px',
                                            },
                                        },
                                    }}
                                    clearIcon
                                />
                                // <Controller
                                //     control={control}
                                //     name="TimeManual"
                                //     render={({ field }) => (
                                //     )}
                                // />
                            }
                        />

                        <FormLayout
                            title="Daylight saving time:"
                            body={
                                <Controller
                                    control={control}
                                    name="DaylightSaving"
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={[0, 1]}
                                            {...field}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    padding: '9.95px 14px !important',
                                                },
                                                '& .MuiButtonBase-root': {
                                                    marginRight: '5px !important',
                                                },
                                                '& .MuiAutocomplete-inputRoot': {
                                                    '& 	.MuiAutocomplete-input': {
                                                        padding: '0px 0px',
                                                    },
                                                },
                                            }}
                                            value={field.value}
                                            onChange={(_, newValue) => {
                                                field.onChange(newValue);
                                                if (newValue == null) {
                                                    setValue('DaylightSaving', systemTime.DaylightSaving);
                                                }
                                            }}
                                            getOptionLabel={(options) => {
                                                const daylightSaving = {
                                                    [TimeInfoDaylightSavingMode.Disable]: 'Disable',
                                                    [TimeInfoDaylightSavingMode.Automatic]: 'Automatic',
                                                };

                                                return daylightSaving[options];
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Select Daylight saving time" />
                                            )}
                                            clearIcon
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="Synchronize:"
                            body={
                                <Controller
                                    control={control}
                                    name="SyncMode"
                                    render={({ field }) => (
                                        <>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            value={0}
                                                            checked={field?.value?.toString() === '0'}
                                                            onChange={(_, checked) => {
                                                                field.onChange(Number(_.target.value));
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography ml={0.5} mt={0.3}>
                                                            Auto
                                                        </Typography>
                                                    }
                                                    sx={{ ml: 0 }}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            value={1}
                                                            checked={field?.value?.toString() === '1'}
                                                            onChange={(_, checked) => {
                                                                field.onChange(Number(_.target.value));
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography ml={0.5} mt={0.3}>
                                                            Manual
                                                        </Typography>
                                                    }
                                                    sx={{ ml: 0 }}
                                                />
                                            </RadioGroup>
                                        </>
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="Schedule:"
                            body={
                                <Controller
                                    control={control}
                                    name="TimeManual"
                                    render={({ field }) => (
                                        <DateTimePicker
                                            {...field}
                                            value={field.value}
                                            sx={{ width: '100%' }}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock,
                                            }}
                                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                            ampm={false}
                                            minutesStep={1}
                                            format="YYYY/MM/DD HH:mm:ss"
                                            // minDateTime={moment() as any}
                                        />
                                    )}
                                />
                            }
                        />
                    </Stack>
                </Box>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            onClose?.();
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        sx={{ minWidth: 130 }}
                        variant={'contained'}
                        disabled={disabled}
                        onClick={handleSubmit((data) => {
                            backdrop.setTrue();

                            data.TimeManual = moment(data.TimeManual).format('YYYY-MM-DD HH:mm:ss')?.toString();
                            const _systemTime: Partial<Solar4gTimeInfo> = {} as Partial<Solar4gTimeInfo>;
                            for (const key in data) {
                                if (data[key] !== defaultValues[key]) {
                                    _systemTime[key] = data[key];
                                }
                            }
                            console.log(_systemTime);
                            onSave(_systemTime)
                                .then(() => {
                                    pushSuccess('Saved successfully');
                                })
                                .catch((res) => {
                                    pushError(res);
                                })
                                .finally(() => {
                                    onClose?.();
                                    backdrop.setFalse();
                                });
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
            minWidthButton={150}
        />
    );
}

const transformedData: TimeZone[] = timezoneJson.flatMap((item) => {
    const _transformedData: TimeZone[] = [];
    _transformedData.push({ label: item.label, value: item.value });
    return _transformedData;
});

function FormLayout(props: { title: string; body: ReactNode }) {
    const { title, body } = props;

    return (
        <Grid container xs={12} alignItems={'center'}>
            <Grid xs={3}>
                <Typography sx={{ wordBreak: 'wordBreak' }}>{title}</Typography>
            </Grid>
            <Grid xs={9}>{body}</Grid>
        </Grid>
    );
}
