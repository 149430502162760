import { Button, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import color from '@theme/Colors';
import { ReactNode } from 'react';

export default function DetailInfoSection(props: {
    icon: JSX.Element;
    title: string;
    content: ReactNode;
    action?: ReactNode;
}) {
    return (
        <Stack direction={'column'} width={'100%'}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={1}
                sx={{
                    background: color.grey100,
                    borderBottom: `1px solid #DDDDDD`,
                    borderRadius: '4px 4px 0px 0px',
                    padding: '12px 16px',
                    position: 'relative',
                }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {props.icon}
                    <Typography variant="h5" mt={'2px'}>
                        {props.title}
                    </Typography>
                </Stack>
                {props.action}
            </Stack>
            {props.content}
        </Stack>
    );
}

export const DetailInfoItem = (props: { leftContent: string; rightContent: string | undefined }) => {
    return (
        <Grid2 container display={'flex'} borderBottom={'1px solid #EEEEEE'}>
            <Grid2 xs={5} padding={'8px 16px'}>
                <Typography mt={'2px'}>{props.leftContent}</Typography>
            </Grid2>
            <Grid2 xs={7} padding={'8px 16px'}>
                <Typography mt={'2px'}>{props.rightContent ?? ''}</Typography>
            </Grid2>
        </Grid2>
    );
};
