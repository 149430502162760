import { PhotoType } from '@EcamModel/model';
import { MINIMUM_SHAPE_SIZE } from '@components/FrameBlur';
import { potentialPhotoContraventionController } from '@controllers/index';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import useImage from 'use-image';
import { PCInfo } from '..';
import { usePotentialContravention } from './PotentialContraventionProvider';
import { Shape } from '@components/frame/SelectRectangle';
import { v4 as uuidv4 } from 'uuid';
import { IPopUp } from '@hooks/usePopUp';
import { PhotoItem } from '../components/EvidencePhotoGroup';
import { IUpdatePotentialContraventionPhoto } from '@EcamModel/controllers/IPotentialContraventionPhotoHttpController';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { appConfig } from '@configs/index';
import { pushSuccess } from '@components/toast';

export type CropArea = {
    x: number;
    y: number;
    width: number;
    height: number;
};
export enum Mode {
    Default,
    Crop,
    Blur,
}

export enum AnchorPoint {
    TopLeft = 'topLeft',
    TopRight = 'topRight',
    BottomLeft = 'bottomLeft',
    BottomRight = 'bottomRight',
}
type Props = IPopUp & {
    selectedItem: PhotoItem;
};

export default function useEditImage(props: Props) {
    // const imageUrl = 'http://192.168.110.68:7003/' + props.selectedItem?.blob;
    const imageUrl = appConfig.gateway.blobURL + props.selectedItem?.blob;

    const { groupPc, setGroupPc } = usePotentialContravention();
    const backdrop = useBackdrop();

    const [image] = useImage(imageUrl, 'anonymous');
    const displayWidth = 1152;
    const displayHeight = displayWidth * 0.5625;
    const initialCropArea: CropArea = {
        x: (displayWidth - 400) / 2,
        y: (displayHeight - (400 / 16) * 9) / 2,
        width: 400,
        height: (400 / 16) * 9,
    };
    const MIN_WIDTH = 200;
    const MIN_HEIGHT = (200 / 16) * 9;

    const stageRef = useRef<Konva.Stage>(null);
    const cropAreaRef = useRef<Konva.Rect>(null);

    const [mode, setMode] = useState<Mode>(Mode.Crop);

    const [photos, setPhotos] = useState<(HTMLImageElement | undefined)[]>([]);

    const [shapes, setShapes] = useState<Shape[]>([]);
    const [selectedShapeId, setSelectedShapeId] = useState<string | null>(null);
    const [draggingShape, setDraggingShape] = useState<string | null>(null);

    const [cropArea, setCropArea] = useState<CropArea | null>(initialCropArea);

    const currentImage = photos[photos.length - 1] || image;

    const hasCoordinatesCrop = cropArea?.width && cropArea?.height;

    const getPhotoField = (type: PhotoType) => {
        let key: keyof PCInfo = 'EntryPhotos';
        switch (type) {
            case PhotoType.Exit:
                key = 'ExitPhotos';
                break;
            case PhotoType.Validation:
                key = 'ValidationPhotos';
                break;
            default:
                return key;
        }
        return key;
    };

    const handleCreateShape = () => {
        const pointerPosition = stageRef.current?.getPointerPosition();
        if (!pointerPosition) return null;

        const _shapes = shapes.slice();
        const id = uuidv4();
        _shapes.push({
            id,
            x: pointerPosition.x,
            y: pointerPosition.y,
            width: 0,
            height: 0,
        });
        setShapes(_shapes);
        return id;
    };

    const setSelectedShape = (id: string | null) => {
        setSelectedShapeId(id);

        if (!id) return;

        const _shapes = shapes.slice();
        const index = _shapes.findIndex((shape) => shape.id === id);
        if (index > -1) {
            const shape = _shapes[index];
            _shapes.splice(index, 1);
            _shapes.push(shape);
            setShapes(_shapes);
        }
    };

    const handleRemoveShape = (id: string) => {
        const _shapes = shapes.slice();
        const index = _shapes.findIndex((shape) => shape.id === id);
        if (index > -1) {
            _shapes.splice(index, 1);
            setShapes(_shapes);
        }
    };

    const handleDragAnchor = (e: Konva.KonvaEventObject<DragEvent>, anchor: AnchorPoint) => {
        if (!cropArea) return;

        const { x, y } = e.target.position();

        let newCropArea: CropArea = { ...cropArea };
        let newWidth = cropArea.width;
        let newHeight = cropArea.height;
        let newX = cropArea.x;

        switch (anchor) {
            case AnchorPoint.TopLeft:
                newWidth = cropArea.width + (cropArea.x - x);
                newHeight = (newWidth / 16) * 9;

                newWidth = Math.max(MIN_WIDTH, newWidth);
                newHeight = Math.max(MIN_HEIGHT, newHeight);

                newX = cropArea.x + cropArea.width - newWidth;

                newCropArea.x = newX;
                newCropArea.y = cropArea.y + cropArea.height - newHeight;
                newCropArea.width = newWidth;
                newCropArea.height = newHeight;
                break;

            case AnchorPoint.TopRight:
                newWidth = x - cropArea.x;
                newHeight = (newWidth / 16) * 9;

                newWidth = Math.max(MIN_WIDTH, newWidth);
                newHeight = Math.max(MIN_HEIGHT, newHeight);

                newCropArea.y = cropArea.y + cropArea.height - newHeight;
                newCropArea.width = newWidth;
                newCropArea.height = newHeight;
                break;

            case AnchorPoint.BottomLeft:
                newWidth = cropArea.width + (cropArea.x - x);
                newHeight = (newWidth / 16) * 9;

                newWidth = Math.max(MIN_WIDTH, newWidth);
                newHeight = Math.max(MIN_HEIGHT, newHeight);

                newX = cropArea.x + cropArea.width - newWidth;

                newCropArea.x = newX;
                newCropArea.width = newWidth;
                newCropArea.height = newHeight;
                break;

            case AnchorPoint.BottomRight:
                newWidth = x - cropArea.x;
                newHeight = (newWidth / 16) * 9;
                newWidth = Math.max(MIN_WIDTH, newWidth);
                newHeight = Math.max(MIN_HEIGHT, newHeight);

                newCropArea.width = newWidth;
                newCropArea.height = newHeight;
                break;

            default:
                break;
        }

        if (
            newCropArea.x <= cropArea.x ||
            newCropArea.y <= cropArea.y ||
            newCropArea.x + newCropArea.width >= cropArea.x + cropArea.width ||
            newCropArea.y + newCropArea.height >= cropArea.y + cropArea.height
        ) {
            e.target.position({
                x:
                    anchor === AnchorPoint.TopLeft || anchor === AnchorPoint.BottomLeft
                        ? cropArea.x
                        : cropArea.x + cropArea.width,
                y:
                    anchor === AnchorPoint.TopLeft || anchor === AnchorPoint.TopRight
                        ? cropArea.y
                        : cropArea.y + cropArea.height,
            });
        }
        setCropArea(newCropArea);
    };

    const handleDragCropArea = (e: Konva.KonvaEventObject<DragEvent>) => {
        if (!cropArea || !stageRef.current) return;
        const { x, y } = e.target.position();

        const stageWidth = stageRef.current.width();
        const stageHeight = stageRef.current.height();

        let newCropArea: CropArea = { ...cropArea };
        newCropArea.x = Math.max(0, Math.min(x, stageWidth - newCropArea.width));
        newCropArea.y = Math.max(0, Math.min(y, stageHeight - newCropArea.height));

        if (newCropArea.width < 0) {
            newCropArea.x += newCropArea.width;
            newCropArea.width = Math.abs(newCropArea.width);
        }
        if (newCropArea.height < 0) {
            newCropArea.y += newCropArea.height;
            newCropArea.height = Math.abs(newCropArea.height);
        }

        setCropArea(newCropArea);
    };

    const handleRectDragEnd = () => {
        if (!cropArea) return;
        const { x, y } = cropArea;
        setCropArea({ ...cropArea, x, y });
        const newCropArea = { ...cropArea, x, y };
        cropAreaRef.current?.position(newCropArea);
    };

    const handleAnchorMouseEnter = (isFrame: boolean) => {
        document.body.style.cursor = isFrame ? 'move' : 'pointer';
    };

    const handleAnchorMouseLeave = () => {
        document.body.style.cursor = 'default';
    };

    const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        if (mode === Mode.Blur) {
            const clickedOnEmpty = e.target.index === 0;
            if (!clickedOnEmpty) return;

            setSelectedShape(null);
            const id = handleCreateShape();
            setDraggingShape(id);
        }
    };

    const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (mode === Mode.Blur) {
            if (!draggingShape) return;

            const pointerPosition = stageRef.current?.getPointerPosition();

            if (!pointerPosition) return;

            const _shapes = shapes.slice();
            const shape = _shapes[_shapes.length - 1];
            shape.width = pointerPosition.x - shape.x;
            shape.height = pointerPosition.y - shape.y;
            setShapes(_shapes);
        }
    };

    const handleMouseUp = (e: KonvaEventObject<MouseEvent>) => {
        if (mode === Mode.Blur) {
            if (!draggingShape) return;

            const _shapes = shapes.slice();
            const shape = _shapes[_shapes.length - 1];
            if (Math.abs(shape.width) < MINIMUM_SHAPE_SIZE && Math.abs(shape.height) < MINIMUM_SHAPE_SIZE) {
                _shapes.pop();
                setShapes(_shapes);
            } else {
                setSelectedShape(draggingShape);
            }

            setDraggingShape(null);
        }
    };

    const handleCropImage = () => {
        if (!currentImage) return;

        if (cropArea && hasCoordinatesCrop) {
            const imageWidth = currentImage.width;
            const imageHeight = currentImage.height;

            const cropX = (cropArea.x / displayWidth) * imageWidth;
            const cropY = (cropArea.y / displayHeight) * imageHeight;
            const cropWidth = (cropArea.width / displayWidth) * imageWidth;
            const cropHeight = (cropArea.height / displayHeight) * imageHeight;

            const scaleX = imageWidth / cropWidth;
            const scaleY = imageHeight / cropHeight;
            const _shapes = shapes.slice();
            for (let i = 0; i < _shapes.length; i++) {
                _shapes[i].x = (_shapes[i].x - cropArea.x) * scaleX;
                _shapes[i].y = (_shapes[i].y - cropArea.y) * scaleY;
                _shapes[i].width *= scaleX;
                _shapes[i].height *= scaleY;
            }
            setShapes(_shapes);

            const croppedCanvas = document.createElement('canvas');
            croppedCanvas.width = cropWidth;
            croppedCanvas.height = cropHeight;

            const ctx = croppedCanvas.getContext('2d')!;
            ctx.drawImage(currentImage, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

            const croppedImage = new window.Image();
            croppedImage.src = croppedCanvas.toDataURL();

            croppedImage.onload = () => {
                setPhotos((prev) => [...prev, croppedImage]);
                setCropArea(initialCropArea);
            };
        }
    };

    const handleResetImage = () => {
        setMode(Mode.Crop);
        setPhotos([]);
        setShapes([]);
        setCropArea(initialCropArea);
    };

    const handleSaveImage = () => {
        if (!stageRef.current || !image) return;
        const stage = stageRef.current;

        setMode(Mode.Default);

        const cropLayer = stage.findOne('.crop');
        const selectedLayer = stage.findOne('.selected-blur');
        cropLayer.hide();
        selectedLayer.hide();

        const displayCanvas = stage.toCanvas();

        const originalWidth = image?.width;
        const originalHeight = image?.height;

        const originalCanvas = document.createElement('canvas');
        originalCanvas.width = originalWidth;
        originalCanvas.height = originalHeight;
        const originalContext = originalCanvas.getContext('2d')!;

        originalContext.drawImage(
            displayCanvas,
            0,
            0,
            displayWidth,
            displayHeight,
            0,
            0,
            originalWidth,
            originalHeight
        );

        const base64Str = originalCanvas.toDataURL('image/jpeg');

        const photoOptions: IUpdatePotentialContraventionPhoto = {
            photoVehicleId: props.selectedItem.photoVehicleId,
            imageBase64: base64Str,
            photoType: props.selectedItem.photoType,
        };
        backdrop.setTrue();
        potentialPhotoContraventionController
            .updatePhoto(photoOptions)
            .then((res) => {
                const photoVehicle = groupPc?.PotentialContraventions?.find(
                    (p) => p.Id === res.PotentialContraventionId
                ) as PCInfo;
                const field = getPhotoField(res.PhotoType);

                const updatedCameraPhotoVehicle = {
                    ...res.CameraPhotoVehicle!,
                    BlobName: res.VehicleBlobName,
                };

                const _pcInfo: PCInfo = {
                    ...photoVehicle,
                    [field]: [photoVehicle[field]![0]].concat(updatedCameraPhotoVehicle),
                };

                setGroupPc((prev) => {
                    const clonePrev = cloneDeep(prev);
                    const itemIndex = clonePrev?.PotentialContraventions?.findIndex((p) => p.Id === _pcInfo.Id);
                    if (clonePrev?.PotentialContraventions && itemIndex !== -1) {
                        clonePrev!.PotentialContraventions[itemIndex!] = _pcInfo;
                    }
                    return clonePrev;
                });
                pushSuccess('Saved successfully');
            })
            .finally(() => {
                props.onClose?.();
                backdrop.setFalse();
            });
    };

    useEffect(() => {
        const handleDeleteKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Delete' && selectedShapeId) {
                handleRemoveShape(selectedShapeId);
            }
        };
        document.addEventListener('keydown', handleDeleteKeyPress);

        return () => {
            document.removeEventListener('keydown', handleDeleteKeyPress);
        };
    }, [selectedShapeId]);

    return {
        stageRef,
        cropAreaRef,
        displayWidth,
        displayHeight,
        image,
        currentImage,
        mode,
        setMode,
        initialCropArea,
        cropArea,
        setCropArea,
        photos,
        setPhotos,
        shapes,
        setShapes,
        selectedShapeId,
        MIN_WIDTH,
        MIN_HEIGHT,
        setSelectedShape,
        handleDragAnchor,
        handleDragCropArea,
        handleRectDragEnd,
        handleAnchorMouseEnter,
        handleAnchorMouseLeave,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handleCropImage,
        handleResetImage,
        handleSaveImage,
    };
}
