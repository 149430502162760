import React, { SVGProps } from 'react';
type Props = {
    isChecked: boolean;
} & SVGProps<SVGSVGElement>;

export default function ZoomImageIcon(props: Props) {
    return (
        <>
            {props.isChecked ? (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12" r="11.5" stroke="white" />
                    <path
                        d="M18.5 12C18.5 12.1016 18.4597 12.199 18.3878 12.2708C18.316 12.3426 18.2186 12.383 18.117 12.383H12.883V17.617C12.883 17.7186 12.8426 17.816 12.7708 17.8878C12.699 17.9597 12.6016 18 12.5 18C12.3984 18 12.301 17.9597 12.2292 17.8878C12.1574 17.816 12.117 17.7186 12.117 17.617V12.383H6.88298C6.78141 12.383 6.68399 12.3426 6.61217 12.2708C6.54035 12.199 6.5 12.1016 6.5 12C6.5 11.8984 6.54035 11.801 6.61217 11.7292C6.68399 11.6574 6.78141 11.617 6.88298 11.617H12.117V6.38298C12.117 6.28141 12.1574 6.18399 12.2292 6.11217C12.301 6.04035 12.3984 6 12.5 6C12.6016 6 12.699 6.04035 12.7708 6.11217C12.8426 6.18399 12.883 6.28141 12.883 6.38298V11.617H18.117C18.2186 11.617 18.316 11.6574 18.3878 11.7292C18.4597 11.801 18.5 11.8984 18.5 12Z"
                        fill="white"
                    />
                </svg>
            ) : (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    strokeWidth={0.5}
                    stroke="white"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16 0C7.1635 0 0 7.1635 0 16C0 24.837 7.1635 32 16 32C24.837 32 32 24.837 32 16C32 7.1635 24.837 0 16 0ZM16 30.0315C8.2805 30.0315 2 23.7195 2 15.9999C2 8.28044 8.2805 1.99994 16 1.99994C23.7195 1.99994 30 8.28047 30 15.9999C30 23.7194 23.7195 30.0315 16 30.0315ZM23 15H17V9C17 8.448 16.552 8 16 8C15.448 8 15 8.448 15 9V15H9C8.448 15 8 15.448 8 16C8 16.552 8.448 17 9 17H15V23C15 23.552 15.448 24 16 24C16.552 24 17 23.552 17 23V17H23C23.552 17 24 16.552 24 16C24 15.448 23.552 15 23 15Z"
                        fill="white"
                    />
                </svg>
            )}
        </>
    );
}
