import CameraOverviewScreen from '@pages/overview/CameraOverviewScreen';
import ReportBI from '@pages/report';
import { lazy } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import AuthLayout from './layout/AuthLayout';
import InsightHeatMap from '@pages/report/InsightHeatMap';
import CropIMG from '@pages/verification/pc-details/components/CropIMG';

const HomeScreen = lazy(() => import('@pages/HomeScreen'));
const CameraUpsert = lazy(() => import('@pages/cameras/add-new'));
const DetailsCamera = lazy(() => import('@pages/cameras/details'));
const Cameras = lazy(() => import('@pages/cameras/list'));
const Locations = lazy(() => import('@pages/locations'));
const LivePhotosPage = lazy(() => import('@pages/cameras/live-photos'));
const Logout = lazy(() => import('@pages/logout'));
const VerificationsOverviewScreen = lazy(() => import('@pages/overview/VerificationsOverviewScreen'));
const ListPCByZone = lazy(() => import('@pages/verification/list-pc-by-zone'));
const WrapPCDetail = lazy(() => import('@pages/verification/pc-details'));
const VerificationsByZone = lazy(() => import('@pages/verification/zone-detail'));
const AddGeoZones = lazy(() => import('@pages/cameras/details/components/AddGeoZones'));
const EditGeoZones = lazy(() => import('@pages/cameras/details/components/EditGeoZones'));
const ReportsAnalyzing = lazy(() => import('@pages/overview'));
const PageNotFound = lazy(() => import('@components/page-not-found/index'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthLayout />,

        children: [
            {
                index: true,
                element: <Navigate to={'locations'} />,
            },
            {
                path: 'home',
                element: <HomeScreen />,
            },
            {
                path: 'locations/:locationId/cameras',
                element: <Cameras />,
            },
            {
                path: 'locations/:locationId/cameras/add',
                element: <CameraUpsert />,
            },
            {
                path: 'locations/cameras/add',
                element: <CameraUpsert />,
            },
            {
                path: 'locations/:locationId/cameras/:id',
                element: <DetailsCamera />,
            },
            {
                path: 'locations/:locationId/cameras/:id/live-photos',
                element: <LivePhotosPage />,
            },
            {
                path: 'locations/:locationId/cameras/:id/add-geo-zones',
                element: <AddGeoZones />,
            },
            {
                path: 'locations/:locationId/cameras/:id/edit-geo-zones/:geoZoneId',
                element: <EditGeoZones />,
            },
            {
                path: 'verification',
                element: <ListPCByZone />,
            },
            {
                path: 'verification/:zoneId',
                element: <VerificationsByZone />,
            },
            {
                path: 'verification/:zoneId/:groupPcId',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'verification/contravention/:contraventionId',
                element: <WrapPCDetail />,
            },
            {
                path: 'verification/:zoneId/step/:step',
                element: <WrapPCDetail />,
            },
            {
                path: 'overview-verification',
                element: <VerificationsOverviewScreen />,
            },
            {
                path: 'camera-overview',
                element: <CameraOverviewScreen />,
            },
            // {
            //     path: 'cameraTime',
            //     element: <CameraTimeScreen />,
            // },
            {
                path: 'insights',
                element: <ReportBI />,
            },
            {
                path: 'bubble-heat-map',
                element: <InsightHeatMap />,
            },
            {
                path: 'reports-analysing',
                element: <ReportsAnalyzing />,
            },
            {
                path: 'overview-verification/:groupPcId',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'overview-verification/:groupPcId/audit',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'verification/:zoneId/:groupPcId/audit',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'locations',
                element: <Locations />,
            },

            // {
            //     path: 'ecam',
            //     element:
            // <FrameCrop /> ,
            // },
            {
                path: 'logout',
                element: <Logout />,
            },
            // {
            //     path: 'dashboard',
            //     element: <Dashboard /> ,
            // },

            // {
            //     path: 'crop',
            //     element: <CropIMG />,
            // },

            // {
            // 	path: "*",
            // 	action: () => redirect("/ecam"),
            // },

            {
                path: '/page-not-found',
                element: <PageNotFound />,
            },

            {
                path: '*',
                element: <Navigate to={'/page-not-found'} />,
            },
        ],
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
