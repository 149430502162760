import IcEdit from '@assets/details-camera-icons/IcEdit';
import { CameraPhotoVehicles, PhotoType, PotentialContraventionWithInfo } from '@EcamModel/model';
import { Box, CircularProgress, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import { Image as KonvaImage, Layer, Rect, Stage } from 'react-konva';
import { useParams } from 'react-router-dom';
import TimeOnPicture from './TimeOnPicture';
import { VerificationAudit } from '@EcamModel/model/VerificationAudit';
import { DrawerContext } from '@components/drawer/useDrawer';
import { useContext, useEffect, useState } from 'react';
import { adjustPreviewSize } from 'src/helpers';
import { usePotentialContravention } from '../hooks/PotentialContraventionProvider';
import { appConfig } from '@configs/index';
import { mappingPhotosType } from './PopUpPlateEditing';

export type PhotoProps = {
    title?: string;
    date: Date;
    blob?: string;
    rootBlob?: string;
    sxBox?: SxProps<Theme>;
    sxBottom?: SxProps<Theme>;
    plate?: string;
    timeZone?: string;
    VerificationAudit?: VerificationAudit;
    handleClick?(url: string): void;
    handleEditImg?(): void;
    handlePlateEdit?(): void;
    photo: CameraPhotoVehicles;
    readonly?: boolean;
    plateRootImage?: string;
};

export default function EvidencePhotoItem({
    title,
    date,
    sxBottom,
    blob,
    rootBlob,
    plate: vehiclePlate,
    sxBox,
    timeZone,
    ...props
}: PhotoProps) {
    const { groupPcId } = useParams();

    const { image, loading, isError } = useImageDimensions(blob);
    const { image: rootImage, loading: rootLoading, isError: rootError } = useImageDimensions(rootBlob);

    const { open } = useContext(DrawerContext);

    const calculateRectangleDimensions = (
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        x3: number,
        y3: number,
        x4: number,
        y4: number
    ) => {
        const width = Math.abs(x2 - x1);
        const height = Math.abs(y3 - y1);
        return { width, height };
    };

    const { X1, X2, X3, X4, Y1, Y2, Y3, Y4 } = props.photo;
    // const plate = calculateRectangleDimensions(X1 + 20, Y1 + 20, X2 + 20, Y2, X3, Y3 + 20, X4, Y4);
    const plate = calculateRectangleDimensions(X1, Y1, X2, Y2, X3, Y3, X4, Y4);

    const width = groupPcId !== undefined ? (open ? 310 : 324) : props.readonly ? (1060 - 32) / 3 : open ? 322 : 336;

    const height = width * 0.5625;

    const increaseWidth = plate.width * 0.15;

    const { previewWidth, previewHeight } = adjustPreviewSize(plate.width, plate.height, width, height);

    return (
        <Stack sx={{ width: '100%' }} direction={'column'}>
            <Stack direction={'column'} spacing={1}>
                <Stack direction={'column'}>
                    {title ? (
                        <Stack
                            width={'100%'}
                            direction={'row'}
                            justifyContent={'center'}
                            alignItems="center"
                            sx={{
                                background: '#000',
                                p: '8px 16px',
                                textAlign: 'center',
                                color: 'white',
                            }}
                            spacing={0.5}
                        >
                            <Typography color="#fff">{title}</Typography>
                            {(!props.readonly || !props.VerificationAudit) && (
                                <Stack
                                    onClick={() => {
                                        props.handleEditImg?.();
                                    }}
                                    sx={{
                                        '&:hover': {
                                            color: (theme) => theme.palette.primary.main,
                                            cursor: 'pointer',
                                            transition: '.2s ease-in-out',
                                        },
                                    }}
                                >
                                    <IcEdit />
                                </Stack>
                            )}
                        </Stack>
                    ) : (
                        <Box></Box>
                    )}

                    <Box
                        width={open ? width + 20.67 : width + 18.67}
                        minHeight={width * 0.5625}
                        sx={{
                            ...sxBox,
                            background: `url(${image?.src})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            cursor: props.handleClick ? 'pointer' : '',
                            position: 'relative',
                        }}
                        onClick={() => image && props.handleClick?.(image?.src)}
                    >
                        {loading && (
                            <Stack flexBasis={1} justifyContent="center">
                                <CircularProgress size={20} sx={{ margin: 'auto' }} />
                            </Stack>
                        )}

                        {isError && (
                            <Stack flexBasis={1} justifyContent="center">
                                <NoDataCameras />
                            </Stack>
                        )}

                        <TimeOnPicture date={date} timezone={timeZone} />
                    </Box>
                </Stack>

                {/* image plate crop */}
                <Box
                    sx={{
                        border: `1px solid #E4E7EC`,
                        padding: 1,
                        borderRadius: '4px',
                    }}
                >
                    {rootLoading ? (
                        <Stack flexBasis={1} justifyContent="center" sx={{ height: 130 }}>
                            <CircularProgress size={20} sx={{ margin: 'auto' }} />
                        </Stack>
                    ) : isError ? (
                        <Stack flexBasis={1} justifyContent="center">
                            <NoDataCameras />
                        </Stack>
                    ) : (
                        <Stack direction={'column'} spacing={1}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography variant="body1">Plate zone</Typography>
                                {isError || props.VerificationAudit ? (
                                    <></>
                                ) : (
                                    <Tooltip title="Edit plate" arrow placement="top">
                                        <Stack
                                            onClick={() => {
                                                props.handlePlateEdit?.();
                                            }}
                                            sx={{
                                                color: '#85858A',
                                                '&:hover': {
                                                    color: (theme) => theme.palette.secondary.main,
                                                    cursor: 'pointer',
                                                    transition: '0.5s ease-in-out',
                                                },
                                            }}
                                            alignItems="center"
                                        >
                                            <IcEdit />
                                        </Stack>
                                    </Tooltip>
                                )}
                            </Stack>
                            {props.plateRootImage ? (
                                <Box
                                    sx={{
                                        width: width,
                                        height: height,
                                        background: `url(${appConfig.gateway.blobURL}${props.plateRootImage}) no-repeat center center`,
                                        backgroundSize: 'contain',
                                    }}
                                />
                            ) : (
                                <Stage width={width} height={height}>
                                    <Layer>
                                        <Rect width={width} height={height} fill="white" />
                                        <KonvaImage
                                            x={(width - previewWidth) / 2}
                                            y={(height - previewHeight) / 2}
                                            width={previewWidth}
                                            height={previewHeight}
                                            image={rootImage}
                                            crop={{
                                                x: X1,
                                                y: Y1,
                                                width: plate.width,
                                                height: plate.height,
                                            }}
                                        />
                                    </Layer>
                                </Stage>
                            )}
                        </Stack>
                    )}
                </Box>
            </Stack>

            {vehiclePlate && (
                <Stack
                    mt={1}
                    flexBasis={1}
                    direction={'row'}
                    justifyContent="center"
                    sx={{
                        p: '6px 16px',
                        background: '#FFFF00',
                        borderRadius: '4px',
                        border: '1px solid #000000',
                    }}
                >
                    <Typography
                        variant="h1"
                        mt={'5px'}
                        sx={{
                            fontFamily: 'Roboto',
                        }}
                    >
                        {vehiclePlate}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
}
