import { GeoZoneCoordinates } from '@EcamModel/model';
import IcCamera from '@assets/details-camera-icons/IcCamera';
import { appConfig } from '@configs/index';
import { Autorenew } from '@mui/icons-material';
import { CircularProgress, Dialog, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import Konva from 'konva';
import { isNil } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Circle, Image, Layer, Line, Stage, Text } from 'react-konva';
import { formatDateByTimeZone } from 'src/helpers';
import { useBackdrop } from 'src/providers/BackdropProvider';
import useImage from 'use-image';
import { getCenterFrame } from '../utils';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';

type Props = {
    prevGeoZone: GeoZoneCoordinates[][];
    blobName?: string;
    captureAt?: Date;
    timeZone?: string;
    onRefresh?(): Promise<any>;
    notAvailable: boolean;
    isHideAction?: boolean;
};

export default function WrapHistoryPhoto(props: Props) {
    const backdrop = useBackdrop();

    const { isHideAction = false } = props;

    const openBackdrop = () => {
        const message = <Typography>Please wait for the camera to take a picture...</Typography>;
        backdrop.setTrue(message);
    };

    return (
        <Stack mt={5}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                spacing={1}
                p={2}
                sx={{
                    background: '#FAFAFA',
                    borderRadius: '4px 4px 0px 0px',
                    height: 50,
                }}
            >
                <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                    <IcCamera style={{ width: 18, height: 18 }} />
                    <Typography fontSize={16}>Photo</Typography>
                </Stack>
                {!isHideAction && (
                    <Stack>
                        <Tooltip title="Refresh latest photo" placement="top" arrow>
                            <IconButton
                                sx={{
                                    borderRadius: '3px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'white',
                                    backgroundColor: '#2E7D32',
                                    ':hover': {
                                        backgroundColor: '#256428',
                                    },
                                    '&.MuiIconButton-root.Mui-disabled': {
                                        backgroundColor: '#eee',
                                    },
                                    '&:hover .rotate-icon': {
                                        transform: 'rotate(90deg) scale(1.1)',
                                    },
                                }}
                                onClick={() => {
                                    openBackdrop();
                                    props.onRefresh?.().finally(() => {
                                        backdrop.setFalse();
                                    });
                                }}
                                disabled={props.notAvailable || backdrop.open}
                            >
                                <Autorenew
                                    sx={{ fontSize: 17, transition: 'transform 0.3s ease-in-out' }}
                                    className="rotate-icon"
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
            </Stack>
            <Stack
                direction="row"
                p={1.7}
                sx={{
                    border: '1px solid #DDDDDD',
                    borderRadius: '0px 0px 4px 4px',
                }}
                justifyContent="space-between"
            >
                {!props.blobName ? <NoDataCameras sx={{ border: 'none' }} /> : <HistoriesPhoto {...props} />}
            </Stack>
        </Stack>
    );
}

function HistoriesPhoto(props: Props) {
    const stageRef = useRef<Konva.Stage>(null);

    const { image, loading, isError } = useImageDimensions(`${props.blobName}`);

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const scaleNotFull = 1100 / (image?.width ?? 1920);
    const scaleFull = 1280 / (image?.width ?? 1920);

    const getGeoZone = (_scale: number) => {
        return props.prevGeoZone?.map((polygon) =>
            polygon.map((point) => ({
                x: point.XPoint * _scale,
                y: point.YPoint * _scale,
                geoZoneId: point.GeoZoneId,
            }))
        );
    };
    const scaledPrevGeoZone = getGeoZone(scaleNotFull);
    const prevGeoZones = getGeoZone(scaleFull);

    return (
        <>
            {!image ? (
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    minHeight={1100 * 0.5625}
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <div style={{ width: 1100, height: 1100 * 0.5625, position: 'relative' }}>
                    <Stage ref={stageRef} width={1100} height={1100 * 0.5625}>
                        <Layer>
                            <Image image={image} scaleX={scaleNotFull} scaleY={scaleNotFull} onClick={handleClick} />
                            {!!scaledPrevGeoZone.length &&
                                scaledPrevGeoZone.map((frame, frameIndex) => {
                                    const center = getCenterFrame(frame);
                                    return (
                                        <React.Fragment key={`frame-${frameIndex}`}>
                                            <Line points={frame.flatMap((point) => [point.x, point.y])} stroke="blue" />
                                            <Circle x={center.x} y={center.y} radius={15} fill="#fff" />
                                            <Text
                                                x={center.x}
                                                y={center.y}
                                                text={String(`${frameIndex + 1}`)}
                                                fontSize={14}
                                                fill={'black'}
                                                align={'center'}
                                                verticalAlign={'middle'}
                                                offsetX={4}
                                                offsetY={6}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                        </Layer>
                    </Stage>
                    <BaseBoxDecoration
                        timeZone={props.timeZone}
                        heading="Latest photo"
                        date={props.captureAt ?? moment().toDate()}
                    />
                    {/* <Dialog
                        open={open}
                        onClose={() => {
                            setOpen(false);
                        }}
                        PaperProps={{
                            sx: {
                                minWidth: 1280,
                                minHeight: 720,
                            },
                        }}
                    >
                        <div style={{ width: 1280, height: 720 }}>
                            <Stage ref={stageRef} width={1280} height={720}>
                                <Layer>
                                    <Image image={image} scaleX={scaleFull} scaleY={scaleFull} />
                                    {!!prevGeoZones?.length &&
                                        prevGeoZones?.map((frame, frameIndex) => {
                                            const center = getCenterFrame(frame);
                                            return (
                                                <React.Fragment key={`frame-${frameIndex}`}>
                                                    <Line
                                                        points={frame.flatMap((point) => [point.x, point.y])}
                                                        stroke="blue"
                                                    />
                                                    <Circle x={center.x} y={center.y} radius={15} fill="#fff" />
                                                    <Text
                                                        x={center.x}
                                                        y={center.y}
                                                        text={String(`${frameIndex + 1}`)}
                                                        fontSize={14}
                                                        fill={'black'}
                                                        align={'center'}
                                                        verticalAlign={'middle'}
                                                        offsetX={4}
                                                        offsetY={6}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                </Layer>
                            </Stage>
                        </div>
                    </Dialog> */}
                    <PreviewImageWithGeoZone
                        open={open}
                        onClose={() => setOpen(false)}
                        imgSrc={image}
                        scaleX={scaleFull}
                        scaleY={scaleFull}
                        geoZones={prevGeoZones}
                    />
                </div>
            )}
        </>
    );
}
const BaseBoxDecoration = ({ heading, date, timeZone }: { heading: string; date: Date; timeZone?: string }) => {
    // console.log({ timeZone });
    return (
        <>
            <Stack
                sx={{
                    p: 1,
                    background: 'rgba(0, 0, 0, 0.5)',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                {heading}
            </Stack>
            <Stack
                sx={{
                    py: 1,
                    px: 2,
                    background: 'rgba(0, 0, 0, 0.5)',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                {formatDateByTimeZone(date, timeZone ?? undefined, true)}
                {/* {moment(date).local().format('DD/MM/YYYY HH:mm:ss')} */}
            </Stack>
        </>
    );
};
export const PreviewImageWithGeoZone = ({
    open,
    onClose,
    imgSrc,
    scaleX,
    scaleY,
    geoZones,
}: {
    open: boolean;
    onClose?(): void;
    imgSrc: HTMLImageElement | undefined;
    scaleX?: number;
    scaleY?: number;
    geoZones: {
        x: number;
        y: number;
        geoZoneId: number | undefined;
    }[][];
}) => {
    const stageRef = useRef<Konva.Stage>(null);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose?.();
            }}
            PaperProps={{
                sx: {
                    minWidth: 1280,
                    minHeight: 720,
                },
            }}
        >
            <div style={{ width: 1280, height: 720 }}>
                {!imgSrc ? (
                    <Stack direction="row" width="100%" justifyContent="center" alignItems="center" minHeight={720}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <Stage ref={stageRef} width={1280} height={720}>
                        <Layer>
                            <Image image={imgSrc} scaleX={scaleX} scaleY={scaleY} />
                            {!!geoZones?.length &&
                                geoZones?.map((frame, frameIndex) => {
                                    const center = getCenterFrame(frame);
                                    return (
                                        <React.Fragment key={`frame-${frameIndex}`}>
                                            <Line
                                                points={frame.flatMap((point) => [point.x, point.y])}
                                                stroke={isNil(frame[0].geoZoneId) ? 'red' : 'blue'}
                                            />
                                            {!isNil(frame[0].geoZoneId) && (
                                                <>
                                                    <Circle x={center.x} y={center.y} radius={15} fill="#fff" />
                                                    <Text
                                                        x={center.x}
                                                        y={center.y}
                                                        text={String(`${frameIndex + 1}`)}
                                                        fontSize={14}
                                                        fill={'black'}
                                                        align={'center'}
                                                        verticalAlign={'middle'}
                                                        offsetX={4}
                                                        offsetY={6}
                                                    />
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </Layer>
                    </Stage>
                )}
            </div>
        </Dialog>
    );
};
