import { Solar4gTimeInfo, TimeInfoDaylightSavingMode, TimeInfoSyncMode } from '@EcamModel/model';
import IcEdit from '@assets/details-camera-icons/IcEdit';
import { solar4gCameraHttpController } from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useCaptureManagement } from '@pages/cameras/hook/Solar4gCameraProvider';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import { useEffect, useState } from 'react';
import PopUpSetSystemTime from './PopUpSetSystemTime';
import moment from 'moment';

export default function DateAndTimeMilesight() {
    const popUpSetSystemTime = usePopUp();

    const captureContext = useCaptureManagement();
    const { systemTimeMilesight, setSolar4gCamera } = captureContext;

    const [systemTime, setSystemTime] = useState<Solar4gTimeInfo>(systemTimeMilesight!);
    const [time, setTime] = useState(moment().local().format('HH:mm:ss'));

    useEffect(() => {
        setInterval(() => {
            setTime(moment().local().format('HH:mm:ss'));
        }, 1000);
    }, []);

    const checkTimeInfo = () => {
        const syncMode = {
            [TimeInfoSyncMode.GPS]: 'Auto',
            [TimeInfoSyncMode.MANUAL]: 'Manual',
        };

        const daylightSaving = {
            [TimeInfoDaylightSavingMode.Disable]: 'Disable',
            [TimeInfoDaylightSavingMode.Automatic]: 'Automatic',
        };

        return {
            syncMode: syncMode[systemTime?.SyncMode],
            daylightSaving: daylightSaving[systemTime?.DaylightSaving],
        };
    };

    const updateTimeInfo = async (data: Partial<Solar4gTimeInfo>) => {
        await solar4gCameraHttpController.updateTimeInfo(systemTime?.DevMac, data).then((res) => {
            setSystemTime(res);
            setSolar4gCamera((prev) => ({ ...prev, Solar4gTimeInfo: res }));
        });
    };

    return (
        <Stack width="100%" direction={{ md: 'row' }} spacing={3} gap={{ xs: 3, md: 0 }}>
            <Stack flex={1}>
                <Stack
                    paddingX={2}
                    paddingY={1}
                    bgcolor={color.grey100}
                    direction="row"
                    justifyContent={'space-between'}
                >
                    <Typography fontSize={16}>Set the system time</Typography>
                    {systemTime?.DevMac && (
                        <Tooltip
                            title={
                                <Typography align="center" fontWeight={450} fontSize={13} color={color.grey100}>
                                    The system will automatically correct the camera time
                                </Typography>
                            }
                            placement="top"
                            arrow
                        >
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <IconButton
                                    disabled
                                    sx={{
                                        borderRadius: '3px',
                                        width: '24px',
                                        height: '24px',
                                        color: 'white',
                                        backgroundColor: '#2E7D32',
                                        ':hover': {
                                            backgroundColor: '#256428',
                                        },
                                        padding: '4px',
                                        '&.MuiIconButton-root.Mui-disabled': {
                                            backgroundColor: '#eee',
                                        },
                                    }}
                                    onClick={() => {
                                        popUpSetSystemTime.setTrue();
                                    }}
                                >
                                    <IcEdit style={{ width: '14px', height: '14px' }} />
                                </IconButton>
                            </Stack>
                        </Tooltip>
                    )}
                </Stack>
                {systemTime ? (
                    <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                        <Grid xs={12} container>
                            <Grid xs={4}>
                                <Typography>Time zone:</Typography>
                            </Grid>
                            <Grid xs={8}>
                                {/* <Typography color={color.secondary}>{systemTime.Timezone}</Typography> */}
                                <Typography color={color.secondary}>(UTC+00:00) United Kingdom(London)</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container>
                            <Grid xs={4}>
                                <Typography>Daylight saving time:</Typography>
                            </Grid>
                            <Grid xs={8}>
                                <Typography>{checkTimeInfo().daylightSaving}</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container>
                            <Grid xs={4}>
                                <Typography>Synchronize:</Typography>
                            </Grid>
                            <Grid xs={8}>
                                <Typography>{checkTimeInfo().syncMode}</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container>
                            <Grid xs={4}>
                                <Typography>Time:</Typography>
                            </Grid>
                            <Grid xs={8}>
                                <Typography>{systemTime.TimeManual}</Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                ) : (
                    <NoDataCameras sx={{ borderRadius: '0px' }} />
                )}
            </Stack>

            <Stack flex={1}>
                <Box paddingX={2} paddingY={1} bgcolor={color.grey100}>
                    <Typography fontSize={16}>Current system time</Typography>
                </Box>

                <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                    <Grid xs={12} container>
                        <Grid xs={4}>
                            <Typography>Date:</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography>{moment().local().format('YYYY-MM-DD')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container>
                        <Grid xs={4}>
                            <Typography>Time:</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography>{time}</Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>

            <PopUpSetSystemTime
                onSave={(data) => updateTimeInfo(data)}
                systemTime={systemTime}
                {...popUpSetSystemTime}
            />
        </Stack>
    );
}
