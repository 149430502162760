import React, { SVGProps } from 'react';
type Props = {
    isChecked: boolean;
} & SVGProps<SVGSVGElement>;

export default function MinimizeImageIcon(props: Props) {
    return (
        <>
            {props.isChecked ? (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12" r="11.5" stroke={props.color} />
                    <path
                        d="M18.5 12.375C18.5 12.4745 18.4725 12.5698 18.4236 12.6402C18.3747 12.7105 18.3083 12.75 18.2391 12.75H6.76087C6.69168 12.75 6.62533 12.7105 6.57641 12.6402C6.52748 12.5698 6.5 12.4745 6.5 12.375C6.5 12.2755 6.52748 12.1802 6.57641 12.1098C6.62533 12.0395 6.69168 12 6.76087 12H18.2391C18.3083 12 18.3747 12.0395 18.4236 12.1098C18.4725 12.1802 18.5 12.2755 18.5 12.375Z"
                        fill={props.color}
                    />
                </svg>
            ) : (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32ZM16 28.8C19.3948 28.8 22.6505 27.4514 25.051 25.051C27.4514 22.6505 28.8 19.3948 28.8 16C28.8 12.6052 27.4514 9.3495 25.051 6.94903C22.6505 4.54857 19.3948 3.2 16 3.2C12.6052 3.2 9.3495 4.54857 6.94903 6.94903C4.54857 9.3495 3.2 12.6052 3.2 16C3.2 19.3948 4.54857 22.6505 6.94903 25.051C9.3495 27.4514 12.6052 28.8 16 28.8ZM24 14.4V17.6H8V14.4H24Z"
                        fill="white"
                    />
                </svg>
            )}
        </>
    );
}
