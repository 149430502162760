import { BaseModel } from '@Core';
import { Camera } from './Camera';
import { CameraPhotoVehicles } from './CameraPhotoVehicles';
import { CancellationReasons } from './CancellationReasons';
import { ContraventionReasons } from './ContraventionReasons';
import { GeoZones } from './GeoZones';
import { GroupPotentialContraventions } from './GroupPotentialContraventions';
import { PotentialContraventionPhotos } from './PotentialContraventionPhotos';
import { QueueSyncStella } from './QueueSyncStella';
import { Zones } from './Zones';

export interface CheckHasPermit {
    Plate: string;
    ContraventionReasonCode: string;
    EventDateTime: Date;
    FirstObservedDateTime: Date;
    ZoneId: number;
}

export interface PermitData {
    hasPermit: boolean;
    permitInfo: {
        bayNumber: string;
        source: string;
        tenant: string;
        zoneId: number;
    };
}
export interface CheckPCNDuplicate {
    ZoneId: number;
    LocationId: number;
    VRN: string;
    Code: string;
    EventDateTime: Date;
    ContraventionId?: number;
}

export interface PCNDuplicate {
    id: number;
    reference?: string;
    source: string;
    status: ContraventionStatus;
    step?: number;
    blobName?: string;
    cancellationReason?: string;
    firstIssuedDate?: Date;
}

export interface PcnDuplicateWithChangePlate {
    pcnDuplicate: PCNDuplicate[];
    isChangePlate: boolean;
    isPOPlate?: boolean;
    PermitData?: PermitData;
}

export interface PCNDuplicateStella {
    reference?: string;
    source?: string;
    status?: string;
    firstIssuedDate?: Date;
}

export enum ContraventionStatus {
    Open,
    Paid,
    Cancelled,
    Timeout,
    Paused,
    Approved,
    InProgress,
}

export interface PotentialContraventionWithInfo extends PotentialContraventions {
    ZoneName: string;
    LocationName: string;
    ContraventionType: string;
    EntryPhotos?: CameraPhotoVehicles[];
    ValidationPhotos?: CameraPhotoVehicles[];
    ExitPhotos?: CameraPhotoVehicles[];
    PCNDuplicate?: PCNDuplicate[];
    PermitData?: PermitData;
    TimeZone?: string;
    IsPOPlate?: boolean;
}

export interface PotentialContraventionLookUp {
    BlobName: string;
    Vrn: string;
    Make: string;
    Model: string;
    Color: string;
    FuelType?: string;
}

export interface PotentialContraventions extends BaseModel {
    ContraventionReasonId: number;
    ContraventionReason?: ContraventionReasons;

    Timezone: string;
    Step: number;

    OutCome: OutCome;

    VerifyByStep1?: string;

    VerifyAtStep1?: Date;

    VerifyByStep2?: string;

    VerifyAtStep2?: Date;

    CancellationReasonId?: number;
    Cancellation?: CancellationReasons;

    CancellationNotes?: string;

    Plate: string;

    IsForeign: boolean;

    ZoneId: number;
    Zone?: Zones;

    VehicleMake?: string;

    VehicleColor?: string;

    VehicleModel?: string;

    VehicleFuelType?: string;

    EntryAt?: Date;

    ExitAt?: Date;

    Reference: string;

    Exemptions?: string;

    GroupPotentialContraventionId?: number;
    GroupPotentialContravention?: GroupPotentialContraventions;

    PotentialContraventionPhotos?: PotentialContraventionPhotos[];

    CameraId?: number;
    Camera?: Camera;

    GeoZoneId: number;
    GeoZone?: GeoZones;

    QueueSyncStella?: QueueSyncStella;
}

export interface PotentialContraventionWithGroup extends PotentialContraventionWithInfo {
    Groups: PotentialContraventionWithInfo[];
}

export enum OutCome {
    InProgress = 'In progress',
    Cancelled = 'Cancelled',
    Approved = 'Approved',
}
