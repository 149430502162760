import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { Camera, CameraPhotos, CameraWithInfo } from '../../model';
import {
    ICameraHttpController,
    IGetCameraProps,
    IUpdateConfigIsVerificationsEnabled,
    IUpdateConfigIsVerificationsEnabledResponse,
    IUpsertCameraProps,
} from '../ICameraHttpController';

export class CameraHttpController extends BaseHttpController<Camera> implements ICameraHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera', client);
    }

    async wakeCameraByManually(id: number) {
        return await this.doGet({ path: `wake-up-manually/${id}` }).then((res) => res.data);
    }

    async upsertWithSolarCamera(props: IUpsertCameraProps): Promise<CameraWithInfo> {
        return await this.doPost({ path: 'upsert-with-solar-camera', body: props }).then((res) => res.data);
    }
    async changeStatus(id: number): Promise<Camera> {
        return await this.doGet({ path: `change-status/${id}` }).then((res) => res.data);
    }

    async list(params: IGetCameraProps): Promise<Paging<CameraWithInfo>> {
        return await this.doPost({ path: 'filter', body: params }).then((res) => res.data);
    }

    async get(id: string): Promise<CameraWithInfo> {
        return (await super.get(id)) as CameraWithInfo;
    }

    async getLatestCameraPhoto(cameraId: number): Promise<CameraPhotos> {
        return await this.doGet({ path: `latest-photo/${cameraId}` }).then((res) => res.data);
    }

    async checkANPRCameraExisted(anprCameraId: number): Promise<boolean> {
        return await this.doGet({ path: `duplicate-anpr-camera/${anprCameraId}` }).then((res) => res.data);
    }

    async updateConfigIsVerificationsEnabled(
        props: IUpdateConfigIsVerificationsEnabled
    ): Promise<IUpdateConfigIsVerificationsEnabledResponse> {
        return await this.doPost({ path: `update-config/IsVerificationsEnabled`, body: props }).then((res) => res.data);
    }

    async checkIsVRNOfPO(vrn: string): Promise<boolean> {
        return await this.doGet({ path: `check-po-vrn/${vrn}` }).then((res) => res.data);
    }
}
