import IcEdit from '@assets/details-camera-icons/IcEdit';
import { Stack, Tooltip } from '@mui/material';
import React from 'react';
type Props = {
    tooltipTitle: string;
    onClick?(): void;
};
export default function ButtonEditDetails(props: Props) {
    return (
        <div>
            <Tooltip title={props.tooltipTitle} arrow placement="top">
                <Stack
                    sx={{
                        color: '#85858A',
                        '&:hover': {
                            color: (theme) => theme.palette.secondary.main,
                            cursor: 'pointer',
                            transition: '0.5s ease-in-out',
                        },
                    }}
                    alignItems="center"
                    onClick={() => {
                        props.onClick?.();
                    }}
                >
                    <IcEdit />
                </Stack>
            </Tooltip>
        </div>
    );
}
