/* eslint-disable react-hooks/exhaustive-deps */
import { Paging, defaultPagination } from '@Core';
import {
    CameraUpdatedToday,
    IGetCameraProps,
    SortByField,
    SortByValue,
} from '@EcamModel/controllers/ICameraHttpController';
import { IGetLocationProps } from '@EcamModel/controllers/ILocationHttpController';
import {
    BatteryStatus,
    CameraWithInfo,
    CaptureInfoUploadMode,
    Location,
    PurposeType,
    Status,
    WifiStatus,
} from '@EcamModel/model';
import { Zones } from '@EcamModel/model/Zones';
import BaseListRender from '@components/BaseListRender';
import StyledDateRangePicker from '@components/date-range-select/StyledDateRangePicker';
import FilterRoot from '@components/filter/FilterRoot';
import IMultiSelectWithCheckBox from '@components/multi-select/IMultiSelectWithCheckBox';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushSuccess } from '@components/toast';
import { cameraController, locationController } from '@controllers/index';
import useScrollToTop from '@hooks/useScrollToTop';
import { SelectionItem } from '@models/cameras/SelectionItem';
import { MultiSearch } from '@models/search-model/SearchModel';
import { Box, Button, Divider, Grid, Stack, TextField, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import color from '@theme/Colors';
import _, { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CameraItem from './components/CameraItem';
import SearchTypeSelect from './components/SearchTypeSelect';
import { search } from './helpers';
import BreadCrumbs, { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import { SelectWithType } from '@pages/overview/components/GraphReportsAnalyzingCriteriaAndPCN';
import { ReportsAnalyzingPcnWithData } from '@pages/overview/hook/ReportsAnalyzingCriteriaAndPcnProvider';

export interface FEFilterOptions {
    page?: number;
    pagePCNsByContraventionDate?: number;
    pageReasonCancellation?: number;
    pageSize?: number;
    search?: MultiSearch;
    location?: Location;
    zones?: Zones[];
    status?: SelectionItem;
    battery?: SelectionItem;
    connection?: SelectionItem;
    emailVerifier?: string;
    emailAuditor?: string;
    auditStatus?: BaseSelect;
    VerificationDateFrom?: Date;
    VerificationDateTo?: Date;
    sortedOrder?: BaseSelect;
    updated?: SelectionItem;
    from?: Date;
    to?: Date;
    sort?: BaseSelect;
}

enum CameraSearchType {
    Name,
    Mac,
}

export type Filter = {
    page: number;
    search?: string;
    searchType?: BaseSelect;
    location?: Location;
    firmware?: BaseSelect;
    zones?: Zones[];
    status?: BaseSelect;
    battery?: BaseSelect;
    connection?: BaseSelect;
    sort?: BaseSelect;
    sortedOrder?: BaseSelect;
    updated?: BaseSelect;
    from?: Date;
    to?: Date;
    camVersion?: BaseSelect;
    uploadMode?: BaseSelect;
    eventLog?: BaseSelect;
    camPurposes?: BaseSelect;
    locationStatus?: BaseSelect;
    priorities?: BaseSelect;
    serviceType?: BaseSelect;
    ping?: BaseSelect;
    reportAnalyzing?: SelectWithType<ReportsAnalyzingPcnWithData>;
};

export const initPaging = {
    page: 1,
    pageSize: 10,
    rows: [],
    total: 0,
    totalPages: 0,
};

export type BaseSelect = {
    Id: number;
    Name: string;
    Value: any;
};

const camerasSearchTypes: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Name',
        Value: CameraSearchType.Name,
    },
    {
        Id: 2,
        Name: 'MAC',
        Value: CameraSearchType.Mac,
    },
];

const cameraSortByType: BaseSelect[] = [
    {
        Id: 4,
        Name: 'Camera Name',
        Value: SortByField.CameraName,
    },
    {
        Id: 1,
        Name: 'Zone',
        Value: SortByField.Zone,
    },
    {
        Id: 2,
        Name: 'Last Contact',
        Value: SortByField.Updated,
    },
    {
        Id: 3,
        Name: 'Battery Level',
        Value: SortByField.BatteryLevel,
    },
];

export const cameraSortByStatus: BaseSelect[] = [
    {
        Id: 1,
        Name: 'ASC',
        Value: SortByValue.Asc,
    },
    {
        Id: 2,
        Name: 'DESC',
        Value: SortByValue.Desc,
    },
];

const filterUpdated: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Not updated today',
        Value: CameraUpdatedToday.Updated,
    },
];

const statuses: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Not available',
        Value: Status.NotAvailable,
    },
    {
        Id: 2,
        Name: 'Active',
        Value: Status.Active,
    },
    {
        Id: 3,
        Name: 'Inactive',
        Value: Status.Inactive,
    },
];
const purposes: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Decommissioned',
        Value: PurposeType.Decommissioned,
    },
    {
        Id: 2,
        Name: 'Live',
        Value: PurposeType.Live,
    },
    {
        Id: 3,
        Name: 'On hold',
        Value: PurposeType.OnHold,
    },
    {
        Id: 4,
        Name: 'Testing',
        Value: PurposeType.Testing,
    },
    {
        Id: 5,
        Name: 'Prospective',
        Value: PurposeType.Prospective,
    },
    {
        Id: 6,
        Name: 'Deleted',
        Value: PurposeType.Deleted,
    },
];

const connections: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Connected',
        Value: WifiStatus.Connected,
    },
    {
        Id: 2,
        Name: 'Disconnected',
        Value: WifiStatus.DisConnected,
    },
];

const batteries: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Critical (0%-20%)',
        Value: BatteryStatus.Critical,
    },
    {
        Id: 2,
        Name: 'Low (21%-50%)',
        Value: BatteryStatus.Low,
    },
    {
        Id: 3,
        Name: 'Normal (51%-100%)',
        Value: BatteryStatus.Normal,
    },
];

const uploadMode: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Immediacy',
        Value: CaptureInfoUploadMode.IMMEDIACY,
    },
    {
        Id: 2,
        Name: 'Timing',
        Value: CaptureInfoUploadMode.TIMING,
    },
];

const initLoading = { location: true, cameras: true };

export default function Cameras() {
    const navigate = useNavigate();
    useScrollToTop();

    const [loadingState, setLoadingState] = useState<LoadingState>({ ...initLoading });
    const [pagingCamera, setPagingCamera] = useState<Paging<CameraWithInfo>>({ ...initPaging });
    const [zones, setZones] = useState<Zones[]>([]);
    const [pagingLocation, setPagingLocation] = useState<Paging<Location>>(defaultPagination());
    const [nameLocation, setNameLocation] = useState<string>('');
    const [filter, setFilter] = useState<Filter>({
        page: 1,
        sortedOrder: cameraSortByStatus[0],
        sort: cameraSortByType[0],
        searchType: camerasSearchTypes[0],
    });
    const [searchValue, setSearchValue] = useState('');

    const setPartialLoading = (loadings: Partial<LoadingState>) => {
        setLoadingState((prev) => ({ ...prev, ...loadings }));
    };

    const locationAutocomplete = useStyledAutocomplete({
        // async getList(props: IGetLocationProps): Promise<Paging<Location>> {
        //     const res = await locationController.listHasCamera({ ...props }).finally(() => {});
        //     return res;
        // },
        // listProps: {
        //     search: { fields: ['name'] } as any,
        // },
        list: {
            options: pagingLocation.rows,
            isFiltered(option, searchText) {
                return search(option.Name, searchText);
            },
        },
        dependencyList: [pagingLocation.rows],
    });

    const onChangeLocation = (l?: Location) => {
        setFilter((prev) => ({ ...prev, location: l, zones: undefined, page: 1 }));
        setZones(l ? l.Zones! : []);
    };

    const statusAutocomplete = useStyledAutocomplete({
        list: {
            options: statuses,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const connectionsAutocomplete = useStyledAutocomplete({
        list: {
            options: connections,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const batteryAutocomplete = useStyledAutocomplete({
        list: {
            options: batteries,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });
    const updatedAutocomplete = useStyledAutocomplete({
        list: {
            options: filterUpdated,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const uploadModeAutocomplete = useStyledAutocomplete({
        list: {
            options: uploadMode,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const purposesAutocomplete = useStyledAutocomplete({
        list: {
            options: purposes,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });
    const { locationId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams({});

    const page = searchParams.get('page');

    const searchBy = searchParams.get('searchBy');
    const searchType = searchParams.get('searchType');
    const _searchType = camerasSearchTypes.find((s) => s.Id === Number(searchType)) || camerasSearchTypes[0];

    const sortType = searchParams.get('sortType');
    const _sortType = cameraSortByStatus.find((c) => c.Id === Number(sortType));

    const sortBy = searchParams.get('sortBy');
    const _sortBy = cameraSortByType.find((c) => c.Id === Number(sortBy));

    // const locationId = searchParams.get('locationId');

    const zoneIds = searchParams.get('zoneIds');

    const lastContact = searchParams.get('lastContact');
    const _lastContact = filterUpdated.find((u) => u.Id === Number(lastContact));

    const from = searchParams.get('from');
    const to = searchParams.get('to');

    const status = searchParams.get('status');
    const _status = statuses.find((s) => s.Id === Number(status));

    const connection = searchParams.get('connection');
    const _connection = connections.find((s) => s.Id === Number(connection));

    const battery = searchParams.get('battery');
    const _battery = batteries.find((s) => s.Id === Number(battery));

    const mode = searchParams.get('mode');
    const _uploadMode = uploadMode.find((s) => s.Id === Number(mode));

    const purposesCam = searchParams.get('purposesCam');
    const _purposes = purposes.find((s) => s.Id === Number(purposesCam));

    const handleChangeParams = (params: any) => {
        const _searchParams: any = {
            page: 1,
            searchType,
            searchBy,
            sortType,
            sortBy,
            // locationId,
            zoneIds,
            lastContact,
            from,
            to,
            status,
            connection,
            battery,
            mode,
            purposesCam,
            ...params,
        };

        Object.entries(_searchParams).forEach(([key, value]) => {
            const firstPage = key === 'page' && Number(value) === 1;
            // if (!_searchParams['locationId']) delete _searchParams['zoneIds'];
            if (!value || firstPage) delete _searchParams[key];
        });

        setSearchParams(new URLSearchParams(_searchParams));
    };

    const debSearchClaim = useCallback(
        debounce((nextValue) => {
            setFilter((prev) => ({ ...prev, search: nextValue, page: 1 }));
        }, 300),
        []
    );
    const handleDebSearchClaim = (e: any) => {
        const value = e.target.value;
        setSearchValue(value);
        debSearchClaim(value);
    };

    const getCameras = useCallback(
        (props?: IGetCameraProps) => {
            setPartialLoading({ cameras: true });

            const _filter: IGetCameraProps = {
                page: Number(page) || 1,
                LocationId: Number(locationId) || undefined,
                ZoneIds: !!zoneIds ? zoneIds.split(',').map(Number) : undefined,
                NotUpdatedToday: Number(lastContact) || undefined,
                pageSize: 10,
                From: !!from ? moment(from).startOf('d').toDate() : undefined,
                To: !!to ? moment(to).startOf('d').toDate() : undefined,
                Battery: _battery?.Value,
                Wifi: _connection?.Value,
                filter: {
                    Status: _status?.Value,
                    PurposeType: _purposes?.Value,
                },
                SortByField: _sortBy?.Value ?? SortByField.CameraName,
                SortByValue: _sortType?.Value ?? SortByValue.Asc,
                search: {
                    fields: _searchType?.Value === CameraSearchType.Name ? ['Name'] : [],
                    content: _searchType?.Value === CameraSearchType.Name ? searchBy! : '',
                },
                Mac: _searchType?.Value === CameraSearchType.Mac ? searchBy! : undefined,
                UploadMode: _uploadMode?.Value,
                ..._.omit(props),
            };
            cameraController
                .list(_filter)
                .then((res) => {
                    setPagingCamera(res);
                })
                .finally(() => setPartialLoading({ cameras: false }));
        },
        [
            page,
            locationId,
            zoneIds,
            lastContact,
            from,
            to,
            battery,
            connection,
            status,
            sortBy,
            sortType,
            searchBy,
            mode,
            purposesCam,
        ]
    );

    const handleResetFilter = () => {
        setSearchValue('');
        setFilter({
            sortedOrder: cameraSortByStatus[0],
            sort: cameraSortByType[0],
            searchType: camerasSearchTypes[0],
        } as Filter);

        setSearchParams({});
    };

    const onChangeStatus = (id: number, checked: boolean) => {
        const paging = { ...pagingCamera };
        paging.rows.forEach((c) => {
            if (c.Id === id) {
                c.Status = checked ? Status.Active : Status.Inactive;
            }
        });
        setPagingCamera({ ...paging });
    };

    const onChangeVerification = (cameraId: number, checked: boolean) => {
        const paging = { ...pagingCamera };
        paging.rows.forEach((c) => {
            if (c.Id === cameraId) {
                c.IsVerificationsEnabled = checked;
            }
        });
        setPagingCamera({ ...paging });
    };

    const onDeleteCamera = (id: number) => {
        cameraController.delete(id.toString()).then((res) => {
            pushSuccess('Delete camera successful');
            handleResetFilter();
        });
    };

    useEffect(() => {
        getCameras({});
        const nameLocations = pagingLocation.rows.find((item) => item.Id === Number(locationId));
        setNameLocation(nameLocations?.Name ?? '');
    }, [
        filter.page,
        filter.search,
        filter.sort,
        filter.sortedOrder,
        filter.zones,
        filter.updated,
        filter.from,
        filter.to,
        filter.status,
        filter.connection,
        filter.battery,
        filter.uploadMode,
        filter.camPurposes,
    ]);

    useEffect(() => {
        locationController
            .listHasCamera({
                pageSize: 1000,
            })
            .then((res) => {
                setPagingLocation(res);
                const _location = res.rows.find((l) => l.Id === parseInt(locationId!));
                const convertedZones = zoneIds?.split(',').map(Number);
                const _zones = _location?.Zones?.filter((z) => convertedZones?.includes(z.Id!));
                const initialZone = !!zones.length ? _zones : _location?.Zones || ([] as Zones[]);
                setZones(initialZone!);
                const _filter: Partial<Filter> = {
                    location: _location || undefined,
                    zones: _zones || undefined,
                };
                setFilter((prev) => ({ ...prev, ..._filter }));
            });
        const _filter: Partial<Filter> = {
            page: page ? Number(page) : 1,
            updated: _lastContact || undefined,
            from: moment(from).startOf('d').toDate(),
            to: moment(to).endOf('d').toDate(),
            status: _status || undefined,
            connection: _connection || undefined,
            battery: _battery || undefined,
            sortedOrder: _sortType || cameraSortByStatus[0],
            sort: _sortBy || cameraSortByType[0],
            searchType: _searchType || camerasSearchTypes[0],
            uploadMode: _uploadMode || undefined,
            camPurposes: _purposes || undefined,
            search: searchBy || '',
        };
        setSearchValue(searchBy || '');

        if (!from) delete _filter.from;
        if (!to) delete _filter.to;

        setFilter((prev) => ({
            ...prev,
            ..._filter,
            status: {
                Id: 2,
                Name: 'Active',
                Value: Status.Active,
            },
        }));
        handleChangeParams({ ...searchParams, status: '2' });
    }, []);

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Location list', href: '/locations' },
        { title: `${nameLocation}`, onBack: false, isColorBlackText: true },
    ];

    return (
        <Stack direction={'column'}>
            {nameLocation && (
                <>
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h3">Location: {nameLocation}</Typography>
                        <Button variant="contained" sx={{ minWidth: '150px' }} onClick={() => navigate(`add`)}>
                            Add camera
                        </Button>
                    </Stack>
                    <BreadCrumbs breadcrumbs={breadcrumbs} />
                </>
            )}
            <Stack mt={2}>
                <FilterRoot
                    handleResetFilter={() => handleResetFilter()}
                    rightTitleContent={
                        <Grid2 container spacing={2} xs={8}>
                            <Grid2 xs={7}>
                                <TextField
                                    sx={{ '& .MuiOutlinedInput-root': { paddingLeft: 0 } }}
                                    fullWidth
                                    label="Search by"
                                    placeholder="Search..."
                                    onChange={(e) => {
                                        handleDebSearchClaim(e);
                                        handleChangeParams({ searchBy: e.target.value });
                                    }}
                                    value={searchValue}
                                    InputProps={{
                                        startAdornment: (
                                            <Stack direction="row">
                                                <SearchTypeSelect
                                                    label="Search by"
                                                    minWidth={80}
                                                    listData={camerasSearchTypes}
                                                    value={filter.searchType!}
                                                    keyEqual="Id"
                                                    keyLabel="Name"
                                                    handleChange={(e) => {
                                                        setFilter((prev) => ({ ...prev, searchType: e }));
                                                        handleChangeParams({ searchType: e.Id });
                                                    }}
                                                />
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    sx={{
                                                        mx: '5px',
                                                        borderRightWidth: 1,
                                                        borderColor: color.grey600,
                                                    }}
                                                    variant="middle"
                                                />
                                            </Stack>
                                        ),
                                        endAdornment: (
                                            <FiSearch
                                                color="disabled"
                                                size={32}
                                                style={{ color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }}
                                            />
                                        ),
                                    }}
                                />
                            </Grid2>
                            <Grid2 xs={5}>
                                <SearchTypeSelect
                                    startAdornment={
                                        <Stack direction={'row'}>
                                            <SearchTypeSelect
                                                listData={cameraSortByStatus}
                                                value={filter.sortedOrder!}
                                                keyEqual="Id"
                                                keyLabel="Name"
                                                handleChange={(e) => {
                                                    setFilter((prev) => ({ ...prev, sortedOrder: e, page: 1 }));
                                                    handleChangeParams({ sortType: e?.Id });
                                                }}
                                                label="Sort by"
                                                minWidth={60}
                                            />
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                                sx={{
                                                    mx: '5px',
                                                    borderRightWidth: 1,
                                                    borderColor: color.grey600,
                                                }}
                                                variant="middle"
                                            />
                                        </Stack>
                                    }
                                    listData={cameraSortByType}
                                    value={filter.sort!}
                                    keyEqual="Id"
                                    keyLabel="Name"
                                    handleChange={(e) => {
                                        setFilter((prev) => ({ ...prev, sort: e, page: 1 }));
                                        handleChangeParams({ sortBy: e?.Id });
                                    }}
                                    label="Sort by"
                                    minWidth={60}
                                />
                            </Grid2>
                        </Grid2>
                    }
                    filterChild={
                        <Grid container spacing={2}>
                            {/* <Grid item xs={6}>
                                <StyledAutocomplete
                                    {...locationAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Location"
                                    value={filter.location}
                                    placeholder="All locations"
                                    onChange={(l) => {
                                        onChangeLocation(l);
                                        handleChangeParams({ locationId: l?.Id });
                                    }}
                                />
                            </Grid> */}
                            <Grid item xs={6}>
                                <IMultiSelectWithCheckBox
                                    limitTags={3}
                                    label={'Zone'}
                                    keyId={'Id'}
                                    keyLabel={'PublicName'}
                                    placeHolder={'Select zone'}
                                    listData={zones}
                                    isDisable={!zones.length}
                                    value={filter.zones ?? []}
                                    setFilter={(option) => {
                                        setFilter((prev) => ({
                                            ...prev,
                                            zones: option.length ? option : undefined,
                                            page: 1,
                                        }));
                                        const handlerIdsParam = option
                                            .map((item) => encodeURIComponent(item.Id!))
                                            .join(',');
                                        handleChangeParams({ zoneIds: handlerIdsParam });
                                    }}
                                    chipSize={150}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <StyledAutocomplete
                                    {...updatedAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Last contact"
                                    value={filter.updated}
                                    placeholder="None"
                                    onChange={(e) => {
                                        setFilter((prev) => ({ ...prev, updated: e, page: 1 }));
                                        handleChangeParams({
                                            lastContact: e?.Id,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StyledDateRangePicker
                                    value={{ startDate: filter.from, endDate: filter.to }}
                                    onChange={(value) => {
                                        setFilter((prev) => ({
                                            ...prev,
                                            from: value.startDate,
                                            to: value.endDate,
                                            page: 1,
                                        }));
                                        handleChangeParams({
                                            from: moment(value.startDate).startOf('d').toISOString(),
                                            to: moment(value.endDate).endOf('d').toISOString(),
                                        });
                                    }}
                                    label={'Production date'}
                                    initialSettings={
                                        {
                                            // isInvalidDate: (date: Moment) => {
                                            //     return moment(date).isAfter();
                                            // },
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StyledAutocomplete
                                    {...uploadModeAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Upload mode"
                                    value={filter.uploadMode}
                                    placeholder="All mode"
                                    onChange={(o) => {
                                        setFilter((prev) => ({ ...prev, uploadMode: o, page: 1 }));
                                        handleChangeParams({ mode: o?.Id });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StyledAutocomplete
                                    {...purposesAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Purposes"
                                    value={filter.camPurposes}
                                    placeholder="All purposes"
                                    onChange={(o) => {
                                        setFilter((prev) => ({ ...prev, camPurposes: o, page: 1 }));
                                        handleChangeParams({ purposesCam: o?.Id });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StyledAutocomplete
                                    {...statusAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Status"
                                    value={filter.status}
                                    placeholder="All status"
                                    onChange={(o) => {
                                        setFilter((prev) => ({ ...prev, status: o, page: 1 }));
                                        handleChangeParams({ status: o?.Id });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StyledAutocomplete
                                    {...connectionsAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Connection"
                                    value={filter.connection}
                                    placeholder="All connections"
                                    onChange={(o) => {
                                        setFilter((prev) => ({ ...prev, connection: o, page: 1 }));
                                        handleChangeParams({ connection: o?.Id });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StyledAutocomplete
                                    {...batteryAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Battery"
                                    value={filter.battery}
                                    placeholder="All levels"
                                    onChange={(o) => {
                                        setFilter((prev) => ({ ...prev, battery: o, page: 1 }));
                                        handleChangeParams({ battery: o?.Id });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                />
            </Stack>
            <Stack mt={3}>
                <Typography textAlign={'right'} sx={{ color: color.priText }}>
                    Total: {pagingCamera.total}
                </Typography>
            </Stack>
            <BaseListRender
                styleContainer={{ mt: 2, mb: 5 }}
                styleList={{ gap: 2 }}
                paging={pagingCamera}
                renderedItem={(camera) => (
                    <CameraItem
                        cameraItem={camera}
                        onChangeStatus={onChangeStatus}
                        onDeleteCamera={onDeleteCamera}
                        onChangeVerification={onChangeVerification}
                    />
                )}
                isLoading={loadingState.cameras}
                setPage={(page) => {
                    setFilter((prev) => ({ ...prev, page }));
                    handleChangeParams({ page: page });
                }}
            />
        </Stack>
    );
}

export interface LoadingState {
    location: boolean;
    cameras: boolean;
}
export const ContainerSort = styled(Grid)({
    background: '#FFF',
});

const LabeledContainer = ({ title, children }) => {
    return (
        <Box sx={{ position: 'relative', background: '#fff', border: '1px solid #ddd' }}>
            <label
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1hu70xq-MuiFormLabel-root-MuiInputLabel-root"
                data-shrink="true"
                htmlFor=":r5s:"
                id=":r5s:-label"
                // style={{ background: '#fff', width: '80px', textAlign: 'center' }}
            >
                Search by
            </label>

            {children}
        </Box>
    );
};
