import {
    CameraServiceType,
    CameraWithInfo,
    CaptureInfoCaptureIndicator,
    Solar4gAlarmROI,
    Solar4gCamera,
    Solar4gCaptureInfo,
    Solar4gSchedule,
} from '@EcamModel/model';
import { GeoZoneOperationConfigDay } from '@EcamModel/model/GeoZoneOperationConfigDay';
import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Autocomplete, Button, Stack, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useCaptureManagement } from '@pages/cameras/hook/Solar4gCameraProvider';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import moment, { Moment } from 'moment';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import BaseTimePicker from './BaseTimePicker';
import { NewItem } from './CaptureManagementMilesight';
import InformationIcon from './icon/InformationIcon';

type Props = Omit<IPopUp, 'onConfirm'> & {
    handleUpdateCapture: (data: Partial<Solar4gCamera>) => Promise<void>;
    handleUpdateCaptureROI: (data: Partial<Solar4gAlarmROI>) => Promise<void>;
    handleUpdateCaptureSchedule: (data: Partial<Solar4gSchedule>) => Promise<void>;
    updatedTimeFields: NewItem[];
    timeFields: NewItem[];
    setTimeFields: Dispatch<SetStateAction<NewItem[]>>;
    camera: CameraWithInfo;
};

export type OperationHours = {
    _fakeId?: string;
    From?: Date;
    To?: Date;
} & Omit<Partial<GeoZoneOperationConfigDay>, 'From' | 'To'>;

type FormValues = {
    From: Moment;
    To: Moment;
};

export default function PopUpEditTriggerCapture(props: Props) {
    const { open, onClose, updatedTimeFields, timeFields, setTimeFields, camera } = props;

    const captureContext = useCaptureManagement();
    const { Solar4gCaptureInfo, Solar4gSchedule, Solar4gAlarmROI } = captureContext;

    const [isCheckValueTrigger, setIsCheckValueTrigger] = useState<boolean>(false);

    const handleAddShifts = () => {
        setTimeFields((prev) => [...prev, { id: updatedTimeFields.length, fromTime: '', toTime: '' }]);
    };

    const handleRemoveShifts = (indexNumber: number) => {
        const _prevState = cloneDeep(timeFields);
        const updatedTimeFields = _prevState.filter((item, index) => index !== indexNumber);
        setTimeFields(updatedTimeFields);
    };

    const {
        control,
        setValue,
        reset,
        handleSubmit,
        clearErrors,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm<Partial<Solar4gCaptureInfo & Solar4gAlarmROI & Solar4gSchedule & FormValues>>({
        mode: 'all',
        defaultValues: {
            Threshold: 0,
            Interval: 0,
            Indicator: 0,
            // LowSpeedLimit: 0,
            HighSpeedLimit: 0,
            // RadarSensitivity: 0,
            // CaptureTimeout: 0,
        },
    });
    const disabled = isCheckValueTrigger ? !isCheckValueTrigger : !(isValid && isDirty);

    const defaultValues: Partial<Solar4gCaptureInfo & Solar4gAlarmROI & Solar4gSchedule> = {
        Threshold: Solar4gAlarmROI?.Threshold,
        Interval: Solar4gAlarmROI?.Interval,
        Indicator: Solar4gCaptureInfo?.Indicator,
        // LowSpeedLimit: Solar4gCaptureInfo?.LowSpeedLimit,
        HighSpeedLimit: Solar4gCaptureInfo?.HighSpeedLimit,
        Trigger: Solar4gSchedule?.Trigger,
        // RadarSensitivity: Solar4gCaptureInfo?.RadarSensitivity,
        // CaptureTimeout: Solar4gCaptureInfo?.CaptureTimeout,
    };

    useEffect(() => {
        if (!open) return;
        reset(defaultValues);
        setTimeFields(updatedTimeFields);
    }, [open]);

    useEffect(() => {
        const timePrev = cloneDeep(timeFields);

        const sendDataTrigger = timePrev.map((item) => {
            return timePrev.length > 0
                ? `${item.fromTime ? item.fromTime : '00:00'}:${item.toTime ? item.toTime : '23:59'}:1`
                : ``;
        });

        if (Solar4gSchedule?.Trigger !== `${sendDataTrigger.join(';')}`) {
            setIsCheckValueTrigger(true);
        } else {
            setIsCheckValueTrigger(false);
        }
    }, [timeFields]);

    const handleEditTriggerCapture = async (data: Partial<Solar4gCaptureInfo & Solar4gAlarmROI & Solar4gSchedule>) => {
        const timePrev = cloneDeep(timeFields);

        const sendDataTrigger = timePrev.map((item) => {
            return timePrev.length > 0
                ? `${item.fromTime ? item.fromTime : '00:00'}:${item.toTime ? item.toTime : '23:59'}:1`
                : ``;
        });

        data.Trigger = `${sendDataTrigger.join(';')}`;

        const _editTriggerCapture: Solar4gCamera = {} as Solar4gCamera;
        for (const key in data) {
            if (data[key] !== defaultValues[key]) {
                _editTriggerCapture[key] = data[key];
            }
        }

        if (Solar4gAlarmROI?.Interval !== data.Interval || Solar4gAlarmROI?.Threshold !== data.Threshold) {
            await props.handleUpdateCaptureROI(_editTriggerCapture);
        }
        if (Solar4gSchedule?.Trigger !== data.Trigger) {
            await props.handleUpdateCaptureSchedule(_editTriggerCapture);
        }
        if (
            Solar4gCaptureInfo?.Indicator !== data.Indicator ||
            // Solar4gCaptureInfo?.LowSpeedLimit !== data.LowSpeedLimit ||
            Solar4gCaptureInfo?.HighSpeedLimit !== data.HighSpeedLimit
            // Solar4gCaptureInfo?.RadarSensitivity !== data.RadarSensitivity ||
            // Solar4gCaptureInfo?.CaptureTimeout !== data.CaptureTimeout
        ) {
            await props.handleUpdateCapture(_editTriggerCapture);
        }
        props.onClose?.();
    };

    const validateSpeedLimits = (value) => {
        const highSpeedLimitValue = Number(watch('HighSpeedLimit'));
        const lowSpeedLimitValue = Number(watch('LowSpeedLimit'));

        if (!isNaN(highSpeedLimitValue) && !isNaN(lowSpeedLimitValue)) {
            if (value >= lowSpeedLimitValue && value <= highSpeedLimitValue) {
                return true;
            } else {
                return 'Maximum detection speed must be greater than or equal to minimum detection speed.';
            }
        }
        return true;
    };

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            onConfirm={() => {}}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        maxWidth: '860px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Trigger capture</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="100%" sx={{ background: '#FAFAFA', p: 2 }} spacing={3}>
                    {/* Al confidence: */}
                    <Grid xs={12} container>
                        <Grid xs={3.5}>
                            <Typography sx={{ width: 240, lineHeight: '40px' }}>Al confidence:</Typography>
                        </Grid>
                        <Grid container xs={8.5} justifyContent={'space-between'}>
                            <Grid xs={9.6}>
                                <Controller
                                    name="Threshold"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'This field is required.',
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: '',
                                        },
                                        minLength: {
                                            value: 1,
                                            message: '',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                const value = Number(e.target.value);
                                                if (!isNaN(value)) {
                                                    field.onChange(isNaN(Number(value)) ? value : Number(value));
                                                }
                                            }}
                                            placeholder={'Al confidence'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={2} textAlign={'end'}>
                                <Button
                                    variant="cancelSmall"
                                    sx={{
                                        px: 1,
                                        py: 0.5,
                                        fontSize: 12,
                                        '&.MuiButtonBase-root': {
                                            marginTop: '7px !important',
                                        },
                                    }}
                                    onClick={() => {
                                        setValue('Threshold', 5, { shouldDirty: true, shouldValidate: true });
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Sensor sensitivity: */}
                    <Grid xs={12} container>
                        <Grid xs={3.5}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography sx={{ lineHeight: '40px' }}>Sensor sensitivity:</Typography>
                                <Tooltip placement="top" title="The system should auto-correct the capture config.">
                                    <InformationIcon />
                                </Tooltip>
                                :
                            </Stack>
                        </Grid>
                        <Grid container xs={8.5} justifyContent={'space-between'}>
                            <Grid
                                xs={12}
                                // xs={9.6}
                            >
                                <Controller
                                    name="RadarSensitivity"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'This field is required.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => {
                                        return (
                                            <BaseTextField
                                                {...field}
                                                value={field.value}
                                                disabled
                                                onChange={(e) => {
                                                    const value = Number(e.target.value);
                                                    if (!isNaN(value)) {
                                                        field.onChange(isNaN(Number(value)) ? value : Number(value));
                                                    }
                                                }}
                                                placeholder={'Sensor sensitivity'}
                                                error={!!error?.message}
                                                helperText={error?.message}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            {/* <Grid md={2} textAlign={'end'}>
                                <Button
                                    variant="cancelSmall"
                                    sx={{
                                        px: 1,
                                        py: 0.5,
                                        fontSize: 12,
                                        '&.MuiButtonBase-root': {
                                            marginTop: '7px !important',
                                        },
                                    }}
                                    onClick={() => {
                                        setValue('RadarSensitivity', 4, { shouldDirty: true, shouldValidate: true });
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    {/* Minimum detection speed: */}
                    <Grid xs={12} container>
                        <Grid xs={3.5}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography sx={{ lineHeight: '40px' }}>Minimum detection speed</Typography>
                                <Tooltip
                                    placement="top"
                                    // title="Targets with a speed smaller than this set value will trigger the capture."
                                    title="The system should auto-correct the capture config."
                                >
                                    <InformationIcon />
                                </Tooltip>
                                :
                            </Stack>
                        </Grid>
                        <Grid container xs={8.5} justifyContent={'space-between'}>
                            <Grid xs={12}>
                                <Controller
                                    name="LowSpeedLimit"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'This field is required.',
                                        },
                                        max: {
                                            message: 'Value must be less than or equal to 70.',
                                            value: 70,
                                        },
                                        validate: validateSpeedLimits,
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            disabled
                                            onChange={(e) => {
                                                const value = Number(e.target.value);
                                                if (!isNaN(value)) {
                                                    field.onChange(isNaN(Number(value)) ? value : Number(value));
                                                }
                                                setValue('HighSpeedLimit', watch().HighSpeedLimit, {
                                                    shouldValidate: true,
                                                });
                                            }}
                                            maxLength={2}
                                            placeholder={'Minimum detection speed'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            {/* <Grid md={2}>
                                <Typography sx={{ lineHeight: '40px' }} textAlign={'end'}>
                                    {' '}
                                    Km/h (0~70)
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    {/* Maximum detection speed: */}
                    <Grid xs={12} container>
                        <Grid xs={3.5}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography sx={{ lineHeight: '40px' }}>Maximum detection speed</Typography>
                                <Tooltip
                                    placement="top"
                                    title="Targets with a speed greater than this set value will trigger the capture."
                                >
                                    <InformationIcon />
                                </Tooltip>
                                :
                            </Stack>
                        </Grid>
                        <Grid container xs={8.5} justifyContent={'space-between'}>
                            <Grid xs={9.6}>
                                <Controller
                                    name="HighSpeedLimit"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'This field is required.',
                                        },
                                        max: {
                                            message: 'Value must be less than or equal to 70.',
                                            value: 70,
                                        },
                                        validate: validateSpeedLimits,
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            onChange={(e) => {
                                                const value = Number(e.target.value);
                                                if (!isNaN(value)) {
                                                    field.onChange(isNaN(Number(value)) ? value : Number(value));
                                                    setValue('LowSpeedLimit', watch().LowSpeedLimit, {
                                                        shouldValidate: true,
                                                    });
                                                }
                                            }}
                                            maxLength={2}
                                            placeholder={'Maximum detection speed'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={2}>
                                <Typography sx={{ lineHeight: '40px' }} textAlign={'end'}>
                                    {' '}
                                    Km/h (0~70)
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Sleep delay: */}
                    <Grid xs={12} container alignItems={'center'}>
                        <Grid xs={3.5}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography>Sleep delay</Typography>
                                <Tooltip
                                    placement="top"
                                    title="The system should auto-correct the capture config."
                                    // title="This value represents the duration of time after which the device enters sleep mode when no target is detected. To avoid missed detections at lower target speeds, it is recommended to configure a larger value for this setting."
                                >
                                    <InformationIcon />
                                </Tooltip>
                                :
                            </Stack>
                        </Grid>
                        <Grid xs={8.5}>
                            <Controller
                                name="CaptureTimeout"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        disabled
                                        options={Array.from({ length: 30 }, (_, index) => index + 1).map(
                                            (second) => second
                                        )}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: '40px',
                                            },
                                            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                                padding: '0px',
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Sleep delay"
                                                sx={{
                                                    '.MuiFormLabel-root': {
                                                        marginTop: '-5px',
                                                    },
                                                }}
                                            />
                                        )}
                                        getOptionLabel={(o) => `${o}s`}
                                        value={field.value}
                                        onChange={(_, newValue) => {
                                            field.onChange(newValue);
                                            if (newValue === null) {
                                                setValue('CaptureTimeout', Solar4gCaptureInfo!.CaptureTimeout);
                                            }
                                        }}
                                        clearIcon={true}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    {/* Capture interval: */}
                    <Grid xs={12} container>
                        <Grid xs={3.5}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography sx={{ lineHeight: '40px' }}>Capture interval</Typography>
                                <Tooltip
                                    placement="top"
                                    title={
                                        camera.ServiceType === CameraServiceType.ECamTechOps
                                            ? "Editing the 'Capture interval' is not available when the camera service type is set to 'eCam TechOps'."
                                            : 'In scenarios where the target moves slowly, setting a longer capture interval can prevent frequent triggering of captures for the same target.'
                                    }
                                >
                                    <InformationIcon />
                                </Tooltip>
                                :
                            </Stack>
                        </Grid>
                        <Grid container xs={8.5} justifyContent={'space-between'}>
                            <Grid xs={9}>
                                <Controller
                                    name="Interval"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'This field is required.',
                                        },
                                        min: {
                                            message: 'Value must be greater than or equal to 100.',
                                            value: 100,
                                        },
                                        max: {
                                            message: 'Value must be less than or equal to 15000.',
                                            value: 15000,
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Please enter a valid Bytes.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            value={field.value}
                                            disabled={
                                                camera.ServiceType === CameraServiceType.ECamTechOps ? true : false
                                            }
                                            onChange={(e) => {
                                                const value = Number(e.target.value);
                                                if (!isNaN(value)) {
                                                    field.onChange(isNaN(Number(value)) ? value : Number(value));
                                                }
                                            }}
                                            placeholder={'Capture interval'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={3} textAlign={'end'}>
                                <Typography sx={{ lineHeight: '40px' }}>Ms (100~15000)</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Indicator light: */}
                    <Grid xs={12} container alignItems={'center'}>
                        <Grid xs={3.5}>
                            <Typography>Indicator light:</Typography>
                        </Grid>
                        <Grid xs={8.5}>
                            <Controller
                                name="Indicator"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={[0, 1]}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: '40px',
                                            },
                                            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                                padding: '0px',
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select indicator light"
                                                sx={{
                                                    '.MuiFormLabel-root': {
                                                        marginTop: '-5px',
                                                    },
                                                }}
                                            />
                                        )}
                                        getOptionLabel={(options) => {
                                            const authTypeDisplayValue = {
                                                [CaptureInfoCaptureIndicator.OFF]: 'Off',
                                                [CaptureInfoCaptureIndicator.ON]: 'On',
                                            };
                                            return authTypeDisplayValue[options];
                                        }}
                                        value={field.value}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            // if (newValue === null) {
                                            //     setValue('Indicator', Solar4gCaptureInfo!.Indicator);
                                            // }
                                        }}
                                        clearIcon={true}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {timeFields?.length === 0 && (
                        <Grid container alignItems={'center'} xs={12}>
                            <Grid xs={3.5}>
                                <Typography
                                    sx={{
                                        lineHeight: '40px',
                                    }}
                                >
                                    Schedule:
                                </Typography>
                            </Grid>
                            <Grid container alignItems={'center'} xs={8.5} justifyContent={'space-between'}>
                                <Tooltip placement="top" title="Add period.">
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            minWidth: 30,
                                            minHeight: 30,
                                            p: '6px !important',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                color: (theme) => theme.palette.primary.light,
                                            },
                                        }}
                                        onClick={() => {
                                            handleAddShifts();
                                        }}
                                    >
                                        <AiOutlinePlus />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}

                    {/* Schedule: */}
                    {timeFields.map((item, index) => {
                        return (
                            <Grid container alignItems={'center'} xs={12}>
                                <Grid xs={3.5}>
                                    <Typography
                                        sx={{
                                            lineHeight: '40px',
                                        }}
                                    >
                                        {index == 0 ? 'Schedule:' : `Schedule: ( ${index} )`}
                                    </Typography>
                                </Grid>
                                <Grid container alignItems={'center'} xs={8.5} justifyContent={'space-between'}>
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <Typography>From</Typography>
                                            <BaseTimePicker
                                                marginTop={-255}
                                                zIndex={10}
                                                key={item.id}
                                                width={'180px'}
                                                value={
                                                    item.fromTime
                                                        ? (moment(item.fromTime, 'HH:mm') as any)
                                                        : (moment('00:00', 'HH:mm') as any)
                                                }
                                                onChange={(newValue) => {
                                                    setTimeFields((prev) => {
                                                        const clonePrev = cloneDeep(prev);

                                                        clonePrev[index].fromTime = String(
                                                            moment(newValue).format('HH:mm')
                                                        );
                                                        clonePrev[index].toTime = item.toTime;

                                                        return clonePrev;
                                                    });
                                                }}
                                            />
                                        </Stack>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <Typography>to</Typography>
                                            <BaseTimePicker
                                                marginTop={-255}
                                                width={'180px'}
                                                value={
                                                    item.toTime
                                                        ? (moment(item.toTime, 'HH:mm') as any)
                                                        : (moment('23:59', 'HH:mm') as any)
                                                }
                                                onChange={(newValue) => {
                                                    setTimeFields((prev) => {
                                                        const clonePrev = cloneDeep(prev);

                                                        clonePrev[index].toTime = String(
                                                            moment(newValue).format('HH:mm')
                                                        );

                                                        return clonePrev;
                                                    });
                                                }}
                                            />
                                        </Stack>
                                    </Stack>

                                    {timeFields.length === 1 ? (
                                        <Stack direction={'row'} justifyContent={'space-between'} width={'13%'}>
                                            <Tooltip placement="top" title="Remove period.">
                                                <Button
                                                    variant="cancelOutlined"
                                                    sx={{
                                                        minWidth: 30,
                                                        minHeight: 30,
                                                        p: '6px !important',
                                                        borderRadius: '8px',
                                                        '&:hover': {
                                                            color: (theme) => theme.palette.grey[700],
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        handleRemoveShifts(index);
                                                    }}
                                                >
                                                    <AiOutlineMinus />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title="Add period.">
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        minWidth: 30,
                                                        minHeight: 30,
                                                        p: '6px !important',
                                                        borderRadius: '8px',
                                                        '&:hover': {
                                                            color: (theme) => theme.palette.primary.light,
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        handleAddShifts();
                                                    }}
                                                >
                                                    <AiOutlinePlus />
                                                </Button>
                                            </Tooltip>
                                        </Stack>
                                    ) : index === 0 ? (
                                        <Tooltip placement="top" title="Add period.">
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    minWidth: 30,
                                                    minHeight: 30,
                                                    p: '6px !important',
                                                    borderRadius: '8px',
                                                    '&:hover': {
                                                        color: (theme) => theme.palette.primary.light,
                                                    },
                                                }}
                                                onClick={() => {
                                                    handleAddShifts();
                                                }}
                                            >
                                                <AiOutlinePlus />
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip placement="top" title="Remove period.">
                                            <Button
                                                variant="cancelOutlined"
                                                sx={{
                                                    minWidth: 30,
                                                    minHeight: 30,
                                                    p: '6px !important',
                                                    borderRadius: '8px',
                                                    '&:hover': {
                                                        color: (theme) => theme.palette.grey[700],
                                                    },
                                                }}
                                                onClick={() => {
                                                    handleRemoveShifts(index);
                                                }}
                                            >
                                                <AiOutlineMinus />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            setTimeFields(updatedTimeFields);
                            props.onClose?.();
                            clearErrors();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={disabled}
                        onClick={handleSubmit(async (data) => {
                            await handleEditTriggerCapture(data);
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
