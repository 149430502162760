import { GeoZones } from '@EcamModel/model';
import NoDataIcon from '@assets/jsx-icon/NoDataIcon';
import { appConfig } from '@configs/index';
import usePopUp from '@hooks/usePopUp';
import { Box, CircularProgress, Stack, Typography, colors } from '@mui/material';
import { PreviewImageWithGeoZone } from '@pages/cameras/details/components/HistoriesPhoto';
import { getCenterFrame } from '@pages/cameras/details/utils';
import PreviewImage, { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import color from '@theme/Colors';
import Konva from 'konva';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { Circle, Image as KonvaImage, Layer, Line, Stage, Text } from 'react-konva';

type ThumbnailWithGeoZoneProps = { image: HTMLImageElement; geoZones: GeoZones[] };

const ThumbnailWithGeoZone = (props: ThumbnailWithGeoZoneProps) => {
    const { image } = props;
    const stageRef = useRef<Konva.Stage>(null);

    const scale = 208 / image.width;
    const previewScale = 1280 / image.width;

    const scaledGeoZones = (_scale = scale) => {
        return props.geoZones
            .map((z) => z.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(z.GeoZoneCoordinates[0]))
            .map((c) => c!.map((p) => ({ x: p.XPoint * _scale, y: p.YPoint * _scale, geoZoneId: p.GeoZoneId })));
    };

    const popUpPreview = usePopUp();

    return (
        <Box>
            <Stage
                ref={stageRef}
                width={208}
                height={117}
                style={{ borderRadius: '5px', overflow: 'hidden', cursor: 'pointer' }}
                onClick={popUpPreview.setTrue}
            >
                <Layer>
                    <KonvaImage image={image} scale={{ x: scale, y: scale }} />
                    {scaledGeoZones(scale).map((frame, frameIndex) => {
                        const center = getCenterFrame(frame);
                        return (
                            <React.Fragment key={`frame-${frameIndex}`}>
                                <Line points={frame?.flatMap((point) => [point.x, point.y])} stroke="blue" />
                                <Circle x={center.x} y={center.y} radius={10} fill="#fff" />
                                <Text
                                    x={center.x}
                                    y={center.y}
                                    text={String(`${frameIndex + 1}`)}
                                    fontSize={14}
                                    fill={'black'}
                                    align={'center'}
                                    verticalAlign={'middle'}
                                    offsetX={4}
                                    offsetY={6}
                                />
                            </React.Fragment>
                        );
                    })}
                </Layer>
            </Stage>

            <PreviewImageWithGeoZone
                {...popUpPreview}
                imgSrc={image}
                scaleX={previewScale}
                scaleY={previewScale}
                geoZones={scaledGeoZones(previewScale)}
            />
        </Box>
    );
};

type Props = {
    blob?: string;
    geoZones: GeoZones[];
};

const CameraThumbnail = (props: Props) => {
    const { blob = '' } = props;
    console.log(`blob:`, blob);
    const [previewImage, setPreviewImage] = useState('');
    const { image, loading, isError } = useImageDimensions(props.blob);
    console.log(`previewImage:`, previewImage);

    return (
        <Box>
            <Stack
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ width: 208, height: 117, borderRadius: '6px', backgroundColor: colors.grey[50] }}
            >
                {loading ? (
                    <CircularProgress size={20} sx={{ margin: 'auto' }} />
                ) : image ? (
                    <ThumbnailWithGeoZone image={image} geoZones={props.geoZones} />
                ) : (
                    <>
                        <NoDataIcon />
                        <Typography sx={{ color: color.grey600 }} textAlign={'center'}>
                            No data
                        </Typography>
                    </>
                )}
            </Stack>
            {previewImage && <PreviewImage blob={previewImage} onClose={() => setPreviewImage('')} />}
        </Box>
    );
};

export default CameraThumbnail;

// <img
//     src={image!.src}
//     width={'100%'}
//     height={117}
//     style={{ objectFit: 'cover', borderRadius: 'inherit', maxWidth: 208, cursor: 'pointer' }}
//     alt="thumbnail"
//     onClick={() => setPreviewImage(blob)}
//     // onError={}
// />
