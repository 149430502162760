import { MINIMUM_SHAPE_SIZE } from '@components/FrameBlur';
import Konva from 'konva';
import React, { useEffect, useRef } from 'react';
import { Transformer, Rect } from 'react-konva';

export type Shape = {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
};

interface SelectRectangleProps {
    shape: Shape;
    isSelected: boolean;
    onRemove?(): void;
    onSelect(): void;
    onChange(value: Shape): void;
}

const SelectRectangle = ({ shape, isSelected, onRemove, onSelect, onChange }: SelectRectangleProps) => {
    const shapeRef = useRef<Konva.Rect>(null);
    const transformerRef = useRef<Konva.Transformer>(null);

    useEffect(() => {
        if (isSelected && shapeRef.current && transformerRef.current) {
            // we need to attach transformer manually
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer()!.batchDraw();
        }
    }, [isSelected]);

    return (
        <>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shape}
                draggable
                fill="transparent"
                onDragEnd={(e) => {
                    onChange({
                        ...shape,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onDragStart={onSelect}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current!;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shape,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(MINIMUM_SHAPE_SIZE, node.width() * scaleX),
                        height: Math.max(MINIMUM_SHAPE_SIZE, node.height() * scaleY),
                    });
                }}
                stroke="purple"
                strokeWidth={isSelected ? 2 : 0}
            />
            {isSelected && (
                <Transformer
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                    keepRatio={false}
                    ref={transformerRef}
                    rotateEnabled={false}
                />
            )}
        </>
    );
};

export default SelectRectangle;
