import {
    ICancelGroupPcnForChangePlate,
    ICancelGroupPotentialContraventionDetail,
    VRNDetail,
} from '@EcamModel/controllers/IGroupPotentialContraventionHttpController';
import {
    GroupPotentialContraventions,
    PcnDuplicateWithChangePlate,
    PotentialContraventionLookUp,
    PotentialContraventionWithInfo,
} from '@EcamModel/model';
import { pushSuccess, pushWarning } from '@components/toast';
import { cancelationReasonController, potentialGroupContraventionController } from '@controllers/index';
import { orderBy } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useThrottle } from 'rooks';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { LocationState, PCGroup, PCInfo, PcGroupStateItem, PcGroupStatus } from '..';
import useModal from './useModal';
import { Zones } from '@EcamModel/model/Zones';
import moment from 'moment';

type Props = {
    readonly?: boolean;
};

export default function usePotentialContraventionContext(props: Props = {}) {
    const { readonly } = props;
    const params = useParams<{ contraventionId: string; groupPcId: string }>();
    const location = useLocation();
    const state: LocationState | null = location.state;
    const backdrop = useBackdrop();
    const navigate = useNavigate();

    const contraventionId = parseInt(params.contraventionId || params.groupPcId || '-1');

    const [isLoadingLookUp, setIsLoadingLookUp] = useState(false);
    const [isShowLookup, setIsShowLookup] = useState(false);
    const [isShowForeignPlate, setIsShowForeignPlate] = useState(false);
    const [duplicate, setDuplicate] = useState<PcnDuplicateWithChangePlate>({
        pcnDuplicate: [],
        isChangePlate: false,
    } as PcnDuplicateWithChangePlate);
    const popupWarningDuplicate = useModal();
    const popupChangePlace = useModal();

    const [groupPcIds, setGroupPcIds] = useState<PcGroupStateItem[]>(state?.pcIds?.map((id) => ({ Id: id })) || []);
    const [groupPc, setGroupPc] = useState<PCGroup>();
    const [selectedPc, setSelectedPc] = useState<PCInfo>();
    const [isLatestItem, setIsLatestItem] = useState(false);
    const [updatingPlate, setUpdatingPlate] = useState(false);

    const step = groupPc?.PotentialContraventions?.[0].Step;
    const zoneId = groupPc?.PotentialContraventions?.[0].ZoneId;

    useEffect(() => {
        if (readonly || groupPcIds.length || !step || !zoneId) return;
        potentialGroupContraventionController
            .getGroupPotentialContraventionIds({ Step: step, ZoneId: zoneId })
            .then((res) => {
                setGroupPcIds(res.Ids.map((id) => ({ Id: id })));
            });
    }, [groupPcIds.length, zoneId, step, readonly]);

    const [conditionExemptionsEnabled, setConditionExemptionsEnabled] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        backdrop.setTrue();
        potentialGroupContraventionController
            .getGroupPotentialContravention(contraventionId)
            .then((res) => {
                if (!res) {
                    navigate('/verification');
                    return;
                }
                const _res: GroupPotentialContraventions = {
                    ...res,
                    PotentialContraventions: orderBy(res.PotentialContraventions, (pc) => -pc.Step),
                };
                console.log(`_res:`, _res);
                setGroupPc(_res);
                setSelectedPc(_res.PotentialContraventions![0] as PCInfo);
                const permitExemptionsEnabled = checkPermitExemptionsEnabled(_res.Zone!);
                setConditionExemptionsEnabled(permitExemptionsEnabled);
            })
            .catch((err) => navigate('/verification'))
            .finally(() => backdrop.setFalse());
    }, [contraventionId, navigate]);

    const checkPermitExemptionsEnabled = (zone: Zones) => {
        if (!zone || !zone.Cameras) {
            return false;
        }
        for (const camera of zone.Cameras) {
            if (camera.GeoZones) {
                for (const geoZone of camera.GeoZones) {
                    if (geoZone.PermitExemptionsEnabled) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const fetchCancellationReasons = async () => {
        const response = await cancelationReasonController.list({ pageSize: 10000 });
        return response.rows;
    };

    const { data: cancellationReasons } = useQuery('cancellationReasons', fetchCancellationReasons, {
        // initialData: [], // Dữ liệu mặc định khi đang loading
        // staleTime: Infinity,
        // enabled: !readonly,
        staleTime: 60 * 60 * 1000,
    });
    console.log(`cancellationReasons:`, readonly);

    const updateGrPcIds = (id: number, status: PcGroupStatus) => {
        setGroupPcIds((prev) => {
            return prev.map((p) => (p.Id === id ? { ...p, Status: status } : p));
        });
    };

    const handleNext = () => {
        const nextIndex = groupPcIds.findIndex((gr) => gr.Id === groupPc?.Id) + 1;
        const nextId = groupPcIds[nextIndex]?.Id;

        if (!nextId) {
            setIsLatestItem(true);
            pushWarning('This item is the last item in the list');
            backdrop.setTrue();
            setTimeout(() => {
                backdrop.setFalse();
                navigate(`/verification`);
            }, 2000);
            return;
        } else {
            navigate(`/verification/contravention/${nextId}`, { replace: true });
        }
    };

    const handleApprove = (pc: PCInfo) => {
        backdrop.setTrue();
        potentialGroupContraventionController
            .doApproveGroupPotentialContravention({ PotentialContraventionId: pc.Id!, IsForeignPlate: pc.IsForeign })
            .then((res) => {
                updateGrPcIds(groupPc!.Id!, 'Approve');
                pushSuccess('Approve PCN successfully');
                handleNext();
            })
            .catch((error) => {
                console.log(error);
                backdrop.setFalse();
            });
    };
    const [throttleApprove] = useThrottle(handleApprove, 2000);

    const handleCancel = (props: ICancelGroupPotentialContraventionDetail) => {
        backdrop.setTrue();
        potentialGroupContraventionController
            .doCancelGroupPotentialContravention(props)
            .then((res) => {
                updateGrPcIds(props.Id, 'Cancel');
                pushSuccess('Cancel PCN successfully ');
                handleNext();
            })
            .catch(() => {
                backdrop.setFalse();
            });
    };
    const [throttleCancel] = useThrottle(handleCancel, 2000);

    const handleChangePc = (pc: Partial<PCInfo>) => {
        setGroupPc((prev) => ({
            ...prev!,
            PotentialContraventions: prev?.PotentialContraventions?.map((_) => ({ ..._, ...pc })),
        }));
        setSelectedPc((prev) => ({ ...prev!, ...pc }));
    };

    const { pathname } = location;
    const conditionAudit = pathname.includes('audit');

    const handleChangePlate = async (
        vrn: VRNDetail,
        isForeign?: boolean,
        isLookup?: boolean,
        isCheckedVerificationAudit?: boolean
    ) => {
        setUpdatingPlate(true);
        await potentialGroupContraventionController
            .doChangePlateGroupPotentialContravention({
                Id: groupPc!.Id!,
                Detail: vrn,
                IsForeignPlate: isForeign,
                IsVerificationAudit: conditionAudit,
            })
            .then((res) => {
                setGroupPc((prev) => ({
                    ...prev!,
                    IsPOPlate: res.isPOPlate,
                    PotentialContraventions: (prev?.PotentialContraventions as PotentialContraventionWithInfo[]).map(
                        (_) => ({ ..._, PCNDuplicate: res.pcnDuplicate })
                    ),
                }));
                setSelectedPc((prev) => ({
                    ...prev!,
                    PermitData: res.PermitData,
                }));

                handleChangePc({
                    Plate: vrn.Vrn,
                    VehicleMake: vrn.Make,
                    VehicleColor: vrn.Color,
                    VehicleModel: vrn.Model,
                    IsForeign: isForeign,
                });
                popupChangePlace.handleClose();

                if (!res.pcnDuplicate.length && !res.PermitData?.hasPermit) {
                    pushSuccess('Successfully!');
                }
                if (res.pcnDuplicate.length) {
                    pushWarning('Has duplicate');
                }
                if (res.PermitData?.hasPermit) {
                    pushWarning('Has permit');
                }
            })
            .catch(() => {
                backdrop.setFalse();
            })
            .finally(() => {
                setUpdatingPlate(false);
            });
    };

    const handleChangeForeign = (isForeign: boolean) => {
        handleChangePc({ IsForeign: isForeign });
    };

    const skippedList = groupPcIds.filter((gr) => !gr.Status);

    const handleContinue = () => {
        setIsLatestItem(false);
        setGroupPcIds(skippedList);
        navigate(`/verification/contravention/${skippedList[0].Id}`, { replace: true });
    };

    const handleCancelDuplicate = async (props: ICancelGroupPcnForChangePlate) => {
        backdrop.setTrue();
        await potentialGroupContraventionController
            .doCancelGroupPcnForChangePlate(props)
            .then((_) => {
                updateGrPcIds(props.Id, 'Cancel');
                pushSuccess('Cancel PCN successfully ');
                handleNext();
            })
            .catch(() => {
                backdrop.setFalse();
            })
            .finally(() => {
                popupWarningDuplicate.handleClose();
            });
    };
    const [throttleCancelDuplicate] = useThrottle(handleCancelDuplicate, 2000);
    const handleClose = () => {
        setIsLoadingLookUp(false);
        setIsShowLookup(false);
        setIsShowForeignPlate(false);
    };

    const [vrnPlate, setVrnPlate] = useState(selectedPc?.Plate);

    const [isForeignPlate, setIsForeignPlate] = useState(false);
    const [updateChange, setUpdateChange] = useState(false);

    const [vehicleLookup, setVehicleLookup] = useState<PotentialContraventionLookUp>(
        {} as PotentialContraventionLookUp
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setVrnPlate(inputValue.toUpperCase());
        setIsShowLookup(false);
        setIsShowForeignPlate(false);
        setIsForeignPlate(false);
    };

    const handleUpdateChange = async () => {
        await handleChangePlate({ ...vehicleLookup, Vrn: vrnPlate! }, isForeignPlate);
    };

    const [searchParams] = useSearchParams();

    const contraventionReason = searchParams.get('contraventionReason');
    const fromDate = searchParams.get('fromDate')
        ? moment(searchParams.get('fromDate'), 'DD-MM-YYYY HH:mm:ss').toDate()
        : null;
    const toDate = searchParams.get('toDate')
        ? moment(searchParams.get('toDate'), 'DD-MM-YYYY HH:mm:ss').toDate()
        : null;
    const zonesIds = searchParams.get('zones') ? searchParams.get('zones')!.split(',').map(Number) : [];
    const emailVerifier = searchParams.get('emailVerifier');

    return {
        groupPc,
        setGroupPc,
        selectedPc,
        setSelectedPc,
        isLatestItem,
        updatingPlate,
        skippedList,
        cancellationReasons,
        handleNext,
        handleContinue,
        handleChangePlate,
        handleChangeForeign,
        throttleApprove,
        throttleCancel,
        duplicate,
        setDuplicate,
        throttleCancelDuplicate,
        popupWarningDuplicate,
        popupChangePlace,
        setIsLoadingLookUp,
        setIsShowLookup,
        setIsShowForeignPlate,
        isLoadingLookUp,
        isShowLookup,
        isShowForeignPlate,
        handleClose,
        isForeignPlate,
        setIsForeignPlate,
        updateChange,
        setUpdateChange,
        vehicleLookup,
        setVehicleLookup,
        vrn: vrnPlate,
        setVrn: setVrnPlate,
        handleInputChange,
        handleUpdateChange,
        handleChangePc,
        conditionExemptionsEnabled,

        conditionAudit,
        contraventionReason,
        toDate,
        fromDate,
        zonesIds,
        emailVerifier,
        setIsLatestItem,
        searchParams,

        updateGrPcIds,
    };
}
type PotentialContraventionContextType = ReturnType<typeof usePotentialContraventionContext>;

export const PotentialContraventionContext = createContext<PotentialContraventionContextType>(
    {} as PotentialContraventionContextType
);

export const usePotentialContravention = () => useContext(PotentialContraventionContext);
