import IcEdit from '@assets/details-camera-icons/IcEdit';
import ICDelete from '@assets/jsx-icon/ICDelete';
import ICNote from '@assets/jsx-icon/ICNote';
import BaseListNote, { tableHeaders } from '@components/BaseListNote';
import PopUpWarning from '@components/PopUpWarning';
import { CellOfRow } from '@components/tables';
import { pushError, pushSuccess } from '@components/toast';
import { verifierNoteHttpController } from '@controllers/index';
import { Paging } from '@Core';
import { VerificationNote } from '@EcamModel/model/VerificationNote';
import usePopUp from '@hooks/usePopUp';
import { Box, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Filter } from '@pages/cameras/list';
import { DangerTooltip } from '@pages/cameras/list/components/TooltipDangerIconButton';
import { useContext, useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { formatDateTime, getFirstAndLastDayOfCurrentWeek } from 'src/helpers';
import { PotentialContraventionContext } from '../hooks/PotentialContraventionProvider';
import DetailInfoSection from './DetailInfoSection';
import { Mode } from './PCDetail';
import PopUpAddNoteVerifier from './PopUpAddNoteVerifier';
import { useBackdrop } from 'src/providers/BackdropProvider';

export default function NoteVerifier() {
    const [mode, setMode] = useState<Mode>(Mode.create);
    const popUpAddOrUpdateNoteVerifier = usePopUp();
    const popUpConfirmDeleteNoteHistory = usePopUp();

    const pcContext = useContext(PotentialContraventionContext);
    const backdrop = useBackdrop();

    const { groupPc } = pcContext;

    const { fromCurrentWeek, toCurrentWeek } = getFirstAndLastDayOfCurrentWeek();

    const [filter, setFilter] = useState<Partial<Filter>>({
        page: 1,
        from: fromCurrentWeek,
        to: toCurrentWeek,
        search: '',
    });

    const [idNote, setIdNote] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [listNotes, setListNotes] = useState<Paging<VerificationNote>>({} as Paging<VerificationNote>);

    const handleGetListNote = async () => {
        setIsLoading(true);
        await verifierNoteHttpController
            .list({
                page: filter.page ?? 1,
                pageSize: 10,
                sorts: ['-Updated'],
                filter: {
                    GroupPotentialContraventionId:
                        groupPc?.PotentialContraventions![0].GroupPotentialContraventionId ?? 0,
                },
            })
            .then((res) => {
                setListNotes(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleGetListNote();
    }, [filter.page]);

    const handleAddOrUpdateNote = async (note: string, idNote?: number) => {
        setIsLoading(true);
        const data: VerificationNote = {
            Id: mode === Mode.edit ? idNote : undefined,
            Notes: note,
            GroupPotentialContraventionId: groupPc?.PotentialContraventions![0].GroupPotentialContraventionId ?? 0,
        };
        await verifierNoteHttpController
            .upsert(data)
            .then(async (res) => {
                if (filter.page !== 1) {
                    setFilter({
                        page: 1,
                        from: filter.from,
                        to: filter.to,
                    });
                } else {
                    await handleGetListNote();
                }
                if (idNote) {
                    pushSuccess('Updated successfully');
                } else {
                    pushSuccess('Add successfully');
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const handleDeleteNote = async (idNote: number) => {
        backdrop.setTrue();
        await verifierNoteHttpController
            .delete(String(idNote))
            .then(async () => {
                if (filter.page !== 1) {
                    setFilter({
                        page: 1,
                    });
                } else {
                    await handleGetListNote();
                }
                pushSuccess('Deleted successfully');
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    return (
        <Stack>
            <DetailInfoSection
                icon={<ICNote />}
                title="Verifier note"
                action={
                    <>
                        <Tooltip title="Add note" placement="top" arrow>
                            <IconButton
                                sx={{
                                    borderRadius: '3px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'white',
                                    backgroundColor: '#2E7D32',
                                    ':hover': {
                                        backgroundColor: '#256428',
                                    },
                                    padding: '4px',
                                    '&.MuiIconButton-root.Mui-disabled': {
                                        backgroundColor: '#eee',
                                    },
                                }}
                                onClick={() => {
                                    setMode(Mode.create);
                                    popUpAddOrUpdateNoteVerifier.setTrue();
                                }}
                            >
                                <MdAdd style={{ width: '16px', height: '16px' }} />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                content={
                    <BaseListNote
                        paging={listNotes}
                        isLoading={isLoading}
                        tableHeaders={[...tableHeaders, '']}
                        styleContainer={{ mt: 2, mb: 5 }}
                        setPage={(page) => {
                            setFilter((prev) => ({ ...prev, page }));
                        }}
                        renderedItem={(item) => {
                            return (
                                <>
                                    <CellOfRow value={formatDateTime(item.Created!)} align="left" />
                                    <CellOfRow value={item.CreatedBy ?? ''} align="left" />
                                    <CellOfRow value={formatDateTime(item.Updated!)} align="left" />
                                    <TableCell
                                        align="left"
                                        sx={{
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        <Typography>{item.Notes.trim() ?? ''}</Typography>
                                    </TableCell>
                                    <CellOfRow
                                        value={
                                            <>
                                                <Stack direction={'row'} spacing={0.7} alignItems={'center'}>
                                                    <Tooltip placement="top" title="Edit">
                                                        <Box
                                                            sx={{
                                                                color: '#85858A',
                                                                transition: '.2s ease-in-out',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    color: (theme) => theme.palette.secondary.main,
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setMode(Mode.edit);
                                                                setIdNote(item.Id ?? 0);
                                                                popUpAddOrUpdateNoteVerifier.setTrue();
                                                            }}
                                                        >
                                                            <IcEdit />
                                                        </Box>
                                                    </Tooltip>
                                                    <DangerTooltip placement="top" title="Delete">
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setIdNote(item.Id ?? 0);
                                                                popUpConfirmDeleteNoteHistory.setTrue();
                                                            }}
                                                        >
                                                            <ICDelete fontSize={18} />
                                                        </Box>
                                                    </DangerTooltip>
                                                </Stack>
                                            </>
                                        }
                                        align="left"
                                    />
                                </>
                            );
                        }}
                    />
                }
            />
            <PopUpAddNoteVerifier
                {...popUpAddOrUpdateNoteVerifier}
                mode={mode}
                listNotes={listNotes}
                groupPc={groupPc}
                idNote={idNote}
                handleAddOrUpdateNote={handleAddOrUpdateNote}
            />
            <PopUpWarning
                {...popUpConfirmDeleteNoteHistory}
                title="Confirm"
                message="Are you sure you want to delete this note?"
                onClose={() => {
                    setIdNote(0);
                    popUpConfirmDeleteNoteHistory.onClose();
                }}
                onConfirm={() => {
                    if (idNote) {
                        handleDeleteNote(idNote);
                        popUpConfirmDeleteNoteHistory.onClose();
                    } else {
                        pushError('Id note does not exist');
                    }
                }}
            />
        </Stack>
    );
}
