import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcDeployed(props: SvgIconProps) {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.37405 2.46994C1.79646 2.16304 2.30572 2.0041 3 1.92177V2.5C3 3.05228 3.44772 3.5 4 3.5C4.55229 3.5 5 3.05228 5 2.5V1.8352C5.3094 1.83333 5.64187 1.83333 6 1.83333C6.35813 1.83333 6.6906 1.83333 7 1.8352V2.5C7 3.05228 7.44772 3.5 8 3.5C8.55229 3.5 9 3.05228 9 2.5V1.92177C9.69428 2.0041 10.2035 2.16304 10.626 2.46994C10.9089 2.67554 11.1578 2.9244 11.3634 3.20738C11.7396 3.72522 11.8935 4.37358 11.9564 5.33333H0.0435574C0.106496 4.37358 0.260377 3.72522 0.63661 3.20738C0.842209 2.9244 1.09107 2.67554 1.37405 2.46994Z"
                fill="#85858A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.00713202 6.33319C0 6.7772 0 7.27398 0 7.83333C0 10.3332 0 11.5831 0.63661 12.4593C0.842209 12.7423 1.09107 12.9911 1.37405 13.1967C2.25027 13.8333 3.50018 13.8333 6 13.8333C6.5593 13.8333 7.05602 13.8333 7.5 13.8262L7.5 13.7795C7.49995 13.2114 7.49993 12.8655 7.54719 12.567C7.80699 10.9267 9.09341 9.64032 10.7337 9.38053C11.0321 9.33326 11.3781 9.33329 11.9461 9.33333L11.9929 9.33333C12 8.88936 12 8.39263 12 7.83333C12 7.27397 12 6.7772 11.9929 6.33318L11.9806 6.33333H0.0192057L0.00713202 6.33319ZM4 8C3.72386 8 3.5 8.22386 3.5 8.5C3.5 8.77614 3.72386 9 4 9H8C8.27614 9 8.5 8.77614 8.5 8.5C8.5 8.22386 8.27614 8 8 8H4Z"
                fill="#85858A"
            />
            <path
                d="M10.626 13.1967C10.1081 13.573 9.45975 13.7268 8.5 13.7898C8.50009 13.1829 8.50232 12.929 8.53488 12.7234C8.7269 11.5111 9.67774 10.5602 10.8901 10.3682C11.0957 10.3357 11.3496 10.3334 11.9564 10.3333C11.8935 11.2931 11.7396 11.9414 11.3634 12.4593C11.1578 12.7423 10.9089 12.9911 10.626 13.1967Z"
                fill="#85858A"
            />
            <path
                d="M4.5 0.5C4.5 0.223858 4.27614 0 4 0C3.72386 0 3.5 0.223858 3.5 0.5V2.5C3.5 2.77614 3.72386 3 4 3C4.27614 3 4.5 2.77614 4.5 2.5V0.5Z"
                fill="#85858A"
            />
            <path
                d="M8.5 0.5C8.5 0.223858 8.27614 0 8 0C7.72386 0 7.5 0.223858 7.5 0.5V2.5C7.5 2.77614 7.72386 3 8 3C8.27614 3 8.5 2.77614 8.5 2.5V0.5Z"
                fill="#85858A"
            />
        </svg>
    );
}
