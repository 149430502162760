import { CameraWithInfo } from '@EcamModel/model';
import IcEdit from '@assets/details-camera-icons/IcEdit';
import usePopUp from '@hooks/usePopUp';
import { Box, CircularProgress, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import useCaptureManagementProvider, { CaptureManagementContext } from '@pages/cameras/hook/Solar4gCameraProvider';
import color from '@theme/Colors';
import { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import CaptureManagementMilesight from '../CaptureManagementMilesight';
import CellularMilesight from '../CellularMilesight';
import CustomTabPanel from '../CustomTabPanel';
import DateAndTimeMilesight from '../DateAndTimeMilesight';
import PopUpEditMilesightAccount from '../PopUpEditMilesightAccount';

interface Props {
    camera: CameraWithInfo;
    blobName: string | undefined;
}

export default function TabDetailedInformationOfMilesight(props: Props) {
    const { blobName, camera } = props;

    const [value, setValue] = useState(1);

    const captureContext = useCaptureManagementProvider();
    const { isLoading } = captureContext;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <CaptureManagementContext.Provider value={captureContext}>
            <Stack flex={1} spacing={3} padding={2} borderRadius={2} bgcolor={color.white} border={`1px solid #E4E7EC`}>
                <Box>
                    <Typography variant="h4">More information</Typography>
                    <Typography color={color.grey600}>Detailed camera information</Typography>
                </Box>

                {isLoading ? (
                    <Stack direction="row" width="100%" justifyContent="center" alignItems="center" minHeight={600}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <>
                        <Tabs
                            sx={{
                                '& .MuiButtonBase-root': {
                                    minWidth: '0 !important',
                                    px: 1.5,
                                    fontSize: 14,
                                    background: color.grey100,
                                    color: color.grey600,
                                },
                                '& .MuiTabs-indicator': {
                                    background: color.success,
                                },

                                '& .Mui-selected': {
                                    color: `${color.success} !important`,

                                    '&.MuiTab-root': {
                                        background: color.lightPrimary,
                                    },
                                },
                                '& .base-Tab-root': {
                                    marginTop: '0 !important',
                                },
                                '&.MuiTabs-root': {
                                    minHeight: 'auto',
                                },
                            }}
                            value={value}
                            onChange={handleChange}
                        >
                            <Tab value={1} label="Cellular" />
                            <Tab value={2} label="Capture management" />
                            <Tab value={3} label="Date & Time" />
                        </Tabs>
                        <CustomTabPanel value={value} index={1}>
                            <CellularMilesight />
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={2}>
                            <CaptureManagementMilesight blobName={blobName} camera={camera} />
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={3}>
                            <DateAndTimeMilesight />
                        </CustomTabPanel>
                    </>
                )}
            </Stack>
        </CaptureManagementContext.Provider>
    );
}
