import { ListProps, Paging } from '@Core';
import {
    BatteryStatus,
    Camera,
    CameraPhotos,
    CameraWithInfo,
    CaptureInfoUploadMode,
    PurposeType,
    Status,
    WifiStatus,
} from '../model';

export interface IGetCameraProps extends ListProps<Camera> {
    Status?: Status;
    LocationId?: number;
    ZoneIds?: number[];
    Battery?: BatteryStatus;
    Wifi?: WifiStatus;
    From?: Date;
    To?: Date;
    Mac?: string;
    NotUpdatedToday?: CameraUpdatedToday;
    SortByField?: SortByField;
    SortByValue?: SortByValue;
    UploadMode?: CaptureInfoUploadMode;
    Purposes?: PurposeType;
}

export enum SortByValue {
    Desc,
    Asc,
}

export enum SortByField {
    Zone,
    BatteryLevel,
    Updated,
    CameraName,
}

export enum CameraUpdatedToday {
    None,
    Updated,
}

export interface IUpsertCameraProps extends Camera {
    Mac: string;
}

export interface IUpdateConfigIsVerificationsEnabled {
    CameraId: number;
    Enable: boolean;
}

export interface IUpdateConfigIsVerificationsEnabledResponse {
    IsVerificationsEnabled: boolean;
    EnablePcnVerificationAt: Date;
}

export interface ICameraHttpController {
    changeStatus(id: number): Promise<Camera>;
    upsertWithSolarCamera(props: IUpsertCameraProps): Promise<CameraWithInfo>;
    list(props: IGetCameraProps): Promise<Paging<CameraWithInfo>>;
    wakeCameraByManually(id: number);
    getLatestCameraPhoto(cameraId: number): Promise<CameraPhotos>;

    checkANPRCameraExisted(anprCameraId: number): Promise<boolean>;
    updateConfigIsVerificationsEnabled(
        props: IUpdateConfigIsVerificationsEnabled
    ): Promise<IUpdateConfigIsVerificationsEnabledResponse>;
}
