import { ListProps, Paging } from '@Core';
import { IUpsertCameraProps } from '@EcamModel/controllers/ICameraHttpController';
import { IGetLocationProps } from '@EcamModel/controllers/ILocationHttpController';
import { CameraServiceType, SolarPanelPositionType, Status } from '@EcamModel/model';
import CancelIcon from '@assets/CancelIcon';
import IcDeployed from '@assets/details-camera-icons/IcDeployed';
import IcGPS from '@assets/details-camera-icons/IcGPS';
import IcInfo from '@assets/details-camera-icons/IcInfo';
import CameraIcon from '@assets/drawer-icon/CameraIcon';
import { BaseTextField } from '@components/BaseTextField';
import IOSSwitch from '@components/IOSSwitch';
import StyledAutocomplete from '@components/styled-autocomplete';
import { cameraController, cameraInstalledPhotoHttpController, locationController } from '@controllers/index';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushError, pushSuccess, pushWarning } from '@components/toast';
import { CancelOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import styled from '@emotion/styled';
import useScrollToTop from '@hooks/useScrollToTop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
    Autocomplete,
    Box,
    Button,
    Collapse,
    Dialog,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import color from '@theme/Colors';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { validateTypingIPAddress, validateTypingInput } from '../details/utils';
import { search } from '../list/helpers';
import myJson from './timezones.json';
import { IListWithECamServiceActive } from '@EcamModel/controllers/ILocationSMHttpController';
import { ServiceSMType } from '@EcamModel/model/ServiceSM';
import UploadImageIcon from '@assets/jsx-icon/UploadImageIcon';

export type ServiceTypeLabel = {
    key: CameraServiceType;
    label: string;
};

export type PositionSolarPanelLabel = {
    key: SolarPanelPositionType;
    label: string;
};

export const camServiceTypes: Record<CameraServiceType, ServiceTypeLabel> = {
    [CameraServiceType.ECamTechOps]: {
        key: CameraServiceType.ECamTechOps,
        label: 'eCam TechOps',
    },
    [CameraServiceType.ANPRTechOps]: {
        key: CameraServiceType.ANPRTechOps,
        label: 'ANPR TechOps',
    },
};

export const positionSolarPanelReadableMapping: Record<SolarPanelPositionType, PositionSolarPanelLabel> = {
    [SolarPanelPositionType.NNE]: {
        key: SolarPanelPositionType.NNE,
        label: 'NNE (North-North-East)',
    },
    [SolarPanelPositionType.ENE]: {
        key: SolarPanelPositionType.ENE,
        label: 'ENE (East-North-East)',
    },
    [SolarPanelPositionType.ESE]: {
        key: SolarPanelPositionType.ESE,
        label: 'ESE (East-South-East)',
    },
    [SolarPanelPositionType.SSE]: {
        key: SolarPanelPositionType.SSE,
        label: 'SSE (South-South-East)',
    },
    [SolarPanelPositionType.SSW]: {
        key: SolarPanelPositionType.SSW,
        label: 'SSW (South-South-West)',
    },
    [SolarPanelPositionType.WSW]: {
        key: SolarPanelPositionType.WSW,
        label: 'WSW (West-South-West)',
    },
    [SolarPanelPositionType.WNW]: {
        key: SolarPanelPositionType.WNW,
        label: 'WNW (West-North-West)',
    },
    [SolarPanelPositionType.NNW]: {
        key: SolarPanelPositionType.NNW,
        label: 'NNW (North-North-West)',
    },
};

type FormValues = {
    name: string;
    mac: string;
    assetName: string;
    ip: string;

    serviceType: ServiceTypeLabel;
    anprId: string;

    userName: string;
    passWord: string;

    location?: any;
    zone?: any;
    time?: TimeZone;

    deployedDate: Moment;
    lensCapacity?: string;

    note?: string;

    createVerification: boolean;

    solarPanelPosition: PositionSolarPanelLabel;

    cameraInstalledPhotos?: Express.Multer.File[];
};

type GMT = {
    Name: string;
    Value: string;
};

interface TimeZone {
    value: string;
    utc: string;
    text: string;
}

export const RequiredTitle = (props: { title: string; required?: boolean; sx?: TypographyProps }) => {
    return (
        <Typography sx={{ lineHeight: '40px', width: 110, ...props.sx }}>
            {props.title}
            {props.required && <span style={{ color: 'red' }}>*</span>}:
        </Typography>
    );
};

export const validateDatePickerValue = (date: Date) => {
    const now = moment().startOf('date');
    const isValidDate = moment(date).isBefore(now);
    if (isValidDate) return 'Not valid date';
    return null;
};
export const validateDatePickerPastDate = (date: Date) => {
    const now = moment().startOf('date');
    const isValidDate = moment(date).isSameOrBefore(now);
    if (isValidDate) return 'Not valid date';
    return null;
};

const maxImageSize = 10 * 1024 * 1024;
const maxImageWidth = 8000;
const maxImageHeight = 4000;

export default function CameraUpsert() {
    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        setError,
        clearErrors,
        formState: { isDirty, isValid, errors, dirtyFields },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            name: '',
            mac: '',
            assetName: '',
            ip: '',
            anprId: '',
            serviceType: camServiceTypes[CameraServiceType.ECamTechOps],
            userName: '',
            passWord: '',
            location: undefined,
            zone: undefined,
            time: undefined,
            deployedDate: moment(),
            lensCapacity: '',
            note: '',
            createVerification: true,
            solarPanelPosition: undefined,
            cameraInstalledPhotos: undefined,
        },
    });
    const { locationId } = useParams();

    console.log(`dirtyFields:`, { isDirty, isValid, errors, dirtyFields });

    const [zones, setZones] = useState<any[]>([]);
    const [showPassword, setShowPassword] = useState(false);
    const isANPR = watch('serviceType.key') === CameraServiceType.ANPRTechOps;
    const [selectedItem, setSelectedItem] = useState<string | undefined>();

    useScrollToTop();

    const navigate = useNavigate();
    const backdrop = useBackdrop();

    const openBackdrop = () => {
        const message = <Typography>Please wait for the camera to take a picture...</Typography>;
        backdrop.setTrue(message);
    };

    const disabled = !(isValid && isDirty);

    const fetchGMTTime = (listProps?: ListProps<GMT>) => {
        return new Promise<Paging<GMT>>((resolve, reject) => {
            setTimeout(() => {
                const paging: Paging<GMT> = {
                    page: 1,
                    pageSize: 9999,
                    rows: [],
                    total: 0,
                    totalPages: 1,
                };

                for (let i = -12; i < 14; i++) {
                    const Value = i.toString();
                    const Name = `UTC${i >= 0 ? '+' : ''}${i}`;

                    paging.rows.push({
                        Name: Name,
                        Value: Value,
                    });
                }
                // const arr = JSON.parse(myJson);

                const searchText = listProps?.search?.content;

                if (searchText) {
                    paging.rows = paging.rows.filter((l) => l.Name.toLowerCase().includes(searchText.toLowerCase()));
                }

                resolve(paging);
            }, 300);
        });
    };

    const getSelectLocations = async (props: IListWithECamServiceActive) => {
        const filteredLocation = await locationController
            .listWithECamServiceActive({
                ...props,
                pageSize: 1,
                filter: {
                    Id: Number(locationId) ?? undefined,
                },
            })
            .finally(() => {});

        const filteredLocationSelected = filteredLocation?.rows?.filter((z) => z.Id === Number(locationId));

        setValue('location', filteredLocation?.rows?.[0]);
        setValue('zone', filteredLocationSelected[0]?.Zones?.[0] ?? undefined);
        setZones(filteredLocationSelected[0]?.Zones ?? []);

        return filteredLocation;
    };

    useEffect(() => {
        if (locationId) {
            getSelectLocations({
                ZoneService: [isANPR ? ServiceSMType.Anpr : ServiceSMType.eCam],
            });
        }
    }, [isANPR]);

    const locationAutocomplete = useStyledAutocomplete({
        async getList(props: IListWithECamServiceActive): Promise<Paging<any>> {
            const res = await locationController.listWithECamServiceActive(props).finally(() => {});
            if (!Number(locationId)) {
                const currentLocation = watch().location;
                const filteredLocation = res?.rows?.filter((l) => l?.Id === currentLocation?.Id);
                const firstLocation = filteredLocation[0];

                if (currentLocation) {
                    setZones(firstLocation?.Zones ?? []);
                    setValue('zone', firstLocation?.Zones ?? []);
                }

                if (firstLocation !== currentLocation) {
                    setValue('location', undefined);
                    setValue('zone', undefined);
                }
            }
            return res;
        },
        listProps: {
            search: { fields: ['name'] } as any,
            ZoneService: [isANPR ? ServiceSMType.Anpr : ServiceSMType.eCam],
        },
        dependencyList: [isANPR],
    });

    const serviceTypeAutocomplete = useStyledAutocomplete({
        // async getList(props: IGetLocationProps): Promise<Paging<Location>> {
        //     const res = await locationController.list(props).finally(() => {});
        //     return res;
        // },
        list: {
            options: Object.values(camServiceTypes),
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
        // listProps: {
        //     search: { fields: ['name'] } as any,
        // },
    });

    const positionSolarPanelAutocomplete = useStyledAutocomplete({
        list: {
            options: Object.values(positionSolarPanelReadableMapping),
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
    });

    const onChangeLocation = (l: any) => {
        setValue('location', l);
        setZones(l.Zones!);
    };
    const zoneAutocomplete = useStyledAutocomplete({
        list: {
            options: zones,
            isFiltered(option, searchText) {
                return option.PublicName.toLowerCase().includes(searchText.toLocaleLowerCase());
            },
        },
        dependencyList: [zones],
    });
    const gmtAutocomplete = useStyledAutocomplete<GMT, ListProps<GMT>>({
        getList(props: ListProps<GMT>) {
            return fetchGMTTime(props);
        },
        listProps: {
            search: {
                fields: ['Name'],
            } as any,
        },
    });
    const handleConfirm = (data: FormValues, isDetails?: boolean) => {
        const _camera: IUpsertCameraProps = {
            Name: data.name.trim(),
            Mac: data.mac.trim(),
            TimeZone: data.time?.utc,
            ZoneId: Number(data.zone?.Id),
            VendorId: 1,
            DeployedDate: moment(data.deployedDate).toDate(),
            Status: Status.Active,
            IP: data.ip,
            UserName: data.userName,
            PassWord: data.passWord,
            LensCapacity: data.lensCapacity,
            ServiceType: data.serviceType.key,
            ANPRCameraId: data.anprId ? parseInt(data.anprId) : null,
            Notes: data.note,
            Asset: data.assetName,
            IsVerificationsEnabled: data.createVerification,
            SolarPanelPosition: data.solarPanelPosition.key,
        };

        openBackdrop();

        cameraController
            .upsertWithSolarCamera(_camera)
            .then(async (res) => {
                if (res.Id && cameraInstalledPhotos.length > 0) {
                    await handleCreateCameraInstalledPhotos(res.Id);
                }
                navigate(
                    `${
                        !!isDetails
                            ? `/locations/${res.Zone?.LocationId}/cameras/${res.Id}`
                            : `/locations/${res.Zone?.LocationId}/cameras`
                    }`
                );
                if (res.CameraPhotos?.[0]) {
                    pushSuccess('Added new camera successfully');
                } else {
                    pushWarning("Didn't receive new photos from camera. Please try again");
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 409) setError('mac', { message: err.response.data.message });
                if (err.response.data.statusCode === 400) setError('anprId', { message: err.response.data.message });
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleCreateCameraInstalledPhotos = async (cameraId: number) => {
        const formData = new FormData() as any;
        cameraInstalledPhotos.forEach((file) => {
            formData.append('CameraInstalledPhotos', file);
        });

        await cameraInstalledPhotoHttpController.createCameraInstalledPhotos(cameraId, formData);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    

    const [cameraInstalledPhotos, setCameraInstalledPhotos] = useState<File[]>([]);
    const [uploadProgress, setUploadProgress] = useState({});

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);

          if (cameraInstalledPhotos.length + newFiles.length > 10) {
              pushError('You can only upload a maximum of 10 images.');
              return;
          }

        newFiles.forEach((file: any) => {
            if (file.size > maxImageSize) {
                pushWarning(
                    `The maximum size can only be ${(maxImageSize / (1024 * 1024)).toFixed(
                        2
                    )}MB, you have just uploaded a file that is ${(file.size / (1024 * 1024)).toFixed(2)}MB in size.`
                );
                return;
            }

            const reader = new FileReader();
            const img = new Image();
            reader.onload = (e: any) => {
                img.onload = () => {
                    if (img.width > maxImageWidth || img.height > maxImageHeight) {
                        pushWarning(
                            `The maximum dimensions allowed are ${maxImageWidth}x${maxImageHeight}px. The uploaded file has dimensions ${img.width}x${img.height}px.`
                        );
                        return;
                    }
                    setCameraInstalledPhotos((prevFiles) => {
                        if (prevFiles.length >= 10) {
                            return prevFiles;
                        }
                        const fileExists = prevFiles.some((prevFile) => prevFile.name === file.name);
                        if (!fileExists) {
                            return [...prevFiles, file];
                        }
                        return prevFiles;
                    });
                    let progress = 0;
                    const interval = setInterval(() => {
                        progress += 25;
                        setUploadProgress((prev) => ({
                            ...prev,
                            [file.name]: progress,
                        }));
                        if (progress >= 100) {
                            clearInterval(interval);
                        }
                    }, 100);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const handleRemoveFile = (fileName) => {
        setCameraInstalledPhotos(cameraInstalledPhotos.filter((file) => file.name !== fileName));
        const updatedProgress = { ...uploadProgress };
        delete updatedProgress[fileName];
        setUploadProgress(updatedProgress);
    };

    return (
        <Box>
            <Typography variant="h3">Add new camera</Typography>

            <BaseSection
                heading="Information details"
                icon={<IcInfo />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="Name" required />
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please enter a name.',
                                    },
                                    validate: validateTypingInput,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Camera name'}
                                        maxLength={50}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="Mac" required />
                            <Controller
                                name="mac"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please enter a mac address.',
                                    },
                                    validate: validateTypingInput,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Mac address'}
                                        error={!!error?.message}
                                        maxLength={30}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="Asset" />
                            <Controller
                                name="assetName"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Asset name'}
                                        maxLength={50}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <RequiredTitle title="Len caps" required />
                            <Controller
                                name="lensCapacity"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField {...field} placeholder={'Lens cap'} maxLength={30} />
                                )}
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BaseSection
                heading="Service"
                icon={<IcInfo />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="Service type" required />
                            <Controller
                                name="serviceType"
                                control={control}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: 'Please select a location.',
                                //     },
                                // }}
                                render={({ field, fieldState: { error } }) => (
                                    <StyledAutocomplete
                                        {...serviceTypeAutocomplete}
                                        {...field}
                                        getOptionLabel={(o) => o.label}
                                        // label={
                                        //     <span>
                                        //         Location<span style={{ color: 'red' }}>{`*`}</span>
                                        //     </span>
                                        // }
                                        // placeholder="Select location"
                                        disabledAllOption
                                        value={field.value}
                                        onChange={(s) => {
                                            setValue('serviceType', s!, { shouldValidate: true, shouldTouch: true });
                                            if (s!.key === CameraServiceType.ANPRTechOps) {
                                                setValue('anprId', '');
                                                clearErrors('anprId');
                                            }
                                        }}
                                        wrapperWidth={'100%'}
                                    />
                                )}
                            />
                        </Stack>
                        <Collapse in={isANPR}>
                            <Stack direction="row" spacing={2}>
                                <RequiredTitle title="ANPR ID" required />
                                <Controller
                                    name="anprId"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: isANPR,
                                            message: 'This field is required.',
                                        },
                                        validate: (value) => {
                                            if (isANPR) {
                                                if (!value || isNaN(Number(value))) {
                                                    return 'Please enter a valid ANPR ID.';
                                                }
                                            }
                                            return true;
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            placeholder={'ANPR ID'}
                                            error={!!error?.message}
                                            maxLength={30}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Stack>
                        </Collapse>
                    </Stack>
                }
            />
            <BaseSection
                heading="Milesights account"
                icon={<IcInfo />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="IP" required />
                            <Controller
                                name="ip"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please enter an IP address.',
                                    },
                                    validate: validateTypingIPAddress,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'IP address'}
                                        maxLength={30}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="Username" required />
                            <Controller
                                name="userName"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please enter a username.',
                                    },
                                    validate: validateTypingInput,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Username'}
                                        error={!!error?.message}
                                        maxLength={30}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="Password" required />
                            <Controller
                                name="passWord"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please enter a password.',
                                    },
                                    validate: validateTypingInput,
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Password'}
                                        error={!!error?.message}
                                        maxLength={30}
                                        helperText={error?.message}
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff sx={{ fontSize: 20 }} />
                                                    ) : (
                                                        <Visibility sx={{ fontSize: 20 }} />
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BaseSection
                heading="Zone & GMT"
                icon={<IcGPS style={{ width: 20, height: 20 }} />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Controller
                                name="location"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please select a location.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <StyledAutocomplete
                                        {...locationAutocomplete}
                                        {...field}
                                        getOptionLabel={(o) => o.Name}
                                        label={
                                            <span>
                                                Location<span style={{ color: 'red' }}>{`*`}</span>
                                            </span>
                                        }
                                        placeholder="Select location"
                                        disabledAllOption
                                        value={field.value}
                                        onChange={(l) => {
                                            setValue('location', l!, { shouldValidate: true, shouldTouch: true });
                                            setValue('zone', undefined);
                                            onChangeLocation(l!);
                                        }}
                                        wrapperWidth="50%"
                                    />
                                )}
                            />
                            <Controller
                                name="zone"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please select a zone.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => {
                                    return (
                                        <StyledAutocomplete
                                            {...zoneAutocomplete}
                                            {...field}
                                            getOptionLabel={(o) => `${o.PublicName} (ZoneID ${o.Id})`}
                                            label={
                                                <span>
                                                    Zone<span style={{ color: 'red' }}>{`*`}</span>
                                                </span>
                                            }
                                            placeholder="Select zone"
                                            disabledAllOption
                                            disabled={!watch().location}
                                            value={field.value}
                                            onChange={(z) =>
                                                setValue('zone', z!, { shouldValidate: true, shouldTouch: true })
                                            }
                                            wrapperWidth="50%"
                                        />
                                    );
                                }}
                            />
                        </Stack>
                        <Controller
                            name="time"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please select a GMT time.',
                                },
                            }}
                            render={({ field, fieldState }) => {
                                return (
                                    <Autocomplete
                                        {...field}
                                        id="grouped-demo"
                                        value={field.value || null}
                                        options={transformedData}
                                        groupBy={(option) => option.text}
                                        getOptionLabel={(option) => option.utc}
                                        isOptionEqualToValue={(option, value) => option.utc === value.utc}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                padding: '9.95px 14px !important',
                                            },
                                            '& .MuiButtonBase-root': {
                                                marginRight: '5px !important',
                                            },
                                            '& .MuiAutocomplete-inputRoot': {
                                                '& 	.MuiAutocomplete-input': {
                                                    padding: '0px 0px',
                                                },
                                            },
                                        }}
                                        renderGroup={(params) => (
                                            <li key={params.key}>
                                                <GroupHeader>
                                                    <Typography variant="h6" color={(theme) => theme.palette.grey[600]}>
                                                        {params.group}
                                                    </Typography>
                                                </GroupHeader>
                                                <GroupItems>{params.children}</GroupItems>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputLabelProps={{ shrink: true }}
                                                label={
                                                    <span>
                                                        Time<span style={{ color: 'red' }}>{`*`}</span>
                                                    </span>
                                                }
                                                placeholder="Select time zone"
                                            />
                                        )}
                                        onChange={(e, val) => {
                                            field.onChange(val);
                                        }}
                                    />
                                );
                            }}
                        />
                    </Stack>
                }
            />
            <BaseSection
                heading="Position of the solar panel"
                icon={<IcInfo />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <Controller
                                name="solarPanelPosition"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please select a position.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <StyledAutocomplete
                                        {...positionSolarPanelAutocomplete}
                                        {...field}
                                        getOptionLabel={(o) => o.label}
                                        label={
                                            <span>
                                                Solar panel position<span style={{ color: 'red' }}>{`*`}</span>
                                            </span>
                                        }
                                        placeholder="Please select a position"
                                        disabledAllOption
                                        value={field.value}
                                        onChange={field.onChange}
                                        wrapperWidth={'100%'}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BaseSection
                heading="Production"
                icon={<IcDeployed style={{ width: 18, height: 18 }} />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <RequiredTitle title="Production" required />
                            <Controller
                                name="deployedDate"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please choose a date.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <DesktopDatePicker
                                        {...field}
                                        value={field.value}
                                        format="DD/MM/YYYY"
                                        shouldDisableDate={(date) =>
                                            validateDatePickerValue(moment(date).toDate()) !== null
                                        }
                                        slotProps={{
                                            textField: {
                                                inputProps: {
                                                    readOnly: true,
                                                },
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BaseSection
                heading="Photo of camera as installed"
                icon={<CameraIcon style={{ width: 19, height: 19 }} />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack alignItems="center" spacing={2}>
                            <Controller
                                name="cameraInstalledPhotos"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Stack
                                            sx={{
                                                border: '1px dashed #ccc',
                                                borderRadius: '5px',
                                                width: '100%',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                mt: 2,
                                            }}
                                            flex={1}
                                            justifyContent={'center'}
                                        >
                                            <input
                                                type="file"
                                                multiple
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                                accept=".svg,.png,.jpg,.jpeg,.gif,.webp,.ico"
                                                id="file-upload"
                                            />
                                            <label htmlFor="file-upload" style={{ padding: '16px' }}>
                                                <UploadImageIcon />
                                                <Typography sx={{ cursor: 'pointer' }}>Click to upload</Typography>
                                                <Typography
                                                    sx={{ cursor: 'pointer' }}
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    SVG, PNG, JPG or GIF (max. {maxImageWidth}x{maxImageHeight}px)
                                                </Typography>
                                            </label>
                                        </Stack>

                                        {cameraInstalledPhotos.map((file, index) => (
                                            <Box
                                                key={index}
                                                display="flex"
                                                alignItems="center"
                                                width={'100%'}
                                                border={`1px solid ${color.grey200}`}
                                                borderRadius={'5px'}
                                                p={'8px 16px'}
                                            >
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt=""
                                                    width={'40px'}
                                                    height={'40px'}
                                                    style={{
                                                        borderRadius: 6,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setSelectedItem(URL.createObjectURL(file));
                                                    }}
                                                />
                                                <Stack flex={1} ml={3}>
                                                    <Typography>{file.name}</Typography>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={uploadProgress[file.name] || 0}
                                                        sx={{
                                                            height: 5,
                                                            borderRadius: 5,
                                                            backgroundColor: color.grey300,
                                                            width: '98%',
                                                        }}
                                                    />
                                                    <Typography variant="caption" color={'rgba(147, 213, 0, 1)'}>
                                                        {uploadProgress[file.name] < 100
                                                            ? `${uploadProgress[file.name]}% uploaded`
                                                            : 'Uploaded successfully!'}
                                                    </Typography>
                                                </Stack>

                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleRemoveFile(file.name)}
                                                    disabled={uploadProgress[file.name] < 100}
                                                >
                                                    <CancelOutlined
                                                        sx={{
                                                            color: `${
                                                                uploadProgress[file.name] < 100 ? 'red' : 'gray'
                                                            }`,
                                                            width: 16,
                                                            height: 16,
                                                        }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        ))}

                                        {selectedItem && (
                                            <Dialog
                                                open={true}
                                                onClose={() => {
                                                    setSelectedItem(undefined);
                                                }}
                                                PaperProps={{
                                                    sx: {
                                                        minWidth: 1280,
                                                        minHeight: 720,
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        aspectRatio: '16/9',
                                                        background: `url(${selectedItem}) no-repeat`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        borderRadius: 1,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Dialog>
                                        )}
                                    </>
                                )}
                            />
                        </Stack>
                    </Stack>
                }
            />

            <BaseSection
                heading="Note"
                icon={<IcDeployed style={{ width: 18, height: 18 }} />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <RequiredTitle title="Note" />
                            <Controller
                                name="note"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField {...field} placeholder={'Enter a description'} maxLength={255} />
                                )}
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BaseSection
                heading="Data processing"
                icon={<IcDeployed style={{ width: 18, height: 18 }} />}
                children={
                    <Stack sx={{ p: 2 }} spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <RequiredTitle title="Create verifications" sx={{ width: 150 }} />
                            <Controller
                                name="createVerification"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <IOSSwitch
                                        {...field}
                                        label={
                                            <Typography sx={{ minWidth: '55px' }}>
                                                {!!field.value ? 'On' : 'Off'}
                                            </Typography>
                                        }
                                        iosSwitchProps={{
                                            checked: field.value,
                                            onChange(event, checked) {
                                                field.onChange(event);
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                }
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={4}>
                <Button
                    variant="cancel"
                    sx={{ flexBasis: 175 }}
                    onClick={() => {
                        if (locationId) {
                            navigate(`/locations/${locationId}/cameras`);
                        } else {
                            navigate(`/locations`);
                        }
                    }}
                >
                    Cancel
                </Button>
                <Stack direction="row" spacing={3} width="50%" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        sx={{ flexBasis: 185 }}
                        onClick={handleSubmit((data) => handleConfirm(data, true))}
                        disabled={disabled}
                    >
                        Save & add geo zone
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ flexBasis: 175 }}
                        onClick={handleSubmit((data) => handleConfirm(data))}
                        disabled={disabled}
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}
const BaseSection = ({
    heading,
    icon,
    children,
}: {
    heading: string;
    icon: React.ReactNode;
    children: React.ReactNode;
}) => {
    return (
        <Stack sx={{ border: '1px solid #EEEEEE', borderRadius: '4px' }} mt={4}>
            <Box
                sx={{
                    p: 2,
                    background: '#FAFAFA',
                    borderBottom: '1px solid #DDDDDD',
                    borderRadius: '4px 4px 0px 0px',
                    userSelect: 'none',
                }}
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    {icon}
                    <Typography variant="h5">{heading}</Typography>
                </Stack>
            </Box>
            {children}
        </Stack>
    );
};

export const transformedData: TimeZone[] = myJson.flatMap((item) => {
    const _transformedData: TimeZone[] = [];
    item.utc.map((utc) => {
        _transformedData.push({ text: item.text, value: item.value, utc });
    });
    return _transformedData;
});

export const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    // padding: '9px 10px',
    paddingLeft: '9px',
    paddingTop: '9px',
    paddingBottom: '5px',
    color: color.grey600,
    backgroundColor: '#FFF',
}));

export const GroupItems = styled('ul')({
    padding: 0,
});
