import { Solar4gCamera, Solar4gCaptureInfo } from '@EcamModel/model';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Stack, Typography } from '@mui/material';
import { useCaptureManagement } from '@pages/cameras/hook/Solar4gCameraProvider';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormPopUpEditUploadSettings from './FormPopUpEditUploadSettings';
import moment from 'moment';
type Props = Omit<IPopUp, 'onConfirm'> & {
    onConfirm?(camera: Solar4gCaptureInfo): void;
    handleUpdateCapture: (data: Partial<Solar4gCamera>) => Promise<void>;
};

export type FormValues = {
    ImageSettings: string;
    ImageType: number;
};

export default function PopUpEditUploadSetting(props: Props) {
    const { open, onClose } = props;

    const captureContext = useCaptureManagement();
    const { Solar4gCaptureInfo } = captureContext;

    const {
        clearErrors,
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm<Partial<Solar4gCaptureInfo>>({
        mode: 'all',
        defaultValues: {},
    });

    const disabled = !(isValid && isDirty);

    const defaultValues: Partial<Solar4gCaptureInfo> = {
        UploadMode: Solar4gCaptureInfo?.UploadMode,
        PostEnable: Solar4gCaptureInfo?.PostEnable,
        SnapshotTopic: Solar4gCaptureInfo?.SnapshotTopic,
        StatusTopic: Solar4gCaptureInfo?.StatusTopic,
        AlarmTopic: Solar4gCaptureInfo?.AlarmTopic,
        Host: Solar4gCaptureInfo?.Host,
        Port: Solar4gCaptureInfo?.Port,
        UserName: Solar4gCaptureInfo?.UserName,
        Password: Solar4gCaptureInfo?.Password,
        ConfigRespTopic: Solar4gCaptureInfo?.ConfigRespTopic,
        ConfigTopic: Solar4gCaptureInfo?.ConfigTopic,
        TlsEnable: Solar4gCaptureInfo?.TlsEnable,
        PostType: Solar4gCaptureInfo?.PostType,
        SnapshotEnable: Solar4gCaptureInfo?.SnapshotEnable,
        AlarmEnable: Solar4gCaptureInfo?.AlarmEnable,
        StatusEnable: Solar4gCaptureInfo?.StatusEnable,
        TlsType: Solar4gCaptureInfo?.TlsType,
        HttpUrl: Solar4gCaptureInfo?.HttpUrl,
        Time1: Solar4gCaptureInfo?.Time1,
        Time2: Solar4gCaptureInfo?.Time2,
        Frequency: Solar4gCaptureInfo?.Frequency,
    };

    useEffect(() => {
        if (!open) return;
        reset(defaultValues);
    }, [open]);

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            onConfirm={() => {}}
            dialogProps={{
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        minWidth: '700px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Upload setting</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <FormPopUpEditUploadSettings
                    control={control}
                    watch={watch()}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            clearErrors();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={disabled}
                        onClick={handleSubmit(async (data) => {
                            const _editUpload: Solar4gCamera = {} as Solar4gCamera;

                            data.TlsType = Number(data.TlsType);
                            data.Time1 = String(moment(data.Time1).format('HH:mm'));
                            data.Time2 = String(moment(data.Time2).format('HH:mm'));

                            if (data.Time1 === 'Invalid date') {
                                data.Time1 = Solar4gCaptureInfo?.Time1;
                            }

                            if (data.Time2 === 'Invalid date') {
                                data.Time2 = Solar4gCaptureInfo?.Time2;
                            }

                            for (const key in data) {
                                if (data[key] !== defaultValues[key]) {
                                    _editUpload[key] = data[key];
                                }
                            }

                            await props.handleUpdateCapture(_editUpload);
                            props.onClose?.();
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
