import { Paging } from '@Core';
import { IUpdateConfigIsVerificationsEnabledResponse, SortByValue } from '@EcamModel/controllers/ICameraHttpController';
import {
    CameraWithInfo,
    ExemptionPeriods,
    LocationSMStatus,
    PurposeType,
    SolarPanelPositionType,
    Status,
    WifiStatus,
} from '@EcamModel/model';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import IcCopy from '@assets/details-camera-icons/IcCopy';
import IcEdit from '@assets/details-camera-icons/IcEdit';
import ICDelete from '@assets/jsx-icon/ICDelete';
import BaseListNote, { tableHeaders } from '@components/BaseListNote';
import { BaseTextField } from '@components/BaseTextField';
import IOSSwitch from '@components/IOSSwitch';
import PopUpWarning from '@components/PopUpWarning';
import StyledDateRangePicker from '@components/date-range-select/StyledDateRangePicker';
import FilterRoot from '@components/filter/FilterRoot';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { CellOfRow } from '@components/tables';
import { pushError, pushSuccess } from '@components/toast';
import { cameraController, cameraNoteHttpController } from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import { Box, Chip, Divider, Grid, IconButton, Stack, TableCell, Tooltip, Typography } from '@mui/material';
import { PositionSolarPanelLabel, camServiceTypes, positionSolarPanelReadableMapping } from '@pages/cameras/add-new';
import { BaseSelect, Filter, cameraSortByStatus } from '@pages/cameras/list';
import { PurposesReadableMapping, getBatteryIcon } from '@pages/cameras/list/components/CameraItem';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import SearchTypeSelect from '@pages/cameras/list/components/SearchTypeSelect';
import { DangerTooltip } from '@pages/cameras/list/components/TooltipDangerIconButton';
import ConnectedIcon from '@pages/cameras/list/components/icons/ConnectedIcon';
import DisconnectedIcon from '@pages/cameras/list/components/icons/DisconnectedIcon';
import { search } from '@pages/cameras/list/helpers';
import color from '@theme/Colors';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BiHide, BiShow } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { MdAdd, MdClose, MdDone } from 'react-icons/md';
import { TbBook2 } from 'react-icons/tb';
import { formatDateTime, formatUKDateTime, getFirstAndLastDayOfCurrentWeek } from 'src/helpers';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { useDebounce } from 'usehooks-ts';
import { validateTypingInput } from '../utils';
import TabDetailedInformationOfMilesight from './DetailedMilesight/TabDetailedInformationOfMilesight';
import PopUpAddExemption from './PopUpAddExemption';
import PopUpEditAllocatedZone from './PopUpEditAllocatedZone';
import PopUpEditMilesightAccount from './PopUpEditMilesightAccount';
import PopUpEditService from './PopUpEditService';
import PopUpHistoryExemption from './PopUpHistoryExemption';
import PopUpNoteHistory from './PopUpNoteHistory';
import PopUpEditCameraPhotoInstalled from './PupupEditCameraPhotoInstalled';
import ButtonEditDetails from './buttons/ButtonEditDetails';
import PopUpEditPurposes from './modals/PopUpEditPurposes';
import ImageCarousel from './ImageCarousel';
import PopUpEditDeployDate from './modals/PopUpEditDeployDate';

type Props = {
    camera: CameraWithInfo;
    setCamera: Dispatch<SetStateAction<CameraWithInfo>>;
    onUpdateCamera?(camera: CameraWithInfo): Promise<void>;
    onUpdateService?(camera: CameraWithInfo): void;
    onAddExemption?(exemption: ExemptionPeriods): void;
    onDeleteExemption?(exemptionId: number): void;
    loading?: false;
    setLoading?: Dispatch<SetStateAction<boolean>>;
    blobName: string | undefined;
    isHideAction?: boolean;
};
export type FormValues = {
    name: string;
    mac: string;
    note: string;
    assets: string;
    fullAddress: string;
    solarPanelPosition: PositionSolarPanelLabel | SolarPanelPositionType;
    position: {
        lat: number;
        lng: number;
    };
};

export const LocationStatusReadableMapping = {
    [LocationSMStatus.Decommissioned]: 'Decommissioned',
    [LocationSMStatus.Live]: 'Live',
    [LocationSMStatus.OnHold]: 'On hold',
    [LocationSMStatus.Prospective]: 'Prospective',
    [LocationSMStatus.Transferred]: 'Transferred',
};

export enum Mode {
    create,
    edit,
}

export const cameraNoteSortByType: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Last updated',
        Value: 'Updated',
    },
    {
        Id: 2,
        Name: 'Created date',
        Value: 'Created',
    },
];
export default function CameraInformation(props: Props) {
    const { camera, blobName, isHideAction = false, setCamera } = props;

    const defaultCenter = {
        lat: 12.6864,
        lng: 108.0378,
    };

    const {
        watch,
        reset,
        control,
        formState: { isDirty, isValid, errors, dirtyFields, isSubmitting },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            name: camera.Name,
            mac: camera.Mac,
            note: camera.Notes,
            assets: camera.Asset,
            // fullAddress: camera.FullAddress,
            solarPanelPosition: positionSolarPanelReadableMapping[camera.SolarPanelPosition],
            // position: {
            //     lat: camera.Latitude,
            //     lng: camera.Longitude,
            // },
        },
    });

    const [mode, setMode] = useState<Mode | null>(null);
    const [selectedExemption, setSelectedExemption] = useState<ExemptionPeriods>({} as ExemptionPeriods);
    const [showPassword, setShowPassword] = useState(false);

    const [filter, setFilter] = useState<Partial<Filter>>({
        page: 1,
        from: undefined,
        to: undefined,
        search: '',
        sortedOrder: cameraSortByStatus[1],
        sort: cameraNoteSortByType[0],
    });

    const [idNote, setIdNote] = useState<number>(0);
    const [listNotes, setListNotes] = useState<Paging<CameraNotes>>({} as Paging<CameraNotes>);
    const [searchValue, setSearchValue] = useState<string>('');
    const debouncedValue = useDebounce<string>(searchValue ?? '', 500);

    const [isLoading, setIsLoading] = useState(false);

    const getStatusColor = (status: PurposeType) => {
        const colorRender = (props: { color: string; bgcolor: string }) => ({
            colorText: props.color,
            bgColor: props.bgcolor,
        });

        const mappingColor = {
            [PurposeType.Prospective]: colorRender({
                color: color.secondary,
                bgcolor: '#E5F2FF',
            }),
            [PurposeType.Live]: colorRender({
                bgcolor: '#E5F5ED',
                color: '#009D4F',
            }),
            [PurposeType.Testing]: colorRender({
                bgcolor: '#CC00661A',
                color: '#CC0066',
            }),
            [PurposeType.Decommissioned]: colorRender({
                bgcolor: '#FCE8E5',
                color: '#E01B00',
            }),
            [PurposeType.OnHold]: colorRender({
                bgcolor: '#FFF8E5',
                color: '#FFB600',
            }),
        };
        return (
            mappingColor[status] ||
            colorRender({
                bgcolor: '#FCE8E5',
                color: '#E01B00',
            })
        );
    };
    const { colorText, bgColor } = getStatusColor(camera.PurposeType!);

    const [isEdit, setIsEdit] = useState({
        name: false,
        mac: false,
        note: false,
        assets: false,
        fullAddress: false,
        solarPanelPosition: false,
        position: {
            lat: defaultCenter.lat,
            lng: defaultCenter.lng,
        },
    });

    const popUpEditCameraPhotoInstalled = usePopUp();
    const popUpEditPurposes = usePopUp();
    const popUpEditProductDate = usePopUp();
    const detailsCamera = [
        {
            title: 'Purposes',
            content: (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Chip
                        size="small"
                        sx={{
                            borderRadius: '16px',
                            fontSize: 12,
                            px: '5px',
                            background: bgColor,
                            color: colorText,
                        }}
                        label={PurposesReadableMapping[camera.PurposeType!] ?? 'N/A'}
                    />
                    <ButtonEditDetails tooltipTitle="Edit purposes" onClick={popUpEditPurposes.setTrue} />
                </Stack>
            ),
        },
        {
            title: 'Network',
            content: (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>
                        {camera.Solar4gCamera?.LastWifiStatus === WifiStatus.Connected ? 'Connected' : 'Not available'}
                    </Typography>
                    {camera.Solar4gCamera?.LastWifiStatus === WifiStatus.Connected ? (
                        <ConnectedIcon width={18} height={18} />
                    ) : (
                        <DisconnectedIcon width={18} height={18} />
                    )}
                </Stack>
            ),
        },
        {
            title: 'Battery',
            content: (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>{`${camera.Solar4gCamera?.LastBatteryStatus}%` ?? 'Not available'}</Typography>
                    {getBatteryIcon(camera.Solar4gCamera?.LastBatteryStatus ?? 0)}
                </Stack>
            ),
        },
        {
            title: 'Firmware version',
            content: <Typography>{camera.Solar4gCamera?.Solar4gSysInfo?.Software}</Typography>,
        },
        {
            title: 'Lens capacity',
            content: <Typography>{camera.LensCapacity}</Typography>,
        },
        {
            title: 'Created by',
            content: <Typography>{camera.CreatedBy}</Typography>,
        },
        {
            title: 'Created at',
            content: <Typography>{moment(camera.Created).local().format('DD/MM/YYYY HH:mm:ss')}</Typography>,
        },
        {
            title: 'Last contact',
            content: (
                <Typography>
                    {moment(camera.Solar4gCamera?.LastContact).local().format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
            ),
        },
    ];

    const isCameraLocationDecommissioned = camera.Zone?.Location?.LocationStatus === LocationSMStatus.Decommissioned;

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [pinnedLocation, setPinnedLocation] = useState<google.maps.LatLngLiteral | null>(defaultCenter);
    const handleReCenter = () => {
        if (map && pinnedLocation) {
            map.panTo(pinnedLocation); // This will animate the panning to the pinned location
            map.setZoom(50); // Adjust zoom level if necessary
        }
    };

    const openInGoogleMaps = () => {
        if (pinnedLocation) {
            const { lat, lng } = pinnedLocation;
            const url = `https://www.google.com/maps?q=${lat},${lng}`;
            window.open(url, '_blank');
        }
    };

    const allocatedZone = [
        { title: 'Location ', content: `${camera.LocationName}` },
        { title: 'Zone', content: `${camera.ZoneName}` },
        {
            title: 'Location status',
            content: (
                <Typography
                    bgcolor={isCameraLocationDecommissioned ? color.danger : 'transparent'}
                    color={isCameraLocationDecommissioned ? color.white : color.textPrimary}
                    p={isCameraLocationDecommissioned ? '4px 10px' : 0}
                    borderRadius={1}
                    maxWidth={'max-content'}
                >
                    {camera.Zone?.Location?.LocationStatus !== null
                        ? LocationStatusReadableMapping[camera?.Zone?.Location?.LocationStatus ?? 0]
                        : 'N/A'}
                </Typography>
            ),
        },
        // {
        //     title: 'Map Position',
        //     content: (
        //         <Stack direction={'row'} alignItems={'center'} spacing={1}>
        //             <Button
        //                 sx={{ height: 30, fontSize: 12, minWidth: 145 }}
        //                 variant="cancel"
        //                 onClick={openInGoogleMaps}
        //                 startIcon={<LocationMapIcon />}
        //             >
        //                 Open in Google Maps
        //             </Button>
        //             <Button
        //                 sx={{ height: 30, fontSize: 12, minWidth: 145 }}
        //                 variant="cancel"
        //                 onClick={handleReCenter}
        //                 startIcon={<ReCenterMapIcon />}
        //             >
        //                 Re-center
        //             </Button>
        //         </Stack>
        //     ),
        // },
    ];

    const popUpWarning = usePopUp();
    const popUpWarningEditMac = usePopUp();
    const popUpEditAllocatedZone = usePopUp();
    const popUpAddExemption = usePopUp();
    const popUpWarningVerification = usePopUp();
    const popUpConfirmDelete = usePopUp();
    const popUpEditMilesightAccount = usePopUp();
    const popupHistoryExemption = usePopUp();
    const popupNoteHistory = usePopUp();
    const popUpConfirmDeleteNoteHistory = usePopUp();

    const [isActive, setIsActive] = useState(camera.Status);
    const [isVerification, setIsVerification] = useState<IUpdateConfigIsVerificationsEnabledResponse>({
        IsVerificationsEnabled: camera.IsVerificationsEnabled!,
        EnablePcnVerificationAt: camera.EnablePcnVerificationAt!,
    });
    const [isEnabled, setIsEnabled] = useState(true);

    const now = new Date();
    const deployDate = new Date(camera.DeployedDate);
    // const boolean = moment(now).isBefore(deployDate) && Boolean(isActive === Status.NotAvailable): boolean = moment(now).isBefore(deployDate) && Boolean(isActive === Status.NotAvailable);
    const handleOnConfirm = () => {
        cameraController
            .changeStatus(camera.Id!)
            .then((res) => {
                pushSuccess('Changed status successfully');
                setIsActive(res.Status);
                setCamera((prev) => ({
                    ...prev,
                    Status: res.Status,
                }));
            })
            .catch((err) => {})
            .finally(() => {
                popUpWarning.onClose();
            });
    };
    const backdrop = useBackdrop();
    const handleConfirmVerification = async (cameraId: number, isEnabled: boolean) => {
        backdrop.setTrue();
        await cameraController
            .updateConfigIsVerificationsEnabled({ CameraId: cameraId, Enable: isEnabled })
            .then((res) => {
                setIsVerification(res);
                pushSuccess('Updated successfully');
                setCamera((prev) => ({
                    ...prev,
                    IsVerificationsEnabled: res.IsVerificationsEnabled,
                    EnablePcnVerificationAt: res.EnablePcnVerificationAt,
                }));
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    useEffect(() => {
        setIsActive(camera.Status);
        setIsVerification({
            IsVerificationsEnabled: camera.IsVerificationsEnabled!,
            EnablePcnVerificationAt: camera.EnablePcnVerificationAt!,
        });
    }, [camera.IsVerificationsEnabled, camera.Status]);

    const popUpEditService = usePopUp({});

    const checkPostCaptureEnable = () => {
        const postCaptureEnable = {
            [0]: 'Disable',
            [1]: 'Enable',
        };
        return postCaptureEnable[camera.Solar4gCamera?.Solar4gCaptureInfo?.PostCaptureEnable!];
    };
    const sortingConditions =
        filter.sortedOrder?.Value === SortByValue.Asc ? `${filter.sort?.Value}` : `-${filter.sort?.Value}`;

    const handleGetListNote = async () => {
        setIsLoading(true);
        await cameraNoteHttpController
            .list({
                page: filter.page ?? 1,
                CameraId: camera.Id ?? 0,
                pageSize: 8,
                filter: {
                    CameraId: camera.Id ?? 0,
                },
                search: {
                    content: searchValue ?? '',
                    fields: ['CreatedBy'],
                },
                sorts: [sortingConditions] as any,
                From: filter.from ?? undefined,
                To: filter.to ?? undefined,
            })
            .then((res) => {
                setListNotes(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleGetListNote();
    }, [filter.page, debouncedValue, filter.to, filter.from, filter.sortedOrder, filter.sort]);

    const handleAddOrUpdateNote = async (idCamera: number, note: string, idNote?: number) => {
        setIsLoading(true);
        const data: CameraNotes = {
            CameraId: idCamera,
            Notes: note,
            Id: mode === Mode.edit ? idNote : undefined,
        };
        await cameraNoteHttpController
            .upsert(data)
            .then(async (res) => {
                if (filter.page !== 1) {
                    setFilter((prev) => ({
                        ...prev,
                        page: 1,
                        sortedOrder: cameraSortByStatus[1],
                        sort: cameraNoteSortByType[0],
                    }));
                } else {
                    await handleGetListNote();
                }
                if (idNote) {
                    pushSuccess('Updated successfully');
                } else {
                    pushSuccess('Add successfully');
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteNote = async (idNote: number) => {
        backdrop.setTrue();
        await cameraNoteHttpController
            .delete(String(idNote))
            .then(async () => {
                if (filter.page !== 1) {
                    setFilter((prev) => ({ ...prev, page: 1 }));
                } else {
                    await handleGetListNote();
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const positionSolarPanelAutocomplete = useStyledAutocomplete({
        list: {
            options: Object.values(positionSolarPanelReadableMapping),
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
    });

    return (
        <>
            <Stack spacing={2} my={5} padding={2} borderRadius={2} border={'1px solid #E4E7EC'}>
                <Box>
                    <Typography variant="h4">Base information</Typography>
                    <Typography color={color.grey600}>Basic camera details</Typography>
                </Box>

                <Stack width="100%" direction={{ md: 'row' }} spacing={3} mt={5} gap={{ xs: 3, md: 0 }}>
                    <Stack flex={1} spacing={3}>
                        <Box>
                            <Box paddingX={2} paddingY={1} bgcolor={color.grey100}>
                                <Typography fontSize={16}>Details</Typography>
                            </Box>

                            <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Name:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems={!!errors.name ? 'flex-start' : 'center'}
                                        >
                                            {!isEdit.name ? (
                                                <Typography>{camera.Name}</Typography>
                                            ) : (
                                                <Box>
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter a name.',
                                                            },
                                                            validate: validateTypingInput,
                                                        }}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <BaseTextField
                                                                {...field}
                                                                variant="standard"
                                                                placeholder={'Camera name'}
                                                                error={!!error?.message}
                                                                helperText={error?.message}
                                                                maxLength={50}
                                                                sx={{
                                                                    '& input': {
                                                                        padding: '0px !important',
                                                                        height: 21,
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            )}
                                            {!isHideAction && (
                                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                                    {!!isEdit.name ? (
                                                        <>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background:
                                                                        dirtyFields.name &&
                                                                        !isSubmitting &&
                                                                        !errors.name
                                                                            ? (theme) => theme.palette.primary.main
                                                                            : '#DDDDDD',
                                                                    borderRadius: 2,
                                                                    color:
                                                                        dirtyFields.name &&
                                                                        !isSubmitting &&
                                                                        !errors.name
                                                                            ? '#fff'
                                                                            : '#85858A',
                                                                    '&:hover': {
                                                                        background:
                                                                            dirtyFields.name &&
                                                                            !isSubmitting &&
                                                                            !errors.name
                                                                                ? (theme) => theme.palette.primary.dark
                                                                                : '#DDDDDD',
                                                                        cursor:
                                                                            dirtyFields.name &&
                                                                            !isSubmitting &&
                                                                            !errors.name
                                                                                ? 'pointer'
                                                                                : 'not-allowed',
                                                                        transition: '0.3s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    if (
                                                                        dirtyFields.name &&
                                                                        !isSubmitting &&
                                                                        !errors.name
                                                                    ) {
                                                                        const _camera: CameraWithInfo = {
                                                                            ...camera,
                                                                            Name: watch().name.trim(),
                                                                        };
                                                                        props.onUpdateCamera?.(_camera);
                                                                        setIsEdit((prev) => ({ ...prev, name: false }));
                                                                    }
                                                                }}
                                                            >
                                                                <MdDone style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background: (theme) => theme.palette.error.main,
                                                                    borderRadius: 2,
                                                                    color: '#fff',
                                                                    '&:hover': {
                                                                        background: (theme) => theme.palette.error.dark,
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    setIsEdit((prev) => ({ ...prev, name: false }));
                                                                    reset({ name: camera.Name });
                                                                }}
                                                            >
                                                                <MdClose style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                        </>
                                                    ) : (
                                                        <Tooltip title="Edit name" arrow placement="top">
                                                            <Stack
                                                                sx={{
                                                                    color: '#85858A',
                                                                    '&:hover': {
                                                                        color: (theme) => theme.palette.secondary.main,
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                onClick={() => {
                                                                    setIsEdit((prev) => ({ ...prev, name: true }));
                                                                    reset({ name: camera.Name });
                                                                }}
                                                            >
                                                                <IcEdit />
                                                            </Stack>
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>MAC:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems={!!errors.mac ? 'flex-start' : 'center'}
                                        >
                                            {!isEdit.mac ? (
                                                <Typography>{camera.Mac}</Typography>
                                            ) : (
                                                <Box>
                                                    <Controller
                                                        name="mac"
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter a mac address.',
                                                            },
                                                            validate: validateTypingInput,
                                                        }}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <BaseTextField
                                                                {...field}
                                                                variant="standard"
                                                                placeholder={'Mac address'}
                                                                error={!!error?.message}
                                                                helperText={error?.message}
                                                                maxLength={30}
                                                                sx={{
                                                                    '& input': {
                                                                        padding: '0px !important',
                                                                        height: 21,
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            )}
                                            {!isHideAction && (
                                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                                    {!!isEdit.mac ? (
                                                        <>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background:
                                                                        dirtyFields.mac && !isSubmitting && !errors.mac
                                                                            ? (theme) => theme.palette.primary.main
                                                                            : '#DDDDDD',
                                                                    borderRadius: 2,
                                                                    color:
                                                                        dirtyFields.mac && !isSubmitting && !errors.mac
                                                                            ? '#fff'
                                                                            : '#85858A',
                                                                    '&:hover': {
                                                                        background:
                                                                            dirtyFields.mac &&
                                                                            !isSubmitting &&
                                                                            !errors.mac
                                                                                ? (theme) => theme.palette.primary.dark
                                                                                : '#DDDDDD',
                                                                        cursor:
                                                                            dirtyFields.mac &&
                                                                            !isSubmitting &&
                                                                            !errors.mac
                                                                                ? 'pointer'
                                                                                : 'not-allowed',
                                                                        transition: '0.3s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    if (
                                                                        dirtyFields.mac &&
                                                                        !isSubmitting &&
                                                                        !errors.mac
                                                                    ) {
                                                                        popUpWarningEditMac.setTrue();
                                                                    }
                                                                }}
                                                            >
                                                                <MdDone style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background: (theme) => theme.palette.error.main,
                                                                    borderRadius: 2,
                                                                    color: '#fff',
                                                                    '&:hover': {
                                                                        background: (theme) => theme.palette.error.dark,
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    setIsEdit((prev) => ({ ...prev, mac: false }));
                                                                    reset({ mac: camera.Mac });
                                                                }}
                                                            >
                                                                <MdClose style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                        </>
                                                    ) : (
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Tooltip title="Edit MAC" arrow placement="top">
                                                                <Stack
                                                                    sx={{
                                                                        color: '#85858A',
                                                                        '&:hover': {
                                                                            color: (theme) =>
                                                                                theme.palette.secondary.main,
                                                                            cursor: 'pointer',
                                                                            transition: '0.5s ease-in-out',
                                                                        },
                                                                    }}
                                                                    alignItems="center"
                                                                    onClick={() => {
                                                                        setIsEdit((prev) => ({ ...prev, mac: true }));
                                                                        reset({ mac: camera.Mac });
                                                                    }}
                                                                >
                                                                    <IcEdit />
                                                                </Stack>
                                                            </Tooltip>
                                                            <Tooltip title="Copy MAC" arrow placement="top">
                                                                <Stack
                                                                    sx={{
                                                                        color: '#85858A',
                                                                        '&:hover': {
                                                                            color: (theme) =>
                                                                                theme.palette.primary.main,
                                                                            cursor: 'pointer',
                                                                            transition: '0.5s ease-in-out',
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(`${camera.Mac}`);
                                                                        pushSuccess('Copied successfully.');
                                                                    }}
                                                                >
                                                                    <IcCopy style={{ width: 18, height: 18 }} />
                                                                </Stack>
                                                            </Tooltip>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Asset:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems={!!errors.assets ? 'flex-start' : 'center'}
                                        >
                                            {!isEdit.assets ? (
                                                <Typography>{camera.Asset}</Typography>
                                            ) : (
                                                <Box>
                                                    <Controller
                                                        name="assets"
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter an assets.',
                                                            },
                                                            validate: validateTypingInput,
                                                        }}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <BaseTextField
                                                                {...field}
                                                                variant="standard"
                                                                placeholder={'Camera assets'}
                                                                error={!!error?.message}
                                                                helperText={error?.message}
                                                                maxLength={50}
                                                                sx={{
                                                                    '& input': {
                                                                        padding: '0px !important',
                                                                        height: 21,
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            )}
                                            {!isHideAction && (
                                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                                    {!!isEdit.assets ? (
                                                        <>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background:
                                                                        dirtyFields.assets &&
                                                                        !isSubmitting &&
                                                                        !errors.assets
                                                                            ? (theme) => theme.palette.primary.main
                                                                            : '#DDDDDD',
                                                                    borderRadius: 2,
                                                                    color:
                                                                        dirtyFields.assets &&
                                                                        !isSubmitting &&
                                                                        !errors.assets
                                                                            ? '#fff'
                                                                            : '#85858A',
                                                                    '&:hover': {
                                                                        background:
                                                                            dirtyFields.assets &&
                                                                            !isSubmitting &&
                                                                            !errors.assets
                                                                                ? (theme) => theme.palette.primary.dark
                                                                                : '#DDDDDD',
                                                                        cursor:
                                                                            dirtyFields.assets &&
                                                                            !isSubmitting &&
                                                                            !errors.assets
                                                                                ? 'pointer'
                                                                                : 'not-allowed',
                                                                        transition: '0.3s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    if (
                                                                        dirtyFields.assets &&
                                                                        !isSubmitting &&
                                                                        !errors.assets
                                                                    ) {
                                                                        const _camera: CameraWithInfo = {
                                                                            ...camera,
                                                                            Asset: watch().assets.trim(),
                                                                        };
                                                                        props.onUpdateCamera?.(_camera);
                                                                        setIsEdit((prev) => ({
                                                                            ...prev,
                                                                            assets: false,
                                                                        }));
                                                                    }
                                                                }}
                                                            >
                                                                <MdDone style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background: (theme) => theme.palette.error.main,
                                                                    borderRadius: 2,
                                                                    color: '#fff',
                                                                    '&:hover': {
                                                                        background: (theme) => theme.palette.error.dark,
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    setIsEdit((prev) => ({ ...prev, assets: false }));
                                                                    reset({ assets: camera.Asset });
                                                                }}
                                                            >
                                                                <MdClose style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                        </>
                                                    ) : (
                                                        <Tooltip title="Edit assets" arrow placement="top">
                                                            <Stack
                                                                sx={{
                                                                    color: '#85858A',
                                                                    '&:hover': {
                                                                        color: (theme) => theme.palette.secondary.main,
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                onClick={() => {
                                                                    setIsEdit((prev) => ({ ...prev, assets: true }));
                                                                    reset({ assets: camera.Asset });
                                                                }}
                                                            >
                                                                <IcEdit />
                                                            </Stack>
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography
                                            sx={{
                                                lineHeight: `${!!isEdit.solarPanelPosition ? '40px' : ''}`,
                                            }}
                                        >
                                            Solar panel position:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems={!!errors.solarPanelPosition ? 'flex-start' : 'center'}
                                        >
                                            {!isEdit.solarPanelPosition ? (
                                                <Typography>
                                                    {positionSolarPanelReadableMapping[camera.SolarPanelPosition]
                                                        ?.label || ''}
                                                </Typography>
                                            ) : (
                                                <Box>
                                                    <Controller
                                                        name="solarPanelPosition"
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: 'Please enter an position.',
                                                            },
                                                        }}
                                                        render={({ field, fieldState: { error } }) => {
                                                            return (
                                                                <StyledAutocomplete
                                                                    {...positionSolarPanelAutocomplete}
                                                                    getOptionLabel={(o) => o.label}
                                                                    label={
                                                                        <span>
                                                                            Solar panel position
                                                                            <span style={{ color: 'red' }}>{`*`}</span>
                                                                        </span>
                                                                    }
                                                                    placeholder="Solar panel position"
                                                                    disabledAllOption
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    wrapperWidth={'100%'}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                            {!isHideAction && (
                                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                                    {!!isEdit.solarPanelPosition ? (
                                                        <>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background:
                                                                        dirtyFields.solarPanelPosition &&
                                                                        !isSubmitting &&
                                                                        !errors.solarPanelPosition
                                                                            ? (theme) => theme.palette.primary.main
                                                                            : '#DDDDDD',
                                                                    borderRadius: 2,
                                                                    color:
                                                                        dirtyFields.solarPanelPosition &&
                                                                        !isSubmitting &&
                                                                        !errors.solarPanelPosition
                                                                            ? '#fff'
                                                                            : '#85858A',
                                                                    '&:hover': {
                                                                        background:
                                                                            dirtyFields.solarPanelPosition &&
                                                                            !isSubmitting &&
                                                                            !errors.solarPanelPosition
                                                                                ? (theme) => theme.palette.primary.dark
                                                                                : '#DDDDDD',
                                                                        cursor:
                                                                            dirtyFields.solarPanelPosition &&
                                                                            !isSubmitting &&
                                                                            !errors.solarPanelPosition
                                                                                ? 'pointer'
                                                                                : 'not-allowed',
                                                                        transition: '0.3s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    if (
                                                                        dirtyFields.solarPanelPosition &&
                                                                        !isSubmitting &&
                                                                        !errors.solarPanelPosition
                                                                    ) {
                                                                        const _camera: CameraWithInfo = {
                                                                            ...camera,
                                                                            SolarPanelPosition:
                                                                                watch().solarPanelPosition['key'],
                                                                        };
                                                                        props.onUpdateCamera?.(_camera);
                                                                        setIsEdit((prev) => ({
                                                                            ...prev,
                                                                            solarPanelPosition: false,
                                                                        }));
                                                                    }
                                                                }}
                                                            >
                                                                <MdDone style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                            <Stack
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    background: (theme) => theme.palette.error.main,
                                                                    borderRadius: 2,
                                                                    color: '#fff',
                                                                    '&:hover': {
                                                                        background: (theme) => theme.palette.error.dark,
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                onClick={() => {
                                                                    setIsEdit((prev) => ({
                                                                        ...prev,
                                                                        solarPanelPosition: false,
                                                                    }));
                                                                    reset({
                                                                        solarPanelPosition:
                                                                            positionSolarPanelReadableMapping[
                                                                                camera.SolarPanelPosition
                                                                            ],
                                                                    });
                                                                }}
                                                            >
                                                                <MdClose style={{ width: 16, height: 16 }} />
                                                            </Stack>
                                                        </>
                                                    ) : (
                                                        <Tooltip
                                                            title="Edit solar panel position"
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <Stack
                                                                sx={{
                                                                    color: '#85858A',
                                                                    '&:hover': {
                                                                        color: (theme) => theme.palette.secondary.main,
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s ease-in-out',
                                                                    },
                                                                }}
                                                                alignItems="center"
                                                                onClick={() => {
                                                                    setIsEdit((prev) => ({
                                                                        ...prev,
                                                                        solarPanelPosition: true,
                                                                    }));
                                                                    reset({
                                                                        solarPanelPosition:
                                                                            positionSolarPanelReadableMapping[
                                                                                camera.SolarPanelPosition
                                                                            ],
                                                                    });
                                                                }}
                                                            >
                                                                <IcEdit />
                                                            </Stack>
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container alignItems="center">
                                    <Grid xs={4}>
                                        <Typography>Status:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        {isHideAction ? (
                                            <Stack
                                                sx={{
                                                    width: 80,
                                                    height: 30,
                                                    background: (theme) => theme.palette.error.main,
                                                    borderRadius: 1,
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Typography color="#FFFFFF">Deleted</Typography>
                                            </Stack>
                                        ) : (
                                            <IOSSwitch
                                                label={
                                                    <Typography sx={{ minWidth: '55px' }}>
                                                        {Boolean(isActive === Status.Active) ? 'Active' : 'Inactive'}
                                                    </Typography>
                                                }
                                                iosSwitchProps={{
                                                    checked: Boolean(isActive === Status.Active),
                                                    onChange(event, checked) {
                                                        popUpWarning.setTrue();
                                                    },
                                                    disabled:
                                                        moment(now).isBefore(deployDate) ||
                                                        Boolean(isActive === Status.NotAvailable),
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>

                                {detailsCamera.map((item, index) => {
                                    return (
                                        <Grid xs={12} container>
                                            <Grid xs={4}>
                                                <Typography>{item.title}:</Typography>
                                            </Grid>
                                            <Grid xs={8}>
                                                <Typography>{item.content}</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Stack>
                        </Box>

                        <Box>
                            <Box paddingX={2} paddingY={1} bgcolor={color.grey100}>
                                <Typography fontSize={16}>Data processing</Typography>
                            </Box>
                            <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Enable verifications:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        {isHideAction ? (
                                            <Typography>On</Typography>
                                        ) : (
                                            <IOSSwitch
                                                label={
                                                    <Typography sx={{ minWidth: '55px' }}>
                                                        {!!isVerification.IsVerificationsEnabled ? 'On' : 'Off'}
                                                    </Typography>
                                                }
                                                iosSwitchProps={{
                                                    checked: isVerification.IsVerificationsEnabled,
                                                    onChange(event, checked) {
                                                        setIsEnabled(checked);
                                                        popUpWarningVerification.setTrue();
                                                    },
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Enable verifications at:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Typography sx={{ minWidth: '55px' }}>
                                            {isVerification.EnablePcnVerificationAt || camera.EnablePcnVerificationAt
                                                ? formatUKDateTime(isVerification.EnablePcnVerificationAt)
                                                : 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>

                        <Box>
                            <Box
                                paddingX={2}
                                paddingY={1}
                                bgcolor={color.grey100}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography fontSize={16}>Production</Typography>
                                <Tooltip title="Edit Production" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpEditProductDate.setTrue();
                                        }}
                                    >
                                        <IcEdit style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Production:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Typography>{moment(camera.DeployedDate).format('DD/MM/YYYY')}</Typography>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>

                    </Stack>

                    <Stack flex={1} spacing={3}>
                        <Box>
                            <Stack
                                paddingX={2}
                                paddingY={1}
                                bgcolor={color.grey100}
                                direction="row"
                                justifyContent={'space-between'}
                            >
                                <Typography fontSize={16}>Allocated zone</Typography>
                                {!isHideAction && (
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                        <Tooltip
                                            title={
                                                props.camera.IsAbleChangeZone
                                                    ? 'Edit allocated zone'
                                                    : 'This location/zone cannot currently be edited as some PCNs and images have not been processed'
                                            }
                                            placement="top"
                                            arrow
                                        >
                                            <IconButton
                                                sx={{
                                                    borderRadius: '3px',
                                                    width: '24px',
                                                    height: '24px',
                                                    color: props.camera.IsAbleChangeZone ? 'white' : 'gray',
                                                    backgroundColor: props.camera.IsAbleChangeZone ? '#2E7D32' : '',
                                                    ':hover': {
                                                        backgroundColor: props.camera.IsAbleChangeZone ? '#256428' : '',
                                                    },
                                                    padding: '4px',
                                                    '&.MuiIconButton-root.Mui-disabled': {
                                                        backgroundColor: '#eee',
                                                    },
                                                    cursor: props.camera.IsAbleChangeZone ? 'pointer' : 'default',
                                                }}
                                                disableRipple
                                                onClick={() => {
                                                    if (props.camera.IsAbleChangeZone) {
                                                        popUpEditAllocatedZone.setTrue();
                                                    }
                                                }}
                                            >
                                                <IcEdit
                                                    style={{
                                                        width: props.camera.IsAbleChangeZone ? '14px' : '18px',
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )}
                            </Stack>

                            <Stack
                                border={'1px solid #E4E7EC'}
                                direction={'column'}
                                paddingY={1}
                                paddingX={2}
                                spacing={2}
                            >
                                {allocatedZone.map((item, index) => {
                                    return (
                                        <Grid xs={12} container alignItems={'center'}>
                                            <Grid xs={4}>
                                                <Typography>{item.title}:</Typography>
                                            </Grid>
                                            <Grid xs={8}>
                                                <Typography>{item.content}</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                {/* <LocationMap
                                    defaultCenter={defaultCenter}
                                    setMap={setMap}
                                    map={map}
                                    pinnedLocation={pinnedLocation}
                                    setPinnedLocation={setPinnedLocation}
                                /> */}
                            </Stack>
                        </Box>

                        <Box>
                            <Stack
                                paddingX={2}
                                paddingY={1}
                                bgcolor={color.grey100}
                                direction="row"
                                justifyContent={'space-between'}
                            >
                                <Typography fontSize={16}>Service</Typography>
                                {!isHideAction && (
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                        <Tooltip title="Edit service" placement="top" arrow>
                                            <IconButton
                                                sx={{
                                                    borderRadius: '3px',
                                                    width: '24px',
                                                    height: '24px',
                                                    color: 'white',
                                                    backgroundColor: '#2E7D32',
                                                    ':hover': {
                                                        backgroundColor: '#256428',
                                                    },
                                                    padding: '4px',
                                                    '&.MuiIconButton-root.Mui-disabled': {
                                                        backgroundColor: '#eee',
                                                    },
                                                }}
                                                onClick={() => {
                                                    popUpEditService.setTrue();
                                                }}
                                            >
                                                <IcEdit style={{ width: '14px', height: '14px' }} />
                                            </IconButton>
                                        </Tooltip>

                                        <PopUpEditService
                                            {...popUpEditService}
                                            camera={camera}
                                            onConfirm={props.onUpdateService}
                                        />
                                    </Stack>
                                )}
                            </Stack>

                            <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Type service:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Typography>{camServiceTypes[camera.ServiceType].label}</Typography>
                                    </Grid>
                                </Grid>
                                {camera.ANPRCameraId && (
                                    <Grid xs={12} container>
                                        <Grid xs={4}>
                                            <Typography>ANPR ID:</Typography>
                                        </Grid>
                                        <Grid xs={8}>
                                            <Typography>{camera.ANPRCameraId}</Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Stack>
                        </Box>

                        {/* <Box>
                            <Box paddingX={2} paddingY={1} bgcolor={color.grey100}>
                                <Typography fontSize={16}>Photo capture config</Typography>
                            </Box>

                            <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Capture enable:</Typography>
                                    </Grid>
                                    <Grid xs={8}>{checkPostCaptureEnable() ?? 'N/A'}</Grid>
                                </Grid>

                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Capture interval:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        {camera.Solar4gCamera?.Solar4gCaptureInfo?.PostCaptureInterval ?? 'N/A'}
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Capture number:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        {camera.Solar4gCamera?.Solar4gCaptureInfo?.PostCaptureNum ?? 'N/A'}
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box> */}

                        <Box>
                            <Stack
                                paddingX={2}
                                paddingY={1}
                                bgcolor={color.grey100}
                                direction="row"
                                justifyContent={'space-between'}
                            >
                                <Typography fontSize={16}>Camera login information</Typography>
                                {!isHideAction && (
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                        <Tooltip title="Edit camera login information" placement="top" arrow>
                                            <IconButton
                                                sx={{
                                                    borderRadius: '3px',
                                                    width: '24px',
                                                    height: '24px',
                                                    color: 'white',
                                                    backgroundColor: '#2E7D32',
                                                    ':hover': {
                                                        backgroundColor: '#256428',
                                                    },
                                                    padding: '4px',
                                                    '&.MuiIconButton-root.Mui-disabled': {
                                                        backgroundColor: '#eee',
                                                    },
                                                }}
                                                onClick={() => {
                                                    popUpEditMilesightAccount.setTrue();
                                                }}
                                            >
                                                <IcEdit style={{ width: '14px', height: '14px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )}
                            </Stack>

                            <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>IP</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <a href={`http://${camera.IP}`} target="_blank" rel="noreferrer">
                                            {camera.IP}
                                        </a>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Username</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Typography>{camera.UserName}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} container>
                                    <Grid xs={4}>
                                        <Typography>Password</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <Typography>
                                                {showPassword ? camera.PassWord : camera.PassWord?.replace(/./g, '*')}
                                            </Typography>
                                            {showPassword ? (
                                                <BiHide
                                                    fontSize={20}
                                                    color="#85858A"
                                                    cursor="pointer"
                                                    onClick={() => setShowPassword(false)}
                                                />
                                            ) : (
                                                <BiShow
                                                    fontSize={20}
                                                    color="#85858A"
                                                    cursor="pointer"
                                                    onClick={() => setShowPassword(true)}
                                                />
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>

                        <Box>
                            <Stack
                                paddingX={2}
                                paddingY={1}
                                bgcolor={color.grey100}
                                direction="row"
                                justifyContent={'space-between'}
                            >
                                <Typography fontSize={16}>Recent exemption periods</Typography>
                                {!isHideAction && (
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                        <Tooltip title="Add exemption" placement="top" arrow>
                                            <IconButton
                                                sx={{
                                                    borderRadius: '3px',
                                                    width: '24px',
                                                    height: '24px',
                                                    color: 'white',
                                                    backgroundColor: '#2E7D32',
                                                    ':hover': {
                                                        backgroundColor: '#256428',
                                                    },
                                                    padding: '4px',
                                                    '&.MuiIconButton-root.Mui-disabled': {
                                                        backgroundColor: '#eee',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setMode(Mode.create);
                                                    popUpAddExemption.setTrue();
                                                }}
                                            >
                                                <MdAdd style={{ width: '16px', height: '16px' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="History exemption" placement="top" arrow>
                                            <IconButton
                                                sx={{
                                                    borderRadius: '3px',
                                                    width: '24px',
                                                    height: '24px',
                                                    color: 'white',
                                                    backgroundColor: '#2E7D32',
                                                    ':hover': {
                                                        backgroundColor: '#256428',
                                                    },
                                                    padding: '4px',
                                                    '&.MuiIconButton-root.Mui-disabled': {
                                                        backgroundColor: '#eee',
                                                    },
                                                }}
                                                onClick={() => {
                                                    popupHistoryExemption.setTrue();
                                                }}
                                            >
                                                <TbBook2 style={{ width: '16px', height: '16px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )}
                            </Stack>

                            {!!camera.ExemptionPeriods?.length && (
                                <Stack border={'1px solid #E4E7EC'} padding={2} spacing={2}>
                                    <>
                                        <Grid xs={12} container borderBottom={`1px solid ${color.grey200}`} pb={1}>
                                            <Grid xs={7.5}>
                                                <Typography fontWeight={500}>Effective</Typography>
                                            </Grid>
                                            <Grid xs={3.5}>
                                                <Typography fontWeight={500}>Description</Typography>
                                            </Grid>
                                        </Grid>
                                        {camera.ExemptionPeriods?.map((item) => {
                                            const isPast = moment(item.EffectiveFrom).isBefore(
                                                moment().startOf('date')
                                            );
                                            return (
                                                <Grid
                                                    xs={12}
                                                    container
                                                    borderBottom={`1px solid ${color.grey200}`}
                                                    pb={1}
                                                >
                                                    <Grid xs={7.5}>
                                                        <Stack>
                                                            <Typography>
                                                                {moment(item.EffectiveFrom).format('DD/MM/YYYY HH:mm')}{' '}
                                                                - {moment(item.EffectiveTo).format('DD/MM/YYYY HH:mm')}
                                                            </Typography>
                                                            <Typography
                                                                fontSize={12}
                                                                sx={{ color: (theme) => theme.palette.grey[700] }}
                                                            >
                                                                By {item.CreatedBy}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid xs={4.5}>
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems={!!errors.name ? 'flex-start' : 'center'}
                                                        >
                                                            <Typography>{item.Notes}</Typography>
                                                            {isPast ? (
                                                                <></>
                                                            ) : (
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="flex-end"
                                                                    spacing={0.7}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            color: '#85858A',
                                                                            transition: '.2s ease-in-out',
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                color: (theme) =>
                                                                                    theme.palette.secondary.main,
                                                                            },
                                                                        }}
                                                                        onClick={() => {
                                                                            setMode(Mode.edit);
                                                                            setSelectedExemption(item);
                                                                            popUpAddExemption.setTrue();
                                                                        }}
                                                                    >
                                                                        <IcEdit />
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            setSelectedExemption(item);
                                                                            popUpConfirmDelete.setTrue();
                                                                        }}
                                                                    >
                                                                        <ICDelete fontSize={18} />
                                                                    </Box>
                                                                </Stack>
                                                            )}
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </>
                                </Stack>
                            )}
                            {!camera.ExemptionPeriods?.length && (
                                <NoDataCameras sx={{ borderRadius: '0px 0px 4px 4px' }} />
                            )}
                        </Box>
                    </Stack>
                </Stack>
                <Box>
                    <Stack
                        paddingX={2}
                        paddingY={1}
                        bgcolor={color.grey100}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Typography fontSize={16}>Photo of camera as installed</Typography>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Tooltip title="Edit Photo of camera as installed" placement="top" arrow>
                                <IconButton
                                    sx={{
                                        borderRadius: '3px',
                                        width: '24px',
                                        height: '24px',
                                        color: 'white',
                                        backgroundColor: '#2E7D32',
                                        ':hover': {
                                            backgroundColor: '#256428',
                                        },
                                        padding: '4px',
                                        '&.MuiIconButton-root.Mui-disabled': {
                                            backgroundColor: '#eee',
                                        },
                                    }}
                                    onClick={() => {
                                        popUpEditCameraPhotoInstalled.setTrue();
                                    }}
                                >
                                    <IcEdit style={{ width: '14px', height: '14px' }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                    {camera?.CameraInstalledPhotos?.length! > 0 ? (
                        <ImageCarousel camera={camera} />
                    ) : (
                        <NoDataCameras sx={{ borderRadius: '0px 0px 4px 4px', height: '250px' }} />
                    )}
                </Box>
                <Box>
                    <Stack
                        paddingX={2}
                        paddingY={1}
                        bgcolor={color.grey100}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Typography fontSize={16}>Note</Typography>
                        <Tooltip title="Add note" placement="top" arrow>
                            <IconButton
                                sx={{
                                    borderRadius: '3px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'white',
                                    backgroundColor: '#2E7D32',
                                    ':hover': {
                                        backgroundColor: '#256428',
                                    },
                                    padding: '4px',
                                    '&.MuiIconButton-root.Mui-disabled': {
                                        backgroundColor: '#eee',
                                    },
                                }}
                                onClick={() => {
                                    setMode(Mode.create);
                                    popupNoteHistory.setTrue();
                                }}
                            >
                                <MdAdd style={{ width: '16px', height: '16px' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                        <Stack mt={2}>
                            <FilterRoot
                                handleResetFilter={() => {
                                    setFilter({
                                        page: 1,
                                        from: filter.from,
                                        to: filter.to,
                                        sortedOrder: cameraSortByStatus[1],
                                        sort: cameraNoteSortByType[0],
                                    });
                                    setSearchValue('');
                                }}
                                rightTitleContent={
                                    <Grid container xs={9} spacing={1} justifyContent={'flex-end'}>
                                        <Grid xs={5}>
                                            <SearchTypeSelect
                                                startAdornment={
                                                    <Stack direction={'row'}>
                                                        <SearchTypeSelect
                                                            listData={cameraSortByStatus}
                                                            value={filter.sortedOrder!}
                                                            keyEqual="Id"
                                                            keyLabel="Name"
                                                            handleChange={(e) => {
                                                                setFilter((prev) => ({
                                                                    ...prev,
                                                                    sortedOrder: e,
                                                                    page: 1,
                                                                }));
                                                            }}
                                                            label="Sort by"
                                                            minWidth={60}
                                                        />
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{
                                                                mx: '5px',
                                                                borderRightWidth: 1,
                                                                borderColor: color.grey600,
                                                            }}
                                                            variant="middle"
                                                        />
                                                    </Stack>
                                                }
                                                listData={cameraNoteSortByType}
                                                value={filter.sort!}
                                                keyEqual="Id"
                                                keyLabel="Name"
                                                handleChange={(e) => {
                                                    setFilter((prev) => ({ ...prev, sort: e, page: 1 }));
                                                }}
                                                label="Sort by"
                                                minWidth={60}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                filterChild={
                                    <Grid container xs={12} spacing={1} flex={1}>
                                        <Grid xs={6}>
                                            <BaseTextField
                                                placeholder={'Search by email'}
                                                value={searchValue}
                                                onChange={(e) => {
                                                    setFilter({
                                                        page: 1,
                                                        from: filter.from,
                                                        to: filter.to,
                                                        sortedOrder: filter.sortedOrder,
                                                        sort: filter.sort,
                                                    });
                                                    setSearchValue(e.target.value);
                                                }}
                                                maxLength={50}
                                                InputProps={{
                                                    endAdornment: (
                                                        <FiSearch
                                                            color="disabled"
                                                            size={16}
                                                            style={{ color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }}
                                                        />
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={6}>
                                            <StyledDateRangePicker
                                                value={{
                                                    startDate: filter?.from || undefined,
                                                    endDate: filter?.to || undefined,
                                                }}
                                                onChange={(value) => {
                                                    setFilter((prev) => ({
                                                        ...prev,
                                                        page: 1,
                                                        from: value.startDate,
                                                        to: value.endDate,
                                                    }));
                                                }}
                                                dateReset={{ startDate: undefined, endDate: undefined }}
                                                label={'Select date'}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </Stack>

                        <BaseListNote
                            paging={listNotes}
                            isLoading={isLoading}
                            tableHeaders={[...tableHeaders, '']}
                            styleContainer={{ mt: 2, mb: 5 }}
                            setPage={(page) => {
                                setFilter((prev) => ({ ...prev, page }));
                            }}
                            renderedItem={(item) => {
                                return (
                                    <>
                                        <CellOfRow value={formatDateTime(item.Created!)} align="left" />
                                        <CellOfRow value={item.CreatedBy ?? ''} align="left" />
                                        <CellOfRow value={formatDateTime(item.Updated!)} align="left" />
                                        <TableCell
                                            align="left"
                                            sx={{
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-wrap',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            <Typography>{item.Notes.trim() ?? ''}</Typography>
                                        </TableCell>
                                        <CellOfRow
                                            value={
                                                <>
                                                    <Stack direction={'row'} spacing={0.7} alignItems={'center'}>
                                                        <Tooltip placement="top" title="Edit">
                                                            <Box
                                                                sx={{
                                                                    color: '#85858A',
                                                                    transition: '.2s ease-in-out',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        color: (theme) => theme.palette.secondary.main,
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    setMode(Mode.edit);
                                                                    setIdNote(item.Id ?? 0);
                                                                    popupNoteHistory.setTrue();
                                                                }}
                                                            >
                                                                <IcEdit />
                                                            </Box>
                                                        </Tooltip>
                                                        <DangerTooltip placement="top" title="Delete">
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setIdNote(item.Id ?? 0);
                                                                    popUpConfirmDeleteNoteHistory.setTrue();
                                                                }}
                                                            >
                                                                <ICDelete fontSize={18} />
                                                            </Box>
                                                        </DangerTooltip>
                                                    </Stack>
                                                </>
                                            }
                                            align="left"
                                        />
                                    </>
                                );
                            }}
                        />
                    </Stack>
                </Box>
            </Stack>

            {!props.isHideAction && <TabDetailedInformationOfMilesight camera={camera} blobName={blobName} />}

            <PopUpEditMilesightAccount
                {...popUpEditMilesightAccount}
                camera={camera}
                onConfirm={(camera) => {
                    props.onUpdateCamera?.(camera);
                    popUpEditMilesightAccount.onClose();
                }}
            />

            <PopUpWarning
                {...popUpWarning}
                title="Confirm"
                message="Are you sure you want to change the status of this camera?"
                onConfirm={handleOnConfirm}
            />
            <PopUpWarning
                {...popUpConfirmDelete}
                title="Confirm"
                message="Are you sure you want to delete this exemption?"
                onConfirm={() => {
                    props.onDeleteExemption?.(selectedExemption.Id!);
                    popUpConfirmDelete.onClose();
                }}
            />
            <PopUpWarning
                {...popUpWarningVerification}
                title="Confirm"
                message="Are you sure you want to change the status of create verifications?"
                onConfirm={() => {
                    handleConfirmVerification(camera.Id!, isEnabled);
                    popUpWarningVerification.onClose();
                }}
            />

            <PopUpWarning
                {...popUpWarningEditMac}
                title="Confirm"
                message="This action will switch the camera to the 'Not available' state until the system retrieves the latest information from this MAC. Are you sure you want to edit this MAC?"
                onConfirm={() => {
                    if (dirtyFields.mac && !isSubmitting) {
                        const _camera: CameraWithInfo = {
                            ...camera,
                            Mac: watch().mac.trim(),
                        };
                        props.onUpdateCamera?.(_camera);
                        setIsEdit((prev) => ({ ...prev, mac: false }));
                        popUpWarningEditMac.onClose();
                    }
                }}
            />

            <PopUpAddExemption
                {...popUpAddExemption}
                mode={mode}
                selectedExemption={selectedExemption}
                onClose={() => {
                    popUpAddExemption.onClose();
                }}
                onConfirm={(data) => {
                    const exemption: ExemptionPeriods = {
                        CameraId: camera.Id!,
                        Id: data.id,
                        EffectiveFrom: moment(data.from).toDate(),
                        EffectiveTo: moment(data.to).toDate(),
                        Notes: data.desc,
                    };

                    props.onAddExemption?.(exemption);
                    popUpAddExemption.onClose();
                }}
            />
            <PopUpEditAllocatedZone
                zones={camera.Zone!}
                loading={props.loading}
                {...popUpEditAllocatedZone}
                camera={camera}
                onUpdateCamera={props.onUpdateCamera}
                setLoading={props.setLoading}
                onConfirm={async (camera) => {
                    await props.onUpdateCamera?.(camera).then(() => {
                        popUpEditAllocatedZone.onClose();
                    });
                }}
            />

            <PopUpHistoryExemption
                {...popupHistoryExemption}
                onClose={() => {
                    popupHistoryExemption.onClose();
                }}
            />

            <PopUpNoteHistory
                {...popupNoteHistory}
                mode={mode}
                camera={camera}
                listNotes={listNotes}
                idNote={idNote}
                handleAddOrUpdateNote={handleAddOrUpdateNote}
            />

            <PopUpWarning
                {...popUpConfirmDeleteNoteHistory}
                title="Confirm"
                message="Are you sure you want to delete this note?"
                onClose={() => {
                    setIdNote(0);
                    popUpConfirmDeleteNoteHistory.onClose();
                }}
                onConfirm={() => {
                    if (idNote) {
                        handleDeleteNote(idNote);
                        popUpConfirmDeleteNoteHistory.onClose();
                    } else {
                        pushError('Id note does not exist');
                    }
                }}
            />
            <PopUpEditPurposes {...popUpEditPurposes} camera={camera} onConfirm={props.onUpdateService} />
            <PopUpEditCameraPhotoInstalled
                {...popUpEditCameraPhotoInstalled}
                camera={camera}
                onConfirm={async () => {
                    await cameraController.get(String(camera.Id!)).then((res) => {
                        setCamera(res);
                    });
                }}
            />
            <PopUpEditDeployDate {...popUpEditProductDate} camera={camera} onConfirm={props.onUpdateService} />
        </>
    );
}
