import moment from 'moment';

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};

export const wait = (timeout: number) => {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, timeout);
    });
};
export const convertDateToSeconds = (date: Date | null) => {
    console.log('🚀 ~ file: index.ts:13 ~ convertDateToSeconds ~ date:', date);
    if (!date) return null;

    const [h, m] = [date.getHours(), date.getMinutes()];

    const second = h * 60 * 60 + m * 60;

    return second;
};
export const convertSecondsToDate = (seconds: number) => {
    if (seconds === 0) return moment().startOf('date');
    if (!seconds) return;
    return moment().startOf('date').seconds(seconds);
};
