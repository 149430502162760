import { Button } from '@mui/material';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { useEffect, useRef, useState } from 'react';
import { Group, Image, Layer, Rect, Stage } from 'react-konva';
import useImage from 'use-image';
import { v4 as uuidv4 } from 'uuid';
import BlurShape from './frame/BlurShape';
import SelectRectangle, { Shape } from './frame/SelectRectangle';
import { appConfig } from '@configs/index';
export const MINIMUM_SHAPE_SIZE = 10;

enum Mode {
    Default,
    Crop,
    Blur,
    Move,
}

type CropArea = {
    x: number;
    y: number;
    width: number;
    height: number;
};
type Point = {
    x: number;
    y: number;
};

const FrameBlur = () => {
    const imageUrl = appConfig.gateway.blobURL + 'ECam/2023/7/11/9f1b3f20-3831-11ee-a5c4-2b13cb4fb0bb.jpg';
    const [image] = useImage(imageUrl, 'anonymous');
    const [photos, setPhotos] = useState<(HTMLImageElement | undefined)[]>([]);

    const currentImage = photos[photos.length - 1] || image;

    const stageRef = React.useRef<Konva.Stage>(null);
    const cropRectRef = useRef<Konva.Rect>(null);
    const overlayRectRef = useRef<Konva.Rect>(null);

    const [mode, setMode] = useState<Mode>(Mode.Crop);

    const [shapes, setShapes] = useState<Shape[]>([]);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [draggingShape, setDraggingShape] = useState<string | null>(null);

    const [cropArea, setCropArea] = useState<CropArea | null>(null);
    console.log('🚀 ~ FrameBlur ~ cropArea:', cropArea);
    const [isCropping, setIsCropping] = useState(false);

    const [position, setPosition] = useState<Point>({ x: 0, y: 0 });
    const [sized, setSized] = useState(100);
    const [scale, setScale] = useState(1);

    const displayWidth = 1200;
    const displayHeight = displayWidth * 0.5625;

    const resetZoom = () => {
        const newScale = 1;
        const stage = stageRef.current;
        if (!stage) return;

        const newX = (stage.width() / 2) * (1 - newScale);
        const newY = (stage.height() / 2) * (1 - newScale);

        stage.scale({ x: newScale, y: newScale });
        stage.position({ x: newX, y: newY });
        stage.batchDraw();

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setSized(100); // Sét lại giá trị sized về 100%
    };
    const setScaleAndPosition = (newScale: number, newSized: number) => {
        const stage = stageRef.current;
        if (!stage) return;

        const newX = (stage.width() / 2) * (1 - newScale);
        const newY = (stage.height() / 2) * (1 - newScale);

        stage.scale({ x: newScale, y: newScale });
        stage.position({ x: newX, y: newY });
        stage.batchDraw();

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setSized(newSized);
    };
    const handleZoomIn = () => {
        if (sized < 200) {
            let newSized = Math.min(sized + 5, 200);
            let newScale = newSized / 100;
            setScaleAndPosition(newScale, newSized);
        }
    };

    const handleZoomOut = () => {
        if (sized > 100) {
            let newSized = Math.max(sized - 5, 100);
            let newScale = newSized / 100;
            setScaleAndPosition(newScale, newSized);
        }
    };

    const handleDragImage = (e: any) => {
        const stage = stageRef.current;
        if (!stage) return;

        const x = stage.x();
        const y = stage.y();

        const minX = -stage.width() * (scale - 1);
        const minY = -stage.height() * (scale - 1);

        if (x > 0 || y > 0 || x < minX || y < minY) {
            stage.position(position);
        } else {
            setPosition({ x, y });
        }
    };

    const handleCreateShape = () => {
        const pointerPosition = stageRef.current?.getPointerPosition();
        if (!pointerPosition) return null;

        const _shapes = shapes.slice();
        const id = uuidv4();
        _shapes.push({
            id,
            x: pointerPosition.x,
            y: pointerPosition.y,
            width: 0,
            height: 0,
        });
        setShapes(_shapes);
        return id;
    };

    const setSelectedShape = (id: string | null) => {
        setSelectedId(id);

        if (!id) return;

        const _shapes = shapes.slice();
        const index = _shapes.findIndex((shape) => shape.id === id);
        if (index > -1) {
            const shape = _shapes[index];
            _shapes.splice(index, 1);
            _shapes.push(shape);
            setShapes(_shapes);
        }
    };

    const handleRemoveShape = (id: string) => {
        const _shapes = shapes.slice();
        const index = _shapes.findIndex((shape) => shape.id === id);
        if (index > -1) {
            _shapes.splice(index, 1);
            setShapes(_shapes);
        }
    };

    const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        if (mode === Mode.Crop) {
            setIsCropping(true);
            if (!stageRef.current) return;
            const pointerPos = stageRef.current.getPointerPosition();

            // Chuyển đổi tọa độ chuột từ tọa độ trên stage sang tọa độ tương đối của ảnh
            const stageScale = stageRef.current.scaleX(); // Lấy tỷ lệ zoom hiện tại của stage
            const stagePosition = stageRef.current.position(); // Lấy vị trí hiện tại của stage
            const imagePos = {
                x: (pointerPos!.x - stagePosition.x) / stageScale, // Chuyển đổi tọa độ x của chuột
                y: (pointerPos!.y - stagePosition.y) / stageScale, // Chuyển đổi tọa độ y của chuột
            };

            if (pointerPos) {
                setCropArea({
                    x: imagePos.x,
                    y: imagePos.y,
                    width: 0,
                    height: 0,
                });
            }
        }
    };

    const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (mode === Mode.Crop) {
            if (!isCropping || !cropArea) return;
            if (!stageRef.current) return;
            const pointerPos = stageRef.current.getPointerPosition();

            // Chuyển đổi tọa độ chuột từ tọa độ trên stage sang tọa độ tương đối của ảnh
            const stageScale = stageRef.current.scaleX();
            const stagePosition = stageRef.current.position();
            const imagePos = {
                x: (pointerPos!.x - stagePosition.x) / stageScale,
                y: (pointerPos!.y - stagePosition.y) / stageScale,
            };

            if (pointerPos) {
                let x = cropArea.x;
                let y = cropArea.y;
                let width = imagePos.x - x;
                let height = imagePos.y - y;

                if (width < 0) {
                    x = imagePos.x;
                    width = Math.abs(width);
                }

                if (height < 0) {
                    y = imagePos.y;
                    height = Math.abs(height);
                }

                setCropArea({ ...cropArea, x, y, width, height });
            }
        } else if (mode === Mode.Blur) {
            if (!draggingShape) return;

            const pointerPosition = stageRef.current?.getPointerPosition();

            if (!pointerPosition) return;

            const _shapes = shapes.slice();
            const shape = _shapes[_shapes.length - 1];
            shape.width = pointerPosition.x - shape.x;
            shape.height = pointerPosition.y - shape.y;
            setShapes(_shapes);
        }
    };

    const handleMouseUp = (e: KonvaEventObject<MouseEvent>) => {
        if (mode === Mode.Crop) {
            if (isCropping && cropArea && cropArea.width && cropArea.height) setIsCropping(false);
        } else if (mode === Mode.Blur) {
            if (!draggingShape) return;

            const _shapes = shapes.slice();
            const shape = _shapes[_shapes.length - 1];
            if (Math.abs(shape.width) < MINIMUM_SHAPE_SIZE && Math.abs(shape.height) < MINIMUM_SHAPE_SIZE) {
                _shapes.pop();
                setShapes(_shapes);
            } else {
                setSelectedShape(draggingShape);
            }

            setDraggingShape(null);
            setSelectedShape(null);
        }
    };

    const handleCropImage = () => {
        if (!currentImage) return;
        if (!stageRef.current) return;

        if (cropArea && cropArea.width && cropArea.height) {
            const imageWidth = currentImage.width;
            const imageHeight = currentImage.height;

            const cropX = (cropArea.x / displayWidth) * imageWidth;
            const cropY = (cropArea.y / displayHeight) * imageHeight;
            const cropWidth = (cropArea.width / displayWidth) * imageWidth;
            const cropHeight = (cropArea.height / displayHeight) * imageHeight;

            const scaleX = imageWidth / cropWidth;
            const scaleY = imageHeight / cropHeight;
            const _shapes = shapes.slice();
            for (let i = 0; i < _shapes.length; i++) {
                _shapes[i].x = (_shapes[i].x - cropArea.x) * scaleX;
                _shapes[i].y = (_shapes[i].y - cropArea.y) * scaleY;
                _shapes[i].width *= scaleX;
                _shapes[i].height *= scaleY;
            }
            setShapes(_shapes);

            const croppedCanvas = document.createElement('canvas');
            croppedCanvas.width = cropWidth;
            croppedCanvas.height = cropHeight;

            const ctx = croppedCanvas.getContext('2d')!;
            ctx.drawImage(currentImage, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

            const croppedImage = new window.Image();
            croppedImage.src = croppedCanvas.toDataURL();

            croppedImage.onload = () => {
                setPhotos((prev) => [...prev, croppedImage]);
                setCropArea(null);
                setIsCropping(false);
                resetZoom();
            };
        }
    };
    const handleConvertToBase64 = () => {
        if (!stageRef.current || !image) return;

        const displayCanvas = stageRef.current.toCanvas();

        const originalWidth = image?.width;
        const originalHeight = image?.height;

        const originalCanvas = document.createElement('canvas');
        originalCanvas.width = originalWidth;
        originalCanvas.height = originalHeight;
        const originalContext = originalCanvas.getContext('2d')!;

        originalContext.drawImage(
            displayCanvas,
            0,
            0,
            displayWidth,
            displayHeight,
            0,
            0,
            originalWidth,
            originalHeight
        );

        const base64Str = originalCanvas.toDataURL();
    };

    useEffect(() => {
        const handleDeleteKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Delete' && selectedId) {
                handleRemoveShape(selectedId);
            }
        };
        document.addEventListener('keydown', handleDeleteKeyPress);

        return () => {
            document.removeEventListener('keydown', handleDeleteKeyPress);
        };
    }, [selectedId]);

    const handleResetImage = () => {
        // setPhotos((prev) => {
        //     const _photos = prev.filter((p, i) => i !== prev.length - 1);
        //     return _photos;
        // });
        setMode(Mode.Default);
        setPhotos([]);
        setShapes([]);
    };

    return (
        <>
            <Stage
                width={displayWidth}
                height={displayHeight}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onDragEnd={handleDragImage}
                onDragMove={handleDragImage}
                draggable={mode === Mode.Move}
                scaleX={scale}
                scaleY={scale}
                x={position.x}
                y={position.y}
                ref={stageRef}
            >
                <Layer>
                    <Group>
                        <Image image={currentImage} width={displayWidth} height={displayHeight} />
                    </Group>
                </Layer>

                <Layer>
                    {shapes.map((shape, i) => {
                        if (
                            Math.abs(shape.height) <= MINIMUM_SHAPE_SIZE ||
                            Math.abs(shape.height) <= MINIMUM_SHAPE_SIZE
                        ) {
                            return null;
                        }

                        return (
                            <BlurShape
                                key={shape.id}
                                imageWidth={displayWidth}
                                imageHeight={displayHeight}
                                imageSrc={photos[photos.length - 1] || image}
                                shape={shape}
                                filterKind={'pixelate'}
                                filterSize={10}
                            />
                        );
                    })}

                    <Group>
                        {shapes.map((shape, i) => {
                            return (
                                <SelectRectangle
                                    isSelected={shape.id === selectedId}
                                    key={shape.id}
                                    onChange={(newAttrs) => {
                                        const _shapes = shapes.slice();
                                        _shapes[i] = newAttrs;
                                        setShapes(_shapes);
                                    }}
                                    onSelect={() => {
                                        setMode(Mode.Blur);
                                        setSelectedShape(shape.id);
                                    }}
                                    shape={shape}
                                />
                            );
                        })}
                    </Group>
                </Layer>

                <Layer>
                    {mode === Mode.Crop && cropArea && (
                        <>
                            <Rect
                                ref={overlayRectRef}
                                x={0}
                                y={0}
                                width={displayWidth}
                                height={cropArea.y}
                                fill="rgba(0, 0, 0, 0.4)"
                            />
                            <Rect
                                ref={overlayRectRef}
                                x={0}
                                y={cropArea.y + cropArea.height}
                                width={displayWidth}
                                height={displayHeight - cropArea.y - cropArea.height}
                                fill="rgba(0, 0, 0, 0.4)"
                            />
                            <Rect
                                ref={overlayRectRef}
                                x={0}
                                y={cropArea.y}
                                width={cropArea.x}
                                height={cropArea.height}
                                fill="rgba(0, 0, 0, 0.4)"
                            />
                            <Rect
                                ref={overlayRectRef}
                                x={cropArea.x + cropArea.width}
                                y={cropArea.y}
                                width={displayWidth - cropArea.x - cropArea.width}
                                height={cropArea.height}
                                fill="rgba(0, 0, 0, 0.4)"
                            />
                            <Rect ref={cropRectRef} {...cropArea} stroke="#fff" strokeWidth={2} />
                        </>
                    )}
                </Layer>
            </Stage>
            <Button
                variant={mode === Mode.Crop ? 'contained' : 'outlined'}
                onClick={() => {
                    setMode(Mode.Crop);
                    setSelectedId(null);
                    setCropArea(null);
                }}
            >
                Crop Mode
            </Button>
            <Button
                variant={mode === Mode.Blur ? 'contained' : 'outlined'}
                onClick={() => {
                    setMode(Mode.Blur);
                }}
            >
                Blur mode
            </Button>
            {mode === Mode.Crop && <Button onClick={handleCropImage}>Crop</Button>}
            <Button onClick={handleConvertToBase64}>Save</Button>
            <Button onClick={handleResetImage}>Reset</Button>
            <Button onClick={handleZoomIn}>+</Button>
            <Button onClick={handleZoomOut}>-</Button>
            <Button variant={mode === Mode.Move ? 'contained' : 'outlined'} onClick={() => setMode(Mode.Move)}>
                Move
            </Button>
            <Button onClick={resetZoom}>Reset Zoom</Button>
        </>
    );
};

export default FrameBlur;
