import { ContraventionStatus } from '@EcamModel/model';
import { RandomColor } from '@pages/overview/VerificationsOverviewScreen';
import * as lodash from 'lodash';
import moment from 'moment';
export default function GetBooleanFromEnv(string: string) {
    return string === 'true';
}

export const formatLocaleTime = (date?: Date) => {
    if (!date) return 'Not available';
    return moment(date).local().format(`DD/MM/YYYY HH:mm:ss`);
};

export const formatUKDateTime = (date?: Date) => {
    if (!date) return 'Not available';
    return moment(date).tz(moment.tz.guess()).format('DD/MM/YYYY HH:mm:ss');
};

export const formatDateByTimeZone = (date: Date, timezone?: string, formatSS?: boolean) => {
    const _format: string = formatSS ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm';
    return moment(date)
        .tz(timezone ?? moment.tz.guess())
        .format('DD/MM/YYYY HH:mm:ss');
};

export const formatUKDate = (date: Date) => {
    return moment(date).format('DD/MM/YYYY');
};

export const formatUKDayMonth = (date: Date) => {
    return moment(date).format('DD/MM');
};

export const formatDateTime = (date: Date) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
};
export const getFirstAndLastDayOfCurrentMonth = () => {
    const currentDate = moment();

    const firstDayOfMonth = currentDate.clone().startOf('month');
    const lastDayOfMonth = currentDate.clone().endOf('month');

    return {
        from: firstDayOfMonth.toDate(),
        to: lastDayOfMonth.toDate(),
    };
};
export const statusMap: Record<ContraventionStatus, string> = {
    [ContraventionStatus.Open]: 'Open',
    [ContraventionStatus.Paid]: 'Paid',
    [ContraventionStatus.Cancelled]: 'Cancelled',
    [ContraventionStatus.Timeout]: 'Timeout',
    [ContraventionStatus.Paused]: 'Paused',
    [ContraventionStatus.Approved]: 'Approved',
    [ContraventionStatus.InProgress]: 'In Progress',
};

export const startOfYesterday = moment().subtract(1, 'day').startOf('day').toDate();
export const endOfYesterday = moment().subtract(1, 'day').endOf('day').toDate();

export const getFirstAndLastDayOfCurrentWeek = () => {
    const currentDate = moment();

    const firstDayOfWeek = currentDate.clone().startOf('week');
    const lastDayOfWeek = currentDate.clone().endOf('week');

    return {
        fromCurrentWeek: firstDayOfWeek.toDate(),
        toCurrentWeek: lastDayOfWeek.toDate(),
    };
};

export const formatUKTimeDate = (date?: Date) => {
    if (!date) return 'Not available';
    return moment(date).tz(moment.tz.guess()).format('HH:mm DD/MM/YYYY');
};

export const formatTimeString = (startTime: Date, EndTime: Date) => {
    return lodash.upperFirst(moment.duration(moment(startTime).diff(moment(EndTime))).humanize());
};

export function formatMinutesToDurationString(minutes) {
    const duration = moment.duration(Math.abs(minutes), 'minutes');
    const humanizedDuration = lodash.upperFirst(duration.humanize(minutes < 0));
    return humanizedDuration;
}

export function generateUniqueColors(arrayLength: number) {
    const uniqueColors = new Set();

    while (uniqueColors.size < arrayLength) {
        const color =
            '#' +
            Math.floor(Math.random() * 16777215)
                .toString(16)
                .padStart(6, '0');
        uniqueColors.add(color);
    }

    return Array.from(uniqueColors);
}

export const formatNumber = (amount: number) => {
    let formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'decimal',
    }).format(amount);
    return formattedNumber;
};

export const formatTruncateDecimal = (value: number, decimalPlaces: number) => {
    let decimalIndex = value.toString().indexOf('.');
    if (decimalIndex === -1) {
        return value.toString();
    }
    return parseFloat(value.toString().slice(0, decimalIndex + 1 + decimalPlaces));
};

export const storedCameraColors: Map<number, string> = localStorage.getItem('cameraChartLineColors')
    ? new Map<number, string>(JSON.parse(localStorage.getItem('cameraChartLineColors')!))
    : new Map<number, string>();

export const saveCameraColorsToLocalStorage = (colorMap: Map<number, string>) => {
    localStorage.setItem('cameraChartLineColors', JSON.stringify(Array.from(colorMap.entries())));
};

export const getRandomUnusedColor = (usedColors: Set<string>): string => {
    let color: string;
    do {
        const getRandomHex = () =>
            Math.floor(Math.random() * 256)
                .toString(16)
                .padStart(2, '0');
        const r = getRandomHex();
        const g = getRandomHex();
        const b = getRandomHex();
        color = `#${r}${g}${b}`;
    } while (usedColors.has(color));

    usedColors.add(color);
    return color;
};

export const adjustPreviewSize = (imageWidth: number, imageHeight: number, width: number, height: number) => {
    const aspectRatio = imageWidth / imageHeight;
    let previewWidth = width;
    let previewHeight = height;

    if (aspectRatio > width / height) {
        previewWidth = width;
        previewHeight = width / aspectRatio;
    } else {
        previewHeight = height;
        previewWidth = height * aspectRatio;
    }

    return { previewWidth, previewHeight };
};
