import ArrowAccordionIcon from '@assets/jsx-icon/ArrowAccordionIcon';
import { BaseHead, CellOfRow } from '@components/tables';
import { appConfig } from '@configs/index';
import { ContraventionStatus, PCNDuplicate } from '@EcamModel/model';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime, formatUKDate } from 'src/helpers';

type Props = {
    reason?: string;
    cancellationNotes?: string;
    pcnDuplicatesWarning: PCNDuplicate[];
    pcnDuplicatesAdditionalInformation: PCNDuplicate[];
    grId?: number;
    pcId?: number;
};

export const statusMapping = {
    [ContraventionStatus.Approved]: 'Approved',
    [ContraventionStatus.Cancelled]: 'Cancelled',
    [ContraventionStatus.InProgress]: 'In Progress',
    [ContraventionStatus.Open]: 'Open',
    [ContraventionStatus.Paid]: 'Paid',
    [ContraventionStatus.Paused]: 'Paused',
    [ContraventionStatus.Timeout]: 'Timeout',
};

export default function DuplicatePCDetail({
    reason,
    cancellationNotes,
    pcnDuplicatesWarning,
    pcnDuplicatesAdditionalInformation,
    grId,
    pcId,
}: Props) {
    const [rotateArrowIcon, setRotateArrowIcon] = useState<boolean>(false);

    const handleRotateArrowIconClick = () => {
        setRotateArrowIcon(!rotateArrowIcon);
    };

    useEffect(() => {
        setRotateArrowIcon(false);
    }, [grId]);

    return (
        <>
            {reason && (
                <Typography variant="body1" color="initial">
                    Reason for cancellation:
                    <Typography pl={'5px'} variant="caption">
                        {reason ?? ''}
                    </Typography>
                </Typography>
            )}

            {cancellationNotes && (
                <Typography>
                    Description:
                    <Typography pl={'5px'} variant="caption">
                        {cancellationNotes ?? ''}
                    </Typography>
                </Typography>
            )}

            {pcnDuplicatesWarning.length > 0 && (
                <>
                    <Typography mt={1}>
                        A duplicate contravention for the same location, zone, VRN and contravention reason has been
                        create within a 24-hour period. Please review these contraventions
                    </Typography>
                    {pcnDuplicatesWarning?.map((e, index) => {
                        return (
                            <Stack direction={'column'} spacing={1} bgcolor={color.white} p={1} mt={1} borderRadius={1}>
                                <Typography minWidth={195} fontWeight={500} color={color.danger}>
                                    Reference:{' '}
                                    <Link
                                        to={
                                            e.source === 'Stella'
                                                ? `${appConfig.apps.stella}/ContraventionManagement/Contravention/Details/${e.id}`
                                                : `/verification/contravention/${e.id}`
                                        }
                                        target={'_blank'}
                                    >
                                        {e.reference ?? ' '}
                                    </Link>
                                </Typography>
                                <Stack
                                    direction="row"
                                    key={index}
                                    divider={
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                borderRightWidth: 1,
                                                borderColor: color.textPrimary,
                                                py: '3px',
                                            }}
                                        />
                                    }
                                    spacing={2}
                                >
                                    <Typography>Source: {e.source}</Typography>
                                    <Typography>Status: {statusMapping[e.status]}</Typography>
                                    {e.step && <Typography>Step: {e.step}</Typography>}
                                    <Typography>Date: {formatDateTime(e.firstIssuedDate!)}</Typography>
                                    {/* <Typography>Date: {formatDateByTimeZone(e.firstIssuedDate!, 'Europe/London', true)}</Typography> */}
                                </Stack>
                            </Stack>
                        );
                    })}
                </>
            )}

            {pcnDuplicatesAdditionalInformation.length > 0 && (
                <Accordion
                    sx={{
                        '&.MuiPaper-root': {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                    expanded={rotateArrowIcon}
                    onChange={handleRotateArrowIconClick}
                >
                    <AccordionSummary
                        sx={{
                            '&.MuiButtonBase-root': {
                                px: 0,
                                minHeight: 0,
                            },
                            '&.MuiAccordionSummary-content': {
                                my: '2px',
                                '&.Mui-expanded': {
                                    margin: 0,
                                },
                            },
                        }}
                    >
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            <Typography color={color.success}>
                                List related canceled PCs ({pcnDuplicatesAdditionalInformation.length ?? 0})
                            </Typography>
                            <ArrowAccordionIcon
                                style={{
                                    transform: rotateArrowIcon ? 'rotate(90deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                            />
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            backgroundColor: color.white,
                            p: 2,
                            borderRadius: 2,
                        }}
                    >
                        <Stack borderRadius={1}>
                            {pcnDuplicatesAdditionalInformation.length > 0 ? (
                                <TableContainer component={Box}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <BaseHead align="left" title="Reference" />
                                                <BaseHead align="center" title="Source" />
                                                <BaseHead align="center" title="Step" />
                                                <BaseHead align="center" title="Status" />
                                                <BaseHead align="center" title="Canceled reason" />
                                                <BaseHead align="center" title="Issued date" />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pcnDuplicatesAdditionalInformation?.map((item, index) => {
                                                return (
                                                    <TableRow key={item.id!}>
                                                        <CellOfRow
                                                            value={
                                                                <Link
                                                                    to={
                                                                        item.source === 'Stella'
                                                                            ? `${appConfig.apps.stella}/ContraventionManagement/Contravention/Details/${item.id}`
                                                                            : `/verification/${pcId}/${item.id}`
                                                                    }
                                                                    target={'_blank'}
                                                                >
                                                                    {item.reference ?? ' '}
                                                                </Link>
                                                            }
                                                            align="left"
                                                        />
                                                        <CellOfRow
                                                            value={
                                                                <Typography component={'span'}>
                                                                    {item.source}
                                                                </Typography>
                                                            }
                                                            align="center"
                                                        />
                                                        <CellOfRow
                                                            value={
                                                                <Typography component={'span'}>{item.step}</Typography>
                                                            }
                                                            align="center"
                                                        />
                                                        <CellOfRow
                                                            value={
                                                                <Typography component={'span'}>
                                                                    {statusMapping[item.status]}
                                                                </Typography>
                                                            }
                                                            align="center"
                                                        />
                                                        <CellOfRow
                                                            value={
                                                                <Typography component={'span'}>
                                                                    {item.cancellationReason}
                                                                </Typography>
                                                            }
                                                            align="center"
                                                        />
                                                        <CellOfRow
                                                            value={
                                                                <Typography component={'span'}>
                                                                    {formatUKDate(item?.firstIssuedDate!)}
                                                                </Typography>
                                                            }
                                                            align="center"
                                                        />
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <ListNoData />
                            )}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
}
