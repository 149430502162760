import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcInfo(props: SvgIconProps) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18ZM9.00001 4C8.44772 4 8.00001 4.44772 8.00001 5C8.00001 5.55228 8.44772 6 9.00001 6H9.01001C9.56229 6 10.01 5.55228 10.01 5C10.01 4.44772 9.56229 4 9.01001 4H9.00001ZM7.5 8C6.94772 8 6.5 8.44772 6.5 9C6.5 9.55228 6.94772 10 7.5 10H8V13C8 13.5523 8.44771 14 9 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H10V9C10 8.44772 9.55228 8 9 8H7.5Z"
                fill="#85858A"
            />
        </svg>
    );
}
