import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function CloseIcon(props: Props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.99967 14.6673C11.6663 14.6673 14.6663 11.6673 14.6663 8.00065C14.6663 4.33398 11.6663 1.33398 7.99967 1.33398C4.33301 1.33398 1.33301 4.33398 1.33301 8.00065C1.33301 11.6673 4.33301 14.6673 7.99967 14.6673Z"
                stroke={props.stroke ? props.stroke : '#85858A'}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.11328 9.88661L9.88661 6.11328"
                stroke={props.stroke ? props.stroke : '#85858A'}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.88661 9.88661L6.11328 6.11328"
                stroke={props.stroke ? props.stroke : '#85858A'}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
