import { defaultPagination } from '@Core';
import { IGetCameraPhotoProps } from '@EcamModel/controllers/ICameraPhotoHttpController';
import {
    CameraPhotoVehicles,
    CaptureType,
    GroupPotentialContraventions,
    PhotoType,
    PotentialContraventionWithInfo,
} from '@EcamModel/model';
import CloseIcon from '@assets/jsx-icon/CloseIcon';
import PopUpBase from '@components/PopUpBase';
import { pushError, pushSuccess } from '@components/toast';
import { appConfig } from '@configs/index';
import { cameraPhotoController, potentialGroupContraventionController } from '@controllers/index';
import { IPopUp } from '@hooks/usePopUp';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    Grow,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';
import { Filter } from '@pages/cameras/live-photos/components';
import BaseList from '@pages/cameras/live-photos/components/BaseList';
import color from '@theme/Colors';
import { orderBy } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { usePotentialContravention } from '../hooks/PotentialContraventionProvider';
import { Controls, pcImage } from './EvidencePhotoGroup';
import { IUpdateEvidencePhoto } from '@EcamModel/controllers/IGroupPotentialContraventionHttpController';

export type Props = Omit<IPopUp, 'onConfirm'> & {
    pc: PotentialContraventionWithInfo;
};

type Column = {
    id: number;
    title: string;
    items: Partial<CameraPhotoVehicles[]>;
};

const photoType = {
    [PhotoType.Entry]: 'Entry photo',
    [PhotoType.Validation]: 'Contravention validation photo',
    [PhotoType.Exit]: 'Exit photo',
};

enum ModeButtonDisabled {
    initial,
    cleared,
}

export default function PopupChangeEvidence(props: Props) {
    const { pc } = props;
    const navigate = useNavigate();
    const backdrop = useBackdrop();
    const { entryPhoto, validationPhoto, exitPhoto } = pcImage(pc);
    const potentialContraventionContext = usePotentialContravention();
    const { setGroupPc } = potentialContraventionContext;

    const initialColumnsEvidencePhoto: Column[] = [
        {
            id: 1,
            title: photoType[PhotoType.Entry],
            items: [
                {
                    Id: entryPhoto?.Id,
                    Plate: entryPhoto?.Plate!,
                    CameraPhoto: entryPhoto?.CameraPhoto!,
                },
            ],
        },
        {
            id: 2,
            title: photoType[PhotoType.Validation],
            items: [
                {
                    Id: validationPhoto?.Id,
                    Plate: validationPhoto?.Plate!,
                    CameraPhoto: validationPhoto?.CameraPhoto!,
                },
            ],
        },
        {
            id: 3,
            title: photoType[PhotoType.Exit],
            items: [
                {
                    Id: exitPhoto?.Id,
                    Plate: exitPhoto?.Plate!,
                    CameraPhoto: exitPhoto?.CameraPhoto!,
                } as any,
            ],
        },
    ];

    const [isCheckedDisableButtonClearOrResetItems, setIsCheckedDisableButtonClearOrResetItems] =
        useState<ModeButtonDisabled>(ModeButtonDisabled.initial);
    const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
    const [selectImagePreview, setSelectImagePreview] = useState<string>('');

    const [paging, setPaging] = useState(defaultPagination<CameraPhotoVehicles>());
    const initFilter: Filter = useMemo(() => {
        return {
            page: 1,
            pageSize: 20,
            timeFrom: moment()
                .tz(pc.TimeZone || moment.tz.guess())
                .startOf('day')
                .toDate(),
            timeTo: moment()
                .tz(pc.TimeZone || moment.tz.guess())
                .endOf('day')
                .toDate(),
            dayStartTime: null,
            dayEndTime: null,
            plate: '',
            refetch: false,
        };
    }, [pc.TimeZone]);

    const [filter, setFilter] = useState<Filter>(initFilter);
    const [livePhotosItems, setLivePhotosItems] = useState<CameraPhotoVehicles[]>([]);
    const [selectedColumnEvidencePhoto, setSelectedColumnEvidencePhoto] = useState<number | null>(null);
    const [selectedItemsLivePhotos, setSelectedItemsLivePhotos] = useState<CameraPhotoVehicles | null>(null);
    const [valueItemsLivePhotoSelected, setValueItemsLivePhotoSelected] =
        useState<Column[]>(initialColumnsEvidencePhoto);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const compareIds = (): boolean => {
        for (let i = 0; i < initialColumnsEvidencePhoto.length; i++) {
            if (initialColumnsEvidencePhoto[i].items[0]?.Id !== valueItemsLivePhotoSelected[i].items[0]?.Id) {
                return false;
            }
        }
        return true;
    };

    const isCheckDisabledButtonConfirm =
        valueItemsLivePhotoSelected.some((column) => column.items.length === 0) ||
        paging.rows.length === 0 ||
        compareIds();

    function updateFilter(params: Partial<Filter>) {
        setFilter((prev) => ({ ...prev, page: 1, ...params }));
    }

    function handleChangeTime(from: Moment | null, to: Moment | null) {
        let updatedFrom = from;
        let updatedTo = to;

        if (!updatedTo) {
            updatedTo = moment().endOf('day');
        }

        if (updatedFrom && updatedTo && updatedFrom.isAfter(updatedTo)) {
            [updatedFrom, updatedTo] = [updatedTo, updatedFrom];
        }

        updateFilter({ dayStartTime: updatedFrom, dayEndTime: updatedTo });
        setIsCheckedDisableButtonClearOrResetItems(ModeButtonDisabled.initial);
    }

    useEffect(() => {
        const existingIds = initialColumnsEvidencePhoto.flatMap((column) => column.items.map((item) => item?.Id));
        const filteredRows = paging.rows.filter((row) => !existingIds.includes(row.Id));
        setLivePhotosItems(filteredRows);
    }, [paging.rows]);

    const handleRadioChangeColumnEvidencePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedColumnEvidencePhoto(Number(event.target.value));
    };
    const handleRadioChangeItemsLivePhotos = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedItem = livePhotosItems.find((item) => item.Id === Number(event.target.value));
        setSelectedItemsLivePhotos(selectedItem || null);
    };

    const handleChangeEvidence = () => {
        if (selectedColumnEvidencePhoto !== null && selectedItemsLivePhotos !== null) {
            const getCaptureAt = (columnId: number): Date | null => {
                const column = valueItemsLivePhotoSelected.find((col) => col.id === columnId);
                const captureAt = column?.items?.[0]?.CameraPhoto?.CaptureAt;
                return captureAt ? new Date(captureAt) : null;
            };

            const captureAt1 = getCaptureAt(1);
            const captureAt2 = getCaptureAt(2);
            const captureAt3 = getCaptureAt(3);
            const selectedCaptureAt = new Date(selectedItemsLivePhotos.CameraPhoto?.CaptureAt!);

            if (isNaN(selectedCaptureAt.getTime())) {
                pushError('Selected item CaptureAt is not a valid date');
                return;
            }

            if (selectedColumnEvidencePhoto === 2) {
                if (captureAt1 && selectedCaptureAt.getTime() <= captureAt1.getTime()) {
                    pushError('Contravention validation/Exit time must be after Entry time');
                    return;
                }
            } else if (selectedColumnEvidencePhoto === 3) {
                if (captureAt1 && selectedCaptureAt.getTime() <= captureAt1.getTime()) {
                    pushError('Contravention validation/Exit time must be after Entry time');
                    return;
                }
                if (captureAt2 && selectedCaptureAt.getTime() < captureAt2.getTime()) {
                    pushError('Exit time must be after Contravention validation time');
                    return;
                }
            } else if (selectedColumnEvidencePhoto === 1) {
                if (captureAt2 && captureAt2.getTime() <= selectedCaptureAt.getTime()) {
                    pushError('Entry time must be before Contravention validation time');
                    return;
                }
                if (captureAt3 && captureAt3.getTime() <= selectedCaptureAt.getTime()) {
                    pushError('Entry time must be before Exit time');
                    return;
                }
            }

            const updateColumn = (columnId: number) => {
                setValueItemsLivePhotoSelected((prevColumns) =>
                    prevColumns.map((column) => {
                        if (column.id === columnId) {
                            const oldItem = column.items?.[0];
                            column.items = [selectedItemsLivePhotos];
                            if (oldItem && oldItem.Id !== selectedItemsLivePhotos.Id) {
                                setLivePhotosItems((prevItems) => {
                                    const newItems = [...prevItems];
                                    const index = newItems.findIndex((item) => item.Id === oldItem.Id);
                                    if (index === -1) {
                                        newItems.push(oldItem);
                                    }
                                    return newItems;
                                });
                            }
                        }
                        return column;
                    })
                );
            };

            if (selectedColumnEvidencePhoto === 2) {
                updateColumn(2);
                updateColumn(3);
            } else if (selectedColumnEvidencePhoto === 3) {
                updateColumn(3);
            } else {
                updateColumn(selectedColumnEvidencePhoto);
            }

            setLivePhotosItems((prevItems) => {
                const newItems = prevItems.filter((item) => item.Id !== selectedItemsLivePhotos.Id);
                return newItems;
            });

            setSelectedItemsLivePhotos(null);
        }
    };

    const handleRemoveItem = (columnId: number, itemId: number) => {
        setValueItemsLivePhotoSelected((prevColumns) =>
            prevColumns.map((column) => {
                if (column.id === columnId) {
                    const updatedItems = column.items.filter((item) => item?.Id !== itemId);
                    const removedItem = column.items.find((item) => item?.Id === itemId);
                    if (removedItem) {
                        setLivePhotosItems((prevItems) => {
                            if (!prevItems.some((item) => item.Id === removedItem.Id)) {
                                return [...prevItems, removedItem];
                            }
                            return prevItems;
                        });
                    }
                    return {
                        ...column,
                        items: updatedItems,
                    };
                }
                return column;
            })
        );
    };

    const getSecond = (date: moment.Moment) => {
        return date.hours() * 60 * 60 + date.minutes() * 60;
    };

    const startOfHour: Moment = moment(pc.EntryAt).startOf('day');
    const endOfHour: Moment = moment(pc.EntryAt).endOf('day');

    const getPlateImageBundlePhotos = async () => {
        setIsLoading(true);
        console.log('pc.Plate', pc.Plate);
        const _filter: IGetCameraPhotoProps = {
            page: filter.page,
            pageSize: filter.pageSize,
            sorts: ['-CaptureAt'],
            CameraId: pc.CameraId ?? 0,
            FromDate: startOfHour.toDate(),
            ToDate: endOfHour.toDate(),
            FromTime: filter.dayStartTime ? getSecond(filter.dayStartTime) : undefined,
            ToTime: filter.dayEndTime ? getSecond(filter.dayEndTime) : undefined,
            Plate: pc.Plate ?? '',
        };
        await cameraPhotoController
            .getCameraPhotoVehiclesByPlate(_filter)
            .then((res) => {
                setPaging(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!props.open) return;
        getPlateImageBundlePhotos();
        setValueItemsLivePhotoSelected(initialColumnsEvidencePhoto);
    }, [
        filter.page,
        filter.timeFrom,
        filter.timeTo,
        filter.dayStartTime,
        filter.dayEndTime,
        filter.pageSize,
        props.open,
    ]);

    const handleChangeEvidencePhoto = async () => {
        backdrop.setTrue();
        const updateEvidencePhoto: IUpdateEvidencePhoto = {
            PotentialContraventionId: pc?.Id!,
            EntryPhotos: {
                CameraPhotoVehicleId: valueItemsLivePhotoSelected?.[0].items?.[0]?.Id!,
                PotentialContraventionId: pc?.Id!,
                PhotoType: PhotoType.Entry,
            },
            ValidationPhotos: {
                CameraPhotoVehicleId: valueItemsLivePhotoSelected?.[1].items?.[0]?.Id!,
                PotentialContraventionId: pc?.Id!,
                PhotoType: PhotoType.Validation,
            },
            ExitPhotos: {
                CameraPhotoVehicleId: valueItemsLivePhotoSelected?.[2].items?.[0]?.Id!,
                PotentialContraventionId: pc?.Id!,
                PhotoType: PhotoType.Exit,
            },
        };
        await potentialGroupContraventionController
            .doUpdateEvidencePhoto(updateEvidencePhoto)
            .then(async (res) => {
                await potentialGroupContraventionController
                    .getGroupPotentialContravention(pc?.GroupPotentialContraventionId!)
                    .then((res) => {
                        props.onClose?.();
                        pushSuccess('Save successfully');
                        const _res: GroupPotentialContraventions = {
                            ...res,
                            PotentialContraventions: orderBy(res.PotentialContraventions, (pc) => -pc.Step),
                        };
                        setGroupPc(_res);
                    })
                    .catch((err) => navigate('/verification'));
            })
            .catch((err) => {})
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleCancel = () => {
        props.onClose?.();
        setSelectedColumnEvidencePhoto(null);
        setSelectedItemsLivePhotos(null);
        setLivePhotosItems([]);
        setFilter(initFilter);
        setValueItemsLivePhotoSelected(initialColumnsEvidencePhoto);
        setIsCheckedDisableButtonClearOrResetItems(ModeButtonDisabled.initial);
    };

    const handleClearAllItems = () => {
        setIsCheckedDisableButtonClearOrResetItems(ModeButtonDisabled.cleared);
        setValueItemsLivePhotoSelected((prevColumns) => {
            const itemIds = new Set(livePhotosItems.map((item) => item.Id));

            prevColumns.forEach((column) => {
                column.items.forEach((item) => {
                    if (item?.Id && !itemIds.has(item.Id)) {
                        setLivePhotosItems((prevItems) => [...prevItems, item]);
                        itemIds.add(item.Id);
                    }
                });
            });
            return prevColumns.map((column) => ({ ...column, items: [] }));
        });
    };

    const handleResetItems = () => {
        setIsCheckedDisableButtonClearOrResetItems(ModeButtonDisabled.initial);
        const resetColumns = initialColumnsEvidencePhoto.map((column) => {
            const resetItems = column.items.map((item) => {
                const livePhotoItem = livePhotosItems.find((livePhoto) => livePhoto.Id === item?.Id);
                return livePhotoItem || item;
            });
            return { ...column, items: resetItems };
        });

        setValueItemsLivePhotoSelected(resetColumns);

        const newLivePhotosItems = livePhotosItems.filter(
            (livePhoto) =>
                !initialColumnsEvidencePhoto
                    .flatMap((column) => column.items.map((item) => item?.Id))
                    .includes(livePhoto.Id)
        );

        setLivePhotosItems(newLivePhotosItems);
    };

    return (
        <PopUpBase
            open={props.open}
            fixOverflow={true}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    '& .MuiPaper-root': {
                        overflowY: 'unset',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
                maxWidth: 'lg',
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Stack>
                    <Typography variant="h4">Change evidence photo</Typography>
                    <Typography variant="body1" textAlign={'center'} color={'#85858A'}>
                        {/* Drag and drop images from the live photo to replace the evidence photos */}
                    </Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack direction={'column'} spacing={1} height={'100%'}>
                    <Stack spacing={1} bgcolor={color.grey100} padding={2} borderRadius={1}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="h5">Evidence photo</Typography>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleResetItems}
                                    disabled={
                                        isCheckedDisableButtonClearOrResetItems === ModeButtonDisabled.initial ||
                                        isLoading
                                    }
                                    sx={{ padding: '4px 8px', fontSize: 12 }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClearAllItems}
                                    disabled={
                                        isCheckedDisableButtonClearOrResetItems === ModeButtonDisabled.cleared ||
                                        isLoading
                                    }
                                    sx={{ padding: '4px 8px', fontSize: 12 }}
                                >
                                    Clear all
                                </Button>
                            </Stack>
                        </Stack>
                        <Stack
                            width={'100%'}
                            spacing={1}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            {valueItemsLivePhotoSelected.map((column) => (
                                <Stack
                                    key={column.id}
                                    width="100%"
                                    direction="column"
                                    spacing={1}
                                    padding="8px 16px"
                                    borderRadius={1}
                                    bgcolor="white"
                                    border={
                                        selectedColumnEvidencePhoto === column.id
                                            ? `1px solid ${color.success}`
                                            : 'none'
                                    }
                                >
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            value={selectedColumnEvidencePhoto}
                                            onChange={handleRadioChangeColumnEvidencePhoto}
                                        >
                                            <Grid container xs={12}>
                                                <Grid>
                                                    <FormControlLabel
                                                        sx={{
                                                            '.MuiFormControlLabel-label': { mt: '3px !important' },
                                                        }}
                                                        control={<Radio value={column.id} />}
                                                        label={column.title}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                    <Stack spacing={1}>
                                        {column.items.length ? (
                                            <>
                                                {column.items.map((item, index) => {
                                                    return (
                                                        <PhotoDetails
                                                            isCheckIcon
                                                            key={index}
                                                            item={item}
                                                            isLoading={isLoading}
                                                            setSelectImagePreview={setSelectImagePreview}
                                                            setOpenImagePreview={setOpenImagePreview}
                                                            props={props}
                                                            handleRemoveItem={handleRemoveItem}
                                                            columnId={column.id}
                                                        />
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <ListNoData
                                                sx={{
                                                    minHeight: 207,
                                                }}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>

                    <Stack direction={'column'} bgcolor={color.grey100} padding={2} spacing={1} borderRadius={1}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant="h5">Live photo</Typography>
                                <Button
                                    variant="outlinedGreen"
                                    sx={{ padding: '4px 8px', fontSize: 12 }}
                                    onClick={handleChangeEvidence}
                                    disabled={!selectedColumnEvidencePhoto || !selectedItemsLivePhotos?.Id}
                                >
                                    Change evidence
                                </Button>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <TimePicker
                                    label="From"
                                    value={filter.dayStartTime || null}
                                    ampm={false}
                                    onAccept={(newValue) => handleChangeTime(newValue, filter.dayEndTime)}
                                    sx={{ width: 200, '& .MuiSvgIcon-root': { width: 18, height: 18 } }}
                                />
                                <TimePicker
                                    label="To"
                                    ampm={false}
                                    disabled={!filter.dayStartTime}
                                    value={filter.dayEndTime || null}
                                    onAccept={(newValue) => handleChangeTime(filter.dayStartTime, newValue)}
                                    sx={{ width: 200, '& .MuiSvgIcon-root': { width: 18, height: 18 } }}
                                />
                            </Stack>
                        </Stack>
                        <Stack
                            sx={{
                                maxHeight: 220,
                                overflowY: 'auto',
                                '::-webkit-scrollbar': { width: '2px' },
                            }}
                            pb={2}
                        >
                            {isLoading ? (
                                <Stack
                                    width={'100%'}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    my={10}
                                >
                                    <Grow in={true} timeout={Math.min(500, 1000)}>
                                        <CircularProgress />
                                    </Grow>
                                </Stack>
                            ) : (
                                <>
                                    <BaseList
                                        list={livePhotosItems}
                                        renderItem={(photo) => (
                                            <Box
                                                key={photo.Id}
                                                bgcolor={color.white}
                                                p={1}
                                                border={1}
                                                borderColor={color.grey200}
                                                borderRadius={1}
                                                position={'relative'}
                                            >
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        value={selectedItemsLivePhotos?.Id || ''}
                                                        onChange={handleRadioChangeItemsLivePhotos}
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <FormControlLabel
                                                                    sx={{ ml: '-6px !important' }}
                                                                    control={<Radio value={photo.Id ?? 0} />}
                                                                    label={photo.Id ?? 0}
                                                                    disabled={!selectedColumnEvidencePhoto}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                                <PhotoDetails
                                                    key={photo.Id}
                                                    item={photo}
                                                    props={props}
                                                    setSelectImagePreview={setSelectImagePreview}
                                                    setOpenImagePreview={setOpenImagePreview}
                                                />
                                            </Box>
                                        )}
                                        count={paging.total}
                                        page={filter.page}
                                        pageSize={filter.pageSize}
                                        onChangePage={(page, pageSize) => {
                                            setFilter((prev) => ({ ...prev, page, pageSize }));
                                            setIsCheckedDisableButtonClearOrResetItems(ModeButtonDisabled.initial);
                                        }}
                                        renderEmpty={() => (
                                            <Stack
                                                width="100%"
                                                minHeight={200}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography variant="h2" fontWeight={400} color="primary.dark">
                                                    {'No results found'}
                                                </Typography>
                                                <Typography variant="h6" fontWeight={400} align="center">
                                                    <>
                                                        We couldn't find what searched for. <br />
                                                        Try searching again.
                                                    </>
                                                </Typography>
                                            </Stack>
                                        )}
                                    />
                                </>
                            )}
                        </Stack>
                    </Stack>
                    {selectImagePreview && (
                        <Dialog
                            open={!!openImagePreview}
                            onClose={() => {
                                setOpenImagePreview(false);
                                setSelectImagePreview('');
                            }}
                            PaperProps={{
                                sx: {
                                    minWidth: 1280,
                                    minHeight: 720,
                                },
                            }}
                        >
                            <TransformWrapper
                                alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                                doubleClick={{
                                    disabled: true,
                                }}
                                wheel={{
                                    disabled: false,
                                }}
                                centerZoomedOut={true}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
                                    return (
                                        <>
                                            <Controls />
                                            <TransformComponent>
                                                <Stack
                                                    sx={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: 1280,
                                                            height: 720,
                                                            background: `url(${selectImagePreview}) no-repeat center center`,
                                                            backgroundSize: 'cover',
                                                            borderRadius: 1,
                                                        }}
                                                    />
                                                </Stack>
                                            </TransformComponent>
                                        </>
                                    );
                                }}
                            </TransformWrapper>
                        </Dialog>
                    )}
                </Stack>
            }
            customActions={
                <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                    <Button sx={{ minWidth: 130 }} variant="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={isCheckDisabledButtonConfirm}
                        onClick={() => {
                            handleChangeEvidencePhoto();
                            handleCancel();
                        }}
                    >
                        Confirm
                    </Button>
                </Stack>
            }
        />
    );
}

const PhotoDetails = ({
    setSelectImagePreview,
    setOpenImagePreview,
    handleRemoveItem,
    item,
    props,
    columnId,
    isCheckIcon,
    isLoading,
}: {
    item: CameraPhotoVehicles | undefined;
    setSelectImagePreview: (url: string) => void;
    setOpenImagePreview: (open: boolean) => void;
    handleRemoveItem?: (columnId: number, itemId: number) => void;
    props: Props;
    columnId?: number;
    isCheckIcon?: boolean;
    isLoading?: boolean;
}) => {
    return (
        <Box position={'relative'}>
            <Box
                sx={{
                    width: '100%',
                    height: '120px',
                    background: `url(${appConfig.gateway.blobURL}${item?.CameraPhoto?.BlobName}) no-repeat center center`,
                    backgroundSize: 'cover',
                    borderRadius: 1,
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setSelectImagePreview(`${appConfig.gateway.blobURL}${item?.CameraPhoto?.BlobName}`);
                    setOpenImagePreview(true);
                }}
            />
            <Stack spacing={0.5} mt={1}>
                <Typography variant="body2">Plate: {item?.Plate || 'Unable to recognize license plate'}</Typography>
                <Typography variant="body2">
                    On date:{' '}
                    {moment(item?.CameraPhoto?.CaptureAt || 0)
                        .tz(props.pc.Timezone || moment.tz.guess())
                        .format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
                <Typography variant="body2">
                    Capture type: {item?.CameraPhoto?.CaptureType === CaptureType.trigged ? 'Triggered' : 'Command'}
                </Typography>
                <Typography variant="body2">
                    Within operational hours: {item?.CameraPhoto?.IsWithinOperationalHours}
                </Typography>
            </Stack>
            {isCheckIcon && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: -3,
                        right: -3,
                    }}
                    disabled={isLoading}
                    onClick={() => handleRemoveItem?.(columnId ?? 0, item?.Id ?? 0)}
                >
                    <CloseIcon stroke={color.white} cursor={isLoading ? 'default' : 'pointer'} />
                </IconButton>
            )}
        </Box>
    );
};
