import {
    ICancelGroupPotentialContraventionDetail,
    IGetGroupPotentialContraventionsCancelled,
    VRNDetail,
} from '@EcamModel/controllers/IGroupPotentialContraventionHttpController';
import {
    CancellationReasons,
    ContraventionStatus,
    GeoZoneCoordinates,
    OutCome,
    PCNDuplicate,
    PotentialContraventionLookUp,
    PotentialContraventionWithInfo,
} from '@EcamModel/model';
import ICDetails from '@assets/jsx-icon/ICDetails';
import ICNote from '@assets/jsx-icon/ICNote';
import BaseCheckbox from '@components/BaseCheckbox';
import BreadCrumbs, { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import { DrawerContext, DrawerPage } from '@components/drawer/useDrawer';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushError, pushSuccess, pushWarning } from '@components/toast';
import {
    auditVerificationHTTPController,
    pcController,
    potentialGroupContraventionController,
} from '@controllers/index';
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { PiCubeFocusThin } from 'react-icons/pi';

import {
    IAuditAndApprovePotentialContravention,
    IAuditAndSkipPotentialContravention,
} from '@EcamModel/controllers/IVerificationAuditHttpController';
import ApproveIcon from '@assets/ApproveIcon';
import CancelIcon from '@assets/CancelIcon';
import AuditorIcon from '@assets/jsx-icon/AuditorIcon';
import ICPO from '@assets/jsx-icon/ICPO';
import { CellOfRow } from '@components/tables';
import usePopUp, { UsePopUpReturnType } from '@hooks/usePopUp';
import useScrollToTop from '@hooks/useScrollToTop';
import {
    Accordion,
    Box,
    Button,
    Dialog,
    Divider,
    Fade,
    FormControlLabel,
    Stack,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { PreviewImageWithGeoZone } from '@pages/cameras/details/components/HistoriesPhoto';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import color from '@theme/Colors';
import theme from '@theme/Theme';
import { convertSecondsToDate } from '@utils/functions';
import * as _ from 'lodash';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatUKDateTime } from 'src/helpers';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { PCGroup, PCInfo } from '..';
import { PotentialContraventionContext } from '../hooks/PotentialContraventionProvider';
import useModal from '../hooks/useModal';
import DetailInfoSection, { DetailInfoItem } from './DetailInfoSection';
import DuplicatePCDetail from './DuplicatePCDetail';
import EvidencePhotoGroup from './EvidencePhotoGroup';
import LastItemNotification from './LastItemNotification';
import ModalCancellationReasons, { FormCancelReasonValues } from './ModalCancellationReasons';
import ModalChangePlate from './ModalChangePlate';
import ModalWarningDuplicatePCNs from './ModelWarningDuplicate';
import NoteOPs from './NoteOPs';
import NoteVerifier from './NoteVerifier';
import PermitDuplicate from './PermitDuplicate';
import PopUpReOpen from './PopUpReOpen';
import PopUpSplitAndComplete from './PopUpSplitAndComplete';
import PopupChangeEvidence from './PopupChangeEvidence';

type PCDetailProps = {
    handleApprove?: (pc: PCInfo) => void;
    handleCancelPc?: (props: ICancelGroupPotentialContraventionDetail) => void;
    handleSkip?: () => void;
    handleChangePlate?(vehicle: VRNDetail, isForeign?: boolean, isLookup?: boolean): Promise<void>;
    handleChangeForeign?(isForeignPlate: boolean): any;
    handleLookupPlate?(pc: PotentialContraventionLookUp, isLookup?: boolean): any;
    groupPc: PCGroup;
    selectedPc: PCInfo;
    isLast?: boolean;
    isHaveSkippedItem?: boolean;
    cancellationReasons: CancellationReasons[];
    handleSetSkippedToList?: () => void;
    onSelectedPc?(pc: PCInfo): any;
    updatingPlate?: boolean;
    readonly?: boolean;
};
export enum Mode {
    create,
    edit,
}
export default function PCDetail(props: PCDetailProps) {
    const isApproved = props.groupPc.OutCome === OutCome.Approved;
    const isCancelled = props.groupPc.OutCome === OutCome.Cancelled;
    const { groupPcId } = useParams();
    const [commentReason, setCommentReason] = useState<string>('');

    const popupChangeEvidence = usePopUp();
    const location = useLocation();
    const { pathname } = location;

    useScrollToTop();

    const { cancellationReasons } = props;
    const pc = props.groupPc.PotentialContraventions![0] as PCInfo;
    const step = pc.Step;
    const geoZones = props.groupPc.Zone?.Cameras?.[0]?.GeoZones?.sort((a, b) => a.Id! - b.Id!);
    const cameraPhotos = props.groupPc.PotentialContraventions?.[0].Camera?.CameraPhotos?.[0];
    const { setActiveItem, open } = useContext(DrawerContext);

    const { image, loading, isError } = useImageDimensions(`${cameraPhotos?.BlobName}`);
    const scale = 1280 / (image?.width ?? 1920);

    const prevGeoZonePoints: GeoZoneCoordinates[][] = geoZones?.map((gz) => {
        return gz.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(gz.GeoZoneCoordinates[0]);
    }) as GeoZoneCoordinates[][];

    const prevGeoZones = prevGeoZonePoints?.map((polygon) =>
        polygon.map((point) => ({
            x: point.XPoint * scale,
            y: point.YPoint * scale,
            geoZoneId: point.GeoZoneId,
        }))
    );

    const [loadingReOpen, setLoadingReOpen] = useState(false);

    const pcContext = useContext(PotentialContraventionContext);

    const {
        popupChangePlace,
        popupWarningDuplicate,
        setIsLoadingLookUp,
        isLoadingLookUp,
        handleClose,
        throttleCancelDuplicate,
        selectedPc,
        conditionExemptionsEnabled,
        groupPc,
        conditionAudit,

        contraventionReason,
        toDate,
        fromDate,
        zonesIds,
        emailVerifier,
        setIsLatestItem,
        searchParams,
    } = pcContext;

    const conditionContraventionType = selectedPc?.ContraventionType === 'Registered users only.';

    const grPCDuplicates = _.uniqBy(
        (props.groupPc.PotentialContraventions as PotentialContraventionWithInfo[])
            .map((item) => {
                return item.PCNDuplicate || [];
            })
            .flat()
            .sort((a, b) => {
                if (a.step && b.step) return b.step - a.step;
                return 0;
            }),
        'id' as keyof PCNDuplicate
    );

    const PCNDuplicatesWarning =
        grPCDuplicates.filter((pc) => {
            const duplicateByStella = pc.source === 'Stella';
            const duplicateByECam =
                pc.source === 'ECam' && pc.step === 2 && pc.status !== ContraventionStatus.Cancelled;

            return duplicateByStella || duplicateByECam;
        }) || [];

    const PCNDuplicatesAdditionalInformation =
        grPCDuplicates.filter((pc) => {
            const duplicateByECam = pc.source === 'ECam' && pc.status === ContraventionStatus.Cancelled;
            return duplicateByECam;
        }) || [];

    const disableButtonApprove =
        (selectedPc?.PermitData?.hasPermit && (conditionExemptionsEnabled || conditionContraventionType)) ||
        PCNDuplicatesWarning.length > 0;

    const popupCancel = useModal();
    const popUpReOpen = usePopUp();
    const popUpSplitAndComplete: UsePopUpReturnType = usePopUp();

    const navigate = useNavigate();

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Verification', href: '/verification' },
        { title: pc.Zone?.PublicName || '' },
    ];

    const [openPreviewIMG, setOpenPreviewIMG] = useState(false);

    const [openPreview, setOpenPreview] = useState(false);

    const cancellationReasonAutocomp = useStyledAutocomplete({
        list: {
            options: cancellationReasons,
            isFiltered(option, searchText) {
                return option.Reason.toLowerCase().includes(searchText.toLocaleLowerCase());
            },
        },
        dependencyList: [cancellationReasons],
    });

    const handleCancelVerification = (cancelReason: FormCancelReasonValues) => {
        const _filterCancelVerification: ICancelGroupPotentialContraventionDetail = {
            Id: props.groupPc.Id!,
            CancellationReasonId: cancelReason.cancellationReason.Id!,
            CancellationNotes: cancelReason.comment,
            GroupPcCancellationRelation: cancelReason.data
                ? {
                      Plate: cancelReason.data?.licensePlate!,
                      Source: cancelReason.data?.source! ?? 'ECam',
                      GroupPotentialContraventionId: groupPc?.Id!,
                      ReferenceId: cancelReason.data.source !== undefined ? pc?.Id! : undefined,
                  }
                : undefined,
        };
        props.handleCancelPc?.(_filterCancelVerification);
    };

    const handleLookUp = async (_pc: PCInfo) => {
        setIsLoadingLookUp(true);
        await pcController
            .lookUp(_pc.Plate)
            .then((res) => {
                const isChanged =
                    _pc.VehicleColor !== res.Color ||
                    _pc.VehicleMake !== res.Make ||
                    _pc.VehicleModel !== res.Model ||
                    _pc.VehicleFuelType !== res.FuelType;

                if (isChanged) {
                    const _vehicle: PotentialContraventionLookUp = {
                        ...res,
                    };
                    props.handleLookupPlate?.(_vehicle, true);
                } else {
                    pushSuccess('Filled successfully');
                }
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoadingLookUp(false);
            });
    };

    const handleReOpen = () => {
        setLoadingReOpen(true);
        potentialGroupContraventionController
            .doReOpenGroupPotentialContravention({ Id: props.groupPc?.Id! })
            .then((res) => {
                popUpReOpen.onClose();
                pushSuccess('Re-open PCN successfully');
            })
            .finally(() => {
                setLoadingReOpen(false);
                navigate(-1);
            });
    };

    useEffect(() => {
        setActiveItem(DrawerPage.Verification);
    }, []);

    const inprogressPCs =
        props.groupPc.PotentialContraventions?.filter((pc) => pc.OutCome === OutCome.InProgress) || [];
    const approvedPCs = props.groupPc.PotentialContraventions?.filter((pc) => pc.OutCome === OutCome.Approved) || [];
    const canceledPCs = props.groupPc.PotentialContraventions?.filter((pc) => pc.OutCome === OutCome.Cancelled) || [];

    const inprogressPcCount = inprogressPCs.length;
    const approvePcCount = approvedPCs.length;
    const cancelPcCount = canceledPCs.length;

    const [selectedTab, setSelectedTab] = useState(1);

    const tabs = [
        {
            id: 1,
            title: `In progress (${inprogressPcCount})`,
            icon: <ApproveIcon />,
            isShow: inprogressPcCount > 0,
        },
        {
            id: 2,
            title: `Approve (${approvePcCount})`,
            icon: <ApproveIcon />,
            isShow: approvePcCount > 0,
        },
        {
            id: 3,
            title: `Cancel (${cancelPcCount})`,
            icon: <CancelIcon />,
            isShow: cancelPcCount > 0,
        },
    ];

    useEffect(() => {
        if (!!inprogressPcCount) setSelectedTab(1);
        else if (!!approvePcCount) setSelectedTab(2);
        else setSelectedTab(3);
    }, [approvePcCount, inprogressPcCount]);

    const handleCancelDuplicateVerification = () => {
        throttleCancelDuplicate?.({
            Id: props.groupPc.Id!,
        });
    };
    const handleCancel = () => {
        handleCancelDuplicateVerification();
        handleClose();
    };

    const [idAudits, setIdAudits] = useState<number[]>([]);

    const getIdsAudit = async () => {
        const _filter: IGetGroupPotentialContraventionsCancelled = {
            CancellationReason: Number(contraventionReason),
            CreatedFromDate: moment(fromDate).startOf('day').toDate(),
            CreatedToDate: moment(toDate).endOf('day').toDate(),
            VerifierEmail: emailVerifier ?? undefined,
            filter: {
                ZoneId: zonesIds,
            },
        };
        await potentialGroupContraventionController
            .getListCancelledNotAuditIds(_filter)
            .then((res) => {
                setIdAudits(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            });
    };

    useEffect(() => {
        if (conditionAudit && !props?.groupPc?.VerificationAudit) {
            getIdsAudit();
        }
    }, [conditionAudit]);

    const handleNextAuditItems = () => {
        const currentIdIndex = idAudits.findIndex((id) => id === Number(groupPcId));
        const nextIndex = currentIdIndex + 1;

        if (nextIndex < idAudits.length) {
            const nextId = idAudits[nextIndex];
            navigate(`/overview-verification/${nextId}/audit?${searchParams.toString()}`, { replace: true });
        } else {
            setIsLatestItem(true);
            pushWarning('This item is the last item in the list');
            backdrop.setTrue();
            setTimeout(() => {
                backdrop.setFalse();
                navigate(`/verification`);
            }, 2000);
            return;
        }
    };

    const conditionPathnameNotAudit = isCancelled && !conditionAudit;
    const conditionPathnameVerification = pathname.includes(`/verification/contravention/${props.groupPc.Id}`);

    const backdrop = useBackdrop();

    const handleAuditAndSkipPcPage = async () => {
        backdrop.setTrue();
        await auditVerificationHTTPController
            .auditAndSkip({ GroupPcId: props.groupPc?.Id! })
            .then((res) => {
                pushSuccess('Audit and skip successfully');
                handleNextAuditItems();
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleAuditAndApprovePcPage = async () => {
        backdrop.setTrue();
        await auditVerificationHTTPController
            .auditAndApprove({ GroupPcId: props.groupPc?.Id!, PotentialContraventionId: pc.Id! })
            .then((res) => {
                pushSuccess('Audit and approve successfully');
                handleNextAuditItems();
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    useEffect(() => {
        if (pathname === `/verification/contravention/${props.groupPc.Id}/audit`) {
            navigate(-1);
        }
    }, [pathname]);

    return (
        <Stack direction="column">
            {!props.isLast && (
                <Stack
                    sx={
                        conditionPathnameNotAudit || props?.groupPc?.VerificationAudit
                            ? {}
                            : {
                                  maxHeight: 700,
                                  overflowY: 'auto',
                                  overflowX: 'auto',
                                  '::-webkit-scrollbar': {
                                      width: '2px',
                                  },
                                  '::-webkit-scrollbar-track': {
                                      background: 'transparent',
                                  },
                              }
                    }
                >
                    <Box
                        sx={{
                            position: conditionPathnameNotAudit ? 'fixed' : 'sticky',
                            top: conditionPathnameNotAudit ? 5 : 0,
                            paddingTop: conditionPathnameNotAudit ? '100px' : 0,
                            zIndex: 1,
                            width: open ? '1086px' : '1126px',
                        }}
                        bgcolor={color.white}
                        pb={1}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" justifyContent={'start'} gap={2} ml={1}>
                                <Typography variant="h3">{pc.Zone?.PublicName}</Typography>

                                {!!inprogressPcCount && (
                                    <Box
                                        sx={{
                                            padding: '5px 16px',
                                            background: '#E8F5E9',
                                            color: '#2E7D32',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {`Step ${step} - ${
                                            props?.groupPc?.VerificationAudit ? 'In progress & Audited' : 'In progress'
                                        }`}
                                    </Box>
                                )}

                                {isApproved && (
                                    <Box
                                        sx={{
                                            padding: '5px 16px',
                                            background: '#E8F5E9',
                                            color: '#2E7D32',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {`Step ${step} - ${
                                            props?.groupPc?.VerificationAudit ? 'Approved & Audited' : 'Approved'
                                        }`}
                                    </Box>
                                )}

                                {isCancelled && (
                                    <Box
                                        sx={{
                                            padding: '5px 16px',
                                            background: '#FFF1F0',
                                            color: '#E01B00',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {`Step ${step} - ${
                                            props?.groupPc?.VerificationAudit ? ' Cancelled & Audited' : 'Cancelled'
                                        }`}
                                    </Box>
                                )}
                            </Stack>
                            {conditionPathnameNotAudit &&
                                !props?.groupPc?.VerificationAudit &&
                                !conditionPathnameVerification && (
                                    <Button
                                        variant="contained"
                                        sx={{ minWidth: 130, position: 'absolute', zIndex: 10, right: 0 }}
                                        onClick={() => {
                                            navigate(`audit?${searchParams.toString()}`);
                                        }}
                                    >
                                        Audit
                                    </Button>
                                )}
                        </Stack>
                        <Stack mt={1} ml={1}>
                            <BreadCrumbs breadcrumbs={breadcrumbs} />
                        </Stack>
                    </Box>
                    {props?.groupPc?.VerificationAudit && (
                        <Stack
                            borderRadius={'4px'}
                            mt={conditionPathnameNotAudit ? 10 : 2}
                            sx={{
                                px: 2,
                                py: 1,
                                background: '#E8F5E9 !important',
                            }}
                            borderLeft={`3px solid ${color.success} `}
                        >
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <AuditorIcon />
                                <Typography variant="body1" color="initial">
                                    This case has been audited
                                </Typography>
                            </Stack>
                            <Stack mt={'4px'} direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography lineHeight={1}>
                                    Auditor:
                                    <Typography pl={'5px'} variant="caption">
                                        {props.groupPc.VerificationAudit.AuditorEmail.split('@')[0] || ''}
                                    </Typography>
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                        borderRightWidth: 1,
                                        borderColor: color.grey600,
                                    }}
                                />
                                <Typography>
                                    Date audited:
                                    <Typography pl={'5px'} variant="caption">
                                        {formatUKDateTime(props?.groupPc?.VerificationAudit?.Created)}
                                    </Typography>
                                </Typography>
                            </Stack>
                        </Stack>
                    )}

                    {pc.Camera?.Notes && (
                        <Fade in={!!pc.Camera?.Notes}>
                            <Stack
                                sx={{
                                    px: 2,
                                    py: 1,
                                    mt: conditionPathnameNotAudit && !props?.groupPc?.VerificationAudit ? 10 : 2,
                                    background: '#E8F5E9',
                                    borderRadius: 1,
                                    wordBreak: 'break-all',
                                }}
                            >
                                {pc.Camera?.Notes}
                            </Stack>
                        </Fade>
                    )}

                    {(props.groupPc?.PotentialContraventions?.[0]?.CancellationReasonId ||
                        PCNDuplicatesWarning?.length > 0 ||
                        PCNDuplicatesAdditionalInformation.length > 0) && (
                        <Stack
                            borderRadius={'4px'}
                            mt={
                                !props.isLast &&
                                !pc.Camera?.Notes &&
                                !props?.groupPc?.VerificationAudit &&
                                conditionPathnameNotAudit
                                    ? 10
                                    : 2
                            }
                            sx={{
                                p: 2,
                                background: '#FFF1F9',
                            }}
                        >
                            <DuplicatePCDetail
                                cancellationNotes={props.groupPc.PotentialContraventions?.[0].CancellationNotes}
                                reason={props.groupPc.PotentialContraventions?.[0].Cancellation?.Reason}
                                pcnDuplicatesAdditionalInformation={PCNDuplicatesAdditionalInformation}
                                pcnDuplicatesWarning={PCNDuplicatesWarning}
                                grId={props.groupPc.Id}
                                pcId={pc.Zone?.Id}
                            />
                        </Stack>
                    )}

                    {selectedPc?.PermitData && <PermitDuplicate permitData={selectedPc?.PermitData} />}

                    <Box>
                        <Stack direction={'row'} gap={3} width={'100%'} mt={1}>
                            <DetailInfoSection
                                icon={<ICDetails />}
                                title="Details"
                                action={
                                    !conditionPathnameNotAudit && (
                                        <Button
                                            variant="orangeContained"
                                            sx={{ px: 1, py: 0.5, position: 'absolute', right: 10, fontSize: 12 }}
                                            onClick={() => {
                                                handleLookUp(pc);
                                            }}
                                            disabled={isLoadingLookUp}
                                        >
                                            {isLoadingLookUp ? 'Looking up...' : 'Look up'}
                                        </Button>
                                    )
                                }
                                content={
                                    <>
                                        <DetailInfoItem leftContent="Vehicle Info Source" rightContent={pc.Plate} />
                                        <DetailInfoItem leftContent="Make" rightContent={pc.VehicleMake} />
                                        <DetailInfoItem leftContent="Model" rightContent={pc.VehicleModel} />
                                        <DetailInfoItem leftContent="Color" rightContent={pc.VehicleColor} />
                                    </>
                                }
                            />
                            <DetailInfoSection
                                icon={<ICNote />}
                                title="Contravention"
                                content={
                                    <>
                                        <DetailInfoItem leftContent="Location" rightContent={pc.Zone?.Location?.Name} />
                                        <DetailInfoItem leftContent="Zone" rightContent={pc.Zone?.PublicName} />
                                        <DetailInfoItem
                                            leftContent="Contravention type"
                                            rightContent={pc.ContraventionType}
                                        />
                                    </>
                                }
                            />
                        </Stack>

                        <Stack my={2}>
                            <NoteOPs />
                        </Stack>

                        <Stack mt={4}>
                            <Typography variant="h5">
                                {props.readonly
                                    ? 'All the evidence photos'
                                    : 'Please select from the evidence photos below:'}
                            </Typography>
                        </Stack>

                        {props.readonly && (
                            <Stack direction="row" gap={1} mt={3}>
                                {tabs
                                    .filter((t) => t.isShow)
                                    .map((item, index) => {
                                        return (
                                            <Stack
                                                key={index + 'z'}
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    width: 200,
                                                    p: '10px',
                                                    background: selectedTab === item.id ? '#E8F5E9' : '#FAFAFA',
                                                    borderRadius: '8px 8px 0px 0px',
                                                    color:
                                                        selectedTab === item.id
                                                            ? theme.palette.success.main
                                                            : color.grey600,
                                                    cursor: 'pointer',
                                                    transition: '.2s',
                                                }}
                                                gap={1}
                                                onClick={() => {
                                                    setSelectedTab(item.id);
                                                }}
                                            >
                                                {item.icon}
                                                <Typography
                                                    variant="body1"
                                                    color={
                                                        selectedTab === item.id
                                                            ? theme.palette.success.main
                                                            : color.grey600
                                                    }
                                                    sx={{ userSelect: 'none', lineHeight: 1 }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </Stack>
                                        );
                                    })}
                            </Stack>
                        )}

                        {props.groupPc.IsPOPlate && (
                            <OrangeStack mt={2} direction={'column'} gap={1}>
                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                    <ICPO />
                                    <Typography variant="h4">This is a Parking Operative’s vehicle</Typography>
                                </Stack>
                            </OrangeStack>
                        )}

                        <Stack
                            flexBasis={1}
                            spacing={3}
                            sx={!props.readonly ? { mt: 2 } : { border: '1px solid #ddd', padding: 2 }}
                        >
                            {(selectedTab === 1 ? inprogressPCs : selectedTab === 2 ? approvedPCs : canceledPCs).map(
                                (pc, index) => {
                                    return (
                                        <EvidencePhotoGroup
                                            key={pc.Id}
                                            pc={pc as PCInfo}
                                            cancellationReasons={cancellationReasons}
                                            selected={pc.Id === props.selectedPc?.Id}
                                            onSelect={props.onSelectedPc}
                                            readonly={props.readonly}
                                            VerificationAudit={props?.groupPc?.VerificationAudit}
                                            handleCancelPc={props.handleCancelPc}
                                        />
                                    );
                                }
                            )}

                            <Dialog
                                open={openPreviewIMG}
                                onClose={() => {
                                    setOpenPreviewIMG(false);
                                }}
                                PaperProps={{
                                    sx: {
                                        minWidth: 1280,
                                        minHeight: 720,
                                    },
                                }}
                            >
                                <div>
                                    <img style={{ width: 1280, height: 1280 * 0.5625 }} src={''} alt="Zoomable" />
                                </div>
                            </Dialog>
                        </Stack>

                        <Stack mt={6}>
                            <DetailInfoSection
                                icon={<PiCubeFocusThin fontSize={28} />}
                                title="Geo-zones"
                                action={
                                    <Tooltip arrow title="View geo-zone" placement="top">
                                        <Button
                                            variant="contained"
                                            sx={{ minWidth: 25, height: 25, py: 1, px: 0.5 }}
                                            onClick={() => {
                                                setOpenPreview(true);
                                            }}
                                        >
                                            <BiSearchAlt />
                                        </Button>
                                    </Tooltip>
                                }
                                content={
                                    <>
                                        {!geoZones?.length ? (
                                            <NoDataCameras />
                                        ) : (
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <CellOfRow
                                                            value={
                                                                <Typography fontSize={16} fontWeight={500}>
                                                                    Geo-zone
                                                                </Typography>
                                                            }
                                                            align="center"
                                                        />
                                                        <CellOfRow
                                                            value={
                                                                <Typography fontSize={16} fontWeight={500}>
                                                                    Operation period
                                                                </Typography>
                                                            }
                                                            align="left"
                                                        />
                                                        <CellOfRow
                                                            value={
                                                                <Typography fontSize={16} fontWeight={500}>
                                                                    Contravention type
                                                                </Typography>
                                                            }
                                                            align="center"
                                                        />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {geoZones?.map((item, index) => {
                                                        const operationHoursConfigs = item.GZOConfigs?.[0];

                                                        const operationHours =
                                                            operationHoursConfigs?.GZOConfigGracePeriods.flatMap(
                                                                (item) => item.GZOConfigDays.map((item) => item)
                                                            );

                                                        const _operationHours = groupBy(operationHours, 'Weekday');

                                                        return (
                                                            <TableRow
                                                                key={`${index}+1`}
                                                                sx={{
                                                                    verticalAlign:
                                                                        !!operationHoursConfigs?.EffectiveFrom
                                                                            ? 'top'
                                                                            : 'middle',
                                                                }}
                                                            >
                                                                <CellOfRow value={index + 1} align="center" />
                                                                <CellOfRow
                                                                    align="left"
                                                                    value={
                                                                        <Stack>
                                                                            {!!operationHoursConfigs?.EffectiveFrom ? (
                                                                                <Typography color="#256428">
                                                                                    Effective from:{' '}
                                                                                    {moment(
                                                                                        operationHoursConfigs?.EffectiveFrom
                                                                                    ).format('DD/MM/YYYY')}
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography
                                                                                    fontStyle="italic"
                                                                                    color={theme.palette.grey[600]}
                                                                                >
                                                                                    No current operation hours
                                                                                </Typography>
                                                                            )}
                                                                            {Object.entries(_operationHours).map(
                                                                                ([key, value], index) => {
                                                                                    return (
                                                                                        <Stack
                                                                                            key={index}
                                                                                            direction="row"
                                                                                            alignItems="flex-start"
                                                                                            spacing={1}
                                                                                            mb={1}
                                                                                        >
                                                                                            <Typography minWidth={80}>
                                                                                                {moment()
                                                                                                    .isoWeekday(
                                                                                                        parseInt(key)
                                                                                                    )
                                                                                                    .format('dddd')}
                                                                                            </Typography>
                                                                                            <Stack>
                                                                                                {value.map((o) => {
                                                                                                    const geoZoneConfig =
                                                                                                        operationHoursConfigs?.GZOConfigGracePeriods.find(
                                                                                                            (p) =>
                                                                                                                p.Id ===
                                                                                                                o.GeoZoneOperationConfigGracePeriodId
                                                                                                        );
                                                                                                    return (
                                                                                                        <Stack
                                                                                                            direction="row"
                                                                                                            alignItems="center"
                                                                                                            spacing={
                                                                                                                0.5
                                                                                                            }
                                                                                                            pr={2}
                                                                                                        >
                                                                                                            <Box
                                                                                                                sx={{
                                                                                                                    p: 0.4,
                                                                                                                    background:
                                                                                                                        '#256428',
                                                                                                                    borderRadius: 1,
                                                                                                                }}
                                                                                                            />
                                                                                                            <Typography color="GrayText">
                                                                                                                {moment(
                                                                                                                    convertSecondsToDate(
                                                                                                                        o.From!
                                                                                                                    )
                                                                                                                ).format(
                                                                                                                    'HH:mm'
                                                                                                                )}
                                                                                                                -
                                                                                                                {moment(
                                                                                                                    convertSecondsToDate(
                                                                                                                        o.To!
                                                                                                                    )
                                                                                                                ).format(
                                                                                                                    'HH:mm'
                                                                                                                )}{' '}
                                                                                                                | Grace
                                                                                                                period:{' '}
                                                                                                                {
                                                                                                                    geoZoneConfig?.GracePeriod
                                                                                                                }
                                                                                                                (s)
                                                                                                            </Typography>
                                                                                                        </Stack>
                                                                                                    );
                                                                                                })}
                                                                                            </Stack>
                                                                                        </Stack>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Stack>
                                                                    }
                                                                />
                                                                <CellOfRow
                                                                    value={
                                                                        item.ContraventionReason
                                                                            ?.ContraventionReasonTranslations?.[0]
                                                                            ?.Summary!
                                                                    }
                                                                    align="center"
                                                                />
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </>
                                }
                            />
                        </Stack>

                        <PreviewImageWithGeoZone
                            open={openPreview}
                            onClose={() => setOpenPreview(false)}
                            imgSrc={image}
                            scaleX={scale}
                            scaleY={scale}
                            geoZones={prevGeoZones}
                        />

                        {/* {selectedPc?.PermitData && <PermitDuplicate permitData={selectedPc?.PermitData} />}

                        {props.selectedPc.IsPOPlate && (
                        <DuplicatePCDetail PCNDuplicate={props.selectedPc.PCNDuplicate!} />
                        )} */}

                        <Stack mt={2}>
                            <NoteVerifier />
                        </Stack>
                    </Box>
                </Stack>
            )}

            {pathname.includes('audit') && !props?.groupPc?.VerificationAudit && !props.isLast && (
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <LoadingButton
                            sx={{
                                minWidth: '150px',
                                justifyContent: props.updatingPlate ? 'space-between' : 'center',
                            }}
                            color="secondary"
                            onClick={() => {
                                popupChangePlace.handleOpen();
                            }}
                            loading={props.updatingPlate}
                            loadingPosition="start"
                            startIcon={props.updatingPlate ? <></> : undefined}
                            variant="orangeContained"
                        >
                            <span>Change plate</span>
                        </LoadingButton>
                        <Button
                            onClick={() => {
                                handleLookUp(pc);
                            }}
                            sx={{
                                minWidth: '150px',
                                backgroundColor: color.darkSecondary,
                                color: color.white,
                                '&:hover': {
                                    backgroundColor: '#17A2B8',
                                },
                            }}
                            disabled={isLoadingLookUp}
                        >
                            {isLoadingLookUp ? 'Looking up...' : 'Look up'}
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: color.colorANPR,
                                color: color.white,
                                minWidth: '150px',
                                '&:hover': {
                                    backgroundColor: '#03AED2',
                                },
                            }}
                            onClick={() => popupChangeEvidence.setTrue()}
                        >
                            Change evidence
                        </Button>
                    </Stack>
                    <PopUpSplitAndComplete
                        {...popUpSplitAndComplete}
                        popupCancel={popupCancel}
                        cancelPc={{
                            CancellationNotes: commentReason ?? undefined,
                            CancellationReasonId: 2,
                            Id: props.groupPc.Id!,
                        }}
                        pc={pc}
                    />
                    <PopupChangeEvidence {...popupChangeEvidence} pc={pc} />
                    <AuditApproveButtons
                        handleAuditAndSkip={handleAuditAndSkipPcPage}
                        grId={props.groupPc?.Id!}
                        pcId={pc.Id!}
                        handleAuditAndApprove={handleAuditAndApprovePcPage}
                        selectedPc={selectedPc!}
                        idAudits={idAudits}
                    />
                    <ModalChangePlate
                        updatingPlate={props.updatingPlate}
                        pathname={pathname}
                        selectedPc={props.selectedPc}
                        {...popupChangePlace}
                        popupChangePlace={popupChangePlace}
                        handleCancelDuplicateVerification={handleCancelDuplicateVerification}
                    />
                    <ModalWarningDuplicatePCNs
                        popupChangePlace={popupWarningDuplicate}
                        {...popupWarningDuplicate}
                        handleCancel={handleCancel}
                    />
                </Stack>
            )}

            {!props.readonly && !isApproved && !isCancelled && !props.isLast && (
                <Stack direction={'row'} justifyContent="space-between" mt={4}>
                    <Stack gap={2} direction="row">
                        <Button
                            variant="errorContained"
                            sx={{ minWidth: '150px' }}
                            onClick={() => popupCancel.handleOpen()}
                        >
                            Cancel
                        </Button>

                        <LoadingButton
                            sx={{
                                minWidth: '150px',
                                justifyContent: props.updatingPlate ? 'space-between' : 'center',
                            }}
                            color="secondary"
                            onClick={() => {
                                popupChangePlace.handleOpen();
                            }}
                            loading={props.updatingPlate}
                            loadingPosition="start"
                            startIcon={props.updatingPlate ? <></> : undefined}
                            variant="orangeContained"
                        >
                            <span>Change plate</span>
                        </LoadingButton>

                        <Button
                            onClick={() => {
                                handleLookUp(pc);
                            }}
                            sx={{
                                minWidth: '150px',
                                backgroundColor: color.darkSecondary,
                                color: color.white,
                                '&:hover': {
                                    backgroundColor: '#17A2B8',
                                },
                            }}
                            disabled={isLoadingLookUp}
                        >
                            {isLoadingLookUp ? 'Looking up...' : 'Look up'}
                        </Button>

                        <Button
                            sx={{
                                backgroundColor: color.colorANPR,
                                color: color.white,
                                minWidth: '150px',
                                '&:hover': {
                                    backgroundColor: '#03AED2',
                                },
                            }}
                            onClick={() => popupChangeEvidence.setTrue()}
                        >
                            Change evidence
                        </Button>
                    </Stack>
                    <PopUpSplitAndComplete
                        {...popUpSplitAndComplete}
                        popupCancel={popupCancel}
                        cancelPc={{
                            CancellationNotes: commentReason ?? undefined,
                            CancellationReasonId: 2,
                            Id: props.groupPc.Id!,
                        }}
                        pc={pc}
                    />
                    <PopupChangeEvidence {...popupChangeEvidence} pc={pc} />
                    <ModalCancellationReasons
                        {...popupCancel}
                        cancellationReasonAutocomplete={cancellationReasonAutocomp}
                        handleCancelPC={handleCancelVerification}
                        popUpSplitAndComplete={popUpSplitAndComplete}
                        commentReason={(comment) => {
                            setCommentReason(comment);
                        }}
                        groupPc={groupPc}
                    />

                    <ModalChangePlate
                        updatingPlate={props.updatingPlate}
                        selectedPc={props.selectedPc}
                        pathname={pathname}
                        {...popupChangePlace}
                        popupChangePlace={popupChangePlace}
                        handleCancelDuplicateVerification={handleCancelDuplicateVerification}
                    />
                    <ModalWarningDuplicatePCNs
                        popupChangePlace={popupWarningDuplicate}
                        {...popupWarningDuplicate}
                        handleCancel={handleCancel}
                    />
                    <Stack gap={2} direction="row">
                        <FormControlLabel
                            sx={{ '&.MuiFormControlLabel-root': { margin: 0 } }}
                            checked={!!pc.IsForeign}
                            control={
                                <BaseCheckbox
                                    onChange={(_, checked) => {
                                        props.handleChangeForeign?.(checked);
                                    }}
                                />
                            }
                            label={
                                <Typography ml={0.5} mt={0.3}>
                                    Foreign plate
                                </Typography>
                            }
                        />
                        <Button
                            variant="contained"
                            sx={{ minWidth: '150px' }}
                            disabled={disableButtonApprove}
                            onClick={() => props.handleApprove?.(props.selectedPc)}
                        >
                            Approve
                        </Button>
                        <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={() => props.handleSkip?.()}>
                            Skip
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

export const OrangeStack = styled(Stack)({
    background: color.gold200,
    borderRadius: `4px`,
    borderLeft: `4px solid ${color.gold600}`,
    padding: `12px 16px`,
});

const getOverTimeString = (outDate: Date, inDate: Date) => {
    const range = moment(outDate).diff(moment(inDate));
    const time = moment.duration(range);
    const hours = time.hours();
    const minutes = time.minutes();
    const seconds = time.seconds();

    return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds
        .toString()
        .padStart(2, '0')}s`;
};

export const MyAccordion = styled(Accordion)({
    '&.MuiPaper-root': {
        // border: `1px solid #DDDDDD`,
        boxShadow: `none`,
    },
});

interface ChangePlateButtonProps {
    updatingPlate: boolean;
    handleOpen: () => void;
}

const ChangePlateButton: React.FC<ChangePlateButtonProps> = ({ updatingPlate, handleOpen }) => (
    <LoadingButton
        sx={{ minWidth: '150px', justifyContent: updatingPlate ? 'space-between' : 'center' }}
        color="secondary"
        onClick={handleOpen}
        loading={updatingPlate}
        loadingPosition="start"
        startIcon={updatingPlate ? <></> : undefined}
        variant="orangeContained"
    >
        <span>Change plate</span>
    </LoadingButton>
);

interface AuditApproveButtonsProps {
    handleAuditAndApprove: (props: IAuditAndApprovePotentialContravention) => void;
    handleAuditAndSkip: (props: IAuditAndSkipPotentialContravention) => void;
    grId: number;
    pcId: number;
    selectedPc: PCInfo;
    idAudits: number[];
}

const AuditApproveButtons: React.FC<AuditApproveButtonsProps> = ({
    handleAuditAndApprove,
    handleAuditAndSkip,
    grId,
    pcId,
    selectedPc,
    idAudits,
}) => (
    <Stack direction="row" spacing={2} flexGrow={1} justifyContent="end">
        <Button
            variant="contained"
            sx={{ minWidth: '150px' }}
            disabled={
                selectedPc?.PermitData?.hasPermit ||
                (selectedPc?.PCNDuplicate && selectedPc.PCNDuplicate.length > 0) ||
                !idAudits.length
            }
            onClick={() =>
                handleAuditAndApprove({
                    GroupPcId: grId,
                    PotentialContraventionId: pcId,
                })
            }
        >
            Audit & Approve
        </Button>

        <Button
            variant="cancel"
            sx={{ minWidth: '150px' }}
            disabled={!idAudits.length}
            onClick={() =>
                handleAuditAndSkip({
                    GroupPcId: grId,
                })
            }
        >
            Audit & Skip
        </Button>
    </Stack>
);
