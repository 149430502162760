import React from 'react';

function ApproveIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.707 9.8l1 1 2.667-2.666"
            ></path>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M7.167 4h2.666c1.334 0 1.334-.667 1.334-1.334 0-1.333-.667-1.333-1.334-1.333H7.167c-.667 0-1.334 0-1.334 1.333C5.833 4 6.5 4 7.167 4z"
            ></path>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M11.167 2.68c2.22.12 3.333.94 3.333 3.986v4c0 2.667-.667 4-4 4h-4c-3.333 0-4-1.333-4-4v-4c0-3.04 1.113-3.866 3.333-3.986"
            ></path>
        </svg>
    );
}

export default ApproveIcon;
