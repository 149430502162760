import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { ContraventionReasonTranslations, GeoZoneOperationConfig, GeoZones } from '../../model';
import { IGeoZoneHttpController } from '../IGeoZoneHttpController';

export class GeoZoneHttpController extends BaseHttpController<GeoZones> implements IGeoZoneHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'geoZone', client);
    }

    async getContraventionReasonTranslations(): Promise<ContraventionReasonTranslations[]> {
        return await this.doGet({ path: `contravention-reason-translation` }).then((res) => res.data);
    }
}
