/* eslint-disable react-hooks/exhaustive-deps */
import { ICameraAverageDailyWorkingHour } from '@EcamModel/controllers/ICameraOverviewHttpController';
import StyledDateRangePicker from '@components/date-range-select/StyledDateRangePicker';
import FilterRoot from '@components/filter/FilterRoot';

import { cameraOverviewHttpController } from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import { Box, Button, CircularProgress, Grow, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import color from '@theme/Colors';
import { useMemo, useState } from 'react';
import PopUpAvgWorkedHours from './components/PopUpAvgWorkedHours';
import PopupDailyAverageWorkedHours from './components/PopUpDailyAverageWorkedHours';

import { BaseTextField } from '@components/BaseTextField';
import { BaseItem } from '@pages/cameras/list/styles';
import { FiSearch } from 'react-icons/fi';
import PopUpCustomizable from './components/PopUpCustomizable';

import { useDebounce } from 'usehooks-ts';
import FilterChildCameraOverview from './components/FilterChildCameraOverview';
import ListCameraConfig from './components/ListCameraConfig';
import useCameraConfigProvider, { CameraConfigContext } from './hook/CameraConfigProvider';
import { endOfYesterday, startOfYesterday } from 'src/helpers';

export interface LoadingState {
    location: boolean;
    paging: boolean;
}
export interface CameraOverviewPopup {
    cameraName: string;
    cameraId: number;
}

export default function CameraOverviewScreen() {
    const popUpAvgWorkedHoursOfCamera = usePopUp();
    const popUpDailyAvgWorkedHours = usePopUp();
    const popUpCustomizable = usePopUp();

    const cameraConfigContext = useCameraConfigProvider();

    const {
        filter,
        setFilter,
        loading,
        setLoadingDailyWorkedHours,
        setCameraDailyAverageWorkedHours,

        handleMileSightCamerasCopy,
        filteredServices,
        searchCameraConfig,
        setSearchCameraConfig,
    } = cameraConfigContext;

    const getDailyAverageWorkedHours = async () => {
        setLoadingDailyWorkedHours(true);
        let req: ICameraAverageDailyWorkingHour = {
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        await cameraOverviewHttpController
            .cameraAverageDailyWorkingHours(req)
            .then((res) => {
                setCameraDailyAverageWorkedHours(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    return (
        <CameraConfigContext.Provider value={cameraConfigContext}>
            <Stack direction={'column'}>
                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h3">Camera overview</Typography>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                        <Button
                            sx={{
                                minWidth: 200,
                                ':hover': {
                                    color: color.darkSecondary,
                                    borderColor: color.darkSecondary,
                                },
                            }}
                            variant="outlined"
                            onClick={handleMileSightCamerasCopy}
                        >
                            Copy data
                        </Button>
                        <Button
                            sx={{ minWidth: 200 }}
                            variant="contained"
                            onClick={() => {
                                popUpDailyAvgWorkedHours.setTrue();
                                getDailyAverageWorkedHours();
                            }}
                        >
                            Daily worked hours
                        </Button>
                    </Stack>
                </Stack>
                <Stack mt={2}>
                    <FilterRoot
                        handleResetFilter={() => {
                            setFilter({});
                            setSearchCameraConfig('');
                        }}
                        rightTitleContent={
                            <Grid2 container sx={{ width: 360 }}>
                                <StyledDateRangePicker
                                    value={{
                                        startDate: filter?.from || startOfYesterday,
                                        endDate: filter?.to || endOfYesterday,
                                    }}
                                    onChange={(value) => {
                                        setFilter({
                                            from: value.startDate,
                                            to: value.endDate,
                                            firmware: filter.firmware,
                                            location: filter.location,
                                            zones: filter.zones,
                                            status: filter.status,
                                            camVersion: filter.camVersion,
                                            uploadMode: filter.uploadMode,
                                            serviceType: filter.serviceType,
                                        });
                                    }}
                                    dateReset={{ startDate: startOfYesterday, endDate: endOfYesterday }}
                                    label={'Select date'}
                                />
                            </Grid2>
                        }
                        filterChild={<FilterChildCameraOverview />}
                    />
                </Stack>

                <BaseItem mt={2}>
                    <Stack mb={2} width={'100%'} direction={'row'} justifyContent={'space-between'}>
                        <Stack flex={1} marginRight={2}>
                            <BaseTextField
                                placeholder={'Search camera'}
                                fullWidth={true}
                                value={searchCameraConfig}
                                onChange={(e) => setSearchCameraConfig(e.target.value)}
                                maxLength={50}
                                InputProps={{
                                    endAdornment: (
                                        <FiSearch
                                            color="disabled"
                                            size={16}
                                            style={{ color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }}
                                        />
                                    ),
                                }}
                            />
                        </Stack>
                        <Stack>
                            <Button
                                sx={{
                                    ':hover': {
                                        color: color.darkSecondary,
                                        borderColor: color.darkSecondary,
                                    },
                                }}
                                variant="outlined"
                                onClick={() => popUpCustomizable.setTrue()}
                            >
                                Customise
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack>
                        {loading ? (
                            <Stack direction="row" justifyContent="center" alignItems="center" my={10}>
                                <Grow in={true} timeout={Math.min(500, 1000)}>
                                    <Box height={'100%'}>
                                        <CircularProgress />
                                    </Box>
                                </Grow>
                            </Stack>
                        ) : (
                            <ListCameraConfig
                                {...popUpAvgWorkedHoursOfCamera}
                                filteredServices={filteredServices}
                                popUpAvgWorkedHoursOfCamera={popUpAvgWorkedHoursOfCamera}
                            />
                        )}
                    </Stack>
                </BaseItem>

                <PopUpAvgWorkedHours {...popUpAvgWorkedHoursOfCamera} />
                <PopupDailyAverageWorkedHours {...popUpDailyAvgWorkedHours} />
                <PopUpCustomizable {...popUpCustomizable} />
            </Stack>
        </CameraConfigContext.Provider>
    );
}
