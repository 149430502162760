import { Box, Typography } from '@mui/material';
import color from '@theme/Colors';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{
                marginTop: '0px !important',
            }}
            p={2}
            border={`1px solid ${color.grey200}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}
