import { Paging, ListProps } from '@Core';
import { CameraServiceType, CameraWithInfo, Location, LocationSM, LocationSMStatus } from '@EcamModel/model';
import { Zones, ZoneSM } from '@EcamModel/model/Zones';
import PopUpBase from '@components/PopUpBase';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { locationController } from '@controllers/index';
import { IPopUp } from '@hooks/usePopUp';
import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { search } from '@pages/cameras/list/helpers';
import { LoadingState } from '@pages/overview/VerificationsOverviewScreen';
import { OrangeStack } from '@pages/verification/pc-details/components/PCDetail';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IGetLocationProps } from '@EcamModel/controllers/ILocationHttpController';
import StyledAutocomplete from '@components/styled-autocomplete';
import { IListWithECamServiceActive } from '@EcamModel/controllers/ILocationSMHttpController';
import { ServiceSMType } from '@EcamModel/model/ServiceSM';
import { camServiceTypes } from '@pages/cameras/add-new';

type Props = Omit<IPopUp, 'onConfirm'> & {
    camera: CameraWithInfo;
    onConfirm?(camera: CameraWithInfo): Promise<void>;
    zones: Zones;
    loading?: false | undefined;
    onUpdateCamera?(camera: CameraWithInfo): void;
    setLoading?: Dispatch<SetStateAction<boolean>> | undefined;
};

type FormValues = {
    location: Location;
    zone: Zones;
};

export default function PopUpEditAllocatedZone(props: Props) {
    const { open, onClose } = props;
    const [checkDisabled, setCheckDisabled] = useState({
        location: true,
        zone: true,
    });
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const {
        control,
        setValue,
        reset,
        handleSubmit,
        watch,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            location: props.zones.Location,
            zone: { Id: props.camera.ZoneId, PublicName: props.zones.PublicName } as Zones,
        },
    });
    const disableButton = !(isDirty && isValid);

    useEffect(() => {
        reset({
            location: props.zones.Location,
            zone: { Id: props.camera.ZoneId, PublicName: props.zones.PublicName } as Zones,
        });
    }, [props]);

    const [loadingState, setLoadingState] = useState<LoadingState>({
        location: false,
        paging: false,
        chartStep: false,
        chartCancellation: false,
    });

    const [locations, setLocations] = useState<LocationSM[]>([]);
    const [zones, setZones] = useState<ZoneSM[]>(props?.zones?.Location?.Zones || []);
    const changeAPILocationFlowServiceType = camServiceTypes[props.camera.ServiceType];

    const locationAutocomplete = useStyledAutocomplete({
        async getList(props: IListWithECamServiceActive): Promise<Paging<any>> {
            const res = await locationController.listWithECamServiceActive(props).finally(() => {});
            setLocations(res.rows);
            return res;
        },
        listProps: {
            search: { fields: ['name'] } as any,
            ZoneService: [
                changeAPILocationFlowServiceType === camServiceTypes[CameraServiceType.ECamTechOps]
                    ? ServiceSMType.eCam
                    : ServiceSMType.Anpr,
            ],
        },
        dependencyList: [changeAPILocationFlowServiceType],
    });

    useEffect(() => {
        if (props.zones.LocationId) {
            locationController
                .listWithECamServiceActive({
                    pageSize: 1,
                    filter: {
                        Id: props.zones.LocationId,
                    },
                    ZoneService: [
                        changeAPILocationFlowServiceType === camServiceTypes[CameraServiceType.ECamTechOps]
                            ? ServiceSMType.eCam
                            : ServiceSMType.Anpr,
                    ],
                })
                .then((res) => {
                    console.log('🚀 ~ .then ~ res:', res);
                    setZones(res?.rows?.[0]?.Zones || []);
                })
                .finally(() => {});
        }
    }, [props.zones.LocationId, open]);

    const zoneAutocomplete = useStyledAutocomplete({
        list: {
            options: zones,
            isFiltered(option, searchText) {
                return option.PublicName.toLowerCase().includes(searchText.toLocaleLowerCase());
            },
        },
        dependencyList: [zones],
    });

    const onChangeLocation = (l?: Location) => {
        setValue('location', l!);
        setZones(l?.Zones || []);
        if (l!.Zones!.length >= 1) setValue('zone', l!.Zones![0]);
    };

    const onChangeZone = (val: Zones) => {
        if (val) {
            setValue('zone', val!);
        } else {
            setCheckDisabled((prev) => ({ ...prev, zone: true }));
        }
    };

    const handleChange = async (data: FormValues) => {
        const _locationAndZone: CameraWithInfo = {
            ...props.camera,
            ZoneId: data.zone?.Id!,
            ZoneName: data.zone?.PublicName,
            LocationName: data.location.Name,
        };
        setIsLoadingSave(true);
        await props
            .onConfirm?.(_locationAndZone)
            .then(() => {
                const zone = locations.filter((items) => items.Id === data.location.Id);
                const zonesArray = zone[0]?.Zones || [];
                setZones(zonesArray);
                setCheckDisabled({ location: true, zone: true });
            })
            .finally(() => {
                setIsLoadingSave(false);
            });
    };

    return (
        <PopUpBase
            open={open}
            onConfirm={() => {}}
            onClose={onClose}
            fixOverflow={true}
            dialogProps={{
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        maxWidth: '700px',
                    },
                    '& .MuiPaper-root': {
                        overflowY: 'unset',
                    },

                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Edit location & zone</Typography>
                    {props.camera.IsAbleChangeZone ? (
                        <Box width={'652px'}></Box>
                    ) : (
                        <OrangeStack mt={2} direction={'row'} gap={1} textAlign={'left'}>
                            <Typography>
                                Please ensure all PCN’s and images have been processed for this camera otherwise we may
                                lose data for PCN generation. Are you sure you want to edit the camera details.
                            </Typography>
                        </OrangeStack>
                    )}
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="100%" sx={{ background: '#FAFAFA', p: 2 }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ width: 130 }}>Location</Typography>
                        <Stack width={'100%'}>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <StyledAutocomplete
                                        {...locationAutocomplete}
                                        {...field}
                                        getOptionLabel={(o) => o.Name}
                                        label={
                                            <span>
                                                Location<span style={{ color: 'red' }}>{`*`}</span>
                                            </span>
                                        }
                                        placeholder="Select location"
                                        disabledAllOption
                                        value={field.value}
                                        onChange={(l) => {
                                            setValue('location', l!, {
                                                shouldValidate: true,
                                                shouldTouch: true,
                                                shouldDirty: true,
                                            });
                                            setValue('zone', undefined as any);
                                            onChangeLocation(l!);
                                        }}
                                        wrapperWidth="100%"
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ width: 130 }}>Zone</Typography>
                        <Stack width={'100%'}>
                            <Controller
                                name="zone"
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Location is required',
                                    },
                                }}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <StyledAutocomplete
                                            {...zoneAutocomplete}
                                            {...field}
                                            getOptionLabel={(o) => `${o?.PublicName} (ZoneID ${o?.Id})`}
                                            label={
                                                <span>
                                                    Zone<span style={{ color: 'red' }}>{`*`}</span>
                                                </span>
                                            }
                                            placeholder="Select zone"
                                            disabledAllOption
                                            disabled={!watch().location}
                                            value={watch().zone}
                                            onChange={(z) =>
                                                setValue('zone', z! as any, {
                                                    shouldValidate: true,
                                                    shouldTouch: true,
                                                    shouldDirty: true,
                                                })
                                            }
                                            wrapperWidth="100%"
                                        />
                                    );
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        disabled={isLoadingSave}
                        onClick={() => {
                            reset({});
                            props.onClose?.();
                            setCheckDisabled({ location: true, zone: true });
                        }}
                    >
                        Cancel
                    </Button>
                    {props.camera.IsAbleChangeZone ? (
                        <Button
                            sx={{ minWidth: 130 }}
                            variant={'contained'}
                            disabled={disableButton}
                            onClick={handleSubmit((data) => {
                                handleChange(data);
                            })}
                        >
                            Save
                        </Button>
                    ) : (
                        <></>
                    )}
                </Stack>
            }
        />
    );
}
