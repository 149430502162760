import EmptyIcon from '@assets/jsx-icon/EmptyIcon';
import PopUpWarning from '@components/PopUpWarning';
import StyledAutocomplete from '@components/styled-autocomplete';
import { TypeReturn } from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushError } from '@components/toast';
import { appConfig } from '@configs/index';
import { potentialGroupContraventionController } from '@controllers/index';
import { ListProps } from '@Core';
import { CancellationReasons, GroupPotentialContraventions, PCNDuplicate } from '@EcamModel/model';
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    Grow,
    Radio,
    RadioGroup,
    Stack,
    SxProps,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import { RequiredTitle } from '@pages/cameras/add-new';
import color from '@theme/Colors';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { formatUKDate, statusMap } from 'src/helpers';
import MyModal from '../hooks/MyModal';
import { KPopup } from '../hooks/useModal';
import usePopUp, { UsePopUpReturnType } from '@hooks/usePopUp';
import { Controls } from './EvidencePhotoGroup';
import { CustomTextField } from './ModalChangePlate';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';

type Props = KPopup & {
    cancellationReasonAutocomplete: TypeReturn<CancellationReasons, ListProps<CancellationReasons>>;
    handleCancelPC: (cancelReason: FormCancelReasonValues) => void;
    popUpSplitAndComplete: UsePopUpReturnType;
    commentReason: (comment: string) => void;
    groupPc: GroupPotentialContraventions | undefined;
};

export interface FormCancelReasonValues {
    cancellationReason: CancellationReasons;
    comment: string;
    data: VehicleInfoCardProps | undefined;
}
interface VehicleInfoCardProps {
    id: number;
    reference: string | undefined;
    source: string | undefined;
    issueDate: Date;
    licensePlate: string;
    imageUrl?: string;
}

export default function ModalCancellationReasons(props: Props) {
    const { commentReason, groupPc } = props;

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { isDirty, isValid, errors, touchedFields },
    } = useForm<FormCancelReasonValues>({
        mode: 'all',
        defaultValues: {
            cancellationReason: undefined,
            comment: '',
            data: undefined,
        },
    });
    const [isCheckReasonMismatch, setIsCheckReasonMismatch] = useState<boolean>(false);

    useEffect(() => {
        if (!props.open) return;
        setIsCheckReasonMismatch(false);
        reset({
            cancellationReason: undefined,
            comment: '',
        });
    }, [props.open]);

    useEffect(() => {
        if (watch()?.cancellationReason?.Id) {
            const filteredReasonMismatch = props?.cancellationReasonAutocomplete?.paging?.rows.some(
                (d) => watch().cancellationReason.Id === 2
            );
            setIsCheckReasonMismatch(filteredReasonMismatch);
        }
    }, [watch()?.cancellationReason?.Id]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
    const [selectImagePreview, setSelectImagePreview] = useState<string>('');
    const [isCheckReasonDuplicates, setIsCheckReasonDuplicates] = useState<boolean>(false);

    const popUpNoItemsSelectedWarning = usePopUp();

    const [searchVrnPlate, setSearchVrnPlate] = useState<string>('');
    const [valueVrnPlate, setValueVrnPlate] = useState<string | undefined>('');
    const [selectedColumnEvidencePhoto, setSelectedColumnEvidencePhoto] = useState<number | null>(null);
    const [listDuplicates, setListDuplicates] = useState<PCNDuplicate[]>([]);

    const handleRadioChangeColumnEvidencePhoto = (
        event: React.ChangeEvent<HTMLInputElement>,
        data: VehicleInfoCardProps
    ) => {
        setSelectedColumnEvidencePhoto(Number(event.target.value));
        setValue('data', data);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setSearchVrnPlate(inputValue.toUpperCase());
    };

    useEffect(() => {
        if (!props.open) return;
        getItemsDuplicate();
    }, [props.open]);

    const getItemsDuplicate = async (searchValue?: string) => {
        setIsLoading(true);
        await potentialGroupContraventionController
            .getGroupPotentialContraventionDuplications({
                GroupPotentialContraventionId: groupPc?.Id!,
                Plate: searchValue ?? undefined,
            })
            .then((res) => {
                setListDuplicates(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSearchReg = (searchValue: string) => {
        setValueVrnPlate(searchValue);
        searchValue ? getItemsDuplicate(searchValue) : getItemsDuplicate(undefined);
    };

    const handleCloseModal = () => {
        props.handleClose();
        clearErrors();
        reset({
            cancellationReason: undefined,
            comment: '',
            data: undefined,
        });
        setSearchVrnPlate('');
        setIsCheckReasonDuplicates(false);
        setSelectedColumnEvidencePhoto(null);
    };

    const handleCancelPC = () => {
        props.handleCancelPC(getValues());
        handleCloseModal();
        setValueVrnPlate(undefined);
        popUpNoItemsSelectedWarning.onClose?.();
    };

    return (
        <MyModal
            {...props}
            handleClose={() => props.handleClose()}
            modalContent={
                <Stack flexBasis={1} p={'24px'} gap={3}>
                    <Typography variant="h4" textAlign={'center'} sx={{ color: color.danger }}>
                        Cancel
                    </Typography>
                    <Typography textAlign={'center'}>Please select the cancellation reasons.</Typography>

                    <Controller
                        name="cancellationReason"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please select a reason.',
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <StyledAutocomplete
                                {...props.cancellationReasonAutocomplete}
                                {...field}
                                isRequired
                                getOptionLabel={(o) => o.Reason}
                                error={!!error?.message}
                                helperText={error?.message}
                                label="Reason"
                                placeholder="Select reason"
                                value={field.value}
                                disabledAllOption
                                onChange={(val) => {
                                    setValue('cancellationReason', val!, { shouldValidate: true, shouldTouch: true });
                                    // if (val?.Id === 11) {
                                    //     setIsCheckReasonDuplicates(true);
                                    // } else {
                                    //     setIsCheckReasonDuplicates(false);
                                    //     setSelectedColumnEvidencePhoto(null);
                                    //     setValue('data', undefined, { shouldValidate: false });
                                    //     clearErrors('data');
                                    // }
                                }}
                            />
                        )}
                    />

                    {/* <Collapse in={isCheckReasonDuplicates}>
                        {isLoading ? (
                            <Stack direction="row" justifyContent="center" alignItems="center" minHeight={200}>
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <>
                                {isCheckReasonDuplicates && (
                                    <>
                                        {listDuplicates?.length > 0 ? (
                                            <Stack direction={'column'} spacing={1}>
                                                <Controller
                                                    control={control}
                                                    name={'data.licensePlate'}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Please select a plate.',
                                                        },
                                                    }}
                                                    render={({ field, fieldState: { error } }) => (
                                                        <TextField
                                                            {...field}
                                                            label={'Plate'}
                                                            required
                                                            placeholder={'Enter plate'}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value);
                                                                setSelectedColumnEvidencePhoto(null);
                                                                setValue('data.reference', undefined);
                                                                setValue('data.source', undefined);
                                                            }}
                                                            inputProps={{
                                                                maxLength: 500,
                                                            }}
                                                            sx={{
                                                                '&.MuiInputBase-input': { p: '0px' },
                                                                '& .MuiOutlinedInput-input': { p: '0px !important' },
                                                            }}
                                                            error={!!error?.message}
                                                            helperText={error?.message}
                                                            multiline
                                                            minRows={1}
                                                            fullWidth
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                                <BaseSearchInputComponent
                                                    handleInputChange={handleInputChange}
                                                    handleSearchReg={() => {
                                                        handleSearchReg(searchVrnPlate);
                                                    }}
                                                    searchVrnPlate={searchVrnPlate}
                                                    style={{
                                                        bgcolor: color.grey100,
                                                        padding: '8px 16px',
                                                        borderRadius: 1,
                                                    }}
                                                />
                                                <Grid
                                                    container
                                                    spacing={0.5}
                                                    sx={{
                                                        maxHeight: 310,
                                                        overflowY: 'auto',
                                                        '::-webkit-scrollbar': { width: '2px' },
                                                    }}
                                                >
                                                    {listDuplicates?.map((item, index) => (
                                                        <Grow
                                                            in={true}
                                                            key={index}
                                                            timeout={Math.min(500 * index, 1000)}
                                                        >
                                                            <Grid item md={4} sm={6} xs={12}>
                                                                <Stack
                                                                    bgcolor={color.white}
                                                                    p={1}
                                                                    border={`1px solid ${color.grey100}`}
                                                                    borderRadius={1}
                                                                    direction={'column'}
                                                                    alignItems={'start'}
                                                                >
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            sx={{ ml: 0.5 }}
                                                                            row
                                                                            aria-labelledby="radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            value={selectedColumnEvidencePhoto}
                                                                            onChange={(
                                                                                event: React.ChangeEvent<HTMLInputElement>
                                                                            ) => {
                                                                                handleRadioChangeColumnEvidencePhoto(
                                                                                    event,
                                                                                    {
                                                                                        id: item.id,
                                                                                        licensePlate:
                                                                                            valueVrnPlate ||
                                                                                            groupPc?.Plate!,
                                                                                        issueDate:
                                                                                            item?.firstIssuedDate!,
                                                                                        source: item.source,
                                                                                        reference: item.reference ?? '',
                                                                                    }
                                                                                );
                                                                                setValue(
                                                                                    'data.licensePlate',
                                                                                    valueVrnPlate || groupPc?.Plate!,
                                                                                    {
                                                                                        shouldValidate: true,
                                                                                        shouldTouch: true,
                                                                                        shouldDirty: true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Grid container xs={12}>
                                                                                <Grid>
                                                                                    <FormControlLabel
                                                                                        sx={{
                                                                                            '.MuiFormControlLabel-label':
                                                                                                {
                                                                                                    mt: '3px !important',
                                                                                                },
                                                                                        }}
                                                                                        control={
                                                                                            <Radio value={item.id} />
                                                                                        }
                                                                                        label={item.id}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                    {item?.blobName ? (
                                                                        <Box
                                                                            sx={{
                                                                                width: '100%',
                                                                                height: '150px',
                                                                                background: `url(${appConfig.gateway.blobURL}${item.blobName}) no-repeat center center`,
                                                                                backgroundSize: 'cover',
                                                                                borderRadius: 1,
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                setSelectImagePreview(
                                                                                    `${appConfig.gateway.blobURL}${item.blobName}`
                                                                                );
                                                                                setOpenImagePreview(true);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <ListNoData changeText="No image" />
                                                                    )}

                                                                    <Stack direction={'column'} width={'100%'}>
                                                                        <Box
                                                                            bgcolor={'#FFFF00'}
                                                                            borderRadius={1}
                                                                            border={`1px solid ${color.textPrimary}`}
                                                                            textAlign={'center'}
                                                                            fontSize={24}
                                                                            fontWeight={500}
                                                                            my={1}
                                                                        >
                                                                            {valueVrnPlate?.toUpperCase() ||
                                                                                groupPc?.Plate.toUpperCase()}
                                                                        </Box>
                                                                        <Typography variant="body2">
                                                                            Issued date:{' '}
                                                                            {formatUKDate(item?.firstIssuedDate!)}
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            Source: {item.source}
                                                                        </Typography>
                                                                        {item.source === 'ECam' && (
                                                                            <Typography variant="body2">
                                                                                Step: {item.step}
                                                                            </Typography>
                                                                        )}
                                                                        <Typography variant="body2">
                                                                            Status: {statusMap[item.status]}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>
                                                        </Grow>
                                                    ))}
                                                </Grid>
                                            </Stack>
                                        ) : (
                                            <>
                                                <BaseSearchInputComponent
                                                    handleInputChange={handleInputChange}
                                                    handleSearchReg={() => {
                                                        handleSearchReg(searchVrnPlate);
                                                    }}
                                                    searchVrnPlate={searchVrnPlate}
                                                />

                                                <Stack
                                                    direction={'column'}
                                                    alignItems={'center'}
                                                    spacing={1}
                                                    border={'1px solid #DDDDDD'}
                                                    borderRadius={1}
                                                    my={2}
                                                >
                                                    <EmptyIcon />
                                                    <Stack textAlign={'center'} direction={'column'}>
                                                        <Typography variant="body1">
                                                            We currently cannot find any IDs related to the duplication
                                                            of this PC.
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            Please check carefully and enter the exact license plate
                                                            number you think is duplicate.
                                                        </Typography>
                                                    </Stack>

                                                    <Controller
                                                        control={control}
                                                        name={'data.licensePlate'}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: 'Please select a plate.',
                                                            },
                                                        }}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <CustomTextField
                                                                {...field}
                                                                placeholder={'Enter plate'}
                                                                autoFocus
                                                                disabled={false}
                                                                inputProps={{
                                                                    maxLength: 20,
                                                                }}
                                                                style={{
                                                                    fontSize: 14,
                                                                    width: '80%',
                                                                }}
                                                                error={!!error?.message}
                                                                helperText={error?.message}
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.value);
                                                                    setSelectedColumnEvidencePhoto(null);
                                                                    setValue('data.reference', undefined);
                                                                    setValue('data.source', undefined);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Collapse> */}

                    <Controller
                        control={control}
                        name={'comment'}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={'Comment'}
                                placeholder={'Enter comment'}
                                onChange={(e) => {
                                    field.onChange(e.target.value);
                                    commentReason(e.target.value);
                                }}
                                inputProps={{
                                    maxLength: 500,
                                }}
                                sx={{
                                    '&.MuiInputBase-input': { p: '0px' },
                                    '& .MuiOutlinedInput-input': { p: '0px !important' },
                                }}
                                multiline
                                minRows={4}
                                fullWidth
                                size="small"
                            />
                        )}
                    />

                    {selectImagePreview && (
                        <Dialog
                            open={!!openImagePreview}
                            onClose={() => {
                                setOpenImagePreview(false);
                                setSelectImagePreview('');
                            }}
                            PaperProps={{
                                sx: {
                                    minWidth: 1280,
                                    minHeight: 720,
                                },
                            }}
                        >
                            <TransformWrapper
                                alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                                doubleClick={{ disabled: true }}
                                wheel={{ disabled: false }}
                                centerZoomedOut={true}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                    <>
                                        <Controls />
                                        <TransformComponent>
                                            <Stack sx={{ position: 'relative' }}>
                                                <Box
                                                    sx={{
                                                        width: 1280,
                                                        height: 720,
                                                        background: `url(${selectImagePreview}) no-repeat center center`,
                                                        backgroundSize: 'cover',
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            </Stack>
                                        </TransformComponent>
                                    </>
                                )}
                            </TransformWrapper>
                        </Dialog>
                    )}

                    <PopUpWarning
                        {...popUpNoItemsSelectedWarning}
                        title="Confirm"
                        message="Are you sure you want to cancel this PC with a manually entered 
                        license plate number and that is not on our suggested list?"
                        onConfirm={handleCancelPC}
                    />

                    <Stack direction="row" justifyContent={'space-between'}>
                        <Button variant={'cancel'} onClick={handleCloseModal} sx={{ minWidth: '200px' }}>
                            Close
                        </Button>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            {isCheckReasonMismatch && (
                                <Button
                                    variant={'errorOutlined'}
                                    onClick={() => {
                                        props.popUpSplitAndComplete?.setTrue();
                                        handleCloseModal();
                                    }}
                                    sx={{ minWidth: '200px' }}
                                    disabled={!isValid}
                                >
                                    Cancel & split
                                </Button>
                            )}

                            <Button
                                variant={'errorContained'}
                                onClick={() => {
                                    // if (
                                    //     isCheckReasonDuplicates &&
                                    //     selectedColumnEvidencePhoto === null &&
                                    //     listDuplicates?.length
                                    // ) {
                                    //     popUpNoItemsSelectedWarning.setTrue();
                                    // } else {
                                        handleCancelPC();
                                    // }
                                }}
                                sx={{ minWidth: '200px' }}
                                disabled={!isValid || isLoading}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            }
        />
    );
}
interface BaseComponentProps {
    searchVrnPlate: string;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSearchReg: (searchValue: string) => void;
    style?: SxProps<Theme>;
}

const BaseSearchInputComponent: React.FC<BaseComponentProps> = ({
    searchVrnPlate,
    handleInputChange,
    handleSearchReg,
    style,
}) => {
    return (
        <Stack direction="column" spacing={1} sx={style}>
            <RequiredTitle sx={{ width: 'max-content', lineHeight: 2 }} title="Please select ID duplicate" required />
            <Stack mb={2} width="100%" direction="row" alignItems="center">
                <Stack flex={1} marginRight={2}>
                    <CustomTextField
                        value={searchVrnPlate}
                        onChange={handleInputChange}
                        placeholder="Search Reg"
                        autoFocus
                        disabled={false}
                        inputProps={{ maxLength: 20 }}
                        style={{ fontSize: 14 }}
                    />
                </Stack>
                <Stack>
                    <Button variant="contained" sx={{ minWidth: 150 }} onClick={() => handleSearchReg(searchVrnPlate)}>
                        Search
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};
