import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function ArrowAccordionIcon(props: Props) {
    return (
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.16907 8.49443C7.67241 7.95964 7.67241 7.12543 7.16907 6.59064L2.06147 1.16381C1.79861 0.884524 1.35911 0.871206 1.07983 1.13406C0.80054 1.39692 0.787221 1.83642 1.05008 2.11571L6.15769 7.54254L1.05008 12.9694C0.787222 13.2487 0.80054 13.6882 1.07983 13.951C1.35911 14.2139 1.79861 14.2005 2.06147 13.9213L7.16907 8.49443Z"
                fill="#009D4F"
            />
        </svg>
    );
}
