import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcCopy(props: SvgIconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.6667 5.83325H7.33337C6.50495 5.83325 5.83337 6.50482 5.83337 7.33325V12.6666C5.83337 13.495 6.50495 14.1666 7.33337 14.1666H12.6667C13.4951 14.1666 14.1667 13.495 14.1667 12.6666V7.33325C14.1667 6.50482 13.4951 5.83325 12.6667 5.83325Z"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.33337 3H6.66671C7.63777 3 8.26551 3.00213 8.72797 3.06431C9.16177 3.12262 9.29771 3.21677 9.37384 3.29289C9.44991 3.36902 9.54411 3.50497 9.60237 3.93871C9.64904 4.28559 9.66191 4.72541 9.66537 5.33333H10.6656C10.6575 3.88331 10.5932 3.09804 10.0809 2.58579C9.49511 2 8.55231 2 6.66671 2H5.33337C3.44775 2 2.50495 2 1.91916 2.58579C1.33337 3.17157 1.33337 4.11438 1.33337 6V7.33333C1.33337 9.21893 1.33337 10.1617 1.91916 10.7475C2.50495 11.3333 3.44775 11.3333 5.33337 11.3333V10.3333C4.36229 10.3333 3.73459 10.3312 3.27208 10.269C2.83834 10.2107 2.70239 10.1165 2.62627 10.0405C2.55014 9.96433 2.45599 9.8284 2.39768 9.3946C2.3355 8.93213 2.33337 8.3044 2.33337 7.33333V6C2.33337 5.02892 2.3355 4.40121 2.39768 3.93871C2.45599 3.50497 2.55014 3.36902 2.62627 3.29289C2.70239 3.21677 2.83834 3.12262 3.27208 3.06431C3.73459 3.00213 4.36229 3 5.33337 3Z"
                fill="currentColor"
            />
        </svg>
    );
}
