import React from 'react';

function CancelIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M9.333 10.774l-2.64-2.64M9.307 8.16l-2.64 2.64M6.667 4h2.666c1.334 0 1.334-.667 1.334-1.334 0-1.333-.667-1.333-1.334-1.333H6.667c-.667 0-1.334 0-1.334 1.333C5.333 4 6 4 6.667 4z"
            ></path>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M10.667 2.68C12.887 2.8 14 3.62 14 6.666v4c0 2.667-.667 4-4 4H6c-3.333 0-4-1.333-4-4v-4C2 3.626 3.113 2.8 5.333 2.68"
            ></path>
        </svg>
    );
}

export default CancelIcon;
