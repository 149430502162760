import BaseLoading from '@components/BaseLoading';
import PopUpBase from '@components/PopUpBase';
import BlurShape from '@components/frame/BlurShape';
import SelectRectangle from '@components/frame/SelectRectangle';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Fade, Stack, Tooltip, Typography } from '@mui/material';
import Konva from 'konva';
import { useEffect } from 'react';
import { AiOutlineUndo } from 'react-icons/ai';
import { IoCropSharp } from 'react-icons/io5';
import { MdBlurOn, MdDone } from 'react-icons/md';
import { Circle, Group, Image, Layer, Rect, Stage } from 'react-konva';
import useEditImage, { AnchorPoint, Mode } from '../hooks/useEditImage';
import { PhotoItem } from './EvidencePhotoGroup';

type Props = IPopUp & {
    selectedItem: PhotoItem;
};
const MINIMUM_SHAPE_SIZE = 10;

export default function PopUpEditImage(props: Props) {
    const {
        stageRef,
        cropAreaRef,
        displayWidth,
        displayHeight,
        image,
        currentImage,
        mode,
        setMode,
        initialCropArea,
        cropArea,
        setCropArea,
        photos,
        setPhotos,
        shapes,
        setShapes,
        setSelectedShape,
        handleAnchorMouseEnter,
        handleAnchorMouseLeave,
        handleCropImage,
        handleDragAnchor,
        handleDragCropArea,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handleRectDragEnd,
        handleResetImage,
        handleSaveImage,
        selectedShapeId,
    } = useEditImage(props);

    useEffect(() => {
        if (!props.open) return;
        setMode(Mode.Crop);
        setPhotos([]);
        setShapes([]);
        setSelectedShape(null);
    }, [props.open]);

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'lg',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={<Typography variant="h4">Edit photo</Typography>}
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <>
                    {!currentImage ? (
                        <BaseLoading displayHeight={displayHeight} />
                    ) : (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                width="100%"
                                sx={{
                                    p: '3px',
                                    background: 'rgba(0, 0, 0, 1)',
                                    position: 'relative',
                                }}
                                alignItems="center"
                                spacing={1.5}
                            >
                                {[
                                    {
                                        Label: 'Crop mode',
                                        Icon: <IoCropSharp />,
                                        Mode: Mode.Crop,
                                        onClick: () => {
                                            setMode(Mode.Crop);
                                            setCropArea(initialCropArea);
                                            setSelectedShape(null);
                                        },
                                    },
                                    {
                                        Label: 'Blur mode',
                                        Icon: <MdBlurOn />,
                                        Mode: Mode.Blur,
                                        onClick: () => {
                                            setMode(Mode.Blur);
                                        },
                                    },
                                ].map((item, index) => {
                                    return (
                                        <Tooltip title={item.Label} placement="top" arrow key={`${index}+action`}>
                                            <Stack
                                                sx={{
                                                    color: '#fff',
                                                    fontSize: 16,
                                                    p: '2px',
                                                    background: (theme) =>
                                                        mode === item.Mode ? theme.palette.primary.dark : 'none',
                                                    border: (theme) => `1px solid ${theme.palette.primary.dark}`,
                                                    cursor: 'pointer',
                                                    borderRadius: 2,
                                                    '&:hover': {
                                                        background: (theme) => theme.palette.primary.dark,
                                                        transition: '.3s ease-in-out',
                                                    },
                                                }}
                                                onClick={item.onClick}
                                            >
                                                {item.Icon}
                                            </Stack>
                                        </Tooltip>
                                    );
                                })}
                                <Tooltip title={'Reset to the original'} placement="top" arrow>
                                    <Stack
                                        sx={{
                                            color: '#fff',
                                            fontSize: 16,
                                            p: 0.5,
                                            border: (theme) => `1px solid ${theme.palette.primary.dark}`,
                                            cursor: 'pointer',
                                            borderRadius: 2,
                                            '&:hover': {
                                                background: (theme) => theme.palette.primary.dark,
                                                transition: '.3s ease-in-out',
                                            },
                                        }}
                                        onClick={handleResetImage}
                                    >
                                        <AiOutlineUndo />
                                    </Stack>
                                </Tooltip>
                                <Fade in={mode === Mode.Crop}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ position: 'absolute', right: 0, pr: 1 }}
                                        spacing={1}
                                    >
                                        <Tooltip title={'Click to crop'} placement="top" arrow>
                                            <Stack
                                                sx={{
                                                    color: (theme) => theme.palette.primary.main,
                                                    fontSize: 22,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={handleCropImage}
                                            >
                                                <MdDone />
                                            </Stack>
                                        </Tooltip>
                                    </Stack>
                                </Fade>
                            </Stack>
                            <Stage
                                width={displayWidth}
                                height={displayHeight}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                ref={stageRef}
                            >
                                <Layer>
                                    <Group>
                                        <Image image={currentImage} width={displayWidth} height={displayHeight} />
                                    </Group>
                                </Layer>

                                <Layer>
                                    <Group name="blur">
                                        {shapes.map((shape, i) => {
                                            if (
                                                Math.abs(shape.height) <= MINIMUM_SHAPE_SIZE ||
                                                Math.abs(shape.height) <= MINIMUM_SHAPE_SIZE
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <BlurShape
                                                    key={shape.id}
                                                    imageWidth={displayWidth}
                                                    imageHeight={displayHeight}
                                                    imageSrc={photos[photos.length - 1] || image}
                                                    shape={shape}
                                                    filterKind={'pixelate'}
                                                    filterSize={10}
                                                />
                                            );
                                        })}
                                    </Group>
                                    <Group name="selected-blur">
                                        {shapes.map((shape, i) => {
                                            return (
                                                <SelectRectangle
                                                    isSelected={shape.id === selectedShapeId}
                                                    key={shape.id}
                                                    onChange={(newAttrs) => {
                                                        const _shapes = shapes.slice();
                                                        _shapes[i] = newAttrs;
                                                        setShapes(_shapes);
                                                    }}
                                                    onSelect={() => {
                                                        setMode(Mode.Blur);
                                                        setSelectedShape(shape.id);
                                                    }}
                                                    shape={shape}
                                                />
                                            );
                                        })}
                                    </Group>
                                </Layer>
                                <Layer>
                                    <Group name="crop">
                                        {mode === Mode.Crop && cropArea && (
                                            <>
                                                {[
                                                    { x: 0, y: 0, width: cropArea.x, height: 720 },
                                                    {
                                                        x: cropArea.x + cropArea.width,
                                                        y: 0,
                                                        width: 1280 - cropArea.x - cropArea.width,
                                                        height: 720,
                                                    },
                                                    { x: cropArea.x, y: 0, width: cropArea.width, height: cropArea.y },
                                                    {
                                                        x: cropArea.x,
                                                        y: cropArea.y + cropArea.height,
                                                        width: cropArea.width,
                                                        height: 720 - cropArea.y - cropArea.height,
                                                    },
                                                ].map((item, index) => {
                                                    return (
                                                        <Rect
                                                            key={`${index}+rect`}
                                                            x={item.x}
                                                            y={item.y}
                                                            width={item.width}
                                                            height={item.height}
                                                            fill="rgba(0, 0, 0, 0.4)"
                                                        />
                                                    );
                                                })}

                                                <Rect
                                                    ref={cropAreaRef}
                                                    {...cropArea}
                                                    stroke="#fff"
                                                    strokeWidth={2}
                                                    draggable
                                                    onDragMove={handleDragCropArea}
                                                    onDragEnd={handleRectDragEnd}
                                                    onMouseEnter={() => {
                                                        handleAnchorMouseEnter(true);
                                                    }}
                                                    onMouseLeave={handleAnchorMouseLeave}
                                                />
                                                {[
                                                    {
                                                        x: cropArea.x,
                                                        y: cropArea.y,
                                                        onDragMove: (e: Konva.KonvaEventObject<DragEvent>) =>
                                                            handleDragAnchor(e, AnchorPoint.TopLeft),
                                                    },
                                                    {
                                                        x: cropArea.x + cropArea.width,
                                                        y: cropArea.y,
                                                        onDragMove: (e: Konva.KonvaEventObject<DragEvent>) =>
                                                            handleDragAnchor(e, AnchorPoint.TopRight),
                                                    },
                                                    {
                                                        x: cropArea.x,
                                                        y: cropArea.y + cropArea.height,
                                                        onDragMove: (e: Konva.KonvaEventObject<DragEvent>) =>
                                                            handleDragAnchor(e, AnchorPoint.BottomLeft),
                                                    },
                                                    {
                                                        x: cropArea.x + cropArea.width,
                                                        y: cropArea.y + cropArea.height,
                                                        onDragMove: (e: Konva.KonvaEventObject<DragEvent>) =>
                                                            handleDragAnchor(e, AnchorPoint.BottomRight),
                                                    },
                                                ].map((item, index) => {
                                                    return (
                                                        <Circle
                                                            key={`${index}+circle`}
                                                            x={item.x}
                                                            y={item.y}
                                                            radius={8}
                                                            fill="#fff"
                                                            draggable
                                                            onDragMove={item.onDragMove}
                                                            onMouseEnter={() => {
                                                                handleAnchorMouseEnter(false);
                                                            }}
                                                            onMouseLeave={handleAnchorMouseLeave}
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}
                                    </Group>
                                </Layer>
                            </Stage>
                        </>
                    )}
                </>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            handleResetImage();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        onClick={handleSaveImage}
                        disabled={!photos.length && !shapes.length}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
