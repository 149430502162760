import { defaultPagination } from '@Core';
import { IGetCameraPhotoProps } from '@EcamModel/controllers/ICameraPhotoHttpController';
import {
    CorrectPlateCameraPhotoVehicle,
    ICancelGroupPotentialContraventionDetail,
    ISplitPCintoMultiplePCs,
} from '@EcamModel/controllers/IGroupPotentialContraventionHttpController';
import { CameraPhotoVehicles, CaptureType, PotentialContraventionWithInfo } from '@EcamModel/model';
import CloseIcon from '@assets/jsx-icon/CloseIcon';
import BaseCheckbox from '@components/BaseCheckbox';
import PopUpBase from '@components/PopUpBase';
import StyledDateRangePicker from '@components/StyledDateRangePicker';
import { pushError, pushSuccess } from '@components/toast';
import { appConfig } from '@configs/index';
import { cameraPhotoController, potentialGroupContraventionController } from '@controllers/index';
import { IPopUp } from '@hooks/usePopUp';
import {
    Box,
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';
import { Filter } from '@pages/cameras/live-photos/components';
import BaseList from '@pages/cameras/live-photos/components/BaseList';
import color from '@theme/Colors';
import moment, { Moment } from 'moment';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { TransformWrapperComponent } from './EvidencePhotoGroup';
import { CustomTextField } from './ModalChangePlate';
import { usePotentialContravention } from '../hooks/PotentialContraventionProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {
    popupCancel: {
        open: boolean;
        setOpen: Dispatch<SetStateAction<boolean>>;
        handleOpen: () => void;
        handleClose: () => void;
    };
    cancelPc: ICancelGroupPotentialContraventionDetail;
    pc: PotentialContraventionWithInfo;
};

interface Group {
    id: number;
    groupItems: CorrectPlateCameraPhotoVehicle[];
}

export default function PopUpSplitAndComplete(props: Props) {
    const { handleNext, updateGrPcIds } = usePotentialContravention();
    const { open, onClose, pc, cancelPc } = props;
    const backdrop = useBackdrop();
    const initFilter: Filter = useMemo(() => {
        return {
            page: 1,
            pageSize: 20,
            timeFrom: moment()
                .tz(pc.TimeZone || moment.tz.guess())
                .startOf('day')
                .toDate(),
            timeTo: moment()
                .tz(pc.TimeZone || moment.tz.guess())
                .endOf('day')
                .toDate(),
            dayStartTime: null,
            dayEndTime: null,
            plate: '',
            refetch: false,
        };
    }, [pc.TimeZone]);

    const [filter, setFilter] = useState<Filter>(initFilter);
    function updateFilter(params: Partial<Filter>) {
        setFilter((prev) => ({ ...prev, page: 1, ...params }));
    }
    function handleChangeTime(from: Moment | null, to: Moment | null) {
        let updatedFrom = from;
        let updatedTo = to;

        if (!updatedTo) {
            updatedTo = moment().endOf('day');
        }

        if (updatedFrom && updatedTo && updatedFrom.isAfter(updatedTo)) {
            [updatedFrom, updatedTo] = [updatedTo, updatedFrom];
        }

        updateFilter({ dayStartTime: updatedFrom, dayEndTime: updatedTo });
    }
    const getSecond = (date: moment.Moment) => {
        return date.hours() * 60 * 60 + date.minutes() * 60;
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
    const [selectImagePreview, setSelectImagePreview] = useState<string>('');
    const [groups, setGroups] = useState<Group[]>([
        { id: Date.now(), groupItems: [{ Plate: '', CameraPhotoVehicle: [] }] },
    ]);
    const [selectedGroup, setSelectedGroup] = useState<{ idGroup: number; groupIndex: number } | null>({
        idGroup: groups[0].id,
        groupIndex: 0,
    });
    const [paging, setPaging] = useState(defaultPagination<CameraPhotoVehicles>());
    const [selectedPhotos, setSelectedPhotos] = useState<CameraPhotoVehicles[]>([]);
    const [originalRows, setOriginalRows] = useState<CameraPhotoVehicles[]>([]);
    const [plateValues, setPlateValues] = useState<string[]>([]);

    const handleRadioChangeColumnEvidencePhoto = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedIndex = Number(event.target.value);
        setSelectedGroup({ idGroup: groups[selectedIndex].id, groupIndex: selectedIndex });
    };

    const handleCheckboxChange = (photo: CameraPhotoVehicles) => {
        setSelectedPhotos((prevSelectedPhotos) =>
            prevSelectedPhotos.includes(photo)
                ? prevSelectedPhotos.filter((selectedPhoto) => selectedPhoto !== photo)
                : [...prevSelectedPhotos, photo]
        );
    };
    const [isCheckedDisableButton, setIsCheckedDisableButton] = useState(false);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
        const { value } = event.target;

        // Update plateValues state
        setPlateValues((prevPlateValues) => {
            const updatedPlateValues = [...prevPlateValues];
            updatedPlateValues[id] = value.toUpperCase(); // Assuming id is the index of the group

            // Check for duplicates
            const isDuplicate = updatedPlateValues.filter((plate) => plate === value.toUpperCase()).length > 1;
            setIsCheckedDisableButton(isDuplicate);

            return updatedPlateValues;
        });

        // Update groups state
        setGroups((prevGroups) =>
            prevGroups.map((group, index) =>
                index === id
                    ? {
                          ...group,
                          groupItems: group.groupItems.map((item, idx) => ({
                              ...item,
                              Plate: idx === 0 ? value.toUpperCase() : item.Plate,
                          })),
                      }
                    : group
            )
        );
    };

    const handleAddGroup = () => {
        const newGroup = { id: Date.now(), groupItems: [{ Plate: '', CameraPhotoVehicle: [] }] };
        setGroups([...groups, newGroup]);
        setSelectedGroup({ idGroup: newGroup.id, groupIndex: groups.length });
    };

    const handleAddPhotosToGroup = () => {
        if (selectedGroup !== null) {
            const updatedGroups = groups.map((group, index) =>
                index === selectedGroup.groupIndex
                    ? {
                          ...group,
                          groupItems: group.groupItems.map((item, idx) => ({
                              ...item,
                              CameraPhotoVehicle:
                                  idx === 0 ? [...item.CameraPhotoVehicle, ...selectedPhotos] : item.CameraPhotoVehicle,
                          })),
                      }
                    : group
            );
            setGroups(updatedGroups);

            const existingIds = updatedGroups.flatMap((group) =>
                group.groupItems.flatMap((item) => item.CameraPhotoVehicle.map((photo) => photo.Id))
            );
            const filteredRows = originalRows.filter((item) => !existingIds.includes(item.Id));
            setPaging((prevPaging) => ({ ...prevPaging, rows: filteredRows }));

            setSelectedPhotos([]);
        }
    };

    useEffect(() => {
        validatePlateValues();
    }, [groups]);

    const validatePlateValues = () => {
        const allPlates = groups.flatMap((group) => group.groupItems.map((item) => item.Plate));
        const uniquePlates = new Set(allPlates);
        setIsCheckedDisableButton(allPlates.length !== uniquePlates.size);
        setPlateValues(allPlates);
    };

    const handleDeleteGroup = (id: number) => {
        const groupToDelete = groups.find((group) => group.id === id);
        if (groupToDelete) {
            const newGroups = groups.filter((group) => group.id !== id);
            setGroups(newGroups);

            const existingIds = newGroups.flatMap((group) =>
                group.groupItems.flatMap((item) => item.CameraPhotoVehicle.map((photo) => photo.Id))
            );
            const filteredRows = originalRows.filter((item) => !existingIds.includes(item.Id));
            setPaging((prevPaging) => ({ ...prevPaging, rows: filteredRows }));

            // Remove plates associated with the deleted group's items
            const newPlateValues = plateValues.filter(
                (plate) => !groupToDelete.groupItems.some((item) => item.Plate === plate)
            );
            setPlateValues(newPlateValues);

            if (selectedGroup && selectedGroup.idGroup === id) {
                setSelectedGroup(newGroups.length > 0 ? { idGroup: newGroups[0].id, groupIndex: 0 } : null);
            }
        }
        setSelectedGroup((prev) => ({ ...prev, groupIndex: 0, idGroup: 0 }));
    };

    const handleRemoveItem = (itemId: number) => {
        const updatedGroups = groups.map((group) => ({
            ...group,
            groupItems: group.groupItems.map((item) => ({
                ...item,
                CameraPhotoVehicle: item.CameraPhotoVehicle.filter((photo) => photo.Id !== itemId),
            })),
        }));
        setGroups(updatedGroups);

        const removedItem = originalRows.find((item) => item.Id === itemId);
        if (removedItem) {
            setPaging((prevPaging) => ({
                ...prevPaging,
                rows: [removedItem, ...prevPaging.rows],
            }));
        }
    };

    const isCheckedDisableButtonConfirm = groups.some(
        (group) =>
            group.groupItems.some((item) => !item.Plate || item.CameraPhotoVehicle.length < 2) || isCheckedDisableButton
    );
    const startOfHour: Moment = moment(pc.EntryAt).startOf('day');
    const endOfHour: Moment = moment(pc.EntryAt).endOf('day');

    const handleGetPlateImageBundles = async () => {
        setIsLoading(true);
        const _filter: IGetCameraPhotoProps = {
            page: filter.page,
            pageSize: filter.pageSize,
            CameraId: pc?.CameraId!,
            FromDate: startOfHour.toDate(),
            ToDate: endOfHour.toDate(),
            FromTime: filter.dayStartTime ? getSecond(filter.dayStartTime) : undefined,
            ToTime: filter.dayEndTime ? getSecond(filter.dayEndTime) : undefined,
            Plate: pc.Plate ?? undefined,
        };

        await cameraPhotoController
            .getCameraPhotoVehiclesByPlate(_filter)
            .then((res) => {
                const existingIds = groups?.flatMap((group) =>
                    group.groupItems?.flatMap((item) => item.CameraPhotoVehicle?.map((photo) => photo.Id))
                );
                const filteredRows = res.rows?.filter((item) => !existingIds.includes(item.Id));

                setOriginalRows(res.rows);
                setPaging({ ...res, rows: filteredRows });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleClosePopup = () => {
        props.onClose?.();
        setSelectedPhotos([]);
        setGroups((prev) => [{ id: Date.now(), groupItems: [{ Plate: '', CameraPhotoVehicle: [] }] }]);
    };

    useEffect(() => {
        if (!open) return;
        setSelectedPhotos([]);
        setGroups((prev) => [{ id: Date.now(), groupItems: [{ Plate: '', CameraPhotoVehicle: [] }] }]);
        setFilter(initFilter);
    }, [open]);

    useEffect(() => {
        if (!open) return;
        handleGetPlateImageBundles();
    }, [open, filter.page, filter.timeFrom, filter.timeTo, filter.dayStartTime, filter.dayEndTime, filter.pageSize]);

    const handlePhotoVehicleForChangePlate = async () => {
        const _filterGroup: CorrectPlateCameraPhotoVehicle[] = groups.flatMap((items) => items.groupItems);

        const _propGroups: ISplitPCintoMultiplePCs = {
            CameraId: pc?.CameraId!,
            IssuedDate: pc?.EntryAt!,
            CorrectPlateVehicles: _filterGroup,
        };

        backdrop.setTrue();
        potentialGroupContraventionController
            .doCancelGroupPotentialContravention({
                CancellationNotes: cancelPc.CancellationNotes ?? undefined,
                CancellationReasonId: cancelPc.CancellationReasonId,
                Id: cancelPc.Id,
            })
            .then(async (_) => {
                await potentialGroupContraventionController
                    .doSplitPCintoMultiplePCs(_propGroups)
                    .then((_) => {
                        pushSuccess('Splitting and regrouping completed. Original PC canceled');
                    })
                    .catch((err) => {
                        pushError(err.response.data.message);
                    })
                    .finally(() => {
                        backdrop.setFalse();
                        props.onClose?.();
                    });
                updateGrPcIds(cancelPc.Id, 'Cancel');
                handleNext();
            })
            .catch(() => {
                backdrop.setFalse();
            });
    };

    return (
        <PopUpBase
            open={open}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
                maxWidth: 'lg',
            }}
            onClose={onClose}
            onConfirm={() => {}}
            title={<Typography variant="h4">Split</Typography>}
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack direction={'column'} spacing={1} height={'100%'}>
                    <Stack spacing={1} bgcolor={color.grey100} padding={2} borderRadius={1}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant="h5">Recreate the PCN group</Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    minWidth: 130,
                                    fontSize: 12,
                                    padding: '4px 8px',
                                }}
                                onClick={handleAddGroup}
                                disabled={isCheckedDisableButtonConfirm}
                            >
                                Add new group
                            </Button>
                        </Stack>
                        <Box
                            sx={{
                                maxHeight: 310,
                                overflowY: 'auto',
                                flexGrow: 1,
                                pr: 1,
                                '::-webkit-scrollbar': { width: '2px' },
                            }}
                        >
                            <Grid container spacing={2}>
                                {groups.map((group, index) => (
                                    <Grid
                                        item
                                        md={groups.length % 2 === 1 && index === groups.length - 1 ? 12 : 6}
                                        key={group.id}
                                    >
                                        <Stack direction={'column'} spacing={1} alignItems={'center'}>
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                                width={'100%'}
                                            >
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        value={selectedGroup?.groupIndex || 0}
                                                        onChange={handleRadioChangeColumnEvidencePhoto}
                                                    >
                                                        <FormControlLabel
                                                            sx={{
                                                                ml: '-5px',
                                                                '.MuiFormControlLabel-label': {
                                                                    mt: '3px !important',
                                                                },
                                                            }}
                                                            control={<Radio value={index} />}
                                                            label={
                                                                <Typography
                                                                    color={
                                                                        selectedGroup?.groupIndex === index
                                                                            ? color.success
                                                                            : color.textPrimary
                                                                    }
                                                                >
                                                                    {`Group ${index + 1}`}
                                                                </Typography>
                                                            }
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                                {index >= 0 && groups.length !== 1 && (
                                                    <Tooltip placement="top" title={`Remove group ${index + 1}`}>
                                                        <Button
                                                            variant="cancelOutlined"
                                                            sx={{
                                                                minWidth: 16,
                                                                minHeight: 16,
                                                                p: '2px',
                                                                borderRadius: '6px',
                                                                borderColor: color.textPrimary,
                                                                '&:hover': {
                                                                    color: (theme) => theme.palette.error[700],
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                handleDeleteGroup(group.id);
                                                            }}
                                                        >
                                                            <AiOutlineMinus color={color.textPrimary} />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </Stack>
                                            <CustomTextField
                                                value={group?.groupItems?.[0].Plate ?? ''}
                                                onChange={(event) => handleInputChange(event, index)}
                                                placeholder={'Enter Reg'}
                                                autoFocus
                                                disabled={false}
                                                inputProps={{
                                                    maxLength: 20,
                                                }}
                                                error={
                                                    plateValues.indexOf(group?.groupItems?.[0].Plate ?? '') !== index
                                                }
                                                style={{
                                                    fontSize: '20px',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                            />
                                            <Stack
                                                width={'100%'}
                                                sx={{
                                                    '& .chart-scroll-bar': {
                                                        '&::-webkit-scrollbar': {
                                                            height: '5px',
                                                        },
                                                        '&::-webkit-scrollbar-track': {
                                                            WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                            height: '5px',
                                                            backgroundColor: color.primary,
                                                            borderRadius: '3px',
                                                        },
                                                        '&::-webkit-scrollbar-thumb:hover': {
                                                            cursor: 'pointer',
                                                            backgroundColor: color.darkPrimary,
                                                        },
                                                    },
                                                }}
                                            >
                                                {group.groupItems[0].CameraPhotoVehicle.length ? (
                                                    <Box width={'100%'} height={'100%'}>
                                                        <ScrollContainer
                                                            className="chart-scroll-bar"
                                                            style={{
                                                                width: '100%',
                                                                paddingBottom: '5px',
                                                                overflowX: 'auto',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                position: 'relative',
                                                            }}
                                                            hideScrollbars={false}
                                                        >
                                                            <Box display="flex" flexDirection="row" gap={1}>
                                                                {group.groupItems[0].CameraPhotoVehicle.map(
                                                                    (photo, idx) => (
                                                                        <Stack key={idx} direction={'column'}>
                                                                            <PhotoDetails
                                                                                isCheckIcon
                                                                                key={photo.Id}
                                                                                item={photo}
                                                                                setSelectImagePreview={
                                                                                    setSelectImagePreview
                                                                                }
                                                                                setOpenImagePreview={
                                                                                    setOpenImagePreview
                                                                                }
                                                                                props={props}
                                                                                isScrollXPosition
                                                                                handleRemoveItem={() => {
                                                                                    handleRemoveItem(photo?.Id ?? 0);
                                                                                }}
                                                                            />
                                                                        </Stack>
                                                                    )
                                                                )}
                                                            </Box>
                                                        </ScrollContainer>
                                                    </Box>
                                                ) : (
                                                    <ListNoData sx={{ minHeight: 206 }} />
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Stack>

                    <Stack direction={'column'} bgcolor={color.grey100} padding={2} spacing={1} borderRadius={1}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant="h5">Live photo</Typography>
                                <Button
                                    variant="outlinedGreen"
                                    sx={{ padding: '4px 8px', fontSize: 12 }}
                                    onClick={handleAddPhotosToGroup}
                                    disabled={!selectedPhotos.length}
                                >
                                    Add group
                                </Button>
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <TimePicker
                                    label="From"
                                    value={filter.dayStartTime || null}
                                    ampm={false}
                                    onAccept={(newValue) => handleChangeTime(newValue, filter.dayEndTime)}
                                    sx={{ width: 200, '& .MuiSvgIcon-root': { width: 18, height: 18 } }}
                                />
                                <TimePicker
                                    label="To"
                                    ampm={false}
                                    disabled={!filter.dayStartTime}
                                    value={filter.dayEndTime || null}
                                    onAccept={(newValue) => handleChangeTime(filter.dayStartTime, newValue)}
                                    sx={{ width: 200, '& .MuiSvgIcon-root': { width: 18, height: 18 } }}
                                />
                            </Stack>
                        </Stack>
                        <Grid
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            py={isLoading ? 10 : 2}
                            sx={{
                                maxHeight: paging?.rows.length ? 240 : 'auto',
                                overflowY: 'auto',
                                '::-webkit-scrollbar': { width: '2px' },
                            }}
                        >
                            <BaseList
                                list={paging?.rows}
                                loading={isLoading}
                                renderItem={(item) => (
                                    <Grid item xs={12}>
                                        <Box
                                            bgcolor={color.white}
                                            p={1}
                                            border={1}
                                            borderColor={color.grey200}
                                            borderRadius={1}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <BaseCheckbox
                                                        checked={selectedPhotos.includes(item)}
                                                        onChange={() => handleCheckboxChange(item)}
                                                    />
                                                }
                                                label={
                                                    <Typography ml={0.5} mt={0.3}>
                                                        {item.Id}
                                                    </Typography>
                                                }
                                                sx={{ ml: 0 }}
                                            />
                                            <PhotoDetails
                                                key={item.Id}
                                                item={item}
                                                props={props}
                                                setSelectImagePreview={setSelectImagePreview}
                                                setOpenImagePreview={setOpenImagePreview}
                                            />
                                        </Box>
                                    </Grid>
                                )}
                                count={paging.total}
                                page={filter.page}
                                pageSize={filter.pageSize}
                                onChangePage={(page, pageSize) => {
                                    setFilter((prev) => ({ ...prev, page, pageSize }));
                                }}
                                renderEmpty={() => (
                                    <Grid item py={8} alignItems="center" justifyContent="center">
                                        <Typography
                                            variant="h2"
                                            textAlign={'center'}
                                            fontWeight={400}
                                            color="primary.dark"
                                        >
                                            {'No results found'}
                                        </Typography>
                                        <Typography variant="h6" fontWeight={400} align="center">
                                            <>
                                                We couldn't find what searched for. <br />
                                                Try searching again.
                                            </>
                                        </Typography>
                                    </Grid>
                                )}
                            />
                        </Grid>
                    </Stack>

                    {selectImagePreview && (
                        <Dialog
                            open={!!openImagePreview}
                            onClose={() => {
                                setOpenImagePreview(false);
                                setSelectImagePreview('');
                            }}
                            PaperProps={{
                                sx: {
                                    minWidth: 1280,
                                    minHeight: 720,
                                },
                            }}
                        >
                            <TransformWrapperComponent
                                customTransformComponent={
                                    <Stack
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 1280,
                                                height: 720,
                                                background: `url(${selectImagePreview}) no-repeat center center`,
                                                backgroundSize: 'cover',
                                                borderRadius: 1,
                                            }}
                                        />
                                    </Stack>
                                }
                            />
                        </Dialog>
                    )}
                </Stack>
            }
            customActions={
                <Stack direction={'column'} spacing={1} alignItems={'start'} width={'100%'}>
                    <Typography variant="body1" color={color.danger}>
                        Note: Once the process of splitting and regrouping the new PC is completed, the original PC will
                        be canceled with the reason of mismatch.
                    </Typography>
                    <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="cancel"
                            onClick={() => {
                                props.popupCancel?.handleOpen();
                                handleClosePopup();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="contained"
                            disabled={isCheckedDisableButtonConfirm}
                            onClick={handlePhotoVehicleForChangePlate}
                        >
                            Split & complete
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
}

const PhotoDetails = ({
    setSelectImagePreview,
    setOpenImagePreview,
    handleRemoveItem,
    item,
    props,
    columnId,
    isCheckIcon,
    isScrollXPosition,
}: {
    item: CameraPhotoVehicles | undefined;
    setSelectImagePreview: (url: string) => void;
    setOpenImagePreview: (open: boolean) => void;
    handleRemoveItem?: (itemId: number) => void;
    props: Props;
    columnId?: number;
    isCheckIcon?: boolean;
    isScrollXPosition?: boolean;
}) => {
    return (
        <Box bgcolor={color.white} p={1} border={1} borderColor={color.grey200} borderRadius={1} position={'relative'}>
            <Box
                sx={{
                    width: isScrollXPosition ? '200px' : '100%',
                    height: '100px',
                    background: `url(${appConfig.gateway.blobURL}${item?.CameraPhoto?.BlobName}) no-repeat center center`,
                    backgroundSize: 'cover',
                    borderRadius: 1,
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setSelectImagePreview(`${appConfig.gateway.blobURL}${item?.CameraPhoto?.BlobName}`);
                    setOpenImagePreview(true);
                }}
            />
            <Stack spacing={0.5} mt={1}>
                <Typography variant="body2">Plate: {item?.Plate || 'Unable to recognize license plate'}</Typography>
                <Typography variant="body2">
                    On date:{' '}
                    {moment(item?.CameraPhoto?.CaptureAt || 0)
                        .tz(props.pc.Timezone || moment.tz.guess())
                        .format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
                <Typography variant="body2">
                    Capture type: {item?.CameraPhoto?.CaptureType === CaptureType.trigged ? 'Triggered' : 'Command'}
                </Typography>
                <Typography variant="body2">
                    Within operational hours: {item?.CameraPhoto?.IsWithinOperationalHours}
                </Typography>
            </Stack>
            {isCheckIcon && (
                <Tooltip title="Remove item" arrow placement="top">
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 3,
                            right: 3,
                        }}
                        onClick={() => handleRemoveItem?.(item?.Id ?? 0)}
                    >
                        <CloseIcon stroke={color.white} />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};
