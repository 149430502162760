import MinimizeImageIcon from '@assets/jsx-icon/MinimizeImageIcon';
import RestartImageIcon from '@assets/jsx-icon/RestartImageIcon';
import ZoomImageIcon from '@assets/jsx-icon/ZoomImageIcon';
import { BoxRadiusGrey } from '@components/filter/FilterRoot';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Box, Button, CircularProgress, Collapse, Slider, Stack, Tooltip, Typography } from '@mui/material';
import NoDataCamera from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import Konva from 'konva';
import { RefObject, useEffect, useRef, useState } from 'react';
import { Circle, Group, Image, Image as KonvaImage, Layer, Rect, Stage, Transformer } from 'react-konva';
import useEditImage, { AnchorPoint, CropArea, Mode } from '../hooks/useEditImage';
import { PhotoItem } from './EvidencePhotoGroup';

import { Action, Point } from '@components/FramePolygonSized';
import { pushError, pushSuccess } from '@components/toast';
import { appConfig } from '@configs/index';
import {
    cameraPhotoVehicleHttpController,
    potentialGroupContraventionController,
    potentialPhotoContraventionController,
} from '@controllers/index';
import {
    CameraPhotoVehicles,
    GroupPotentialContraventions,
    PhotoType,
    PotentialContraventionPhotos,
} from '@EcamModel/model';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import _, { cloneDeep, orderBy } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { usePotentialContravention } from '../hooks/PotentialContraventionProvider';
import { adjustPreviewSize } from 'src/helpers';
import { PCInfo } from '..';
import { IUpdatePotentialContraventionPhotoVehicle } from '@EcamModel/controllers/IPotentialContraventionPhotoHttpController';
import useImage from 'use-image';

type Props = Omit<IPopUp, 'onConfirm'> & {
    selectedItem: PhotoItem;
    exitPhoto?: CameraPhotoVehicles;
    validationPhoto?: CameraPhotoVehicles;
    entryPhoto?: CameraPhotoVehicles;
};

export const mappingPhotosType = {
    [PhotoType.Entry]: 'EntryPhotos',
    [PhotoType.Validation]: 'ValidationPhotos',
    [PhotoType.Exit]: 'ExitPhotos',
    [PhotoType.Overview]: 'Overview',
};
interface DisplayStageProps {
    image: HTMLImageElement | null;
    brightness: number;
    contrast: number;
    imageRef: RefObject<Konva.Image>;
    width: number;
    height: number;
    setBase64: (base64: string) => void;
}

interface HTMLImageElementToBase64 {
    canvas: HTMLCanvasElement | undefined;
    setBase64?: (base64: string) => void;
}

//custom width and height preview
const width = 264;
const height = 150;

export default function PopUpPlateEditing(props: Props) {
    const { entryPhoto: rootEntryPhoto, exitPhoto: rootExitPhoto, validationPhoto: rootValidationPhoto } = props;

    const potentialContraventionContext = usePotentialContravention();
    const { setSelectedPc, setGroupPc, selectedPc } = potentialContraventionContext;

    const params = useParams<{ contraventionId: string; groupPcId: string }>();
    const contraventionId = parseInt(params.contraventionId || params.groupPcId || '-1');
    const backdrop = useBackdrop();
    const navigate = useNavigate();

    const imageRef = useRef<Konva.Image>(null);

    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [sized, setSized] = useState(100);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState<Point>({ x: 0, y: 0 });

    const [initCurrentImage, setInitCurrentImage] = useState<HTMLImageElement | undefined>();
    const [croppedImage, setCroppedImage] = useState<HTMLImageElement | null>(null);
    const [originalImage, setOriginalImage] = useState<(HTMLImageElement | undefined)[]>([]);
    const [typeSelected, setTypeSelected] = useState<Action>('draw');

    const [imageBase64, setImageBase64] = useState<string | null>(null);

    const { image: rootImage } = useImageDimensions(props.selectedItem.rootBlob);

    const [isCropDisabled, setIsCropDisabled] = useState(true);

    const [photosType, setPhotosType] = useState<string>('');

    const [brightness, setBrightness] = useState<number>(0);
    const [contrast, setContrast] = useState<number>(0);

    const [imageEntryBlob] = useImage(`${appConfig.gateway.blobURL}${rootEntryPhoto?.BlobName}`, 'anonymous');
    const [imageValidationsBlob] = useImage(
        `${appConfig.gateway.blobURL}${rootValidationPhoto?.BlobName}`,
        'anonymous'
    );
    const [imageExitBlob] = useImage(`${appConfig.gateway.blobURL}${rootExitPhoto?.BlobName}`, 'anonymous');

    //custom width and height original image
    const displayWidth = 585;
    const displayHeight = displayWidth * 0.5625;

    //Get the width of rootImage
    const rootImageWidth = rootImage?.width || 1920;

    // Function to convert {X1, X2, X3, X4, Y1, Y2, Y3, Y4} to CropArea
    const convertToCropArea = (coordinates: {
        X1: number;
        X2: number;
        X3: number;
        X4: number;
        Y1: number;
        Y2: number;
        Y3: number;
        Y4: number;
    }): CropArea => {
        const x = Math.min(coordinates.X1, coordinates.X3);
        const y = Math.min(coordinates.Y1, coordinates.Y2);
        const width = Math.max(coordinates.X2, coordinates.X4) - x;
        const height = Math.max(coordinates.Y3, coordinates.Y4) - y;

        return { x, y, width, height };
    };

    //Min-max limits, width-height of the cropping frame
    const MIN_WIDTH = 15;
    const MIN_HEIGHT = 15;
    const MAX_WIDTH = displayWidth - 100;
    const MAX_HEIGHT = displayHeight - 100;

    const imageUrl = appConfig.gateway.blobURL + props.selectedItem?.rootBlob;
    const [image] = useImage(imageUrl, 'anonymous');
    const currentImage = originalImage[originalImage.length - 1] || image;

    const {
        stageRef,
        cropAreaRef,
        mode,
        setMode,
        cropArea,
        setCropArea,
        handleAnchorMouseEnter,
        handleAnchorMouseLeave,
        handleDragCropArea,
        handleRectDragEnd,
        handleResetImage,
    } = useEditImage(props);

    const { X1, X2, X3, X4, Y1, Y2, Y3, Y4 } = props.selectedItem.photo ?? {};

    function calculateRectangleDimensions(x1, y1, x2, y2, x3, y3, x4, y4) {
        const width = Math.abs(x2 - x1);
        const height = Math.abs(y3 - y1);

        return { width, height };
    }

    const plate = calculateRectangleDimensions(X1, Y1, X2, Y2, X3, Y3, X4, Y4);

    //The ratio of the current image's width-height compared to the standard image (1920 x 1080).
    const scaleSize = rootImageWidth / displayWidth; //Equal to the ratio of the standard image divided by the ratio of the current image's width-height

    //black border around the cropping frame
    const adjustedCropArea = cropArea
        ? {
              x: cropArea.x,
              y: cropArea.y,
              width: cropArea.width,
              height: cropArea.height,
          }
        : null;

    const adjustedAreas = adjustedCropArea
        ? [
              {
                  x: 0,
                  y: 0,
                  width: adjustedCropArea.x,
                  height: displayHeight,
              },
              {
                  x: adjustedCropArea.x + adjustedCropArea.width,
                  y: 0,
                  width: displayWidth - adjustedCropArea.x - adjustedCropArea.width,
                  height: displayHeight,
              },
              {
                  x: adjustedCropArea.x,
                  y: 0,
                  width: adjustedCropArea.width,
                  height: adjustedCropArea.y,
              },
              {
                  x: adjustedCropArea.x,
                  y: adjustedCropArea.y + adjustedCropArea.height,
                  width: adjustedCropArea.width,
                  height: displayHeight - adjustedCropArea.y - adjustedCropArea.height,
              },
          ]
        : [];

    //Calculate the cropping frame
    const cropAnchors = [
        {
            x: cropArea?.x!,
            y: cropArea?.y!,
            onDragMove: (e: Konva.KonvaEventObject<DragEvent>) => handleDragAnchor(e, AnchorPoint.TopLeft),
        },
        {
            x: cropArea?.x! + cropArea?.width!,
            y: cropArea?.y!,
            onDragMove: (e: Konva.KonvaEventObject<DragEvent>) => handleDragAnchor(e, AnchorPoint.TopRight),
        },
        {
            x: cropArea?.x!,
            y: cropArea?.y! + cropArea?.height!,
            onDragMove: (e: Konva.KonvaEventObject<DragEvent>) => handleDragAnchor(e, AnchorPoint.BottomLeft),
        },
        {
            x: cropArea?.x! + cropArea?.width!,
            y: cropArea?.y! + cropArea?.height!,
            onDragMove: (e: Konva.KonvaEventObject<DragEvent>) => handleDragAnchor(e, AnchorPoint.BottomRight),
        },
    ];
    //Retrieve coordinates { X1, X2, X3, X4, Y1, Y2, Y3, Y4 } from the cropping frame and send them to the back-end
    const resultCrop = {
        X1: cropAnchors[0].x,
        X2: cropAnchors[1].x,
        X3: cropAnchors[2].x,
        X4: cropAnchors[3].x,
        Y1: cropAnchors[0].y,
        Y2: cropAnchors[1].y,
        Y3: cropAnchors[2].y,
        Y4: cropAnchors[3].y,
    };

    // Convert resultCrop to CropArea
    const initialCropArea: CropArea | null = convertToCropArea({
        X1: X1! / scaleSize,
        X2: X2! / scaleSize,
        X3: X3! / scaleSize,
        X4: X4! / scaleSize,
        Y1: Y1! / scaleSize,
        Y2: Y2! / scaleSize,
        Y3: Y3! / scaleSize,
        Y4: Y4! / scaleSize,
    });

    useEffect(() => {
        if (!props.open) return;

        const img = new window.Image();
        img.src = appConfig.gateway.blobURL + props.selectedItem.rootBlob;

        img.onload = () => {
            setLoading(false);
            setInitCurrentImage(img);
        };

        img.onerror = () => {
            setLoading(false);
            setIsError(true);
        };
        setCroppedImage(null);
        setCropArea(initialCropArea);
    }, [props.open]);

    useEffect(() => {
        setPhotosType(mappingPhotosType[props.selectedItem.photoType]);

        setBrightness(0);
        setContrast(0);
    }, [props.open, photosType]);

    useEffect(() => {
        setIsCropDisabled(!cropArea);
    }, [cropArea]);

    const handleDragImage = (e: any) => {
        const stage = stageRef.current;
        if (!stage) return;

        const x = stage.x();
        const y = stage.y();

        const minX = -stage.width() * (scale - 1);
        const minY = -stage.height() * (scale - 1);

        if (x > 0 || y > 0 || x < minX || y < minY) {
            stage.position(position);
        } else {
            setPosition({ x, y });
        }
    };

    const handleResetZoom = () => {
        const newScale = 1;
        const stage = stageRef.current;
        if (!stage) return;

        const newX = (stage.width() / 2) * (1 - newScale);
        const newY = (stage.height() / 2) * (1 - newScale);

        stage.scale({ x: newScale, y: newScale });
        stage.position({ x: newX, y: newY });
        stage.batchDraw();

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setSized(100);
    };

    // Image cropping function
    const handleCropImage = () => {
        if (!currentImage || !cropArea) return;

        const imageWidth = currentImage.width;
        const imageHeight = currentImage.height;

        const cropX = (cropArea.x / displayWidth) * imageWidth;
        const cropY = (cropArea.y / displayHeight) * imageHeight;
        const cropWidth = (cropArea.width / displayWidth) * imageWidth;
        const cropHeight = (cropArea.height / displayHeight) * imageHeight;

        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        const ctx = croppedCanvas.getContext('2d')!;
        ctx.drawImage(currentImage, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

        const croppedImage = new window.Image();
        croppedImage.src = croppedCanvas.toDataURL();

        // Cập nhật hình ảnh đã cắt
        setCroppedImage(croppedImage);

        croppedImage.onload = () => {
            if (!originalImage) {
                setOriginalImage((prev) => [...prev, croppedImage]);
            }
            setIsCropDisabled(true);

            const dataURL = croppedCanvas.toDataURL('image/jpeg');
            setImageBase64(dataURL);
        };

        setBrightness(0);
        setContrast(0);
    };

    const checkBoundary = (newCropArea: CropArea) => {
        if (newCropArea.x < 0) {
            newCropArea.x = 0;
        }
        if (newCropArea.y < 0) {
            newCropArea.y = 0;
        }
        if (newCropArea.x + newCropArea.width > displayWidth) {
            newCropArea.x = displayWidth - newCropArea.width;
        }
        if (newCropArea.y + newCropArea.height > displayHeight) {
            newCropArea.y = displayHeight - newCropArea.height;
        }
    };

    const setScaleAndPosition = (newScale: number, newSized: number) => {
        const stage = stageRef.current;
        if (!stage) return;

        const newX = (stage.width() / 2) * (1 - newScale);
        const newY = (stage.height() / 2) * (1 - newScale);

        stage.scale({ x: newScale, y: newScale });
        stage.position({ x: newX, y: newY });
        stage.batchDraw();

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setSized(newSized);
    };

    const handleZoomIn = () => {
        if (sized < 200) {
            let newSized = Math.min(sized + 5, 200);
            let newScale = newSized / 100;
            setScaleAndPosition(newScale, newSized);
        }
    };

    //Function to move the cropping frame, zoom in and out of the cropping frame
    const handleDragAnchor = (e: Konva.KonvaEventObject<DragEvent>, anchor: AnchorPoint) => {
        if (!cropArea) return;

        const { x, y } = e.target.position();
        let newCropArea: CropArea = { ...cropArea };

        switch (anchor) {
            case AnchorPoint.TopLeft:
                newCropArea.width = cropArea.x + cropArea.width - x;
                newCropArea.height = cropArea.y + cropArea.height - y;
                newCropArea.x = x;
                newCropArea.y = y;
                if (newCropArea.width < MIN_WIDTH || newCropArea.height < MIN_HEIGHT) {
                    e.target.position({ x: cropArea.x, y: cropArea.y }); // Update position for the Circle component
                }
                break;
            case AnchorPoint.TopRight:
                newCropArea.width = x - cropArea.x;
                newCropArea.height = cropArea.y + cropArea.height - y;
                newCropArea.y = y;
                if (newCropArea.width < MIN_WIDTH || newCropArea.height < MIN_HEIGHT) {
                    e.target.position({ x: cropArea.x + cropArea.width, y: cropArea.y }); // Update position for the Circle component
                }
                break;
            case AnchorPoint.BottomLeft:
                newCropArea.width = cropArea.x + cropArea.width - x;
                newCropArea.height = y - cropArea.y;
                newCropArea.x = x;
                if (newCropArea.width < MIN_WIDTH || newCropArea.height < MIN_HEIGHT) {
                    e.target.position({ x: cropArea.x, y: cropArea.y + cropArea.height }); // Update position for the Circle component
                }
                break;
            case AnchorPoint.BottomRight:
                newCropArea.width = x - cropArea.x;
                newCropArea.height = y - cropArea.y;
                if (newCropArea.width < MIN_WIDTH || newCropArea.height < MIN_HEIGHT) {
                    e.target.position({ x: cropArea.x + cropArea.width, y: cropArea.y + cropArea.height }); // Update position for the Circle component
                }
                break;
            default:
                break;
        }

        // Ensure new dimensions are within limits
        newCropArea.width = Math.max(MIN_WIDTH, Math.min(newCropArea.width, MAX_WIDTH));
        newCropArea.height = Math.max(MIN_HEIGHT, Math.min(newCropArea.height, MAX_HEIGHT));
        checkBoundary(newCropArea);
        setCropArea(newCropArea);
    };

    const handleZoomOut = () => {
        if (sized > 100) {
            let newSized = Math.max(sized - 5, 100);
            let newScale = newSized / 100;
            setScaleAndPosition(newScale, newSized);
        }
    };

    const handleReset = () => {
        handleResetImage();
        handleResetZoom();
        setCropArea(initialCropArea);
        setLoading(true);
        setBrightness(0);
        setContrast(0);
    };

    const handleBrightnessChange = (event: Event, newValue: number | number[]) => {
        setBrightness(newValue as number);
        setIsCropDisabled(true);
    };

    const handleContrastChange = (event: Event, newValue: number | number[]) => {
        setContrast(newValue as number);
        setIsCropDisabled(true);
    };

    const applyFilters = (image: HTMLImageElement) => {
        image.crossOrigin = 'anonymous';
        if (imageRef.current) {
            imageRef.current.cache();
            imageRef.current.filters([Konva.Filters.Brighten, Konva.Filters.Contrast]);
            imageRef.current.brightness(brightness || 0);
            imageRef.current.contrast(contrast || 0);
            imageRef.current.getLayer()?.batchDraw();

            const layer = imageRef.current.getLayer();
            if (layer) {
                const canvas = layer.getCanvas()._canvas;
                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = canvas.width;
                tempCanvas.height = canvas.height;
                const ctx = tempCanvas.getContext('2d');
                if (ctx) {
                    ctx.fillStyle = color.white;
                    ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
                    ctx.drawImage(canvas, 0, 0);
                    const dataURL = tempCanvas.toDataURL('image/jpeg');
                    setImageBase64(dataURL);
                }
            }
        }
    };
    useEffect(() => {
        if (currentImage && imageRef.current) {
            applyFilters(currentImage);
        }
    }, [brightness, contrast, currentImage]);

    const handleUpdatePhotoVehicleWidthBrightnessAndContrast = async () => {
        const selectedItem = props.selectedItem;

        const _filter: IUpdatePotentialContraventionPhotoVehicle = {
            brightness: brightness,
            contrast: contrast,
            imageBase64: imageBase64 ?? '',
            photoVehicleId: selectedItem?.photo?.Id! ?? undefined,
            photoType: selectedItem.photoType,
        };
        await potentialPhotoContraventionController
            .updatePhotoVehicle(_filter)
            .then((res) => {
                setSelectedPc((prevSelectedPc) => {
                    if (!prevSelectedPc) return prevSelectedPc;
                    if (prevSelectedPc.Id === res.PotentialContraventionId) {
                        return {
                            ...prevSelectedPc,
                            PotentialContraventionPhotos: prevSelectedPc.PotentialContraventionPhotos?.map((photo) =>
                                photo.CameraPhotoVehicleId === res.CameraPhotoVehicleId ? res : photo
                            ),
                        };
                    }
                    return prevSelectedPc;
                });
            })
            .catch((error) => {
                pushError(error?.response?.data?.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    //Call API cropping image
    const handleUpsertPhotoVehicle = async () => {
        backdrop.setTrue();

        const selectedItem = props.selectedItem?.photo;
        const { X1, X2, X3, X4, Y1, Y2, Y3, Y4 } = resultCrop;

        const photoVehicle: CameraPhotoVehicles = {
            CameraPhotoId: selectedItem?.CameraPhotoId ?? 0,
            Plate: selectedItem?.Plate ?? '',
            Id: selectedItem?.Id,
            X1: X1 * scaleSize,
            X2: X2 * scaleSize,
            X3: X3 * scaleSize,
            X4: X4 * scaleSize,
            Y1: Y1 * scaleSize,
            Y2: Y2 * scaleSize,
            Y3: Y3 * scaleSize,
            Y4: Y4 * scaleSize,
        };

        await cameraPhotoVehicleHttpController
            .updateCameraPhotoVehicleCoordinates(photoVehicle)
            .then(async (res) => {
                await handleUpdatePhotoVehicleWidthBrightnessAndContrast();
                backdrop.setTrue();
                await potentialGroupContraventionController
                    .getGroupPotentialContravention(contraventionId)
                    .then(async (res) => {
                        props.onClose?.();
                        pushSuccess('Saved successfully');
                        const _res: GroupPotentialContraventions = {
                            ...res,
                            PotentialContraventions: orderBy(res.PotentialContraventions, (pc) => -pc.Step),
                        };
                        setGroupPc(_res);
                        setSelectedPc(_res.PotentialContraventions![0] as PCInfo);
                    })
                    .catch((err) => navigate('/verification'))
                    .finally(() => {
                        backdrop.setFalse();
                    });
            })
            .catch((error) => {
                pushError(error?.response?.data?.message);
            })
            .finally(() => {
                backdrop.setFalse();
                handleReset();
            });
    };

    const { previewWidth, previewHeight } = adjustPreviewSize(plate.width, plate.height, width!, height!);

    const isBrightOrContrastZeroEntry = rootEntryPhoto?.Brightness === 0 && rootEntryPhoto?.Contrast === 0;

    const isBrightOrContrastZeroValidation =
        rootValidationPhoto?.Brightness === 0 && rootValidationPhoto?.Contrast === 0;

    const isBrightOrContrastZeroExit = rootExitPhoto?.Brightness === 0 && rootExitPhoto?.Contrast === 0;

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        maxWidth: '933px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Stack>
                    <Typography variant="h4">Plate editing</Typography>
                    <Typography color={color.grey600}>
                        You can customize the plate cropping to get an accurate and suitable picture
                    </Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <BoxRadiusGrey>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <Stack direction={'column'} spacing={2} alignItems={'start'}>
                            <Typography variant="body1">Original image</Typography>
                            {loading ? (
                                <Stack height={displayHeight} width={displayWidth}>
                                    <CircularProgress size={20} sx={{ margin: 'auto' }} />
                                </Stack>
                            ) : (
                                <>
                                    {isError ? (
                                        <Stack width={displayWidth} flexBasis={1} justifyContent="center">
                                            <NoDataCamera />
                                        </Stack>
                                    ) : (
                                        <Box>
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                width="100%"
                                                sx={{
                                                    p: '6px',
                                                    background: 'rgba(0, 0, 0, 1)',
                                                    position: 'relative',
                                                }}
                                                alignItems="center"
                                                spacing={1.5}
                                            >
                                                {[
                                                    {
                                                        Label: 'Zoom in',
                                                        Icon: <ZoomImageIcon isChecked color={color.white} />,
                                                        onClick: () => {
                                                            handleZoomIn();
                                                            setTypeSelected('move');
                                                        },
                                                    },
                                                    {
                                                        Label: 'Reset to the original',
                                                        Icon: <RestartImageIcon isChecked color={color.white} />,
                                                        onClick: () => {
                                                            handleResetZoom();
                                                        },
                                                    },
                                                    {
                                                        Label: 'Zoom out',
                                                        Icon: <MinimizeImageIcon isChecked color={color.white} />,
                                                        onClick: () => {
                                                            handleZoomOut();
                                                            setTypeSelected('move');
                                                        },
                                                    },
                                                ].map((item, index) => {
                                                    return (
                                                        <Tooltip
                                                            title={item.Label}
                                                            placement="top"
                                                            arrow
                                                            key={`${index}+action`}
                                                        >
                                                            <Stack
                                                                sx={{
                                                                    color: '#fff',
                                                                    fontSize: 16,
                                                                    cursor: 'pointer',
                                                                    borderRadius: '50%',
                                                                    '&:hover': {
                                                                        background: 'rgba(255, 255, 255, 0.4)',
                                                                        borderRadius: '50%',
                                                                        transition: '.3s ease-in-out',
                                                                    },
                                                                }}
                                                                onClick={item.onClick}
                                                            >
                                                                {item.Icon}
                                                            </Stack>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Stack>
                                            <Stage
                                                width={displayWidth}
                                                height={displayHeight}
                                                onDragEnd={handleDragImage}
                                                onDragMove={handleDragImage}
                                                draggable={Boolean(typeSelected === 'move')}
                                                ref={stageRef}
                                                scaleX={scale}
                                                scaleY={scale}
                                                x={position.x}
                                                y={position.y}
                                            >
                                                <Layer>
                                                    <Group>
                                                        <Image
                                                            image={initCurrentImage}
                                                            width={displayWidth}
                                                            height={displayHeight}
                                                            draggable
                                                        />
                                                    </Group>
                                                </Layer>
                                                <Layer>
                                                    <Group name="crop">
                                                        {mode === Mode.Crop && cropArea && (
                                                            <>
                                                                {adjustedAreas.map((item, index) => {
                                                                    return (
                                                                        <Rect
                                                                            key={`${index}+rect`}
                                                                            x={item.x}
                                                                            y={item.y}
                                                                            width={item.width}
                                                                            height={item.height}
                                                                            fill="rgba(0, 0, 0, 0.4)"
                                                                        />
                                                                    );
                                                                })}

                                                                <Rect
                                                                    ref={cropAreaRef}
                                                                    {...cropArea}
                                                                    stroke="#fff"
                                                                    strokeWidth={2}
                                                                    draggable
                                                                    onDragMove={handleDragCropArea}
                                                                    onDragEnd={handleRectDragEnd}
                                                                    onMouseEnter={() => {
                                                                        handleAnchorMouseEnter(true);
                                                                    }}
                                                                    onMouseLeave={handleAnchorMouseLeave}
                                                                />
                                                                {cropAnchors.map((anchor, index) => {
                                                                    return (
                                                                        <Circle
                                                                            key={`${index}+circle`}
                                                                            x={anchor.x}
                                                                            y={anchor.y}
                                                                            radius={8}
                                                                            fill="red"
                                                                            draggable
                                                                            width={5}
                                                                            onDragMove={anchor.onDragMove}
                                                                            onMouseEnter={() => {
                                                                                handleAnchorMouseEnter(false);
                                                                            }}
                                                                            onMouseLeave={handleAnchorMouseLeave}
                                                                        />
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                    </Group>
                                                </Layer>
                                            </Stage>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Stack>

                        <Stack width={'100%'} direction={'column'} spacing={1.5} alignItems={'start'}>
                            <Typography variant="body1" textAlign={'left'}>
                                Preview
                            </Typography>
                            {loading ? (
                                <Stack minHeight={100} width={'100%'}>
                                    <CircularProgress size={20} sx={{ margin: 'auto' }} />
                                </Stack>
                            ) : (
                                <>
                                    {isError ? (
                                        <Stack width={'100%'} justifyContent="center">
                                            <NoDataCamera />
                                        </Stack>
                                    ) : (
                                        <>
                                            {croppedImage !== null ? (
                                                <DisplayStage
                                                    imageRef={imageRef}
                                                    image={croppedImage}
                                                    brightness={brightness}
                                                    contrast={contrast}
                                                    width={width}
                                                    height={height}
                                                    setBase64={setImageBase64}
                                                />
                                            ) : (
                                                <>
                                                    {rootEntryPhoto?.BlobName !== null &&
                                                    photosType === 'EntryPhotos' ? (
                                                        <Stage width={width} height={height}>
                                                            <Layer>
                                                                <KonvaImage
                                                                    ref={imageRef}
                                                                    image={imageEntryBlob}
                                                                    width={
                                                                        isBrightOrContrastZeroEntry
                                                                            ? previewWidth
                                                                            : undefined
                                                                    }
                                                                    height={
                                                                        isBrightOrContrastZeroEntry
                                                                            ? previewHeight
                                                                            : undefined
                                                                    }
                                                                    x={
                                                                        (
                                                                            isBrightOrContrastZeroEntry
                                                                                ? previewWidth < previewHeight ||
                                                                                  isBrightOrContrastZeroEntry
                                                                                : previewWidth < previewHeight &&
                                                                                  isBrightOrContrastZeroEntry
                                                                        )
                                                                            ? (width - previewWidth) / 2
                                                                            : undefined
                                                                    }
                                                                    y={
                                                                        previewWidth > previewHeight &&
                                                                        isBrightOrContrastZeroEntry
                                                                            ? (height - previewHeight) / 2
                                                                            : undefined
                                                                    }
                                                                    filters={[
                                                                        Konva.Filters.Brighten,
                                                                        Konva.Filters.Contrast,
                                                                    ]}
                                                                    brightness={brightness}
                                                                    contrast={contrast}
                                                                />
                                                            </Layer>
                                                        </Stage>
                                                    ) : rootValidationPhoto?.BlobName !== null &&
                                                      photosType === 'ValidationPhotos' ? (
                                                        <Stage width={width} height={height}>
                                                            <Layer>
                                                                <KonvaImage
                                                                    ref={imageRef}
                                                                    image={imageValidationsBlob}
                                                                    width={
                                                                        isBrightOrContrastZeroValidation
                                                                            ? previewWidth
                                                                            : undefined
                                                                    }
                                                                    height={
                                                                        isBrightOrContrastZeroValidation
                                                                            ? previewHeight
                                                                            : undefined
                                                                    }
                                                                    x={
                                                                        (
                                                                            isBrightOrContrastZeroValidation
                                                                                ? previewWidth < previewHeight ||
                                                                                  isBrightOrContrastZeroValidation
                                                                                : previewWidth < previewHeight &&
                                                                                  isBrightOrContrastZeroValidation
                                                                        )
                                                                            ? (width - previewWidth) / 2
                                                                            : undefined
                                                                    }
                                                                    y={
                                                                        previewWidth > previewHeight &&
                                                                        isBrightOrContrastZeroValidation
                                                                            ? (height - previewHeight) / 2
                                                                            : undefined
                                                                    }
                                                                    filters={[
                                                                        Konva.Filters.Brighten,
                                                                        Konva.Filters.Contrast,
                                                                    ]}
                                                                    brightness={brightness}
                                                                    contrast={contrast}
                                                                />
                                                            </Layer>
                                                        </Stage>
                                                    ) : rootExitPhoto?.BlobName !== null &&
                                                      photosType === 'ExitPhotos' ? (
                                                        <Stage width={width} height={height}>
                                                            <Layer>
                                                                <KonvaImage
                                                                    ref={imageRef}
                                                                    image={imageExitBlob}
                                                                    width={
                                                                        isBrightOrContrastZeroExit
                                                                            ? previewWidth
                                                                            : undefined
                                                                    }
                                                                    height={
                                                                        isBrightOrContrastZeroExit
                                                                            ? previewHeight
                                                                            : undefined
                                                                    }
                                                                    x={
                                                                        (
                                                                            isBrightOrContrastZeroExit
                                                                                ? previewWidth < previewHeight ||
                                                                                  isBrightOrContrastZeroExit
                                                                                : previewWidth < previewHeight &&
                                                                                  isBrightOrContrastZeroExit
                                                                        )
                                                                            ? (width - previewWidth) / 2
                                                                            : undefined
                                                                    }
                                                                    y={
                                                                        previewWidth > previewHeight &&
                                                                        isBrightOrContrastZeroExit
                                                                            ? (height - previewHeight) / 2
                                                                            : undefined
                                                                    }
                                                                    filters={[
                                                                        Konva.Filters.Brighten,
                                                                        Konva.Filters.Contrast,
                                                                    ]}
                                                                    brightness={brightness}
                                                                    contrast={contrast}
                                                                />
                                                            </Layer>
                                                        </Stage>
                                                    ) : rootEntryPhoto?.BlobName === null ||
                                                      rootValidationPhoto?.BlobName === null ||
                                                      rootExitPhoto?.BlobName === null ? (
                                                        <Stage width={width} height={height}>
                                                            <Layer>
                                                                <Rect width={width} height={height} fill="white" />
                                                                <KonvaImage
                                                                    ref={imageRef}
                                                                    image={currentImage}
                                                                    x={(width - previewWidth) / 2}
                                                                    y={(height - previewHeight) / 2}
                                                                    width={previewWidth}
                                                                    height={previewHeight}
                                                                    crop={{
                                                                        x: X1!,
                                                                        y: Y1!,
                                                                        width: plate.width,
                                                                        height: plate.height,
                                                                    }}
                                                                    filters={[
                                                                        Konva.Filters.Brighten,
                                                                        Konva.Filters.Contrast,
                                                                    ]}
                                                                    brightness={brightness}
                                                                    contrast={contrast}
                                                                />
                                                            </Layer>
                                                        </Stage>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            <Stack direction={'column'} width={'100%'}>
                                <Collapse in={!loading}>
                                    <Box>
                                        <Typography variant="body2">Brightness</Typography>
                                        <Slider
                                            value={brightness}
                                            onChange={handleBrightnessChange}
                                            onClick={() => {
                                                if (currentImage && imageRef.current) {
                                                    applyFilters(currentImage);
                                                }
                                            }}
                                            aria-labelledby="brightness-slider"
                                            valueLabelFormat={(value) => `${value}%`}
                                            valueLabelDisplay="auto"
                                            min={-1}
                                            max={1}
                                            step={0.1}
                                            size="small"
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body2">Contrast</Typography>
                                        <Slider
                                            value={contrast}
                                            onChange={handleContrastChange}
                                            onClick={() => {
                                                if (currentImage && imageRef.current) {
                                                    applyFilters(currentImage);
                                                }
                                            }}
                                            aria-labelledby="contrast-slider"
                                            valueLabelFormat={(value) => `${value}%`}
                                            valueLabelDisplay="auto"
                                            min={-100}
                                            max={100}
                                            step={10}
                                            size="small"
                                        />
                                    </Box>
                                </Collapse>
                            </Stack>

                            {!isError && (
                                <>
                                    <Button
                                        sx={{ minWidth: 264, border: '1px solid #E4E7EC' }}
                                        variant="contained"
                                        onClick={handleCropImage}
                                    >
                                        Crop to preview
                                    </Button>
                                    <Button
                                        sx={{ minWidth: 264 }}
                                        variant="outlinedGreen"
                                        onClick={() => {
                                            setCroppedImage(null);
                                            setIsCropDisabled(false);
                                            setCropArea(initialCropArea);
                                            setBrightness(
                                                selectedPc?.[photosType]?.[selectedPc?.[photosType]?.length! - 1]
                                                    .Brightness ?? 0
                                            );
                                            setContrast(
                                                selectedPc?.[photosType]?.[selectedPc?.[photosType]?.length! - 1]
                                                    .Contrast ?? 0
                                            );
                                        }}
                                    >
                                        Reset to the original image
                                    </Button>
                                </>
                            )}
                        </Stack>
                    </Stack>
                </BoxRadiusGrey>
            }
            customActions={
                <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            handleReset();
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={!isCropDisabled}
                        onClick={() => {
                            handleUpsertPhotoVehicle();
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}

const DisplayStage: React.FC<DisplayStageProps> = ({
    image,
    brightness,
    contrast,
    imageRef,
    width,
    height,
    setBase64,
}) => {
    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.cache();
            imageRef.current.filters([Konva.Filters.Brighten, Konva.Filters.Contrast]);
            imageRef.current.brightness(brightness || 0);
            imageRef.current.contrast(contrast || 0);
            imageRef.current.getLayer()?.batchDraw();

            const canvas = imageRef.current.getLayer()?.getCanvas()._canvas;
            if (canvas) {
                const dataURL = canvas.toDataURL('image/jpeg');
                setBase64(dataURL);
            }
        }
    }, [brightness, contrast, image]);

    const { previewWidth, previewHeight } = adjustPreviewSize(image?.width || 0, image?.height || 0, width, height);

    return (
        <>
            {!image ? null : (
                <Stage width={width} height={height}>
                    <Layer>
                        <Rect width={width} height={height} fill="white" />
                        <KonvaImage
                            ref={imageRef}
                            x={(width - previewWidth) / 2}
                            y={(height - previewHeight) / 2}
                            width={previewWidth}
                            height={previewHeight}
                            image={image}
                            filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
                            brightness={brightness}
                            contrast={contrast}
                        />
                    </Layer>
                </Stage>
            )}
        </>
    );
};
