import ICNote from '@assets/jsx-icon/ICNote';
import BaseListNote, { tableHeaders } from '@components/BaseListNote';
import StyledDateRangePicker from '@components/date-range-select/StyledDateRangePicker';
import { CellOfRow } from '@components/tables';
import { pushError } from '@components/toast';
import { cameraNoteHttpController } from '@controllers/index';
import { Paging } from '@Core';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import { Grid, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Filter } from '@pages/cameras/list';
import { useContext, useEffect, useState } from 'react';
import { formatDateTime, getFirstAndLastDayOfCurrentWeek } from 'src/helpers';
import { PotentialContraventionContext } from '../hooks/PotentialContraventionProvider';
import DetailInfoSection from './DetailInfoSection';

export default function NoteOPs() {
    const pcContext = useContext(PotentialContraventionContext);

    const { groupPc } = pcContext;

    const [filter, setFilter] = useState<Partial<Filter>>({
        page: 1,
        from: undefined,
        to: undefined,
        search: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [listNotes, setListNotes] = useState<Paging<CameraNotes>>({} as Paging<CameraNotes>);

    const handleGetListNote = async () => {
        setIsLoading(true);
        await cameraNoteHttpController
            .list({
                page: filter.page ?? 1,
                CameraId: groupPc?.PotentialContraventions![0].CameraId ?? 0,
                pageSize: 8,
                From: filter.from ?? undefined,
                To: filter.to ?? undefined,
                filter: {
                    CameraId: groupPc?.PotentialContraventions![0].CameraId ?? 0,
                },
                sorts: ['-Updated'],
            })
            .then((res) => {
                setListNotes(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleGetListNote();
    }, [filter.from, filter.to, filter.page]);

    return (
        <>
            {!!listNotes?.rows?.length && (
                <DetailInfoSection
                    icon={<ICNote />}
                    title="OPs note"
                    action={
                        <>
                            <Grid container xs={9} justifyContent={'flex-end'}>
                                <Grid xs={5}>
                                    <StyledDateRangePicker
                                        value={{
                                            startDate: filter?.from || undefined,
                                            endDate: filter?.to || undefined,
                                        }}
                                        onChange={(value) => {
                                            setFilter({
                                                from: value.startDate,
                                                to: value.endDate,
                                                page: 1,
                                            });
                                        }}
                                        dateReset={{ startDate: undefined, endDate: undefined }}
                                        label={'Select date'}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                    content={
                        <BaseListNote
                            paging={listNotes}
                            isLoading={isLoading}
                            tableHeaders={tableHeaders}
                            styleContainer={{ mt: 2, mb: 5 }}
                            setPage={(page) => {
                                setFilter((prev) => ({ ...prev, page }));
                            }}
                            renderedItem={(item) => {
                                return (
                                    <>
                                        <CellOfRow value={formatDateTime(item.Created!)} align="left" />
                                        <CellOfRow value={item.CreatedBy ?? ''} align="left" />
                                        <CellOfRow value={formatDateTime(item.Updated!)} align="left" />
                                        <TableCell
                                            align="left"
                                            sx={{
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-wrap',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            <Typography>{item.Notes.trim() ?? ''}</Typography>
                                        </TableCell>
                                    </>
                                );
                            }}
                        />
                    }
                />
            )}
        </>
    );
}
