import Konva from 'konva';
import { useEffect, useRef } from 'react';
import { Group, Image } from 'react-konva';

type Shape = {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
};

export interface BlurShapeProps {
    shape: Shape;
    imageSrc?: HTMLImageElement;
    imageWidth: number;
    imageHeight: number;
    filterKind?: 'blur' | 'pixelate';
    filterSize?: number;
}

const BlurShape = ({ shape, imageWidth, imageHeight, imageSrc, filterSize }: BlurShapeProps) => {
    const imageRef = useRef<Konva.Image>(null);

    useEffect(() => {
        if (imageSrc) {
            imageRef.current?.cache();
        }
    }, [imageSrc]);

    if (!imageSrc) return null;

    const getClip = () => {
        let x = shape.x;
        let y = shape.y;
        let height = shape.height;
        let width = shape.width;

        if (height < 0) {
            height *= -1;
            y = y - height;
        }
        if (width < 0) {
            width *= -1;
            x = x - width;
        }

        return {
            x,
            y,
            height,
            width,
        };
    };

    const clip = getClip();

    const getFilterProps = () => {
        return {
            filters: [Konva.Filters.Pixelate],
            pixelSize: filterSize,
        };
    };

    return (
        <Group clip={clip} key={shape.id}>
            <Image {...getFilterProps()} height={imageHeight} image={imageSrc} ref={imageRef} width={imageWidth} />
        </Group>
    );
};

export default BlurShape;
