import { Typography } from '@mui/material';
import { formatDateByTimeZone } from 'src/helpers';

type Props = {
    date?: Date;
    timezone?: string;
};

export default function TimeOnPicture(props: Props) {
    return (
        <Typography
            sx={{
                color: 'red',
                // WebkitTextStroke: '0.6px white',
                whiteSpace: 'nowrap',
                position: 'absolute',
                bottom: 4,
                right: 8,
                lineHeight: '1rem',
            }}
        >
            {formatDateByTimeZone(props.date || new Date(), props.timezone, true)}
        </Typography>
    );
}
