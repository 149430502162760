export const getCenterFrame = (frame) => {
    let totalX = 0,
        totalY = 0;
    for (let i = 0; i < frame.length; i++) {
        totalX += frame[i].x;
        totalY += frame[i].y;
    }
    return {
        x: totalX / frame.length,
        y: totalY / frame.length,
    };
};
export const getRandomPolygon = (width: number, height: number, heightOffset: number, size: number) => {
    const XPoint = Math.random() * (width - size);
    const YPoint = Math.random() * (height - heightOffset - size) + heightOffset;
    return [
        { XPoint: XPoint, YPoint: YPoint, Index: 1 },
        { XPoint: XPoint + size, YPoint: YPoint, Index: 2 },
        { XPoint: XPoint + size, YPoint: YPoint + size, Index: 3 },
        { XPoint: XPoint, YPoint: YPoint + size, Index: 4 },
        { XPoint: XPoint, YPoint: YPoint, Index: 5 },
    ];
};
export const getGracePeriods = (timeStr: string) => {
    // If it's just a number, return it as seconds
    if (/^[1-9]\d*$/.test(timeStr)) {
        return parseInt(timeStr, 10);
    }
    let result = 0;
    if (timeStr) {
        let hours = 0,
            minutes = 0,
            seconds = 0;
        let timeArray = timeStr.split(' ');
        for (let i = 0; i < timeArray.length; i++) {
            if (timeArray[i].includes('h')) {
                hours = parseInt(timeArray[i], 10);
            }
            if (timeArray[i].includes('m')) {
                minutes = parseInt(timeArray[i], 10);
            }
            if (timeArray[i].includes('s')) {
                seconds = parseInt(timeArray[i], 10);
            }
        }

        result = hours * 3600 + minutes * 60 + seconds;
    }
    return result;
};
export const convertGracePeriodToString = (gracePeriod: number) => {
    let timeStr = '';
    let hours = Math.floor(gracePeriod / 3600);
    gracePeriod %= 3600;

    let minutes = Math.floor(gracePeriod / 60);

    let seconds = gracePeriod % 60;

    if (hours === 0 && minutes === 0) {
        timeStr = `${seconds}`;
    } else {
        timeStr = `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}m` : ''} ${
            seconds > 0 ? `${seconds}s` : ''
        }`;
    }
    return timeStr.trim();
};

export const validateTypingInput = (value: string) => {
    if (value.trim() === '') {
        return 'Invalid value.';
    }
    if (/[!@#$%^&*(),.;?":{}|<>]/.test(value)) {
        return 'Invalid value.';
    }
    return true;
};

export const validateTypingIPAddress = (value: string) => {
    if (value.trim() === '') {
        return 'Invalid value.';
    }
    if (/[!@#$%^&*(),;?"{}|<>]/.test(value)) {
        return 'Invalid value.';
    }
    return true;
};
