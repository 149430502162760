import { Solar4gCamera } from '@EcamModel/model';
import { pushError } from '@components/toast';
import { solar4gCameraHttpController } from '@controllers/index';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function useCaptureManagementProvider() {
    const [solar4gCamera, setSolar4gCamera] = useState<Solar4gCamera>({
        Solar4gCaptureInfo: {
            Width: 1920,
            Height: 1080,
        },
        Solar4gSchedule: {
            Trigger: '',
        },
    } as Solar4gCamera);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getSolar4gCameraInfo = async (cameraId: number) => {
        setIsLoading(true);
        await solar4gCameraHttpController
            .getLatestInfoByCameraId(cameraId)
            .then((res) => {
                setSolar4gCamera(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const { id } = useParams();

    useEffect(() => {
        getSolar4gCameraInfo(Number(id));
    }, []);

    const Solar4gCaptureInfo = solar4gCamera.Solar4gCaptureInfo;

    const Solar4gSchedule = solar4gCamera.Solar4gSchedule;

    const Solar4gAlarmROI = solar4gCamera.Solar4gAlarmROI;

    const partsTimeTrigger = Solar4gSchedule?.Trigger?.split(';');

    const enableValueInContinuousCapture = Solar4gAlarmROI?.Region[0]?.enable;

    const valueImageSettings: string =
        Solar4gCaptureInfo?.Width !== null || Solar4gCaptureInfo?.Height !== null
            ? `${Solar4gCaptureInfo?.Width} x ${Solar4gCaptureInfo?.Height}`
            : '';

    const cellularStatus = solar4gCamera.Solar4gCellularStatus;
    const cellularSettings = solar4gCamera.Solar4gCellularInfo;
    const systemTimeMilesight = solar4gCamera.Solar4gTimeInfo;

    return {
        valueImageSettings,
        isLoading,

        solar4gCamera,
        Solar4gCaptureInfo,
        Solar4gSchedule,
        Solar4gAlarmROI,

        enableValueInContinuousCapture,

        cellularStatus,
        cellularSettings,
        systemTimeMilesight,

        partsTimeTrigger,
        setSolar4gCamera,
    };
}
type CaptureManagementContextType = ReturnType<typeof useCaptureManagementProvider>;

export const CaptureManagementContext = createContext<CaptureManagementContextType>({} as CaptureManagementContextType);

export const useCaptureManagement = () => useContext(CaptureManagementContext);
