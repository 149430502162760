import { CameraPhotoVehicles, CancellationReasons, PhotoType, PhotoVersion } from '@EcamModel/model';
import MinimizeImageIcon from '@assets/jsx-icon/MinimizeImageIcon';
import RestartImageIcon from '@assets/jsx-icon/RestartImageIcon';
import ZoomImageIcon from '@assets/jsx-icon/ZoomImageIcon';
import { appConfig } from '@configs/index';
import usePopUp from '@hooks/usePopUp';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Radio,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import color from '@theme/Colors';
import Konva from 'konva';
import moment from 'moment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Image as KonvaImage, Layer, Stage } from 'react-konva';
import { PCInfo } from '..';
import EvidencePhotoItem from './EvidencePhotoItem';
import PopUpEditImage from './PopUpEditImage';
import TimeOnPicture from './TimeOnPicture';

import { ICancelGroupPotentialContraventionDetail } from '@EcamModel/controllers/IGroupPotentialContraventionHttpController';
import BaseCheckbox from '@components/BaseCheckbox';
import { useParams } from 'react-router-dom';
import { TransformComponent, TransformWrapper, useControls } from 'react-zoom-pan-pinch';
import { OrangeStack } from './PCDetail';
import PopUpPlateEditing from './PopUpPlateEditing';
import PopUpWarning from '@components/PopUpWarning';
import { VerificationAudit } from '@EcamModel/model/VerificationAudit';

export type PhotoItem = {
    title: string;
    blob?: string;
    timeZone: string;
    date: Date | undefined;
    plate: string;
    photo: CameraPhotoVehicles | undefined;
    photoType: PhotoType;
    photoVersion: PhotoVersion;
    photoVehicleId: number;
    rootBlob: string;
};

type Props = {
    readonly?: boolean;
    pc: PCInfo;
    selected?: boolean;
    onSelect?(pc: PCInfo): any;
    cancellationReasons: CancellationReasons[];
    handleCancelPc?: ((props: ICancelGroupPotentialContraventionDetail) => void) | undefined;
    VerificationAudit?: VerificationAudit;
};

interface TransformWrapperComponentProps {
    handleTransform?: (e: any) => void;
    loading?: boolean;
    displayWidth?: number;
    displayHeight?: number;
    scale?: number;
    scaleZoomChangeCursor?: number;
    image?: any;
    stageRef?: React.RefObject<any>;
    customTransformComponent?: React.ReactNode;
}

const formatDuration = (from, to) => {
    const fromTime = moment(from);
    const toTime = moment(to);
    const duration = moment.duration(toTime.diff(fromTime));

    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const convertNum = (num: number) => {
        return String(num).padStart(2, '0');
    };
    const formattedDuration = `${convertNum(hours)}h ${convertNum(minutes)}m ${convertNum(seconds)}s`;

    return formattedDuration;
};

export const pcImage = (pc: PCInfo) => {
    const entryPhoto = pc.EntryPhotos?.[pc.EntryPhotos.length - 1];
    const validationPhoto = pc.ValidationPhotos?.[pc.ValidationPhotos.length - 1];
    const exitPhoto = pc.ExitPhotos?.[pc.ExitPhotos.length - 1];

    const entryTime = entryPhoto?.CameraPhoto?.CaptureAt;
    const validationTime = validationPhoto?.CameraPhoto?.CaptureAt;
    const exitTime = exitPhoto?.CameraPhoto?.CaptureAt;

    const rootEntryPhoto = pc.EntryPhotos?.[0];
    const rootValidationPhoto = pc.ValidationPhotos?.[0];
    const rootExitPhoto = pc.ExitPhotos?.[0];
    return {
        entryPhoto,
        validationPhoto,
        exitPhoto,
        entryTime,
        validationTime,
        exitTime,
        rootEntryPhoto,
        rootValidationPhoto,
        rootExitPhoto,
    };
};

const EvidencePhotoGroup = (props: Props) => {
    const { pc, cancellationReasons, handleCancelPc } = props;
    const { Timezone, ExitPhotos, ValidationPhotos } = pc;
    const { groupPcId } = useParams();

    const {
        entryPhoto,
        exitPhoto,
        validationPhoto,
        entryTime,
        exitTime,
        validationTime,
        rootEntryPhoto,
        rootExitPhoto,
        rootValidationPhoto,
    } = pcImage(pc);

    const isMoreThan10Minutes = (from: Date | undefined, to: Date | undefined) => {
        const fromTime = moment(from);
        const toTime = moment(to);
        const duration = moment.duration(toTime.diff(fromTime));

        return duration.asMinutes() > 10;
    };

    const cameraExitPhotosId = ExitPhotos?.[0]?.CameraPhotoId;
    const cameraValidationPhotosId = ValidationPhotos?.[0]?.CameraPhotoId;

    const isCheckedDuplicateImageExitAndValidationPhoto = cameraExitPhotosId === cameraValidationPhotosId;
    const isCheckDurationExceeding5Minutes = isMoreThan10Minutes(entryTime, exitTime);

    const cancellationNotes = [
        {
            id: 1,
            label: 'Can be External Graphic',
        },
        {
            id: 2,
            label: 'The car can go around and around many times',
        },
        {
            id: 3,
            label: 'Maybe the car is not parked',
        },
    ];

    const [warningCheckedPcHasProperty, setWarningCheckedPcHasProperty] = useState(() => {
        const initialCheckedValues = {};
        cancellationNotes.forEach((checkbox) => {
            initialCheckedValues[checkbox.label] = false;
        });
        return initialCheckedValues;
    });

    const getCheckedLabels = () => {
        return cancellationNotes
            .filter((checkbox) => warningCheckedPcHasProperty[checkbox.label])
            .map((checkbox) => checkbox.label);
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setWarningCheckedPcHasProperty((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const durationTime = formatDuration(entryTime, exitTime);

    const entered = moment(entryTime).tz(Timezone).format('DD/MM/YYYY HH:mm:ss');
    const exited = moment(exitTime).tz(Timezone).format('DD/MM/YYYY HH:mm:ss');

    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState<HTMLImageElement>();

    const [selectedItem, setSelectedItem] = useState<PhotoItem>();

    const popUpEditImage = usePopUp();
    const popUpPlateEditing = usePopUp();
    const popUpConfirmCancelPcHasProperty = usePopUp();

    const handleCancelPCHasProperty = () => {
        const checkedLabelsString = getCheckedLabels().join(', ');
        const idReason = cancellationReasons.find((c) => c.Id === 5);

        const _props: ICancelGroupPotentialContraventionDetail = {
            CancellationNotes: checkedLabelsString,
            CancellationReasonId: idReason?.Id!,
            Id: pc.GroupPotentialContraventionId!,
        };
        handleCancelPc?.(_props);
        popUpConfirmCancelPcHasProperty.onClose?.();
    };

    const [open, setOpen] = useState(false);
    const stageRef = useRef<Konva.Stage>(null);

    const displayWidth = 1280;
    const displayHeight = 720;

    //Drag mode move positon
    const [scale, setScale] = useState(1);
    const [scaleZoomChangeCursor, setScaleZoomChangeCursor] = useState<number>(1);

    useEffect(() => {
        if (image) {
            const naturalWidth = image.naturalWidth;
            const naturalHeight = image.naturalHeight;
            const scaleX = displayWidth / naturalWidth;
            const scaleY = displayHeight / naturalHeight;
            const initialScale = Math.min(scaleX, scaleY);
            setScale(initialScale);
        }
    }, [image, displayWidth, displayHeight]);

    useEffect(() => {
        if (!open) return;
        if (!selectedItem?.blob) {
            setLoading(false);
            return;
        }
        const img = new window.Image();
        img.src = appConfig.gateway.blobURL + selectedItem?.blob;

        img.onload = () => {
            setImage(img);
            setLoading(false);
        };

        img.onerror = () => {
            setLoading(false);
        };
    }, [selectedItem?.blob, open]);

    // zoom len la 1 dinh luat cua 1 dãy số Fibonacci
    function handleTransform(e: any) {
        setScaleZoomChangeCursor(e.instance.transformState.scale);
    }
    return (
        <Stack
            direction={'column'}
            width={'100%'}
            sx={{
                boxShadow: props.selected && !props.readonly ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : undefined,
                transition: 'all  0.3s',
                ':hover': {
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                },
            }}
        >
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={1}
                sx={{
                    background: color.grey100,
                    borderRadius: '4px 4px 0px 0px',
                    padding: '12px 16px',
                    position: 'relative',
                    border: props.selected || props.readonly ? `1px solid #ddd` : `1px solid transparent`,
                    borderBottom: `1px solid #DDDDDD`,
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    onClick={() => props.onSelect?.(pc)}
                    flex={1}
                    sx={{ cursor: 'pointer', overflow: 'hidden', paddingRight: 4 }}
                >
                    {!props.readonly && <Radio checked={props.selected} />}
                    {/* <CameraIcon /> */}
                    <Typography variant="h5" sx={{ userSelect: 'none', whiteSpace: 'nowrap' }}>
                        Evidence photos
                    </Typography>

                    <Typography variant="h5" noWrap>{`(${pc.Camera?.Name})`}</Typography>
                </Stack>
                <Stack flex={1}>
                    <Typography
                        noWrap
                    >{`Entered: ${entered}  -  Exited: ${exited}  -  Duration: ${durationTime}`}</Typography>
                </Stack>
            </Stack>
            <Box
                sx={{
                    width: '100%',
                    p: '12px 16px',
                    border: `1px solid ${color.grey300}`,
                    borderTop: 'none',
                    borderRadius: '0 0 4px 4px',
                }}
            >
                {isCheckDurationExceeding5Minutes && isCheckedDuplicateImageExitAndValidationPhoto && (
                    <OrangeStack mb={2} direction={'column'} gap={1}>
                        <Stack direction={'column'} alignItems={'start'} gap={1}>
                            <Typography variant="body1">
                                We have determined that the vehicle is possibly in motion. Please review this case.
                            </Typography>
                            <Stack direction={'row'} spacing={1}>
                                <Typography variant="body1">
                                    Validation Time = Exit Time (only two evident photos)
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ display: 'flex' }}
                                    color={color.textPrimary}
                                />
                                <Typography variant="body1">{`Total duration > 10 minutes`}</Typography>
                            </Stack>
                            {!groupPcId && (
                                <Stack width={'100%'} bgcolor={'white'} p={2}>
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        width={'100%'}
                                    >
                                        <Typography variant="body1">
                                            The reason was determined to be Tech error. Choose to attach descriptions
                                            below:
                                        </Typography>
                                        <Button
                                            variant="errorOutlined"
                                            disabled={
                                                !Object.values(warningCheckedPcHasProperty).some(
                                                    (value) => value === true
                                                )
                                            }
                                            onClick={() => {
                                                popUpConfirmCancelPcHasProperty.setTrue();
                                            }}
                                        >
                                            Cancel this PC
                                        </Button>
                                    </Stack>

                                    <Stack direction={'row'} mt={1} spacing={1}>
                                        {cancellationNotes.map((cancelNotes, index) => (
                                            <FormGroup
                                                key={cancelNotes.id}
                                                sx={{
                                                    paddingLeft: '10px',
                                                    display: 'flex',
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <BaseCheckbox
                                                            name={cancelNotes.label}
                                                            checked={warningCheckedPcHasProperty[cancelNotes.label]}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    }
                                                    label={
                                                        <Typography ml={1} mt={'1px'}>
                                                            {cancelNotes.label}
                                                        </Typography>
                                                    }
                                                />
                                            </FormGroup>
                                        ))}
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </OrangeStack>
                )}

                <Stack direction={'row'} spacing={2}>
                    {[
                        {
                            title: 'Entry photo',
                            blob: entryPhoto?.CameraPhoto?.BlobName,
                            rootBlob: rootEntryPhoto?.CameraPhoto?.BlobName,
                            plateRootImage: entryPhoto?.BlobName,
                            timeZone: Timezone,
                            date: entryTime,
                            plate: pc.Plate,
                            photo: entryPhoto,
                            photoType: PhotoType.Entry,
                            photoVersion: entryPhoto?.CameraPhoto?.Version,
                            photoVehicleId: entryPhoto?.Id,
                        },
                        {
                            title: 'Contravention validation photo',
                            blob: validationPhoto?.CameraPhoto?.BlobName,
                            rootBlob: rootValidationPhoto?.CameraPhoto?.BlobName,
                            plateRootImage: validationPhoto?.BlobName,
                            timeZone: Timezone,
                            date: validationTime,
                            plate: pc.Plate,
                            photo: validationPhoto,
                            photoType: PhotoType.Validation,
                            photoVersion: validationPhoto?.CameraPhoto?.Version,
                            photoVehicleId: validationPhoto?.Id,
                        },
                        {
                            title: 'Exit photo',
                            blob: exitPhoto?.CameraPhoto?.BlobName,
                            rootBlob: rootExitPhoto?.CameraPhoto?.BlobName,
                            plateRootImage: exitPhoto?.BlobName,
                            timeZone: Timezone,
                            date: exitTime,
                            plate: pc.Plate,
                            photo: exitPhoto,
                            photoType: PhotoType.Exit,
                            photoVersion: exitPhoto?.CameraPhoto?.Version,
                            photoVehicleId: exitPhoto?.Id,
                        },
                    ].map((item, index) => (
                        <EvidencePhotoItem
                            key={item.title}
                            title={item.title}
                            blob={item.blob}
                            rootBlob={item.rootBlob}
                            timeZone={item.timeZone}
                            plateRootImage={item.plateRootImage}
                            VerificationAudit={props?.VerificationAudit}
                            date={item.date!}
                            plate={item.plate}
                            handleClick={(url) => {
                                setSelectedItem(item as any);
                                setOpen(true);
                            }} // Nếu bạn cần truyền callback handleClick vào đây.
                            handleEditImg={() => {
                                setSelectedItem(item as any);
                                popUpEditImage.setTrue();
                            }}
                            handlePlateEdit={() => {
                                setSelectedItem(item as any);
                                popUpPlateEditing.setTrue();
                            }}
                            photo={item.photo!}
                            readonly={props.readonly}
                        />
                    ))}
                </Stack>
            </Box>

            <PopUpWarning
                {...popUpConfirmCancelPcHasProperty}
                message="Are you sure you want to cancel this PC?"
                onConfirm={handleCancelPCHasProperty}
            />

            {selectedItem?.blob && (
                <>
                    <Dialog
                        open={!!open}
                        onClose={() => {
                            setOpen(false);
                            setSelectedItem(undefined);
                        }}
                        PaperProps={{
                            sx: {
                                minWidth: 1280,
                                minHeight: 720,
                            },
                        }}
                    >
                        <TransformWrapperComponent
                            handleTransform={handleTransform}
                            loading={loading}
                            displayWidth={displayWidth}
                            displayHeight={displayHeight}
                            scale={scale}
                            scaleZoomChangeCursor={scaleZoomChangeCursor}
                            image={image}
                            stageRef={stageRef}
                        />
                        <TimeOnPicture date={selectedItem?.date} timezone={selectedItem?.timeZone} />
                    </Dialog>

                    <PopUpEditImage {...popUpEditImage} selectedItem={selectedItem} />

                    <PopUpPlateEditing
                        {...popUpPlateEditing}
                        selectedItem={selectedItem}
                        exitPhoto={exitPhoto}
                        validationPhoto={validationPhoto}
                        entryPhoto={entryPhoto}
                    />
                </>
            )}
        </Stack>
    );
};

export default EvidencePhotoGroup;

export const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    const iconsAction = [
        { icon: <ZoomImageIcon isChecked={false} />, label: 'Zoom in', id: 1 },
        { icon: <RestartImageIcon isChecked={false} />, label: 'Reset', id: 2 },
        { icon: <MinimizeImageIcon isChecked={false} />, label: 'Zoom out', id: 3 },
    ];

    return (
        <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            sx={{
                position: 'absolute',
                bottom: '5%',
                right: '40%',
                zIndex: 2,
            }}
        >
            {iconsAction.map((icon, index) => (
                <Tooltip title={icon.label} arrow placement="top">
                    <IconButton
                        key={index}
                        sx={{ color: '#ddd' }}
                        onClick={() => {
                            if (icon.id === 1) {
                                zoomIn();
                            } else if (icon.id === 2) {
                                resetTransform();
                            } else if (icon.id === 3) {
                                zoomOut();
                            }
                        }}
                    >
                        {icon.icon}
                    </IconButton>
                </Tooltip>
            ))}
        </Stack>
    );
};

export const TransformWrapperComponent: React.FC<TransformWrapperComponentProps> = ({
    handleTransform,
    loading,
    displayWidth,
    displayHeight,
    scale,
    scaleZoomChangeCursor,
    image,
    stageRef,
    customTransformComponent,
}) => {
    return (
        <TransformWrapper
            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
            doubleClick={{ disabled: true }}
            wheel={{ disabled: false }}
            centerZoomedOut={true}
            onTransformed={(e) => handleTransform?.(e)}
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <Controls />
                    <TransformComponent>
                        {!customTransformComponent ? (
                            <Stack
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                {loading ? (
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        minHeight={700}
                                        minWidth={1280}
                                        color={'#E7E9ED'}
                                    >
                                        <CircularProgress sx={{ margin: 'auto' }} />
                                    </Stack>
                                ) : (
                                    <Stage
                                        ref={stageRef}
                                        width={displayWidth}
                                        height={displayHeight}
                                        scaleX={scale}
                                        scaleY={scale}
                                        style={{
                                            cursor: scaleZoomChangeCursor !== 1 ? 'grab' : 'default',
                                        }}
                                    >
                                        <Layer>
                                            <KonvaImage image={image} />
                                        </Layer>
                                    </Stage>
                                )}
                            </Stack>
                        ) : (
                            <>{customTransformComponent}</>
                        )}
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    );
};
