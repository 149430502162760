import { Box, CircularProgress, Fade, Grid, Grow, PaginationItem, Stack } from '@mui/material';
import color from '@theme/Colors';
import { ConfigProvider, Pagination } from 'antd';
import React from 'react';

type Props<T> = {
    list: T[];
    loading?: boolean;
    renderItem?(item: T): React.ReactElement;
    renderEmpty?(): React.ReactElement;
    count?: number;
    page?: number;
    pageSize?: number;
    onChangePage?(page: number, pageSize: number): void;
};

export default function BaseList<T>({ list, loading, renderItem, ...props }: Props<T>) {
    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <Stack>
                {loading ? (
                    <Stack direction={'row'} justifyContent="center">
                        <Grow in={loading}>
                            <CircularProgress />
                        </Grow>
                    </Stack>
                ) : !list.length ? (
                    <Fade in={true}>
                        <div>{props.renderEmpty?.() || <></>}</div>
                    </Fade>
                ) : (
                    <Stack alignItems="center">
                        <Grid xs={12} item container spacing={3} justifyContent="flex-start">
                            {list.map((item: T, index: number) => {
                                return (
                                    <Grow in={true} key={index} timeout={Math.min(500 * index, 1000)}>
                                        <Grid item md={3} sm={6} xs={12}>
                                            <Grow in={!!item} timeout={500}>
                                                <Box height={'100%'}>{renderItem ? renderItem?.(item) : <></>}</Box>
                                            </Grow>
                                        </Grid>
                                    </Grow>
                                );
                            })}
                        </Grid>

                        <Stack mt={5}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        // colorLinkActive: '#000 !important',
                                        borderRadius: 4,
                                        colorPrimary: color.grey600,
                                        colorPrimaryBg: '#E8F5E9',
                                        colorPrimaryActive: 'red',
                                        colorLinkActive: 'red',
                                        colorInfoTextActive: 'red',
                                        // coloractive
                                        // colorLinkActive: color.primary,
                                    },
                                }}
                            >
                                <Pagination
                                    // hideOnSinglePage
                                    pageSize={props.pageSize}
                                    defaultPageSize={20}
                                    current={props.page}
                                    total={props.count}
                                    showSizeChanger
                                    showQuickJumper
                                    onChange={(page, pageSize) => props.onChangePage?.(page, pageSize)}
                                    // showTotal={(total) => `Total ${total} items`}
                                />
                            </ConfigProvider>
                        </Stack>
                        {/* {props.count! > 1 && (
                            <Pagination
                                count={props.count}
                                page={props.page}
                                onChange={(e: any, value: any) => {
                                    props.onChangePage?.(value);
                                }}
                                shape="rounded"
                                sx={{
                                    mt: 5,
                                    '& .Mui-selected': {
                                        background: '#3479BB1A !important',
                                    },
                                    '& .MuiPaginationItem-previousNext': {
                                        background: '#EEEEEE',
                                    },
                                }}
                                renderItem={(item) => <PaginationItem {...item} />}
                            />
                        )} */}
                    </Stack>
                )}
            </Stack>
        </div>
    );
}
